import React, { useEffect, useState } from "react"
import "./LinkListScreen.scss"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import useLinkList from "../../state/customHooks/link/useLinkList"
import LinkCard from "../../components/linkscreens/LinkCard"
import Popup from "../../components/sellerscreens/Popup"
import { baseURLWeb } from "../../utils/base"
import Loader from "../../components/utils/Loader"
import { BasicPagination } from "../../components/utils/Pagination"
import { Button } from "@mui/material"
import { Link, useSearchParams } from "react-router-dom"

const LinkListScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryObject, setQueryObject] = useState({})

  const pageNumberQuery = searchParams.get("pageNumber")

  useEffect(() => {
    setQueryObject({ pageNumber: pageNumberQuery })
  }, [])

  useEffect(() => {
    setSearchParams({ ...queryObject })
  }, [queryObject])

  const [loading, , links, , pages] = useLinkList(queryObject)

  const [show, setShow] = useState(false)
  const [linkSelected, setLinkSelected] = useState({})
  const [pageNumber, setPageNumber] = useState({})

  const handleOpenModal = (link) => {
    setLinkSelected(link)
    setShow(true)
  }

  return (
    <div className="link-list-screen">
      <InnerHeader title="Links" className="inner-header">
        <Button className="btn-blue">
          <Link to="/link/create" className="link-w">
            Generate Link
          </Link>
        </Button>
      </InnerHeader>
      <InnerContainer>
        <div className="list-with-pagination">
          <div className="list">
            {loading ? (
              <Loader />
            ) : (
              links?.length &&
              links.map((link) => (
                <LinkCard link={link} onClick={() => handleOpenModal(link)} />
              ))
            )}
          </div>
          <div className="pagination">
            <BasicPagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pages={pages}
              queryObject={queryObject}
              setQueryObject={setQueryObject}
            />
          </div>
        </div>

        <Popup trigger={show} setTrigger={setShow} title="Link Detail">
          <div className="link-detail">
            <div className="flex label-wrapper">
              <p className="text-bold">Name: </p>
              <p>{linkSelected.name}</p>
            </div>

            <div className="flex label-wrapper">
              <p>URL: </p>
              <a
                href={`${baseURLWeb}/redirect?to=${linkSelected.slug}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                {baseURLWeb}redirect?to={linkSelected.slug}
              </a>
            </div>

            <div className="flex label-wrapper">
              <p>URL Redirect: </p>
              <a
                href={`${baseURLWeb}redirect?to=${linkSelected.slug?.slice(1)}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                {baseURLWeb}
                {linkSelected.url?.slice(1)}
              </a>
            </div>

            <div className="flex label-wrapper">
              <p>Title: </p>
              <p>{linkSelected.title}</p>
            </div>

            <div className="flex label-wrapper">
              <p>Description: </p>
              <p>{linkSelected.description}</p>
            </div>
            <div
              className="flex col
            label-wrapper"
            >
              <p>Image:</p>
              <img src={linkSelected.image} alt="" className="image" />
            </div>
          </div>
        </Popup>
      </InnerContainer>
    </div>
  )
}

export default LinkListScreen
