import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getBrandList,
  getBrandDetail,
  moveBrandPostion,
} from "../../../state/actions/brandActions"
import Message from "../../utils/Message"
import "./BrandList.scss"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import SortButtons from "./SortButtons"
import { useSearchParams } from "react-router-dom"

const BrandList = () => {
  const dispatch = useDispatch()
  const [brandId, setBrandId] = useState("")

  const [searchParams, setSearchParams] = useSearchParams()

  const brandQ = searchParams.get("brand")

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading, error, brands } = brandList

  const brandDetail = useSelector((state) => state.carCatalogue.brandDetail)
  const { loading: loadingBrandDetail, brand: oneBrand } = brandDetail

  const brandUpdate = useSelector((state) => state.carCatalogue.brandUpdate)
  const { success } = brandUpdate
  const brandCreate = useSelector((state) => state.carCatalogue.brandCreate)
  const { success: brandCreateSuccess } = brandCreate

  useEffect(() => {
    dispatch(getBrandList())
  }, [dispatch, success, brandCreateSuccess])

  useEffect(() => {
    if (!loadingBrandDetail && !oneBrand) {
      setBrandId("")
    }
    if (brandId) {
      dispatch(getBrandDetail(brandId))
    }
  }, [dispatch, brandId])

  useEffect(() => {
    if (!loadingBrandDetail && !oneBrand._id) {
      setBrandId("")
    }
    if (brandQ) {
      setBrandId(brandQ)
    }
  }, [oneBrand, brandQ])

  const movePosition = (moveTo) => {
    if (brandId) {
      dispatch(moveBrandPostion({ brandId, moveTo }))
    }
  }

  return (
    <div className="brand-lists">
      <div className="brand-lists-wrapper flex jcsb">
        <div className="brand-list flex col">
          <div className="inner-title">Popular</div>
          <div className="flex aic">
            <div className="brands-wrapper">
              {loading ? (
                <div className="">Loading</div>
              ) : error ? (
                <Message variant="error">{error}</Message>
              ) : (
                <>
                  {brands?.popularBrands.map((brand) => (
                    <p
                      value={brand._id}
                      className={
                        !brand.active && brand._id === brandId
                          ? "option active disabled"
                          : !brand.active
                          ? "option disabled"
                          : brand.active && brand._id === brandId
                          ? "option active"
                          : "option"
                      }
                      key={brand._id}
                      onClick={() => {
                        setBrandId(brand._id)
                        setSearchParams({
                          brand: brand._id,
                          pageNumber: 1,
                        })
                      }}
                    >
                      {brand.name}
                    </p>
                  ))}
                </>
              )}
            </div>
            <SortButtons
              type="popular"
              brands={brands?.popularBrands}
              brandId={brandId}
            />
          </div>
          <div className="sort-buttons sort-right">
            <div>
              <button onClick={() => movePosition("others")}>
                <ArrowForwardIosIcon color="primary" />
              </button>
            </div>
          </div>
        </div>
        <div className="brand-list flex col">
          <h5 className="inner-title">Others</h5>
          <div className="flex aic">
            <div className="brands-wrapper">
              {loading ? (
                <div className="">Loading</div>
              ) : error ? (
                <Message variant="error">{error}</Message>
              ) : (
                brands.otherBrands.map((brand, i) => (
                  <option
                    value={brand._id}
                    onClick={(e) => {
                      setBrandId(e.target.value)
                      setSearchParams({
                        brand: e.target.value,
                        pageNumber: 1,
                      })
                    }}
                    className={
                      !brand.active && brand._id === brandId
                        ? "option active disabled"
                        : !brand.active
                        ? "option disabled"
                        : brand.active && brand._id === brandId
                        ? "option active"
                        : "option"
                    }
                    key={brand._id}
                  >
                    {brand.name}
                  </option>
                ))
              )}
            </div>
            <SortButtons
              type="others"
              brands={brands?.otherBrands}
              brandId={brandId}
            />
          </div>
          <div className="sort-buttons sort-right">
            <div>
              <button onClick={() => movePosition("popular")}>
                <ArrowBackIosIcon color="primary" className="ml-3" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandList
