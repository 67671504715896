import React from "react"
import InnerHeader from "../../components/header/InnerHeader"
import UsersTabs from "../../components/userscreens/UsersTabs"
import "./UserListScreen.scss"

const UserListScreen = () => {
  return (
    <div className="user-list-screen">
      <InnerHeader title="Users" className="inner-header"></InnerHeader>
      <div className="body-container">
        <UsersTabs />
      </div>
    </div>
  )
}

export default UserListScreen
