import React, { useState } from "react"
import EditPencil from "../../images/common/EditPencil.svg"
import "./EditableLabelInputPopup.scss"
import TextPopup from "./TextPopup"

const EditableLabelInputPopup = ({
  value = "N/A",
  setValue,
  labelTitle,
  name,
  className,
}) => {
  const [show, setShow] = useState(false)

  return (
    <div className="label-popup flex">
      {labelTitle && <label className="label-title">{labelTitle}</label>}
      <div className="label-input-wrapper">
        <div className="hover">
          <div className="empty-d"></div>
          <img
            src={EditPencil}
            alt="icon"
            className="icon"
            onClick={() => {
              setShow(true)
            }}
          />
        </div>

        <p className={className ? className : "label-text"}>{value}</p>
        <TextPopup
          trigger={show}
          setTrigger={setShow}
          value={value}
          setValue={setValue}
          name={name}
        />
      </div>
    </div>
  )
}

export default EditableLabelInputPopup
