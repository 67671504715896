import React from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CloseIcon from "@mui/icons-material/Close"
import Drawer from "@mui/material/Drawer"
import "./MSideDrawer.scss"

export default function MSideDrawer(props) {
  return (
    <Drawer anchor={"right"} open={props.show}>
      <div className="drawer-container">
        <header className="drawer-header">
          <div className="drawer-header-col-1">
            <ArrowBackIcon
              className="back-icon"
              onClick={() => {
                props.toggleDrawer(false)
                props.onClose && props.onClose()
              }}
            />
            <p className="title">{props.title}</p>
          </div>
          <div className="drawer-header-col-2">
            {" "}
            <CloseIcon
              className="close-icon"
              onClick={() => {
                props.toggleDrawer(false)
                props.onClose && props.onClose()
              }} // Use an arrow function to pass a function reference
            />
          </div>
        </header>

        <section className="drawer-body">{props.children}</section>
      </div>
    </Drawer>
  )
}
