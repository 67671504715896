import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { AUCTION_CREATE_RESET } from "../../constants/auctionConstants"

const useAuctionCreate = () => {
  const auctionCreate = useSelector((state) => state.auction.auctionCreate)
  const { loading, error, success, auction } = auctionCreate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Auction Created Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: AUCTION_CREATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, auction]
}

export default useAuctionCreate
