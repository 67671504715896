import React from "react"
import "./EventCard.scss"

const EventCard = ({ event, i, setEvent, active }) => {
  return (
    <div
      className={active === true ? "event-card active-bg" : "event-card"}
      onClick={() => setEvent(event)}
    >
      <div className="r1">
        <div className="c1">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="c2">
          <p className="text">{i}</p>
        </div>
        <div className="c3">
          <p className="text">{event.title}</p>
        </div>
        <div className="c4">
          <p className="text">{event.pageUrl}</p>
        </div>
        <div className="c5">
          <p className="text">{event.backgroundColor}</p>
        </div>
      </div>
    </div>
  )
}

export default EventCard
