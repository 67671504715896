import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { YEAR_SPEC_UPDATE_RESET } from "../../constants/carDatabase"

const useYearSpecTempUpdate = () => {
  const yearSpecTemplateUpdate = useSelector(
    (state) => state.carDbTemplate.yearSpecTemplateUpdate
  )
  const { loading, error, success, year } = yearSpecTemplateUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Template Updated Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: RESPONSE_RESET })
        dispatch({ type: YEAR_SPEC_UPDATE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, year]
}

export default useYearSpecTempUpdate
