import React from "react"
import StatusChangePopup from "../carsmpscreens/StatusChangePopup"
import { Button } from "@mui/material"

const ConfirmDeletePopup = ({ trigger, title, onCancel, onConfirm }) => {
  return (
    <div>
      <StatusChangePopup trigger={trigger} title={title}>
        <div className="text">Are you sure you want to delete?</div>
        <div className="status-button-wrapper">
          <Button className="btn light" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="btn primary" onClick={onConfirm}>
            Delete
          </Button>
        </div>
      </StatusChangePopup>
    </div>
  )
}

export default ConfirmDeletePopup
