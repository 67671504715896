import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SALE_DETAIL_RESET } from "../../constants/saleConstants"
import { getSaleDetail } from "../../actions/saleAction"

const useSaleDetail = (id) => {
  const dispatch = useDispatch()

  const saleDetail = useSelector((state) => state.sale.saleDetail)
  const { loading, error, sale, cars } = saleDetail

  useEffect(() => {
    dispatch({ type: SALE_DETAIL_RESET })
    id && dispatch(getSaleDetail(id))
  }, [dispatch, id])

  return [loading, error, sale, cars]
}

export default useSaleDetail
