import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getModelList, getModelListReview } from "../../actions/modelActions"

export const useModelList = (brandId) => {
  const dispatch = useDispatch()

  useEffect(() => {
    brandId && dispatch(getModelList(brandId))

    // eslint-disable-next-line
  }, [dispatch, brandId])

  const modelList = useSelector((state) => state.carCatalogue.modelList)

  const { loading, error, models } = modelList

  return [loading, error, models]
}

export const useModelListReview = (brandId) => {
  const dispatch = useDispatch()

  useEffect(() => {
    brandId && dispatch(getModelListReview(brandId))

    // eslint-disable-next-line
  }, [dispatch, brandId])

  const modelListReview = useSelector(
    (state) => state.carCatalogue.modelListReview
  )

  const { loading, error, models } = modelListReview

  return [loading, error, models]
}
