import React, { useState, useEffect } from "react"
import axios from "../../utils/axios"
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material"
import { CustomButton } from "../utils/Button"

const RoleForm = ({ onClose, onRoleCreated, onRoleUpdated, role }) => {
  const [roleName, setRoleName] = useState("")
  const [permissions, setPermissions] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get("/v1/permissions")
        setPermissions(response.data)
      } catch (error) {
        console.error("Error fetching permissions:", error)
      }
    }

    fetchPermissions()
  }, [])

  useEffect(() => {
    if (role?._id) {
      setRoleName(role.name)
      setPermissions(role.permissions)
      const ids = role.permissions.map((permission) => permission._id)
      setSelectedPermissions(ids)
    }
  }, [role])

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value)
  }

  const handlePermissionChange = (e) => {
    setSelectedPermissions(e.target.value)
  }

  console.log(selectedPermissions)
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (role) {
        // Edit existing role
        const response = await axios.put(`/v1/roles/${role._id}`, {
          name: roleName,
          permissions: selectedPermissions,
        })
        onRoleUpdated(response.data)
      } else {
        // Create new role
        const response = await axios.post("/v1/roles", {
          name: roleName,
          permissions: selectedPermissions,
        })
        onRoleCreated(response.data)
      }
      onClose()
    } catch (error) {
      console.error("Error submitting role:", error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Role Name"
        value={roleName}
        onChange={handleRoleNameChange}
        fullWidth
        required
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Permissions</InputLabel>
        <Select
          multiple
          value={selectedPermissions}
          onChange={handlePermissionChange}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <div key={value}>{value}</div>
              ))}
            </div>
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                width: "200px", // Set your desired width here
              },
            },
          }}
        >
          {permissions.map((perm) => (
            <MenuItem key={perm._id} value={perm._id}>
              {perm.resource} - {perm.actions.join(", ")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomButton label={role ? "Edit" : "Create"} type="submit" drawer />
    </form>
  )
}

export default RoleForm
