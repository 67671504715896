import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateCar } from "../../../state/actions/carMpActions"
import "./CarDetailCard.scss"
import { Button } from "@mui/material"
import { numberWithCommas, transformComa } from "../../../utils/utilsFunctions"
import CustomSwitch from "../../../components/selection/Switch"
import StatusChangePopup from "../../../components/carsmpscreens/StatusChangePopup"
import EditableLabelInputPopup from "../../input/EditableLabelInputPopup"
import CarDetailEditable from "./CarDetailEditable"
import EditableLabelSelect from "../../input/EditableLabelSelect"

const CarDetailCard = ({ car, carOptions }) => {
  const [isPremium, setIsPremium] = useState()
  const [isRecommended, setIsRecommended] = useState()
  const [isRepossessed, setIsRepossessed] = useState()
  const [isCustomLicensePlate, setIsCustomLicensePlate] = useState()
  const [isCarmateContact, setIsCarmateContact] = useState()

  const [status, setStatus] = useState(car.status)
  const [statusPopup, setStatusPopup] = useState(false)
  const [description, setDescription] = useState(car.description)
  const dispatch = useDispatch()

  const updateCarPremium = () => {
    dispatch(updateCar(car._id, { isPremium: !isPremium }))
  }
  const updateCarRecommended = () => {
    dispatch(updateCar(car._id, { isRecommended: !isRecommended }))
  }
  const updateCarIsRepossessed = () => {
    dispatch(updateCar(car._id, { isRepossessed: !isRepossessed }))
  }
  const updateCarIsCustomLicensePlate = () => {
    dispatch(
      updateCar(car._id, { isCustomLicensePlate: !isCustomLicensePlate })
    )
  }
  const updateCarIsCarmateContact = () => {
    dispatch(updateCar(car._id, { isCarmateContact: !isCarmateContact }))
  }

  useEffect(() => {
    setIsCarmateContact(car.isCarmateContact)
    setIsCustomLicensePlate(car.isCustomLicensePlate)
    setIsRepossessed(car.isRepossessed)
    setDescription(car.description)
    setIsRecommended(car.isRecommended)
    setIsPremium(car.isPremium)
  }, [])

  return (
    <div className="car-detail-card flex jcsb">
      <div className="left-col flex">
        <div className="c1">
          <img src={car.images[0]} alt="car" className="car-image" />
        </div>

        <div className="c2">
          <CarDetailEditable car={car} />
          <div className="text-field flex aic">
            <span className="text-semibold">{`Created At: `}</span>
            <p className="text">
              {`${car.createdAt.substring(0, 10).split("-")[2]}/${
                car.createdAt.substring(0, 10).split("-")[1]
              }/${car.createdAt.substring(0, 10).split("-")[0]}`}
            </p>
          </div>
          <div className="text-field flex aic">
            <span className="text-semibold">{`Updated At: `}</span>
            <p className="text">
              {`${car.updatedAt.substring(0, 10).split("-")[2]}/${
                car.updatedAt.substring(0, 10).split("-")[1]
              }/${car.updatedAt.substring(0, 10).split("-")[0]}`}
            </p>
          </div>
          <div className="text-field flex aic"></div>
          <div className="text-field flex aic">
            <span className="text-semibold">Condition: </span>
            <EditableLabelSelect
              options={carOptions.CONDITIONS}
              value={car.condition}
              title="Condition"
            />
          </div>
          <div className="text-field flex aic">
            <span className="text-semibold">Tax Status: </span>
            <p className="text">{car.taxStatus}</p>
          </div>
        </div>
        <div className="car-detail c3">
          <div className="text-field flex">
            <span className="text-semibold">Mileage: </span>
            {car.mileage ? (
              <p className="text">
                {transformComa(car.mileage)}
                {car.mileageUnit}
              </p>
            ) : (
              <p className="text">&#8212;</p>
            )}
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Fuel: </span>

            <p className="text">{car.fuel || <>&#8212;</>}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Transmission: </span>
            <p className="text">{car.transmission || <>&#8212;</>}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Option: </span>
            <p className="text">{car.vehicleOption || <>&#8212;</>}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Color: </span>
            <p className="text">{car.color || <>&#8212;</>}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Drive Type: </span>
            <p className="text">{car.driveType || <>&#8212;</>}</p>
          </div>
        </div>
        <div className="car-detail c4">
          <div className="text-field flex">
            <span className="text-semibold">Cylinders: </span>
            <p className="text">{car.cylinders || <>&#8212;</>}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Engine Size: </span>
            <p className="text">
              {car.displacement ? `${car.displacement}L` : <>&#8212;</>}
            </p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Warranty: </span>
            <p className="text">
              {car.warranty === 0
                ? `No Warranty`
                : car.warranty < 30 && car.warranty !== 0
                ? `${car.warranty / 7} week`
                : car.warranty < 365 && car.warranty > 29
                ? `${car.warranty / 30} months`
                : `${car.warranty / 365} Years`}
            </p>
          </div>
          <EditableLabelInputPopup
            labelTitle="Description"
            value={description}
            setValue={setDescription}
          />
        </div>
        <div className="car-detail c5">
          <div className="text-field flex">
            <span className="text-semibold">Price: </span>
            <p className="text">
              {car.price && `$${numberWithCommas(car.price)}`}
            </p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Commission: </span>
            <p className="text">{car.commission || <>&#8212;</>}</p>
          </div>
        </div>
        <div className="car-detail c6">
          <div className="text-field flex">
            <span className="text-semibold">Dealership: </span>
            <p className="text blue">{car?.seller?.dealership?.name}</p>
          </div>
          <div className="text-field flex">
            <span className="text-semibold">Uploaded By: </span>
            <p className="text blue">{`${car?.seller?.firstName} ${car?.seller?.lastName}`}</p>
          </div>
        </div>
      </div>
      <div className="right-col flex">
        <div className="switch-r1">
          <div className="switch flex aic">
            <p className="text blue">{isCarmateContact ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsCarmateContact}
              checked={isCarmateContact}
              label="Show Carmate Contact"
              onChange={updateCarIsCarmateContact}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isCustomLicensePlate ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsCustomLicensePlate}
              checked={isCustomLicensePlate}
              label="Custom License Plate"
              onChange={updateCarIsCustomLicensePlate}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isRepossessed ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsRepossessed}
              checked={isRepossessed}
              label="Repossessed"
              onChange={updateCarIsRepossessed}
            />
          </div>
        </div>
        <div className="flex col">
          <div className="switch flex aic">
            <p className="text blue">{isPremium ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsPremium}
              checked={isPremium}
              label="Premium"
              onChange={updateCarPremium}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isRecommended ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsRecommended}
              checked={isRecommended}
              label="Recommended"
              onChange={updateCarRecommended}
            />
          </div>
        </div>

        <div className="status">
          <p className="text blue" onClick={() => setStatusPopup(true)}>
            Change Status
          </p>
          <StatusChangePopup trigger={statusPopup} title="Change Status">
            <select
              className="status-select"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <option value="PENDING">PENDING</option>
              <option value="LISTED">LISTED</option>
              <option value="SOLD">SOLD</option>
              <option value="ARCHIVE">ARCHIVE</option>
            </select>
            <div className="status-button-wrapper">
              <Button
                className="btn light"
                onClick={() => {
                  setStatusPopup(false)
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn primary"
                onClick={() => {
                  setStatusPopup(false)
                  dispatch(updateCar(car._id, { status }))
                }}
              >
                Save
              </Button>
            </div>
          </StatusChangePopup>
        </div>
      </div>
    </div>
  )
}

export default CarDetailCard
