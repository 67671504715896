import React from "react"
import "./Popup.scss"
import closeicon from "../../images/dealership/closeicon.svg"

const Popup = (props) => {
  const closeHandler = () => {
    props.setTrigger(false)
    props.resetOption && props.resetOption(false)
  }

  return props.trigger ? (
    <div className="popup">
      <div
        className={
          props.className ? `popup-inner ${props.className}` : `popup-inner`
        }
      >
        <div className="popup-header flex jcsb">
          <div className="title">{props.title}</div>
          <div onClick={closeHandler} className="close-button flex jcc aic">
            <img src={closeicon} alt="close-icon" className="close-icon" />
          </div>
        </div>

        <div className="popup-children">{props.children}</div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default Popup
