import {
  CAR_AUCTION_LIST_FAIL,
  CAR_AUCTION_LIST_REQUEST,
  CAR_AUCTION_LIST_SUCCESS,
  CAR_CREATE_FAIL,
  CAR_CREATE_REQUEST,
  CAR_CREATE_SUCCESS,
  CAR_DELETE_FAIL,
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
  CAR_DETAIL_FAIL,
  CAR_DETAIL_REQUEST,
  CAR_DETAIL_SUCCESS,
  CAR_FILTER_OPTION_FAIL,
  CAR_FILTER_OPTION_REQUEST,
  CAR_FILTER_OPTION_SUCCESS,
  CAR_IMAGE_UPDATE_FAIL,
  CAR_IMAGE_UPDATE_REQUEST,
  CAR_IMAGE_UPDATE_SUCCESS,
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  CAR_OPPORTUNITY_LIST_FAIL,
  CAR_OPPORTUNITY_LIST_REQUEST,
  CAR_OPPORTUNITY_LIST_SUCCESS,
  CAR_UPDATE_FAIL,
  CAR_UPDATE_REQUEST,
  CAR_UPDATE_SUCCESS,
} from "../constants/carMPConstants"
import Axios from "../../utils/axios"
import { generateQueryURL } from "../../utils/utilsFunctions"

export const getCarList = (queryObject) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_LIST_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(
      `/v1/cars?${generateQueryURL(queryObject)}`,
      config
    )
    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCar = (id, car) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_UPDATE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.put(`/v1/cars/${id}`, car, config)
    dispatch({
      type: CAR_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFilterOptions = () => async (dispatch) => {
  try {
    dispatch({
      type: CAR_FILTER_OPTION_REQUEST,
    })

    const { data } = await Axios.get(`/v1/cars/details/all`)
    dispatch({
      type: CAR_FILTER_OPTION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_FILTER_OPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_DETAIL_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/cars/${id}?platform=admin`, config)
    dispatch({
      type: CAR_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const deleteCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/cars/${id}`, config)
    dispatch({
      type: CAR_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: CAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCarImages = (id, images) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_IMAGE_UPDATE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.put(`/v1/cars/${id}/images`, images, config)
    dispatch({
      type: CAR_IMAGE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_IMAGE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarOpportunityList = (carId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_OPPORTUNITY_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.get(`/v1/cars/${carId}/opportunities`, config)
    dispatch({
      type: CAR_OPPORTUNITY_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_OPPORTUNITY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createCar = (car) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_CREATE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.post(`/v1/cars/`, car, config)
    dispatch({
      type: CAR_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Auction Actions

export const getCarAuctionList = (activeTab) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_AUCTION_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.get(
      `/v1/cars/auctions?status=${activeTab}&platform=Admin`,
      config
    )
    dispatch({
      type: CAR_AUCTION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_AUCTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarListKeyword = (keyword) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_LIST_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(
      `/v1/cars/keyword?keyword=${keyword}`,
      config
    )
    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
