import React, { useState, useEffect, Suspense } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import CloseIcon from "@mui/icons-material/Close"
import Drawer from "@mui/material/Drawer"
import Brand from "../Forms/Brand"
import Model from "../Forms/ModelForm"
import { ModelDetailForm } from "../Forms/ModelDetailForm"
import YearForm from "../Forms/YearForm"
import GenerationForm from "../Forms/GenerationForm"
import { TrimForm } from "../Forms/TrimForm"
import YearsRangeForm from "../Forms/YearsRangeForm"
import "./SideBarDrawer.scss"

export default function SideBarDrawer({
  show = false,
  toggleDrawer,
  title = "",
  type = "",
  data = {} || [],
  setReload = false,
}) {
  const [lastPage, setLastPage] = useState("")
  const [nextPage, setNextPage] = useState("")
  const [selected, setSelected] = useState("")
  const [propsData, setPropsData] = useState({})
  const [trim, setTrim] = useState({})
  const [year, setYear] = useState({})

  useEffect(() => {
    setLastPage("")
    setNextPage("")
    setSelected(0)
  }, [show])

  const goNext = (next) => {
    setLastPage(type)
    setNextPage(next)
  }
  const goBack = (prev) => {
    setLastPage(prev)
    setNextPage("")
  }

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={"right"} open={show} onClose={toggleDrawer(false)}>
          <div className="drawer-container">
            <header className="drawer-header">
              <div className="drawer-header-col-1">
                <ArrowBackIcon
                  className="back-icon"
                  onClick={() => {
                    goBack("year")
                    setTrim("")
                    setYear("")
                  }}
                />
                <span className="title">{title}</span>
              </div>
              <div className="drawer-header-col-2">
                <CloseIcon
                  className="close-icon"
                  onClick={toggleDrawer(false)}
                />
              </div>
            </header>
            <section className="drawer-body">
              {lastPage !== "" ? (
                nextPage === "add-years" ? (
                  <YearsRangeForm data={data} propsData={propsData} />
                ) : nextPage === "add-year" ? (
                  <YearForm data={data} propsData={propsData} />
                ) : nextPage === "add-generation" ? (
                  <GenerationForm
                    data={data}
                    propsData={propsData}
                    setReload={setReload}
                  />
                ) : nextPage === "add-trim" ? (
                  <TrimForm
                    data={data}
                    propsData={propsData}
                    trim={trim}
                    year={year}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {nextPage === "" ? (
                type === "brand" ? (
                  <Brand />
                ) : type === "model" ? (
                  <Model />
                ) : type === "year" ? (
                  <Suspense>
                    <ModelDetailForm
                      setPropsData={setPropsData}
                      data={data}
                      goNext={goNext}
                      type={type}
                      setSelected={setSelected}
                      selected={selected}
                      setReload={setReload}
                      setTrim={setTrim}
                      setYear={setYear}
                    />
                  </Suspense>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </section>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  )
}
