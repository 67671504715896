import * as React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@mui/material/styles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import "./CarDetailTabs.scss"
import TasksTab from "./TasksTab"
import PicturesTab from "./PicturesTab"
import VinReportTab from "./VinReportTab"
import CarDetailManaged from "./CarDetailManaged"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const CarDetailTabs = ({ car }) => {
  const theme = useTheme()

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className="car-detail-tabs">
      <AppBar position="static" color="pale" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#1C86B8",
            },
          }}
          textColor="inherit"
          aria-label="full width tabs example"
          className="tabs"
        >
          <Tab label="Tasks" {...a11yProps(0)} />
          <Tab label="Photos" {...a11yProps(1)} />
          <Tab label="Commission" {...a11yProps(2)} />
          <Tab label="VIN Report" {...a11yProps(3)} />
          <Tab label="Documents" {...a11yProps(4)} />
          <Tab label="Managed" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <TasksTab car={car} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <PicturesTab car={car} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <VinReportTab car={car} />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          <CarDetailManaged car={car} />
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default CarDetailTabs
