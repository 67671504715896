import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { SELLER_UPDATE_RESET } from "../../constants/sellerContants"

const useSellerUpdate = () => {
  const sellerUpdate = useSelector((state) => state.seller.sellerUpdate)

  const dispatch = useDispatch()

  const {
    loading: loadingSellerUpdate,
    success: successSellerUpdate,
    error: errorSellerUpdate,
    seller: updatedSeller,
  } = sellerUpdate

  useEffect(() => {
    if (successSellerUpdate) {
      dispatch(setMessage("Seller Updated Successfully"))
      setTimeout(() => {
        dispatch({ type: SELLER_UPDATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
    if (errorSellerUpdate) {
      dispatch(setMessage(errorSellerUpdate))
      setTimeout(() => {
        dispatch({ type: SELLER_UPDATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successSellerUpdate, errorSellerUpdate])

  return [
    loadingSellerUpdate,
    errorSellerUpdate,
    successSellerUpdate,
    updatedSeller,
  ]
}

export default useSellerUpdate
