import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import { getBrandList } from "../../state/actions/brandActions"
import { getModelList } from "../../state/actions/modelActions"
import { getSellerList } from "../../state/actions/sellerActions"
import { SELLER_LIST_RESET } from "../../state/constants/sellerContants"
import "./CarCreateEditScreen.scss"
import CustomSwitch from "../../components/selection/Switch"
import _ from "lodash"
import {
  getFilterOptions,
  updateCar,
  createCar,
} from "../../state/actions/carMpActions"
import CarPictures from "../../components/carsmpscreens/CarPictures"
import { useNavigate, useSearchParams, useParams, Link } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import { useSellerSpecificListKeyword } from "../../state/customHooks/seller/useSellerList"
import { SELLER_SPECIFIC_LIST_RESET } from "../../state/constants/sellerSpecificConstants"
import { useTrimListKeyword } from "../../state/customHooks/carct/useTrimList"
import TextInputWithDropdown from "../../components/input/TextInputWithDropdown"
import { TRIM_LIST_RESET } from "../../state/constants/trimConstants"
import TextInput from "../../components/input/TextInput"
import Select from "../../components/input/Select"
import { getCarDetail } from "../../state/actions/carMpActions"
import useCarDetail from "../../state/customHooks/carmp/useCarDetail"

import useCarCreate from "../../state/customHooks/carmp/useCarCreate"
import Loader from "../../components/utils/Loader"
import Message from "../../components/utils/Message"
import RadioButtonsGroup from "../../components/common/RadioGroup"
import { currencyWithCommas } from "../../utils/utilsFunctions"
import RichText from "../../components/input/RichTextInput"
import useCarUpdate from "../../state/customHooks/carmp/useCarUpdate"
import { useUserList } from "../../state/customHooks/user/useUserList"
import useAuctionList from "../../state/customHooks/auction/useAuctionList"
import CustomPopup from "../../components/utils/CustomPopup"
import ImageWithOrientation from "../../components/utils/ImageWithOrientation"
import {
  convertTimeToOptionString,
  extractDateValue,
} from "../../utils/saleUtilsFuncs"

const CarCreateEditScreen = ({ uploadHandler }) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { id: carId } = useParams()

  useEffect(() => {
    carId && dispatch(getCarDetail(carId))
  }, [carId])

  const carUpdateDetailHandler = () => {
    if (!carId) {
      dispatch(createCar(carUpdate))
    } else {
      dispatch(updateCar(carId, carUpdate))
      setIsImageLoaded(false)
    }
  }
  const carUpdateDetailWithExitHandler = () => {
    dispatch(updateCar(carId, carUpdate))
    setIsImageLoaded(false)
    navigate(-1)
  }

  const [searchParams] = useSearchParams()

  const carType = searchParams.get("type")

  const [carUpdate, setCarUpdate] = useState({})

  console.log(carUpdate)

  const [seller, setSeller] = useState("")
  const [branch, setBranch] = useState("")
  const [sellerName, setSellerName] = useState("")
  const [branchName, setBranchName] = useState("")

  const [trim, setTrim] = useState("")
  const [trimName, setTrimName] = useState("")
  const [images, setImages] = useState([])
  const [imagesOriginal, setImagesOriginal] = useState([])
  const [optionsSelected, setOptionsSelected] = useState([])
  const [isAuction, setIsAuction] = useState(false)
  const [onSale, setOnSale] = useState(false)
  const [isFeatured, setIsFeatured] = useState(false)

  const [saleMode, setSaleMode] = useState("")
  const [salePercentage, setSalePercentage] = useState("")
  const [saleAmount, setSaleAmount] = useState("")
  const [isRichText, setIsRichText] = useState(false)
  const [richText, setRichText] = useState("")

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const [openModal, setOpenModal] = useState("")
  const [image, setImage] = useState("")

  const [selectedDateStart, setSelectedDateStart] = useState("")
  const [selectedDateEnd, setSelectedDateEnd] = useState("")
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")

  const [isLimitedTime, setIsLimitedTime] = useState("")

  const sellerList = useSelector((state) => state.seller.sellerList)
  const { sellers } = sellerList

  const [users] = useUserList(seller._id)

  const usersSelect = users?.map((user) => ({
    value: user._id,
    name: user.username,
  }))

  const [branchesList] = useSellerSpecificListKeyword(
    carType === "officialBrands" ? "branches" : carType,
    seller?._id,
    branchName
  )

  const [, , auctions] = useAuctionList()

  const [, , successCarUpdate, carUpdatedOne] = useCarUpdate()
  const [loadingCarDetail, errorCarDetail, car] = useCarDetail(
    carId,
    successCarUpdate
  )

  const [loadingCarCreate, , ,] = useCarCreate()

  useEffect(() => {
    if (loadingCarCreate) {
      navigate(-1)
    }
  }, [loadingCarCreate])

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { brands } = brandList

  const modelList = useSelector((state) => state.carCatalogue.modelList)
  const { models } = modelList

  const [trimsList] = useTrimListKeyword(
    carUpdate.model,
    carUpdate.otherYear || carUpdate?.year?._id,
    trimName
  )

  const carOptions = useSelector((state) => state.carMarketplace.carOptions)
  const { options } = carOptions

  const yearsList = _.range(new Date().getFullYear(), 1989).map((year) => ({
    value: year.toString(),
    name: year.toString(),
  }))

  const [timeOutId, setTimeOutId] = useState("")

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      //this delay is called debounce as we call it after
      setTimeout(() => {
        if (sellerName) {
          dispatch(getSellerList(carType, "", "ACTIVE", sellerName, "", []))
        }
      }, 1000)
    )
  }, [dispatch, sellerName])

  useEffect(() => {
    dispatch({ type: SELLER_LIST_RESET })
    dispatch(getBrandList("", "ADMIN", "TRUE"))
    dispatch(getFilterOptions())
  }, [dispatch])

  useEffect(() => {
    if (carUpdate?.brand) {
      dispatch(getModelList(carUpdate.brand, "", "ACTIVE"))
    }
  }, [carUpdate?.brand])

  const VEHICLE_OPTIONS = []
  options?.VEHICLE_OPTIONS?.map((option) =>
    VEHICLE_OPTIONS.push({ value: option, name: option })
  )

  const TRANSMISSIONS = []
  options?.TRANSMISSIONS?.map((v) => TRANSMISSIONS.push({ value: v, name: v }))

  const FUELS = []
  options?.FUELS?.map((fuel) => FUELS.push({ value: fuel, name: fuel }))

  const COLORS = []
  options?.COLORS?.map((colors) => COLORS.push({ value: colors, name: colors }))

  const TAX_STATUS = []
  options?.TAX_STATUS?.map((taxStatus) =>
    TAX_STATUS.push({ value: taxStatus, name: taxStatus })
  )

  const MILEAGE_UNITS = []
  options?.MILEAGE_UNITS?.map((mileageUnit) =>
    MILEAGE_UNITS.push({ value: mileageUnit, name: mileageUnit })
  )

  const WARRANTYS = []
  options?.WARRANTY?.map((warranty) => WARRANTYS.push(warranty))

  const DISPLACEMENT = []
  options?.DISPLACEMENT?.map((engineSize) =>
    DISPLACEMENT.push({ value: engineSize, name: engineSize })
  )

  const CYLINDERS = []
  options?.CYLINDERS?.map((cylinders) =>
    CYLINDERS.push({ value: cylinders, name: cylinders })
  )

  const DRIVE_TYPES = []
  options?.DRIVE_TYPES?.map((driveType) =>
    DRIVE_TYPES.push({ value: driveType, name: driveType })
  )

  const CONDITIONS = []
  options?.CONDITIONS?.map((Condition) =>
    CONDITIONS.push({ value: Condition, name: Condition })
  )
  const auctionsNameInter = []
  auctions.map((auction) =>
    auctionsNameInter.push({
      ...auction,
      name: `${auction.name} ${auction.location.city}`,
    })
  )

  const popularBrands = []
  brands?.popularBrands?.map((brand) =>
    popularBrands.push({ value: brand, name: brand })
  )

  const Models = []
  options?.Models?.map((model) => Models.push({ value: model, name: model }))

  const yearList = []
  options?.yearList?.map((year) => yearList.push({ value: year, name: year }))

  const handleSellerSelect = (seller) => {
    setSeller({
      _id: seller._id,
      name: carType === "individuals" ? seller.username : seller?.name,
      firstName: seller?.firstName || null,
      lastName: seller?.lastName || null,
      _primaryUser: seller?._primaryUser?._id,
    })
    setSellerName("")
    dispatch({ type: SELLER_LIST_RESET })

    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      seller: seller._primaryUser._id,
      _sellerDealership: carType === "dealerships" ? seller._id : "",
      _sellerCompany: carType === "companies" ? seller._id : "",
      _sellerOfficialBrand: carType === "officialBrands" ? seller._id : "",
      _sellerBranch: carType === "officialBrands" ? branch._id : "",
      _sellerIndividual: carType === "individuals" ? seller._id : "",
    }))
  }

  const sellerChangeHandler = (e) => {
    setSeller({})
    setSellerName(e.target.value)
  }

  const trimSelectHandler = (option) => {
    setTrim({
      _id: option._id,
      name: option.name,
    })
    setTrimName("")

    dispatch({ type: TRIM_LIST_RESET })
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      otherTrim: "",
      trim: option._id,
    }))
  }

  const carUpdateHandler = (e) => {
    const { name, value, type, checked } = e.target
    const inputValue = type === "checkbox" ? checked : value

    if (name === "warranty" && inputValue === "No Warranty") {
      setCarUpdate((prevCarUpdate) => ({
        ...prevCarUpdate,
        warranty: 0,
      }))
    } else {
      setCarUpdate((prevCarUpdate) => ({
        ...prevCarUpdate,
        [name]: inputValue,
      }))
    }
  }

  const updateCarAuction = (e) => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      isAuction: e.target.checked,
    }))
  }
  const updateCarOnSale = (e) => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      isSale: e.target.checked,
    }))
  }
  const updateCarIsFeatured = (e) => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      isFeatured: e.target.checked,
    }))
  }

  const updateCarFixedSale = (e) => {
    let saleModeTemp = e.target.value
    setCarUpdate((prevCarUpdate) => {
      setSaleMode(saleModeTemp)
      return {
        ...prevCarUpdate,
        salePercentage: saleModeTemp === "percentage" ? salePercentage : "",
        saleAmount: saleModeTemp === "amount" ? saleAmount : "",
      }
    })
  }

  const updateRichDesc = (richDesc) => {
    setCarUpdate((prevCarUpdate) => {
      return {
        ...prevCarUpdate,
        richDescription: richDesc,
      }
    })
  }

  useEffect(() => {
    if (!loadingCarDetail && carId === car?._id) {
      const updatedCar = {
        ...car,
        brand: car?.brand?._id || "",
        model: car?.model?._id || "",
        year: car?.year?._id || "",
        yearName: car?.year?.name || "",
        _auction: car?._auction?._id,
        engineSize: car?.displacement || "",
        option: car?.vehicleOption || "",
      }
      setTrim(carUpdatedOne?.trim)

      setSeller(
        car?._sellerCompany ||
          car?._sellerDealership ||
          car?._sellerOfficialBrand || {
            _id: car._sellerIndividual._id,
            name:
              carType === "individuals"
                ? car.seller.username
                : car.seller?.name,
            firstName: car.seller?.firstName || null,
            lastName: car.seller?.lastName || null,
            _primaryUser: car.seller._id,
          }
      )
      updatedCar.richDescription && setIsRichText(true)
      setRichText(updatedCar.richDescription)
      setCarUpdate(updatedCar)
      setIsAuction(updatedCar.isAuction)
      setOnSale(updatedCar.isSale)
      setIsFeatured(updatedCar.isFeatured)
      setSalePercentage(updatedCar.salePercentage)
      setSaleAmount(updatedCar.saleAmount)
      setSelectedStartTime(
        convertTimeToOptionString(updatedCar?._sale?.startDate)
      )
      setSelectedEndTime(convertTimeToOptionString(updatedCar._sale?.endDate))
      setSelectedDateStart(extractDateValue(updatedCar?._sale?.startDate))
      setSelectedDateEnd(extractDateValue(updatedCar?._sale?.endDate))
      setIsLimitedTime(updatedCar.isLimitedTime)
      setOptionsSelected(updatedCar.options)
      successCarUpdate
        ? setImages(() => {
            setIsImageLoaded(true)
            setImagesOriginal(carUpdatedOne.imagesOriginal)
            return carUpdatedOne.images
          })
        : setImages(() => {
            setIsImageLoaded(true)
            setImagesOriginal(updatedCar.imagesOriginal)
            return updatedCar.images
          })

      setSaleMode(updatedCar.salePercentage ? "percentage" : "amount")
    }
  }, [loadingCarDetail, successCarUpdate, car])

  useEffect(() => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      images,
      imagesOriginal,
    }))
  }, [images, imagesOriginal])

  useEffect(() => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      options: optionsSelected,
    }))
  }, [optionsSelected])
  useEffect(() => {
    setCarUpdate((prevCarUpdate) => ({
      ...prevCarUpdate,
      options: optionsSelected,
    }))
  }, [optionsSelected])

  const viewImageHandler = (imageId) => {
    setOpenModal(true)
    setImage(imageId)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setImage("")
  }

  const updateIsLimitedTime = (e) => {
    setCarUpdate((prev) => {
      return {
        ...prev,
        isLimitedTime: !isLimitedTime,
      }
    })
  }
  const updatePromotionTitle = (e) => {
    setCarUpdate((prev) => {
      return {
        ...prev,
        promotionTitle: e.target.value,
      }
    })
  }

  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event.target.value)
  }
  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event.target.value)
  }

  const handleStartTimeChange = (event) => {
    setSelectedStartTime(event.target.value)
  }

  const handleEndTimeChange = (event) => {
    setSelectedEndTime(event.target.value)
  }

  useEffect(() => {
    setCarUpdate((prev) => {
      // Concatenate the date and time strings
      const startDateTimeStr = `${selectedDateStart}T${selectedStartTime}`
      const endDateTimeStr = `${selectedDateEnd}T${selectedEndTime}`

      // Create Date objects from the concatenated strings
      const startDate = new Date(startDateTimeStr)
      const endDate = new Date(endDateTimeStr)
      return { ...prev, startDate, endDate }
    })
  }, [selectedDateStart, selectedDateEnd, selectedStartTime, selectedEndTime])

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }
    return options
  }

  const generateEndTimeOptions = () => {
    const options = []

    const [startHour, startMinute] = selectedStartTime?.split(":")?.map(Number)
    for (let hour = 0; hour < 24; hour++) {
      const minuteStart =
        hour === startHour ? Math.ceil(startMinute / 30) * 30 : 0
      for (let minute = minuteStart; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }

    return options
  }

  return (
    <div className="car-create-screen">
      <InnerHeader
        title={carId ? "Edit Car" : "Create Car"}
        className="inner-header"
      >
        <div className="buttons-wrapper">
          {carId && (
            <div
              className="btn-save exit"
              onClick={carUpdateDetailWithExitHandler}
            >
              <p>Save and Exit</p>
            </div>
          )}

          <div className="btn-save" onClick={carUpdateDetailHandler}>
            {loadingCarCreate ? (
              <CircularProgress color="inherit" size={15} />
            ) : carId ? (
              `Update`
            ) : (
              `Save`
            )}
          </div>
        </div>
      </InnerHeader>

      {carId && loadingCarDetail ? (
        <Loader />
      ) : errorCarDetail ? (
        <Message variant="error">{errorCarDetail}</Message>
      ) : (
        <InnerContainer>
          <form onChange={carUpdateHandler}>
            <div className="section grid-4">
              <div className="input-with-dropdown">
                <div className="input-wrapper">
                  {carType === "dealerships" ? (
                    <label className="label">Dealership Name</label>
                  ) : carType === "companies" ? (
                    <label className="label">Company Name</label>
                  ) : carType === "officialBrands" ? (
                    <label className="label">Official Brand</label>
                  ) : carType === "individuals" ? (
                    <label className="label">Individual</label>
                  ) : null}

                  <input
                    type="text"
                    className="input"
                    value={seller.name || seller.username}
                    onChange={(e) => {
                      !seller.name
                        ? setSellerName(e.target.value)
                        : sellerChangeHandler(e)
                    }}
                  />
                </div>

                {sellers?.length > 0 && (
                  <div className="dropdown-dealership">
                    {sellers?.map((seller) => (
                      <div
                        className="dealership-card"
                        key={seller._id}
                        onClick={() => handleSellerSelect(seller)}
                      >
                        <p className="text">{seller.name}</p>
                      </div>
                    ))}
                  </div>
                )}
                {carType === "individuals" && sellers?.length > 0 && (
                  <div className="dropdown-dealership">
                    {sellers?.map((seller) => (
                      <div
                        className="dealership-card"
                        key={seller._id}
                        onClick={() => handleSellerSelect(seller)}
                      >
                        <p className="text">{seller.username}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {carType === "officialBrands" && (
                <div className="input-with-dropdown">
                  <div className="input-wrapper">
                    <label className="label">Branch Name</label>
                    <input
                      type="text"
                      className="input"
                      value={branch.name}
                      disabled={!seller._id}
                      onChange={(e) => {
                        setBranchName(e.target.value)
                      }}
                    />
                  </div>
                  {branchesList?.length > 0 && (
                    <div className="dropdown-dealership">
                      {branchesList?.map((seller) => (
                        <div
                          className="dealership-card"
                          key={seller._id}
                          onClick={() => {
                            setBranch({
                              _id: seller._id,
                              name: seller.name,
                              _primaryUser: seller._primaryUser._id,
                            })
                            setSellerName("")
                            dispatch({ type: SELLER_SPECIFIC_LIST_RESET })
                          }}
                        >
                          <p className="text">{seller.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {carType === "dealerships" && (
                <div className="input-wrapper">
                  <Select
                    value={carUpdate?.seller?._id}
                    label={"User"}
                    options={usersSelect}
                    name="seller"
                    disabled={!seller}
                  />
                </div>
              )}
              {carType === "individuals" && seller && (
                <div className="label-wrapper">
                  <Link
                    to={`/seller/individuals/${seller._id}/show`}
                    className="link"
                    target="_blank"
                  >
                    {seller.firstName || seller.lastName
                      ? `${seller.firstName} ${seller.lastName}`
                      : `No Name`}
                  </Link>
                </div>
              )}
            </div>
            <div className="section">
              <p className="section-title">Car Information</p>
              <div className="r grid-4">
                <div className="input-wrapper">
                  <Select
                    value={carUpdate?.brand}
                    label={"Brand"}
                    options={
                      brands?.popularBrands && brands?.otherBrands
                        ? brands?.popularBrands.concat(brands?.otherBrands)
                        : []
                    }
                    name="brand"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.model}
                    label={"Models"}
                    options={models}
                    name="model"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.otherYear || carUpdate?.yearName}
                    label={"Year"}
                    options={yearsList}
                    name="otherYear"
                  />
                </div>
                <TextInputWithDropdown
                  label="Trim"
                  value={carUpdate?.otherTrim || trim?.name}
                  name="otherTrim"
                  onChange={setTrimName}
                  options={trimsList}
                  onSelect={trimSelectHandler}
                />
              </div>

              <div className="r grid-4">
                <div className="input-wrapper">
                  <TextInput
                    value={carUpdate.note}
                    label={"Note"}
                    name="note"
                  />
                </div>
              </div>

              <div className="r grid-4">
                <div className="input-wrapper">
                  <Select
                    value={carUpdate.condition}
                    label="Condition"
                    options={CONDITIONS}
                    name="condition"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.transmission}
                    label={"Transmission Type"}
                    options={TRANSMISSIONS}
                    name="transmission"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.fuel}
                    label={"Fuel"}
                    options={FUELS}
                    name="fuel"
                  />
                </div>

                <Select
                  value={carUpdate.color}
                  label={"Colors"}
                  options={COLORS}
                  name="color"
                />
              </div>

              <div className="r grid-4">
                {/* Options */}

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.vehicleOption}
                    label={"Option"}
                    options={VEHICLE_OPTIONS}
                    name="vehicleOption"
                  />
                </div>

                <div className="input-wrapper">
                  <TextInput
                    value={carUpdate.otherOption}
                    label="otherOption"
                    name="otherOption"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.taxStatus}
                    label={"Tax Status"}
                    options={TAX_STATUS}
                    name="taxStatus"
                  />
                </div>

                {/* Mileage */}

                <div>
                  <div className="flex aic">
                    <div
                      className="input-wrapper"
                      style={{ width: "100%", marginRight: "8px" }}
                    >
                      <TextInput
                        value={carUpdate.mileage}
                        label={"Mileage"}
                        name="mileage"
                        type="number"
                      />
                    </div>
                    {/* Mileage Unit */}

                    <Select
                      label={"Unit"}
                      value={carUpdate.mileageUnit}
                      options={MILEAGE_UNITS}
                      name="mileageUnit"
                    />
                  </div>
                </div>
              </div>

              <div className="r grid-4">
                <TextInput
                  value={carUpdate.vin}
                  label={"VIN/Chasis No."}
                  name="vin"
                />

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.warranty}
                    label={"Warranty"}
                    options={WARRANTYS}
                    name="warranty"
                  />
                </div>
                <div className="input-wrapper">
                  <TextInput
                    value={carUpdate.price}
                    label={"Price"}
                    name="price"
                  />
                </div>
              </div>
            </div>

            <div className="section">
              <p className="section-title">Extra Information (Optional)</p>
              <div className="r grid-4">
                <div className="input-wrapper">
                  <Select
                    value={carUpdate.displacement}
                    label={"Engine Size"}
                    options={DISPLACEMENT}
                    name="displacement"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.cylinders}
                    label={"Cylinders"}
                    options={CYLINDERS}
                    name="cylinders"
                  />
                </div>

                <div className="input-wrapper">
                  <Select
                    value={carUpdate.driveType}
                    label={"Drive Type"}
                    options={DRIVE_TYPES}
                    name="driveType"
                  />
                </div>

                <div className="input-wrapper">
                  <TextInput
                    value={carUpdate.youtubeLink}
                    label={"Youtube Link"}
                    name="youtubeLink"
                  />
                </div>
              </div>
            </div>

            <div className="section">
              <p className="section-title">Auction(Optional)</p>
              <div className="r grid-4">
                <div className="">
                  <CustomSwitch
                    label="Auction"
                    updateStatus={setIsAuction}
                    checked={isAuction}
                    onChange={updateCarAuction}
                  />
                </div>
              </div>
              {isAuction && (
                <div className="r grid-4">
                  <Select
                    value={carUpdate._auction}
                    options={auctionsNameInter}
                    name="_auction"
                    label="Auction"
                  />
                  <TextInput
                    value={carUpdate.minimumOfferPrice}
                    label={"Mininum Offer Price"}
                    name="minimumOfferPrice"
                  />
                </div>
              )}
            </div>

            <div className="section">
              <p className="section-title">On Sale(Optional)</p>
              <div className="r grid-4">
                <CustomSwitch
                  label="On Sale"
                  updateStatus={setOnSale}
                  checked={onSale}
                  onChange={updateCarOnSale}
                />
              </div>
              {onSale && (
                <div>
                  <div className="r grid-4">
                    <div className="input-wrapper">
                      <RadioButtonsGroup
                        value={saleMode}
                        onChange={updateCarFixedSale}
                        options={[
                          {
                            value: "percentage",
                            label: (
                              <TextInput
                                value={salePercentage}
                                setValue={setSalePercentage}
                                label={"Sale Percentage %"}
                                name="salePercentage"
                                type="number"
                              />
                            ),
                          },
                          {
                            value: "amount",
                            label: (
                              <TextInput
                                value={saleAmount}
                                setValue={setSaleAmount}
                                label={"Sale Amount $"}
                                name="saleAmount"
                                type="number"
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                    <div className="input-warpper">
                      <label className="label">Sale Amount</label>
                      <p className="">
                        {carUpdate.price
                          ? saleMode === "amount"
                            ? currencyWithCommas(carUpdate.price - saleAmount)
                            : currencyWithCommas(
                                carUpdate.price -
                                  (salePercentage * carUpdate.price) / 100
                              )
                          : carUpdate.price}
                      </p>
                    </div>
                  </div>
                  {
                    <CustomSwitch
                      label="Limited Time"
                      checked={isLimitedTime}
                      updateStatus={setIsLimitedTime}
                      onChange={updateIsLimitedTime}
                    />
                  }

                  {isLimitedTime ? (
                    <>
                      <div className="section grid-4">
                        <TextInput
                          type="date"
                          name="selectedDateStart"
                          value={selectedDateStart}
                          label={"Start Date:"}
                          onChange={handleDateChangeStart}
                          id="date"
                        />

                        <Select
                          id="start-time"
                          value={selectedStartTime}
                          name="selectedStartTime"
                          onChange={handleStartTimeChange}
                          label="Start Time:"
                          options={generateTimeOptions()}
                        />
                        <TextInput
                          type="date"
                          name="selectedDateEnd"
                          value={selectedDateEnd}
                          label={"End Date:"}
                          onChange={handleDateChangeEnd}
                          id="date"
                        />
                        <Select
                          id="end-time"
                          value={selectedEndTime}
                          name="selectedEndTime"
                          onChange={handleEndTimeChange}
                          label="End Time:"
                          options={generateEndTimeOptions()}
                        />
                      </div>
                      <div className="section grid-4">
                        <TextInput
                          value={carUpdate.promotionTitle}
                          label="Promotion Title"
                          name="promotionTitle"
                          onChange={updatePromotionTitle}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </div>
            <div className="section">
              <div className="r grid-4">
                <TextInput
                  value={carUpdate.secretPrice}
                  label={"Secret Price"}
                  name="secretPrice"
                />
                <TextInput
                  value={carUpdate.commission}
                  label={"Commission"}
                  name="commission"
                />
              </div>
              <div className="r grid-4">
                <CustomSwitch
                  label="Featured"
                  updateStatus={setIsFeatured}
                  checked={isFeatured}
                  onChange={updateCarIsFeatured}
                />
              </div>
            </div>
            <div className="section">
              <p className="section-title">Options</p>
              <div className="options-wrapper flex aic">
                {options?.OPTIONS?.map((option, i) => (
                  <div className="input-wrapper-checkbox flex aic" key={option}>
                    <input
                      type="checkbox"
                      value={option}
                      key={option}
                      checked={optionsSelected?.includes(option)}
                      onChange={(e) => {
                        // add to list
                        if (e.target.checked) {
                          setOptionsSelected([...optionsSelected, option])
                        } else {
                          // remove from list
                          setOptionsSelected(
                            optionsSelected.filter((o) => o !== option)
                          )
                        }
                      }}
                      className="checkbox"
                    />
                    <label className="label-checkbox">{option}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="section">
              <p className="section-title">Description</p>
              <div className="flex aic">
                <textarea
                  name="description"
                  className="text-area"
                  value={carUpdate.description}
                ></textarea>
              </div>
            </div>
          </form>
          <div className="section">
            <p className="section-title">Rich Description</p>
            <CustomSwitch
              label="Rich Description"
              updateStatus={setIsRichText}
              checked={isRichText}
            />
            {isRichText && (
              <RichText richText={richText} onSubmit={updateRichDesc} />
            )}
          </div>

          <div className="section">
            <p className="section-title">Photos</p>

            <CarPictures
              id={carId}
              images={images}
              setImages={setImages}
              imagesOriginal={imagesOriginal}
              setImagesOriginal={setImagesOriginal}
              loadingImages={isImageLoaded}
              viewImageHandler={viewImageHandler}
            />
          </div>
          <CustomPopup
            trigger={openModal} // Only open the modal for the corresponding auction index
            onClose={handleCloseModal}
            setTrigger={handleCloseModal}
            title="Image"
            closeLg
          >
            <div className="flex jcc aic">
              <ImageWithOrientation src={image} alt="" className="" />
            </div>
          </CustomPopup>
        </InnerContainer>
      )}
    </div>
  )
}

export default CarCreateEditScreen
