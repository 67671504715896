import React, { useState } from "react"
import EditPencil from "../../../images/common/EditPencil.svg"
import CarBrandModelYearEditPopup from "./CarBrandModelYearEditPopup"
import "./CarDetailEditable.scss"

const CarDetailEditable = ({ car }) => {
  const [year, setYear] = useState(car?.year?.name)
  const [otherYear, setOtherYear] = useState(car.otherYear)
  const [brand, setBrand] = useState(car.brand._id)
  const [otherBrand, setOtherBrand] = useState(car.otherBrand)
  const [model, setModel] = useState(car.model._id)
  const [otherModel, setOtherModel] = useState(car.otherModel)
  const [trim, setTrim] = useState(car?.trim?._id)
  const [otherTrim, setOtherTrim] = useState(car.otherTrim)
  const [note, setNote] = useState(car.note)

  const [show, setShow] = useState(false)
  return (
    <div className="">
      <div className="car-detail-popup flex">
        <div className="label-input-wrapper">
          <div className="hover">
            <div className="empty-d"></div>
            <img
              src={EditPencil}
              alt="icon"
              className="icon"
              onClick={() => {
                setShow(true)
              }}
            />
          </div>
          <h5 className="text-bold">
            {otherYear || car?.year?.name || ""} {otherBrand || car.brand.name}{" "}
            {otherModel || car.model.name} {car?.trim?.name || otherTrim}{" "}
            {car.note || ""}
          </h5>
        </div>
      </div>
      <CarBrandModelYearEditPopup
        trigger={show}
        setTrigger={setShow}
        brand={brand}
        setBrand={setBrand}
        otherBrand={otherBrand}
        setOtherBrand={setOtherBrand}
        model={model}
        setModel={setModel}
        otherModel={otherModel}
        setOtherModel={setOtherModel}
        year={year}
        setYear={setYear}
        otherYear={otherYear}
        setOtherYear={setOtherYear}
        trim={trim}
        setTrim={setTrim}
        otherTrim={otherTrim}
        setOtherTrim={setOtherTrim}
        note={note}
        setNote={setNote}
      />
    </div>
  )
}

export default CarDetailEditable
