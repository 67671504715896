import React, { useState } from "react"
import { Alert, Button } from "@mui/material"
import "./CarDetailNotes.scss"
import TextArea from "../../input/TextArea"
import Note from "../../common/Note"
import { useEffect } from "react"
import {
  createNote,
  getNotesList,
  updateNote,
} from "../../../state/actions/commonActions"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../utils/Loader"
import { CircularProgress } from "@mui/material"

const CarDetailNotes = ({ car }) => {
  const [note, setNote] = useState("")
  const [noteId, setNoteId] = useState("")

  const noteList = useSelector((state) => state.common.noteList)
  const { loading: loadingNotes, error: errorNotes, notes } = noteList

  const noteCreate = useSelector((state) => state.common.noteCreate)
  const { loading: loadingNoteCreate, success: successNoteCreate } = noteCreate

  const noteUpdate = useSelector((state) => state.common.noteUpdate)
  const {
    loading: loadingNotesUpdate,
    error: errorNoteUpdate,
    success: successNoteUpdate,
  } = noteUpdate

  const noteDelete = useSelector((state) => state.common.noteDelete)
  const { success: successNoteDelete } = noteDelete

  const dispatch = useDispatch()

  const noteHandler = () => {
    if (noteId) {
      dispatch(updateNote("cars", car._id, noteId, note))
      setNoteId("")
    } else {
      dispatch(createNote("cars", car._id, note))
      setNoteId("")
    }
  }

  useEffect(() => {
    if (successNoteCreate) {
      setNote("")
    } else if (successNoteUpdate) {
      setNoteId("")
      setNote("")
    }
  }, [successNoteCreate, errorNoteUpdate, successNoteCreate, successNoteUpdate])

  useEffect(() => {
    dispatch(getNotesList("cars", car._id))
  }, [dispatch, successNoteCreate, successNoteUpdate, successNoteDelete])

  return (
    <div className="car-detail-notes flex col">
      <p className="title">NOTES</p>
      <TextArea
        rows={4}
        placeholder="Enter notes"
        value={note}
        setValue={setNote}
      />
      <div className="align-right">
        <Button
          variant="green"
          onClick={() => {
            noteHandler()
          }}
        >
          {loadingNoteCreate || loadingNotesUpdate ? (
            <CircularProgress color="inherit" size={15} />
          ) : noteId ? (
            "Update"
          ) : (
            "Enter"
          )}
        </Button>
      </div>
      <div className="notes-list">
        {loadingNotes ? (
          <Loader />
        ) : errorNotes ? (
          <Alert severity="error">{errorNotes}</Alert>
        ) : (
          notes
            .slice(0)
            .reverse()
            .map((note) => (
              <Note
                key={note._id}
                note={note}
                setNote={setNote}
                setNoteId={setNoteId}
                id={car._id}
              />
            ))
        )}
      </div>
    </div>
  )
}

export default CarDetailNotes
