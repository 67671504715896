import React, { useEffect, useState } from "react"
import Popup from "../sellerscreens/Popup"
import { useDispatch } from "react-redux"
import {
  duplicateCheckSeller,
  getSellerTags,
} from "../../state/actions/sellerActions"
import { useSellerDuplicateList } from "../../state/customHooks/seller/useSellerDuplicateCheck"
import { Link, useNavigate } from "react-router-dom"
import { SELLER_DUPLICATE_CHECK_RESET } from "../../state/constants/sellerContants"
import { Button } from "@mui/material"

const SellerDuplicatePopup = (props) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [keyword, setKeyword] = useState("")

  const [loadingSellerList, , sellersList] = useSellerDuplicateList()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(duplicateCheckSeller(props.sellerType, keyword, props.id))
  }

  useEffect(() => {
    if (!loadingSellerList) {
      if (sellersList?.length > 0) {
      } else if (keyword) {
        navigate(
          `/seller/branches/create?sellerName=${keyword}&officialBrandId=${props.seller._id}&officialBrandName=${props.seller.name}`
        )
      }
    }
    props.sellerType && dispatch(getSellerTags(props.sellerType))
  }, [navigate, loadingSellerList, sellersList])

  return (
    <Popup
      trigger={props.trigger}
      setTrigger={props.setTrigger}
      title={sellersList?.length ? "Duplicate Check" : `Enter Branch  Name`}
      className={sellersList?.length && "duplicate-check"}
      resetOption={() => dispatch({ type: SELLER_DUPLICATE_CHECK_RESET })}
    >
      {sellersList?.length ? (
        <div className="duplicate-check-wrapper">
          <p className="text">
            It looks like there are duplicates. Please review possible matches.
          </p>
          <Link
            to={`/seller/${props.sellerType}/create?sellerName=${keyword}`}
            className="link"
            onClick={() => {
              dispatch({ type: SELLER_DUPLICATE_CHECK_RESET })
            }}
          >
            THIS DEALERSHIP IS NOT A DUPLICATE
          </Link>
          <div className="duplicate-check-list">
            {sellersList?.map((seller, i) => (
              <div className="card flex jcsb" key={i}>
                <div className="c1 flex">
                  {seller.profileImage ? (
                    <img src={seller.profileImage} alt="profileImage" />
                  ) : (
                    <p className="dealership-name-initials">
                      {seller.name
                        .match(/\b(\w)/g)
                        .join("")
                        .toUpperCase()}
                    </p>
                  )}
                  <div className="text-wrapper">
                    <p className="bold-text">{seller.name}</p>
                    <div className="r2 flex">
                      {/* <div className="text margin-r">
                        {dealership._primaryUser.firstName}{" "}
                        {dealership._primaryUser.lastName}
                      </div> */}
                      <div className="text">{seller.location.city}</div>
                    </div>
                  </div>
                </div>
                <div className="c2">
                  <p className="text margin-b">ID# {seller.id}</p>
                  {seller.status === "ACTIVE" ? (
                    <p className="text">Active</p>
                  ) : (
                    <p className="text">Suspended</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <form onSubmit={submitHandler} className="form-wrapper">
          <div className="input-wrapper flex col">
            <label className="input-label">Branch Name</label>
            <input
              type="text"
              name="dealership-name"
              className="input-text"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
            />
          </div>
          <div className="button-wrapper flex">
            <Button
              className="btn light"
              onClick={() => {
                props.setCreateBranchPopup(false)
              }}
            >
              Cancel
            </Button>
            <input
              type="submit"
              value="Save"
              className="btn primary"
              style={{ cursor: "pointer" }}
            />
          </div>
        </form>
      )}
    </Popup>
  )
}

export default SellerDuplicatePopup
