import React, { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import InnerHeader from "../../components/header/InnerHeader"
import InnerHeaderButton from "../../components/utils/Button"
import "./SaleCreateEditScreen.scss"
import InnerContainer from "../../components/utils/InnerContainer"
import { useSellerList } from "../../state/customHooks/seller/useSellerList"
import { useDispatch } from "react-redux"
import { SELLER_LIST_RESET } from "../../state/constants/sellerContants"
import TextInput from "../../components/input/TextInput"
import Select from "../../components/input/Select"
import TextArea from "../../components/input/TextArea"
import Loader from "../../components/utils/Loader"
import Message from "../../components/utils/Message"
import { Button, CircularProgress } from "@mui/material"
import useSaleCreate from "../../state/customHooks/sale/useSaleCreate"
import { createSale, updateSale } from "../../state/actions/saleAction"
import useSaleDetail from "../../state/customHooks/sale/useSaleDetail"
import useSaleUpdate from "../../state/customHooks/sale/useSaleUpdate"
import CarMultiSelectModal from "../../components/modals/CarMultiSelectModal"
import { useCarListKeyword } from "../../state/customHooks/carmp/useCarList"
import CustomSwitch from "../../components/selection/Switch"

import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { SortableItem } from "./SortableItem"
import { baseURLWeb } from "../../utils/base"

const SaleCreateEditScreen = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams()

  const dispatch = useDispatch()

  const auctionTypeQ = searchParams.get("type")

  const [auctionType, setAuctionType] = useState("")

  const [loading, error, saleDetail, carsDetail] = useSaleDetail(id)

  const [sellerName, setSellerName] = useState("")
  const [seller, setSeller] = useState("")

  const [sellers] = useSellerList(auctionType, sellerName)

  const [sale, setSale] = useState({ cars: [] })

  const [selectedDateStart, setSelectedDateStart] = useState("")
  const [selectedDateEnd, setSelectedDateEnd] = useState("")
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")

  const [isLimitedTime, setIsLimitedTime] = useState("")

  const [keyword, setKeyword] = useState("")
  const [, , , , , cars] = useCarListKeyword(keyword)
  const [carsSelected, setCarsSelected] = useState([])

  const [isOpen, setIsOpen] = useState("")

  const [loadingSaleCreate, , success, eventCreated] = useSaleCreate()
  const [loadingAuctionUpdate, , successAuctionUpdate, updatedAuction] =
    useSaleUpdate()

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      setAuctionType(auctionTypeQ)
    } else {
      setAuctionType(saleDetail?._company ? "companies" : null)
    }
  }, [id])

  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event.target.value)
  }
  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event.target.value)
  }

  const handleStartTimeChange = (event) => {
    setSelectedStartTime(event.target.value)
  }

  const handleEndTimeChange = (event) => {
    setSelectedEndTime(event.target.value)
  }
  const convertTimeToOptionString = (startTime) => {
    const date = new Date(startTime)
    const hour = date.getHours()
    const minute = date.getMinutes()
    const timeString = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`
    return timeString
  }

  const extractDateValue = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateValue = `${year}-${month}-${day}`
    return dateValue
  }

  useEffect(() => {
    if (id && saleDetail) {
      const cars = carsDetail?.map((car) => car._id)
      setSale({ ...saleDetail, cars })
      setCarsSelected(carsDetail)
      setSelectedStartTime(convertTimeToOptionString(saleDetail.startDate))
      setSelectedEndTime(convertTimeToOptionString(saleDetail.endDate))
      setSelectedDateStart(extractDateValue(saleDetail.startDate))
      setSelectedDateEnd(extractDateValue(saleDetail.endDate))
      setSeller(saleDetail._company)
      setIsLimitedTime(saleDetail.isLimitedTime)
    } else {
      setSale({})
    }
  }, [id, saleDetail])

  useEffect(() => {
    if (successAuctionUpdate) {
      setSale(updatedAuction)
      setSelectedStartTime(convertTimeToOptionString(updatedAuction.startDate))
      setSelectedEndTime(convertTimeToOptionString(updatedAuction.endDate))
      setSelectedDateStart(extractDateValue(updatedAuction.startDate))
      setSelectedDateEnd(extractDateValue(updatedAuction.endDate))
      setSeller(updatedAuction._company)
      setIsLimitedTime(updatedAuction.isLimitedTime)
    }
  }, [successAuctionUpdate])

  const auctionUpdateHandler = (e) => {
    if (
      ["latitude", "longitude", "address", "city", "desc"].includes(
        e.target.name
      )
    ) {
      setSale((prev) => {
        return {
          ...prev,
          location: { ...sale.location, [e.target.name]: e.target.value },
        }
      })
    } else {
      setSale((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        }
      })
    }
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setCarsSelected((items) => {
        const activeIndex = items
          .map(function (e) {
            return e.id
          })
          .indexOf(active.id)

        const overIndex = items
          .map(function (e) {
            return e.id
          })
          .indexOf(over.id)
        setSale((prev) => {
          const ids = items.map((item) => item.id)
          return {
            ...prev,
            cars: arrayMove(ids, activeIndex, overIndex),
          }
        })

        return arrayMove(items, activeIndex, overIndex)
      })
    }
  }

  const updateIsLimitedTime = (e) => {
    setSale((prev) => {
      return {
        ...prev,
        isLimitedTime: !isLimitedTime,
      }
    })
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  useEffect(() => {
    setSale((prev) => {
      // Concatenate the date and time strings
      const startDateTimeStr = `${selectedDateStart}T${selectedStartTime}`
      const endDateTimeStr = `${selectedDateEnd}T${selectedEndTime}`

      // Create Date objects from the concatenated strings
      const startDate = new Date(startDateTimeStr)
      const endDate = new Date(endDateTimeStr)
      return { ...prev, startDate, endDate }
    })
  }, [selectedDateStart, selectedDateEnd, selectedStartTime, selectedEndTime])

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }
    return options
  }

  const generateEndTimeOptions = () => {
    const options = []

    const [startHour, startMinute] = selectedStartTime?.split(":")?.map(Number)
    for (let hour = 0; hour < 24; hour++) {
      const minuteStart =
        hour === startHour ? Math.ceil(startMinute / 30) * 30 : 0
      for (let minute = minuteStart; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }

    return options
  }

  const auctionCreateUpdateHandler = () => {
    if (!id) {
      dispatch(createSale(sale))
    } else {
      dispatch(updateSale(id, sale))
    }
  }

  useEffect(() => {
    if (success) {
      navigate(`/sale/${eventCreated._id}/edit`)
    }
  }, [success])

  return (
    <div className="sale-create-edit-screen">
      <InnerHeader
        title={!id ? "Create Event" : "Update Event"}
        className="inner-header"
      >
        <InnerHeaderButton onClick={auctionCreateUpdateHandler}>
          {loadingAuctionUpdate || loadingSaleCreate ? (
            <CircularProgress color="inherit" size={15} />
          ) : id ? (
            `Update`
          ) : (
            `Save`
          )}
        </InnerHeaderButton>
      </InnerHeader>
      <InnerContainer>
        {id && loading ? (
          <Loader />
        ) : error ? (
          <Message variant="error">{error}</Message>
        ) : (
          <>
            <form onChange={auctionUpdateHandler}>
              <div className="section grid-4">
                <TextInput name="name" label="Event Name" value={sale.name} />
                <div className="input-with-dropdown">
                  <div className="input-wrapper">
                    {auctionType === "dealerships" ? (
                      <label className="label">
                        Dealership Name (Optional)
                      </label>
                    ) : auctionType === "companies" ? (
                      <label className="label">Company Name (Optional)</label>
                    ) : (
                      <label className="label">OfficialBrand (Optional)</label>
                    )}

                    <input
                      type="text"
                      className="input"
                      value={seller?.name}
                      name="_company"
                      onChange={(e) => {
                        setSellerName(e.target.value)
                      }}
                    />
                  </div>
                  {sellers?.length > 0 && (
                    <div className="dropdown-dealership">
                      {sellers?.map((seller) => (
                        <div
                          className="dealership-card"
                          key={seller._id}
                          onClick={() => {
                            setSeller({
                              _id: seller._id,
                              name: seller.name,
                              _primaryUser: seller?._primaryUser?._id,
                            })
                            setSale((prev) => {
                              return {
                                ...prev,
                                _company: seller._id,
                              }
                            })
                            setSellerName("")
                            dispatch({ type: SELLER_LIST_RESET })
                          }}
                        >
                          <p className="text">{seller.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {
                <div className="section grid-4">
                  <CustomSwitch
                    label="Limited Time"
                    checked={isLimitedTime}
                    updateStatus={setIsLimitedTime}
                    onChange={updateIsLimitedTime}
                  />
                </div>
              }
              {isLimitedTime ? (
                <div className="section grid-4">
                  <TextInput
                    type="date"
                    name="selectedDateStart"
                    value={selectedDateStart}
                    label={"Start Date:"}
                    onChange={handleDateChangeStart}
                    id="date"
                  />

                  <Select
                    id="start-time"
                    value={selectedStartTime}
                    name="selectedStartTime"
                    onChange={handleStartTimeChange}
                    label="Start Time:"
                    options={generateTimeOptions()}
                  />
                  <TextInput
                    type="date"
                    name="selectedDateEnd"
                    value={selectedDateEnd}
                    label={"End Date:"}
                    onChange={handleDateChangeEnd}
                    id="date"
                  />
                  <Select
                    id="end-time"
                    value={selectedEndTime}
                    name="selectedEndTime"
                    onChange={handleEndTimeChange}
                    label="End Time:"
                    options={generateEndTimeOptions()}
                  />
                </div>
              ) : null}

              <div className="section">
                <p className="section-title">Location (Optional)</p>
                <div className="section grid-4">
                  <TextInput
                    name="latitude"
                    label="Latitude"
                    value={sale?.location?.latitude}
                  />
                  <TextInput
                    name="longitude"
                    label="Longitude"
                    value={sale?.location?.longitude}
                  />
                  <TextInput
                    name="address"
                    label="Address"
                    value={sale?.location?.address}
                  />
                  <TextInput
                    name="city"
                    label="City"
                    value={sale?.location?.city}
                  />
                </div>
                <div className="section grid-4">
                  <TextArea
                    rows={3}
                    label="Description"
                    name="desc"
                    value={sale?.location?.desc}
                  />
                </div>
              </div>
            </form>
            {sale.slug && (
              <div className="label-wrapper flex">
                <p>URL: </p>
                <a
                  className="link"
                  href={`${baseURLWeb}cars/listing?group=${sale.slug}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {baseURLWeb}cars/listing?group={sale.slug}
                </a>
              </div>
            )}

            <div className="car-list-wrapper">
              <div className="flex jcsb">
                <div className=""></div>
                <Button onClick={() => setIsOpen(true)}>Add</Button>
              </div>
              <div className="car-list">
                {carsSelected?.length > 0 && (
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    sensors={sensors}
                  >
                    <SortableContext
                      items={carsSelected}
                      strategy={verticalListSortingStrategy}
                    >
                      {/* We need components that use the useSortable hook */}
                      {carsSelected?.map((car) => (
                        <SortableItem
                          carsSelected={carsSelected}
                          setCarsSelected={setCarsSelected}
                          sale={sale}
                          setSale={setSale}
                          car={car}
                          id={car._id}
                          key={car._id}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                )}
              </div>

              <CarMultiSelectModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                keyword={keyword}
                setKeyword={setKeyword}
                cars={cars}
                carsSelected={carsSelected}
                setCarsSelected={setCarsSelected}
                sale={sale}
                setSale={setSale}
              />
            </div>
          </>
        )}
      </InnerContainer>
    </div>
  )
}

export default SaleCreateEditScreen
