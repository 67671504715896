import React, { useState } from "react"
import EditPencil from "../../images/common/EditPencil.svg"
import DropDownPopup from "./DropDownPopup"
import "./EditableLabelSelect.scss"

const EditableLabelSelect = ({ options, value, setValue, title, name }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <div className="editable-tag flex jcc aic">
        <span className="label">{value}</span>
        <div className="edit-button">
          <div className="empty-d"></div>
          <img
            src={EditPencil}
            alt="icon"
            className="icon"
            onClick={() => {
              setShow(true)
            }}
          />
        </div>
      </div>
      <DropDownPopup
        trigger={show}
        value={value}
        options={options}
        setValue={setValue}
        setTrigger={setShow}
        title={title}
        name={name}
      />
    </>
  )
}

export default EditableLabelSelect
