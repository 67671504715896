import React from "react";
import "./StatusChangePopup.scss";

const StatusChangePopup = (props) => {
  return props.trigger ? (
    <div className="popup-status-change">
      <div className="popup-status-change-inner">
        <div className="popup-status-change-header flex jcsb">
          <div className="title">{props.title}</div>
        </div>
        <div className="popup-children">{props.children}</div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default StatusChangePopup;
