import * as React from "react"
import { Pagination } from "@mui/material"
import { Stack } from "@mui/material"
import { useSearchParams } from "react-router-dom"

export const BasicPagination = ({
  pageNumber,
  setPageNumber,
  pages,
  queryObject,
  setQueryObject,
}) => {
  const [searchParams] = useSearchParams()

  const pageNumberQuery = searchParams.get("pageNumber")
  return (
    <Stack spacing={2}>
      <Pagination
        count={pages}
        defaultPage={Number(pageNumber) || queryObject?.pageNumber}
        page={Number(pageNumberQuery) || queryObject?.pageNumber}
        onChange={(event, value) => {
          setPageNumber && setPageNumber(value)
          setQueryObject &&
            setQueryObject({
              ...queryObject,
              pageNumber: value,
            })
        }}
      />
    </Stack>
  )
}
