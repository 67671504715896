import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LINK_DETAIL_RESET } from "../../constants/linkConstants"
import { getLinkDetail } from "../../actions/linkAction"

const useLinkDetail = (id) => {
  const dispatch = useDispatch()

  const linkDetail = useSelector((state) => state.link.linkDetail)
  const { loading, error, link } = linkDetail

  useEffect(() => {
    dispatch({ type: LINK_DETAIL_RESET })
    id && dispatch(getLinkDetail(id))
  }, [dispatch, id])

  return [loading, error, link]
}

export default useLinkDetail
