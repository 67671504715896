import Axios from "../../utils/axios"
import {
  SELLER_CREATE_FAIL,
  SELLER_CREATE_REQUEST,
  SELLER_CREATE_SUCCESS,
  SELLER_DELETE_FAIL,
  SELLER_DELETE_REQUEST,
  SELLER_DELETE_SUCCESS,
  SELLER_DETAIL_FAIL,
  SELLER_DETAIL_REQUEST,
  SELLER_DETAIL_SUCCESS,
  SELLER_DUPLICATE_CHECK_FAIL,
  SELLER_DUPLICATE_CHECK_REQUEST,
  SELLER_DUPLICATE_CHECK_SUCCESS,
  SELLER_LIST_FAIL,
  SELLER_LIST_REQUEST,
  SELLER_LIST_SUCCESS,
  SELLER_TAGS_LIST_FAIL,
  SELLER_TAGS_LIST_REQUEST,
  SELLER_TAGS_LIST_SUCCESS,
  SELLER_UPDATE_FAIL,
  SELLER_UPDATE_REQUEST,
  SELLER_UPDATE_SUCCESS,
} from "../constants/sellerContants"

export const getSellerList =
  (type, pageNumber, filterStatus, keyword = "", all, tags) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_LIST_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const tagsString = tags.length > 0 ? tags.join(",") : ""
      const { data } = await Axios.get(
        `/v1/${type}?pageNumber=${pageNumber}&status=${filterStatus}&keyword=${keyword}&all=${all}&tags=${tagsString}`,
        config
      )
      dispatch({
        type: SELLER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateSeller =
  (type, id, seller) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_UPDATE_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await Axios.put(`/v1/${type}/${id}`, seller, config)
      dispatch({
        type: SELLER_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getSellerDetail = (type, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_DETAIL_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.get(
      `/v1/${type}/${id}`,

      config
    )
    dispatch({
      type: SELLER_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELLER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createSellerWithUser =
  (seller, type) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_CREATE_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await Axios.post(
        `/v1/users?type=${type}`,
        seller,
        config
      )
      dispatch({
        type: SELLER_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const duplicateCheckSeller =
  (type, sellerName, parentSeller) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_DUPLICATE_CHECK_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await Axios.get(
        `/v1/${type}?keyword=${sellerName}&parentSeller=${parentSeller}`,
        config
      )
      dispatch({
        type: SELLER_DUPLICATE_CHECK_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_DUPLICATE_CHECK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getSellerTags = (type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_TAGS_LIST_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.get(`/v1/${type}/filter`, config)
    dispatch({
      type: SELLER_TAGS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELLER_TAGS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// seller delete action

export const deleteSeller = (type, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SELLER_DELETE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.delete(`/v1/${type}/${id}`, config)
    dispatch({
      type: SELLER_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SELLER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
