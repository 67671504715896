import { Button } from "@mui/material"
import React from "react"
import "./Modal.scss"
import closeicon from "../../images/dealership/closeicon.svg"

const Modal = (props) => {
  return props.trigger ? (
    <div className="modal">
      <div className="modal-inner-wrapper" style={props.style}>
        <div className="modal-header">
          <div className="title">{props.title}</div>
          {props.closeIcon ? (
            <img
              src={closeicon}
              alt="closeicon"
              onClick={() => {
                props.setTrigger(false)
              }}
            />
          ) : null}
        </div>
        <div className="modal-body">{props.children}</div>
        <div className="btns-row">
          {props.setTrigger && props.onCancelTitle && (
            <Button
              className="btn light"
              onClick={() => {
                props.setTrigger(false)
              }}
            >
              {props.onCancelTitle}
            </Button>
          )}
          {props.onSubmit && (
            <Button
              className="btn primary"
              onClick={() => {
                props.setTrigger(false)
                props.onSubmit()
              }}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Modal
