import { useSelector } from "react-redux"

const useYearUpdate = () => {
  const yearUpdate = useSelector((state) => state.carCatalogue.yearUpdate)
  const {
    loading: loadingYearUpdate,
    error: errorYearUpdate,
    success: successYearUpdate,
    year: updatedYear,
  } = yearUpdate

  return [loadingYearUpdate, errorYearUpdate, successYearUpdate, updatedYear]
}

export default useYearUpdate
