import React from "react"
import "./AnalyticsValueCard.scss"

const AnalyticsValueCard = ({ title, value, label }) => {
  return (
    <div className="analytics-value-card flex jcc aic col">
      <h5 className="card-title">{title}</h5>
      <h4 className="card-value">{value}</h4>
      <p className="card-label">{label}</p>
    </div>
  )
}

export default AnalyticsValueCard
