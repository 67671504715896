import Axios from "../../utils/axios"
import { generateQueryURL } from "../../utils/utilsFunctions"
import {
  LINK_CREATE_FAIL,
  LINK_CREATE_REQUEST,
  LINK_CREATE_SUCCESS,
  LINK_DELETE_FAIL,
  LINK_DELETE_REQUEST,
  LINK_DELETE_SUCCESS,
  LINK_DETAIL_FAIL,
  LINK_DETAIL_REQUEST,
  LINK_DETAIL_SUCCESS,
  LINK_LIST_FAIL,
  LINK_LIST_REQUEST,
  LINK_LIST_SUCCESS,
  LINK_UPDATE_FAIL,
  LINK_UPDATE_REQUEST,
  LINK_UPDATE_SUCCESS,
} from "../constants/linkConstants"

export const createLink = (link) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LINK_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.post(`/v1/links/`, link, config)
    dispatch({
      type: LINK_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINK_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLinkList = (queryObject) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LINK_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(
      `/v1/links?${generateQueryURL(queryObject)}`,
      config
    )
    dispatch({
      type: LINK_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINK_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLinkDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LINK_DETAIL_REQUEST,
    })

    const { data } = await Axios.get(`/v1/links/${id}`)

    dispatch({
      type: LINK_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINK_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLink = (id, link) => async (dispatch) => {
  try {
    dispatch({
      type: LINK_UPDATE_REQUEST,
    })

    const { data } = await Axios.put(`/v1/links/${id}`, link)

    dispatch({
      type: LINK_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteLink = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LINK_DELETE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.delete(`/v1/links/${id}`, config)

    dispatch({
      type: LINK_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LINK_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
