import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { SALE_UPDATE_RESET } from "../../constants/saleConstants"

const useSaleUpdate = () => {
  const saleUpdate = useSelector((state) => state.sale.saleUpdate)
  const { loading, error, success, sale } = saleUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Event Updated Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: SALE_UPDATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, sale]
}

export default useSaleUpdate
