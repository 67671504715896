import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import InnerHeader from "../../components/header/InnerHeader"
import {
  createSellerWithUser,
  updateSeller,
} from "../../state/actions/sellerActions"
import "./SellerCreateEditScreen.scss"
import Message from "../../components/utils/Message"
import { useParams, useSearchParams } from "react-router-dom"
import ImageInput from "../../components/input/ImageInput"
import { useSellerList } from "../../state/customHooks/seller/useSellerList"
import TextInput from "../../components/input/TextInput"
import useSellerDetail from "../../state/customHooks/seller/useSellerDetail"
import InnerHeaderButton from "../../components/utils/Button"
import useSellerCreate from "../../state/customHooks/seller/useSellerCreate"
import useSellerUpdate from "../../state/customHooks/seller/useSellerUpdate"
import Select from "../../components/input/Select"

const SellerCreateEditScreen = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const officialBrand = {
    id: searchParams.get("officialBrandId"),
    name: searchParams.get("officialBrandName"),
  }

  const sellerType = params.sellerType
  const id = params.id
  const [sellerName] = useState(searchParams.get("sellerName"))
  const sellerNumber = searchParams.get("sellerNumber")
  const [longitude, setLongitude] = useState("")
  const [latitude, setLatitude] = useState("")
  const [address, setAddress] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber1, setPhoneNumber1] = useState("")
  const [telecom1, setTelecom1] = useState("")
  const [phoneNumber2, setPhoneNumber2] = useState("")
  const [telecom2, setTelecom2] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [city, setCity] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [email, setEmail] = useState("")
  const [isPrimary, setIsPrimary] = useState(true)
  const [customError, setCustomError] = useState("")
  const [sellerImage, setSellerImage] = useState("")
  const [sellerObj, setSellerObj] = useState({})

  const [keyword, setKeyword] = useState("")

  const [loadingSellerCreate, error, success] = useSellerCreate()
  const [, ,] = useSellerUpdate()

  const [sellers] = useSellerList(
    keyword && sellerType === "branches" && "officialBrands",
    keyword
  )

  const [loadingSellerDetail, , sellerDetail, , ,] = useSellerDetail(
    sellerType,
    id
  )

  const cellular = [
    { name: "Smart", value: "Smart" },
    { name: "Cellcard", value: "Cellcard" },
    { name: "Metfone", value: "Metfone" },
  ]

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const seller = {
    name: sellerName,
    contacts: [
      {
        phoneNumber: phoneNumber1,
        telecom: telecom1,
      },
      {
        phoneNumber: phoneNumber2,
        telecom: telecom2,
      },
    ],
    phoneNumber1,
    username,
    sellerImage,
    location: {
      latitude,
      longitude,
      address,
      city,
    },
    firstName,
    lastName,
    password,
    email,
    isPrimary,
    officialBrandId: officialBrand.id || sellerObj._id,
  }

  useEffect(() => {
    if (success) {
      navigate(-1)
    }
  }, [success])

  useEffect(() => {
    if (id && sellerDetail && !loadingSellerDetail) {
      setCity(sellerDetail._individual?.location?.city)
      setLatitude(sellerDetail._individual?.location?.latitude)
      setLongitude(sellerDetail._individual?.location?.longitude)
      setAddress(sellerDetail._individual?.location?.address)
      setFirstName(sellerDetail.firstName)
      setLastName(sellerDetail.lastName)
      setUsername(sellerDetail.username)
      sellerDetail?.contacts &&
        sellerDetail?.contacts[0] &&
        setPhoneNumber1(sellerDetail?.contacts[0]?.phoneNumber)
      sellerDetail?.contacts &&
        sellerDetail?.contacts[0] &&
        setTelecom1(sellerDetail?.contacts[0]?.telecom)
      sellerDetail?.contacts &&
        sellerDetail?.contacts[1] &&
        setPhoneNumber2(sellerDetail?.contacts[1]?.phoneNumber)
      sellerDetail?.contacts &&
        sellerDetail?.contacts[1] &&
        setTelecom2(sellerDetail?.contacts[1]?.telecom)
    }
  }, [id, sellerDetail, loadingSellerDetail])

  useEffect(() => {
    setPhoneNumber1(sellerNumber)
    setUsername(sellerNumber)
  }, [sellerNumber])

  const submitHandler = () => {
    if (password === confirmPassword) {
      if (!phoneNumber2) {
        seller.contacts.splice(1, 1)
      }
      if (!id) {
        dispatch(createSellerWithUser(seller, sellerType))
      } else {
        dispatch(updateSeller(sellerType, id, seller))
      }
    } else {
      setCustomError("Password Mismatch")
    }
  }
  const disabled =
    sellerType !== "branches" && (!username || !city || !phoneNumber1)

  return (
    <div className="seller-create-screen">
      <InnerHeader
        title={
          sellerType === "dealerships"
            ? "Dealership"
            : sellerType === "companies"
            ? "Company"
            : sellerType === "branches"
            ? "Branch"
            : sellerType === "individuals"
            ? "Individual"
            : "Official Brand"
        }
        className="inner-header"
      >
        <InnerHeaderButton
          loading={loadingSellerCreate}
          disabled={disabled}
          onClick={submitHandler}
        >
          Create
        </InnerHeaderButton>
      </InnerHeader>
      {error && <Message variant="error">{error} </Message>}
      {customError && <Message variant="error">{customError} </Message>}
      <div className="seller-form-wrapper">
        <div className="seller-form-inner">
          <div className="seller-details">
            <div
              className={sellerType === "branches" ? "r grid-4" : "r grid-3"}
            >
              {sellerType !== "individuals" ? (
                <TextInput
                  value={sellerName}
                  disabled={true}
                  label={
                    sellerType === "dealership"
                      ? "Dealership Name"
                      : sellerType === "companies"
                      ? "Company Name"
                      : sellerType === "branches"
                      ? "Branch Name"
                      : "Official Brand Name"
                  }
                />
              ) : null}

              {sellerType === "branches" && (
                <div className="input-with-dropdown">
                  <div className="input-wrapper">
                    {sellerType === "branches" ? (
                      <label className="label">Official Brand Name</label>
                    ) : sellerType === "companies" ? (
                      <label className="label">Company Name</label>
                    ) : null}

                    <input
                      type="text"
                      className="input"
                      disabled={officialBrand ? true : false}
                      value={
                        officialBrand.id ? officialBrand.name : sellerObj.name
                      }
                      onChange={(e) => {
                        setKeyword({})
                      }}
                    />
                  </div>
                  {sellers?.length > 0 && (
                    <div className="dropdown-seller">
                      {sellers?.map((seller) => (
                        <div
                          className="seller-card"
                          key={seller._id}
                          onClick={() => {
                            setSellerObj({
                              _id: seller._id,
                              name: seller.name,
                              _primaryUser: seller._primaryUser,
                            })
                            setKeyword("")
                          }}
                        >
                          <p className="text">{seller.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <TextInput
                value={latitude}
                setValue={setLatitude}
                label="Latitude (Optional)"
              />
              <TextInput
                value={longitude}
                setValue={setLongitude}
                label="Longitude (Optional)"
              />
            </div>
            <div className="r grid-2">
              <TextInput
                value={address}
                setValue={setAddress}
                label="Address(Optional)"
              />
              <TextInput
                value={city}
                setValue={setCity}
                label={"City *"}
                name="city"
                required={true}
              />
            </div>
          </div>
          {sellerType !== "branches" && (
            <div className="dealer-details">
              <p className="text">User</p>
              <div className="r grid-3">
                <TextInput
                  value={firstName}
                  setValue={setFirstName}
                  name="firstName"
                  label={
                    sellerType !== "individuals"
                      ? "First Name"
                      : "First Name (Optional)"
                  }
                  required={sellerType !== "officialBrands" ? true : false}
                />
                <TextInput
                  value={lastName}
                  setValue={setLastName}
                  label={
                    sellerType !== "individuals"
                      ? "Last Name *"
                      : "Last Name (Optional)"
                  }
                  name="lastName"
                  required={sellerType !== "individuals" ? true : false}
                />
                <div className="flex aic">
                  <div style={{ marginRight: "8px" }}>
                    <Select
                      value={telecom1}
                      name="fuelEconomy"
                      label={
                        sellerType !== "individuals" ? "Telecom" : "Telecom *"
                      }
                      options={cellular}
                      required={true}
                      onChange={(e) => {
                        setTelecom1(e.target.value)
                      }}
                    />
                  </div>
                  <div className="input-wrapper" style={{ width: "100%" }}>
                    <TextInput
                      value={phoneNumber1}
                      label={
                        sellerType !== "individuals"
                          ? "Phone Number 1"
                          : "Phone Number 1 *"
                      }
                      setValue={setPhoneNumber1}
                      required={true}
                    />
                  </div>
                </div>
                <div className="flex aic">
                  <div style={{ marginRight: "8px" }}>
                    <Select
                      value={telecom2}
                      name="fuelEconomy"
                      label={
                        sellerType !== "individuals" ? "Telecom" : "Telecom *"
                      }
                      options={cellular}
                      onChange={(e) => {
                        setTelecom2(e.target.value)
                      }}
                    />
                  </div>
                  <div className="input-wrapper" style={{ width: "100%" }}>
                    <TextInput
                      value={phoneNumber2}
                      label={
                        sellerType !== "individuals"
                          ? "Phone Number 2"
                          : "Phone Number 2 *"
                      }
                      setValue={setPhoneNumber2}
                    />
                  </div>
                </div>
              </div>
              <div className="r grid-3">
                <TextInput
                  value={username}
                  setValue={setUsername}
                  label={
                    sellerType !== "individuals" ? "Username" : "Username *"
                  }
                  required={true}
                />
                {sellerType !== "individuals" && (
                  <TextInput
                    value={password}
                    setValue={setPassword}
                    label={"Password"}
                    required={true}
                  />
                )}
                {sellerType !== "individuals" && (
                  <TextInput
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    label={"Confirm Password"}
                    required={true}
                  />
                )}
              </div>
              <div className="r grid-1">
                <TextInput
                  value={email}
                  setValue={setEmail}
                  label={"Email (Optional)"}
                  required={false}
                  type={"email"}
                />

                {sellerType !== "individuals" && (
                  <div className="input-wrapper-checkbox flex aic">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isPrimary}
                      onChange={() => {
                        setIsPrimary(!isPrimary)
                      }}
                    />
                    <label className="label-checkbox">Set Primary</label>
                  </div>
                )}
              </div>
              {sellerType !== "individuals" && (
                <div className="r grid-1">
                  <ImageInput
                    image={sellerImage}
                    setImage={setSellerImage}
                    label={"Profile Picture"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SellerCreateEditScreen
