import * as React from "react"
import TreeView from "@mui/lab/TreeView"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import TreeItem from "@mui/lab/TreeItem"
import "./Tree.scss"

export default function CustomTreeView({
  countBuffer,
  queryObject,
  setQueryObject,
  loading,
}) {
  return (
    <TreeView
      className="tree"
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={["2"]}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {queryObject.activeTab === "All" && (
        <TreeItem
          nodeId="1"
          className="item"
          label={`Pending (${countBuffer?.pendingCars || 0})`}
          disabled={loading}
          sx={{
            backgroundColor:
              queryObject.status === "PENDING" && "rgba(28, 134, 184, 0.25)",
          }}
          onClick={() => {
            if (!loading) {
              setQueryObject((prev) => ({
                ...prev,
                status: "PENDING",
                pageNumber: 1,
                keyword: "",
              }))
            }
          }}
        ></TreeItem>
      )}

      <TreeItem
        nodeId="2"
        label={`Listed (${countBuffer?.listedCars || 0})`}
        disabled={loading}
        selected={queryObject.status === "LISTED"}
        sx={{
          backgroundColor:
            queryObject.activeTab === "All" &&
            queryObject.status === "LISTED" &&
            "rgba(28, 134, 184, 0.25)",
        }}
        onClick={() => {
          if (!loading) {
            setQueryObject((prev) => ({
              ...prev,
              status: "LISTED",
              pageNumber: 1,
              keyword: "",
            }))
          }
        }}
      >
        {(queryObject.activeTab === "OnSale" ||
          queryObject.activeTab === "Auction") && (
          <>
            <TreeItem
              nodeId="5"
              label={`Scheduled (${
                countBuffer?.subStatusCarCount?.scheduled || 0
              })`}
              sx={{
                backgroundColor:
                  (queryObject.activeTab === "OnSale" ||
                    queryObject.activeTab === "Auction") &&
                  queryObject.status === "LISTED" &&
                  queryObject.subStatus === "SCHEDULED" &&
                  "rgba(28, 134, 184, 0.25)",
              }}
              disabled={loading}
              onClick={() => {
                if (!loading) {
                  setQueryObject((prev) => ({
                    ...prev,
                    status: "LISTED",
                    pageNumber: 1,
                    keyword: "",
                    subStatus: "SCHEDULED",
                  }))
                }
              }}
            ></TreeItem>
            <TreeItem
              nodeId="6"
              label={`Active  (${countBuffer?.subStatusCarCount?.active || 0})`}
              disabled={loading}
              sx={{
                backgroundColor:
                  (queryObject.activeTab === "OnSale" ||
                    queryObject.activeTab === "Auction") &&
                  queryObject.status === "LISTED" &&
                  queryObject.subStatus === "ACTIVE" &&
                  "rgba(28, 134, 184, 0.25)",
              }}
              onClick={() => {
                if (!loading) {
                  setQueryObject((prev) => ({
                    ...prev,
                    status: "LISTED",
                    pageNumber: 1,
                    keyword: "",
                    subStatus: "ACTIVE",
                  }))
                }
              }}
            ></TreeItem>
            <TreeItem
              nodeId="7"
              label={`Closed (${countBuffer?.subStatusCarCount?.closed || 0})`}
              disabled={loading}
              sx={{
                backgroundColor:
                  (queryObject.activeTab === "OnSale" ||
                    queryObject.activeTab === "Auction") &&
                  queryObject.status === "LISTED" &&
                  queryObject.subStatus === "CLOSED" &&
                  "rgba(28, 134, 184, 0.25)",
              }}
              onClick={() => {
                if (!loading) {
                  setQueryObject((prev) => ({
                    ...prev,
                    status: "LISTED",
                    pageNumber: 1,
                    keyword: "",
                    subStatus: "CLOSED",
                  }))
                }
              }}
            ></TreeItem>
            <TreeItem
              nodeId="8"
              label={`Cancelled (${
                countBuffer?.subStatusCarCount?.cancelled || 0
              })`}
              disabled={loading}
              sx={{
                backgroundColor:
                  (queryObject.activeTab === "OnSale" ||
                    queryObject.activeTab === "Auction") &&
                  queryObject.status === "LISTED" &&
                  queryObject.subStatus === "CANCELLED" &&
                  "rgba(28, 134, 184, 0.25)",
              }}
              onClick={() => {
                if (!loading) {
                  setQueryObject((prev) => ({
                    ...prev,
                    status: "LISTED",
                    pageNumber: 1,
                    keyword: "",
                    subStatus: "CANCELLED",
                  }))
                }
              }}
            ></TreeItem>
          </>
        )}
      </TreeItem>
      <TreeItem
        nodeId="3"
        label={`Sold (${countBuffer?.soldCars || 0})`}
        disabled={loading}
        sx={{
          backgroundColor:
            queryObject.status === "SOLD" && "rgba(28, 134, 184, 0.25)",
        }}
        onClick={() => {
          if (!loading) {
            setQueryObject((prev) => {
              return {
                ...prev,
                status: "SOLD",
                pageNumber: 1,
                keyword: "",
              }
            })
          }
        }}
      ></TreeItem>
      <TreeItem
        nodeId="4"
        label={`Archived (${countBuffer?.archivedCars || 0})`}
        disabled={loading}
        sx={{
          backgroundColor:
            queryObject.status === "ARCHIVE" && "rgba(28, 134, 184, 0.25)",
        }}
        onClick={() => {
          if (!loading) {
            setQueryObject((prev) => ({
              ...prev,
              status: "ARCHIVE",
              pageNumber: 1,
              keyword: "",
            }))
          }
        }}
      ></TreeItem>
    </TreeView>
  )
}
