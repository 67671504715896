import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RESPONSE_RESET } from "../../../constants/responseConstants"
import { NOTE_CREATE_RESET } from "../../../constants/commonConstants"
import { setMessage } from "../../../actions/responseAction"
import { getNotesList } from "../../../actions/commonActions"

const useNoteCreate = (sellerType, sellerId) => {
  const noteCreate = useSelector((state) => state.common.noteCreate)
  const { loading, error, success } = noteCreate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      if (success) {
        dispatch(setMessage("Note Created Successfully!"))
        dispatch(getNotesList(sellerType, sellerId))
      }
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: NOTE_CREATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error, sellerType, sellerId])

  return [loading, error, success]
}

export default useNoteCreate
