import React, { useEffect, useState } from "react"

const TextInputWithDropdown = ({
  label,
  value,
  onChange,
  options,
  onSelect,
  disabled,
  name,
}) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value])

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleOptionSelection = (option) => {
    setInputValue(option.name)
    if (onSelect) {
      onSelect(option)
    }
  }

  return (
    <div className="input-with-dropdown">
      <div className="input-wrapper">
        <label className="label">{label}</label>
        <input
          type="text"
          className="input"
          name={name}
          value={inputValue}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </div>
      {options && options.length > 0 && (
        <div className="dropdown-dealership">
          {options.map((option) => (
            <div
              className="dealership-card"
              key={option._id}
              onClick={() => handleOptionSelection(option)}
            >
              <p className="text">{option.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TextInputWithDropdown
