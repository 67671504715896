import React from "react"
import { formatDateAndTime } from "../../utils/utilsFunctions"
import { BasicPagination } from "../utils/Pagination"
import "./LogList.scss"
import { Link } from "react-router-dom"

const LogList = ({ pages, logs, queryObject, setQueryObject }) => {
  return (
    <div className="log-list">
      <div className="list flex">
        <table className="grid">
          <tbody>
            <tr className="table-head">
              <th className="table-head-text name"> Seller</th>
              <th className="table-head-text">Car name</th>
              <th className="table-head-text cars">Called Time</th>
            </tr>
            {logs?.map((log, i) => (
              <tr className="table-data" key={i}>
                {log.sellerCompanyName ? (
                  <td className="table-data-text">
                    <Link
                      to={`/seller/companies/${log.sellerCompanyId}/show`}
                      className="link"
                      target="blank"
                    >
                      {log.sellerCompanyName}
                    </Link>
                  </td>
                ) : log.sellerDealershipName ? (
                  <td className="table-data-text">
                    <Link
                      to={`/seller/dealerships/${log.sellerDealershipId}/show`}
                      className="link"
                      target="blank"
                    >
                      {log.sellerDealershipName}
                    </Link>
                  </td>
                ) : (
                  <td className="table-data-text">
                    <Link
                      to={`/seller/individuals/${log.sellerIndividualId}/show`}
                      className="link"
                      target="blank"
                    >
                      {`${log.sellerIndividualFirstName} ${log.sellerIndividualLastName} ${log.sellerIndividualName}`}
                    </Link>
                  </td>
                )}

                <td className="table-data-text">
                  <Link to={`/car/${log.car}`} className="link" target="blank">
                    {log.brand} {log.model} {log.year}
                  </Link>
                </td>
                <td className="table-data-text">
                  {formatDateAndTime(log.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {queryObject.pageNumber && (
          <BasicPagination
            queryObject={queryObject}
            setQueryObject={setQueryObject}
            pages={pages}
          />
        )}
      </div>
    </div>
  )
}

export default LogList
