import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CustomTabs from "../../components/common/CustomTabs"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import "./AuctionListScreen.scss"
import { DropDownMenu } from "../../components/common/DropDownMenu"

import AuctionList from "../../components/auctionscreen/AuctionList"
import Message from "../../components/utils/Message"
import { LinearProgress } from "@mui/material"
import useAuctionList from "../../state/customHooks/auction/useAuctionList"

const AuctionListScreen = () => {
  const [activeTab, setActiveTab] = useState("scheduled")

  const navigate = useNavigate()

  const [loading, error, auctions, count] = useAuctionList(activeTab)

  const [countBuffer, setCountBuffer] = useState({})

  useEffect(() => {
    !loading && setCountBuffer(count)
  }, [count])

  const dealershipCreateHandler = () => {
    navigate(`/auction/create?type=dealerships`)
  }
  const companyCreateHandler = () => {
    navigate(`/auction/create?type=companies`)
  }
  const officialBrandCreateHandler = () => {
    navigate(`/auction/create?type=officialBrands`)
  }

  const menuItem = [
    {
      title: "Dealership",
      action: dealershipCreateHandler,
    },
    {
      title: "Company",
      action: companyCreateHandler,
    },
    {
      title: "Official Brand",
      action: officialBrandCreateHandler,
    },
  ]

  const carStatusTab = [
    { tabName: "scheduled", count: countBuffer?.scheduled || 0 },
    { tabName: "active", count: countBuffer?.active || 0 },
    { tabName: "closed", count: countBuffer?.closed || 0 },
    { tabName: "canceled", count: countBuffer?.canceled || 0 },
    { tabName: "archived", count: countBuffer?.archived || 0 },
  ]

  const AuctionTabsBody = [
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList auctionList={auctions} />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList auctionList={auctions} />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList auctionList={auctions} />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList auctionList={auctions} />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList auctionList={auctions} />
      )}
    </>,
  ]

  const tabClickHandler = (click) => {
    setActiveTab(click.tabName)
  }

  return (
    <div className="auction-screen">
      <InnerHeader title="Auction List" className="inner-header">
        <DropDownMenu type="inner-header" menuItem={menuItem} title="Create" />
      </InnerHeader>

      <InnerContainer>
        <CustomTabs
          tabs={carStatusTab}
          tabsBody={AuctionTabsBody}
          activeTab={activeTab}
          tabClickHandler={tabClickHandler}
        />
      </InnerContainer>
    </div>
  )
}

export default AuctionListScreen
