import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getNotesList } from "../../../actions/commonActions"

const useNoteList = (sellerType, sellerId) => {
  const noteList = useSelector((state) => state.common.noteList)
  const { loading: loadingNotes, error: errorNotes, notes } = noteList

  const dispatch = useDispatch()

  useEffect(() => {
    if ((sellerType, sellerId)) {
      dispatch(getNotesList(sellerType, sellerId))
    }
  }, [dispatch, sellerId, sellerType])

  return [loadingNotes, errorNotes, notes]
}

export default useNoteList
