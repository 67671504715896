import "./Forms.scss"
import React, { useState, useEffect } from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./YearForm.scss"
import { useNavigate } from "react-router-dom"
import useYearDetail from "../../state/customHooks/carct/useYearDetail"
import { useDispatch } from "react-redux"
import { updateYear } from "../../state/actions/yearActions"
import useYearUpdate from "../../state/customHooks/carct/useYearUpdate"

const YearForm = (props) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [, , successYearUpdate, updatedYear] = useYearUpdate()

  const [fields, setFields] = useState({
    year: "",
    generation: "",
    active: false,
    popular: false,
    id: "",
  })

  useEffect(() => {
    if (props.propsData) {
      let param = props.propsData.year
      if (param?._id) {
        setFields({
          ...fields,
          year: new Date("1-1-" + param.name),
          generation: param.generation ? param.generation._id : "",
          active: param.active,
          isPopular: param.isPopular,
          id: param._id,
        })
      }
    }
  }, [props.propsData])

  useEffect(() => {
    if (successYearUpdate) {
      setFields({
        ...fields,
        generation: updatedYear?.generation,
      })
    }
  }, [successYearUpdate])

  const [loadingYearDetail, , year] = useYearDetail(fields.id)

  const handleGen = (e) => {
    if (!fields.id) {
      setFields({
        ...fields,
        generation: e.target.value,
      })
    } else {
      dispatch(
        updateYear(fields.id, {
          generation: e.target.value,
        })
      )
    }
  }

  const handleYear = (e, type) => {
    setFields({
      ...fields,
      [type]: e,
    })
  }

  const submitYear = () => {
    if (!fields.id) {
      let year = new Date(fields.year).getFullYear()
      let params = {
        name: year,
        generation: fields.generation,
        active: fields.active,
        brand: props.data.model.brand,
        model: props.data.model._id,
        id: fields.id,
        popular: fields.popular,
      }

      navigate(
        `/catalogue/year?name=${params.name}&brand=${params.brand}&model=${params.model}&generation=${params.generation}`
      )
    } else if (!loadingYearDetail && year.year._id) {
      navigate(`/catalogue/year/${year.year._id}/edit`)
    }
  }

  return (
    <div className="forms-wrapper year-form">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Year
          </label>
          <DatePicker
            selected={fields.year}
            onChange={(date) => handleYear(date, "year")}
            showYearPicker
            dateFormat="yyyy"
            disabled={fields.id ? true : false}
            placeholderText="Year"
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Generation
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select Generation"
            input={<OutlinedInput />}
            value={fields.generation}
            name="generation"
            displayEmpty
            label="Select"
            onChange={handleGen}
          >
            <MenuItem value="" disabled>
              --
            </MenuItem>
            {props.propsData.generations.length > 0
              ? props.propsData.generations.map((gen) => {
                  return (
                    <MenuItem key={gen._id} value={gen._id}>
                      {gen.name}
                    </MenuItem>
                  )
                })
              : ""}
          </Select>
        </FormControl>
      </div>

      <div className="form-group pt-17 input-wrapper flex col"></div>

      <div className="r2 flex">
        <Button
          className={!fields.year ? `bg-green disabled` : "bg-green"}
          onClick={() => {
            submitYear()
          }}
          disabled={!fields.year}
        >
          {fields.id && fields.id !== "" ? `EDIT` : `SAVE`}
        </Button>
      </div>
    </div>
  )
}

export default YearForm
