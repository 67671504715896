import React, { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import AnalyticsValueCard from "../../components/common/AnalyticsValueCard"
import CarList from "../../components/common/CarList"
import SellerDetailTabs from "../../components/sellerdetailscreen/SellerDetailTabs"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import Loader from "../../components/utils/Loader"
import Message from "../../components/utils/Message"
import { updateSeller } from "../../state/actions/sellerActions"
import "./SellerDetailScreen.scss"
import SellerGeoCard from "../../components/sellerdetailscreen/SellerGeoCard"
import SellerNotes from "../../components/sellerdetailscreen/SellerNotes"
import useSellerUpdate from "../../state/customHooks/seller/useSellerUpdate"
import useUserCreate from "../../state/customHooks/user/useUserCreate"
import useSellerDetail from "../../state/customHooks/seller/useSellerDetail"
import useUserUpdate from "../../state/customHooks/user/useUserUpdate"
import { updateArrayState } from "../../utils/utilsFunctions"
import useUserDelete from "../../state/customHooks/user/useUserDelete"
import InnerHeaderButton from "../../components/utils/Button"
import SellerCardList from "../../components/sellerdetailscreen/SellerCardList"
import SellerDuplicatePopup from "../../components/common/SellerDuplicatePopup"

export const SellerContext = createContext()

const SellerDetailScreen = () => {
  const params = useParams()
  const { id, sellerType } = params

  const dispatch = useDispatch()

  const [sellerBuffer, setSellerBuffer] = useState({})
  const [loadingBuffer, setLoadingBuffer] = useState(true)
  const [usersBuffer, setUsersBuffer] = useState([])
  const [createBranchPopup, setCreateBranchPopup] = useState(false)

  const [
    loadingSellerDetail,
    errorSellerDetail,
    seller,
    analytics,
    users,
    parentUsers,
  ] = useSellerDetail(sellerType, id)

  const [loadingSellerUpdate, , successSellerUpdate, updatedSeller] =
    useSellerUpdate()

  const [loadingUserCreate, , successUserCreate, userCreated] = useUserCreate()

  const [loadingUserUpdate, , successUserUpdate, updatedUser] = useUserUpdate()

  const [loadingUserDelete, , successUserDelete, userDeleted] = useUserDelete()

  useEffect(() => {
    if (!loadingSellerDetail && seller?._id) {
      setSellerBuffer({
        ...seller,
        geoLocation: `${seller?.location?.latitude || "-"}, ${
          seller?.location?.longitude || "-"
        } `,
      })
      setUsersBuffer(users)
      setLoadingBuffer(false)
    }
  }, [dispatch, id, loadingSellerDetail])
  useEffect(() => {
    if (!loadingSellerUpdate && updatedSeller?._id && successSellerUpdate) {
      setSellerBuffer({
        ...updatedSeller,
        geoLocation: `${updatedSeller.location.latitude}, ${updatedSeller.location.longitude} `,
      })
      setLoadingBuffer(false)
    }
    if (!loadingUserUpdate && successUserUpdate) {
      updateArrayState(updatedUser, setUsersBuffer)
    }
    if (!loadingUserCreate && successUserCreate) {
      setUsersBuffer((prev) => {
        return [...prev, userCreated]
      })
    }
    if (!loadingUserDelete && successUserDelete && userDeleted) {
      setUsersBuffer((prev) => {
        return prev.filter((user) => user._id !== userDeleted)
      })
    }
  }, [
    dispatch,
    successSellerUpdate,
    successUserUpdate,
    successUserCreate,
    successUserDelete,
  ])

  const updateSellerHandler = (key, value) => {
    if (["geoLocation", "city", "address"].includes(key)) {
      let latitude
      let longitude
      if (key === "geoLocation") {
        const geolocationSplit = value.split(",")
        latitude = geolocationSplit[0]
        longitude = geolocationSplit[1]
        setSellerBuffer((prev) => {
          dispatch(
            updateSeller(sellerType, sellerBuffer._id, {
              ...prev,
              location: { ...sellerBuffer.location, latitude, longitude },
              geoLocation: `${latitude}, ${longitude} `,
            })
          )
          return {
            ...prev,
            location: { ...sellerBuffer.location, latitude, longitude },
          }
        })
      } else {
        setSellerBuffer((prev) => {
          dispatch(
            updateSeller(sellerType, sellerBuffer._id, {
              ...prev,
              location: { ...sellerBuffer.location, [key]: value },
            })
          )
          return {
            ...prev,
            location: { ...sellerBuffer.location, [key]: value },
          }
        })
      }
    } else {
      setSellerBuffer((prev) => {
        dispatch(
          updateSeller(sellerType, sellerBuffer._id, { ...prev, [key]: value })
        )
        return { ...prev, [key]: value }
      })
    }
  }

  return loadingBuffer ? (
    <Loader />
  ) : errorSellerDetail ? (
    <Message variant="error">{errorSellerDetail} </Message>
  ) : (
    <div className="dealership-detail-screen">
      <InnerHeader
        className="inner-header"
        title={
          sellerType === "dealerships"
            ? `Dealership: ${sellerBuffer?.name} / Dealership ID ${seller?._id}`
            : sellerType === "companies"
            ? `Company: ${seller?.name} / Company ID ${seller?._id}`
            : sellerType === "officialBrands"
            ? `Official Brand: ${seller?.name} / Official Brand ID ${seller?._id} `
            : sellerType === "individuals" && users.length
            ? `Individual: ${users[0]?.firstName} ${users[0]?.lastName} / Individual ID ${seller?._id} `
            : `Branch: ${seller?.name} / Branch ID ${seller?._id}`
        }
      >
        <div className="flex buttons-wrapper">
          {sellerType === "officialBrands" && (
            <InnerHeaderButton onClick={() => setCreateBranchPopup(true)}>
              Create Branch
            </InnerHeaderButton>
          )}
        </div>
      </InnerHeader>

      <InnerContainer>
        <SellerGeoCard
          seller={sellerBuffer}
          updateSellerHandler={updateSellerHandler}
          loadingSellerDetail={loadingSellerDetail}
        />
        <div className="dealership-detail-r1 flex jcsb">
          <div className="dealership-detail-c1">
            <SellerContext.Provider
              value={{
                sellerBuffer,
                users: usersBuffer,
                sellerType,
                parentUsers,
              }}
            >
              <SellerDetailTabs />
            </SellerContext.Provider>
            <div className="notes-card">
              <SellerNotes seller={seller} sellerType={sellerType} />
            </div>
          </div>

          <div className="dealership-detail-c2 flex col">
            <div className="dealership-stats flex">
              <AnalyticsValueCard
                value={analytics?.carsListedCount || 0}
                label="Cars"
                title="Cars Posted"
              />
              <AnalyticsValueCard
                value={analytics?.carsSoldCount || 0}
                label="Cars"
                title="Cars Sold"
              />
              <AnalyticsValueCard
                value={analytics?.daysSinceLastActivity || "N/A"}
                label="Days"
                title="Last Activity"
              />
              <AnalyticsValueCard
                value={analytics?.daysSinceLastCarPosted || "N/A"}
                label="Days"
                title="Last Car Posted"
              />
            </div>
            <div className="seller-car-list">
              {sellerType !== "officialBrands" ? (
                <CarList seller={sellerBuffer} sellerType={sellerType} />
              ) : (
                <SellerCardList seller={sellerBuffer} sellerType={sellerType} />
              )}
            </div>
          </div>
        </div>
      </InnerContainer>
      <SellerDuplicatePopup
        trigger={createBranchPopup}
        setTrigger={setCreateBranchPopup}
        sellerType={sellerType}
        seller={seller}
      />
    </div>
  )
}

export default SellerDetailScreen
