import React, { useEffect, useState } from "react"
import "./SmsCredit.scss"
import Axios from "../../utils/axios"
import { currencyWithCommas, extractNumber } from "../../utils/utilsFunctions"
import { REACT_APP_ENV } from "../../utils/base"

const SmsCredit = () => {
  const [smsCredit, setSmsCredit] = useState("")

  const checkSMSCredit = async () => {
    const { data } = await Axios.get(`/v1/utils/check-credit`)
    const credit = extractNumber(data) / 10000
    setSmsCredit(credit.toFixed(2))
  }

  useEffect(() => {
    if (REACT_APP_ENV === "PUBLIC-PROD" || REACT_APP_ENV === "PUBLIC-DEV") {
      checkSMSCredit()
    } else {
      setSmsCredit("0")
    }
  }, [])

  return (
    <div className="SMScontainer">
      <div className="text-left">
        <div className="smsText">
          <h2>SMS</h2>
        </div>{" "}
        Credit Remaining
      </div>
      <div className="text-right">{currencyWithCommas(smsCredit)}</div>
    </div>
  )
}

export default SmsCredit
