import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import { getBrandList } from "../../state/actions/brandActions"
import { getFilterOptions } from "../../state/actions/carMpActions"
import { getModelList } from "../../state/actions/modelActions"
import "./OpportunityCreateScreen.scss"
import _ from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import { createOpportunity } from "../../state/actions/opportunityActions"

const OpportunityCreateScreen = () => {
  const navigate = useNavigate()

  const [brand, setBrand] = useState("")
  const [year, setYear] = useState("")
  const [model, setModel] = useState("")
  const [trim, setTrim] = useState("")
  const [otherOption, setOtherOption] = useState("")
  const [condition, setCondition] = useState([])
  const [option, setOption] = useState([])
  const [color, setColor] = useState("")
  const [fuels, setFuels] = useState([])
  const [lowPrice, setLowPrice] = useState("")
  const [highPrice, setHighPrice] = useState("")

  const opportunityCreate = useSelector(
    (state) => state.opportunity.opportunityCreate
  )
  const { success } = opportunityCreate

  const params = useParams()
  const { id } = params

  const opportunity = {
    brand,
    model,
    year,
    trim,
    _user: id,
    condition,
    options: { option, otherOption },
    fuel: fuels,
    color,
    budget: [lowPrice, highPrice],
  }

  const dispatch = useDispatch()
  const opportunitySaveHandler = () => {
    dispatch(createOpportunity(opportunity))
  }

  useEffect(() => {
    dispatch(getBrandList())
    dispatch(getFilterOptions())
    if (brand) {
      dispatch(getModelList(brand))
    }
  }, [dispatch, brand])

  useEffect(() => {
    if (success) {
      navigate(-1)
    }
  }, [success])

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading: loadingBrands, brands } = brandList
  const modelList = useSelector((state) => state.carCatalogue.modelList)
  const { loading: loadingModels, models } = modelList

  const carOptions = useSelector((state) => state.carMarketplace.carOptions)
  const { options } = carOptions

  const yearsList = _.range(new Date().getFullYear(), 1989).map((item) =>
    item.toString()
  )

  return (
    <div className="opportunity-create-screen">
      <InnerHeader title="Opportunity" className="inner-header">
        <div className="btn-save" onClick={opportunitySaveHandler}>
          Save
        </div>
      </InnerHeader>
      <InnerContainer>
        <div className="section">
          <p className="section-title">Car Information</p>
          <div className="r grid-4">
            <div className="input-wrapper">
              <label className="label">Brand</label>
              <select
                name="brand"
                className="select"
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value)
                }}
              >
                <option value="">-</option>
                {!loadingBrands &&
                  brands.popularBrands.map((brand, i) => (
                    <option value={brand._id} key={i}>
                      {brand.name}
                    </option>
                  ))}
                {!loadingBrands &&
                  brands.otherBrands.map((brand, i) => (
                    <option value={brand._id} key={i}>
                      {brand.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Model</label>
              <select
                name="model"
                className="select"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value)
                }}
              >
                <option value="">-</option>
                {!loadingModels &&
                  models.map((model, i) => (
                    <option value={model._id} key={i}>
                      {model.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Year</label>
              <select
                name="year"
                className="select"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
              >
                <option value="">-</option>
                {yearsList.map((year, i) => (
                  <option value={year} key={i}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Trim</label>
              <input
                name="trim"
                className="input"
                value={trim}
                onChange={(e) => {
                  setTrim(e.target.value)
                }}
              />
            </div>
          </div>
        </div>

        <div className="r grid-4">
          <div className="options-wrapper">
            <label className="label">Condition</label>
            <div className="flex aic">
              {options?.CONDITIONS?.map((option) => (
                <div className="input-wrapper-checkbox flex aic">
                  <input
                    type="checkbox"
                    value={option}
                    onChange={(e) => {
                      // add to list
                      if (e.target.checked) {
                        setCondition([...condition, option])
                      } else {
                        // remove from list
                        setCondition(condition.filter((o) => o !== option))
                      }
                    }}
                    className="checkbox"
                  />
                  <label className="label-checkbox">{option}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="options-wrapper">
            <label className="label">Options</label>
            <div className="flex aic">
              {options?.VEHICLE_OPTIONS?.map((vehicleOption) => (
                <div className="input-wrapper-checkbox flex aic">
                  <input
                    type="checkbox"
                    value={vehicleOption}
                    onChange={(e) => {
                      // add to list
                      if (e.target.checked) {
                        setOption([...option, vehicleOption])
                      } else {
                        // remove from list
                        setOption(condition.filter((o) => o !== vehicleOption))
                      }
                    }}
                    className="checkbox"
                  />
                  <label className="label-checkbox">{vehicleOption}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="input-wrapper">
            <label className="label">Other Option</label>
            <input
              name="option"
              className="input"
              value={otherOption}
              onChange={(e) => {
                setOtherOption(e.target.value)
              }}
            />
          </div>
          <div className="options-wrapper">
            <label className="label">Fuel</label>
            <div className="flex aic">
              {options?.FUELS?.map((fuel) => (
                <div className="input-wrapper-checkbox flex aic">
                  <input
                    type="checkbox"
                    value={fuel}
                    onChange={(e) => {
                      // add to list
                      if (e.target.checked) {
                        setFuels([...fuels, fuel])
                      } else {
                        // remove from list
                        setFuels(condition.filter((o) => o !== fuel))
                      }
                    }}
                    className="checkbox"
                  />
                  <label className="label-checkbox">{fuel}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="r grid-4">
          <div className="input-wrapper">
            <label className="label">Color</label>
            <select
              name="model"
              className="select"
              value={color}
              onChange={(e) => {
                setColor(e.target.value)
              }}
            >
              <option value="">-</option>
              {options?.COLORS?.map((color, i) => (
                <option value={color} key={i}>
                  {color}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="r grid-4">
          <div className="options-wrapper flex aic">
            <div className="input-wrapper">
              <label className="label">Price Min</label>
              <input
                name="budget"
                className="input"
                value={lowPrice}
                onChange={(e) => {
                  setLowPrice(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="options-wrapper flex aic">
            <div className="input-wrapper">
              <label className="label">Price Max</label>
              <input
                name="budget"
                className="input"
                value={highPrice}
                onChange={(e) => {
                  setHighPrice(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default OpportunityCreateScreen
