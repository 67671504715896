import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AUCTION_DETAIL_RESET } from "../../constants/auctionConstants"
import { getAuctionDetail } from "../../actions/auctionsAction"

const useAuctionDetail = (id) => {
  const dispatch = useDispatch()

  const auctionDetail = useSelector((state) => state.auction.auctionDetail)
  const { loading, error, auction } = auctionDetail

  useEffect(() => {
    dispatch({ type: AUCTION_DETAIL_RESET })
    id && dispatch(getAuctionDetail(id))
  }, [dispatch, id])

  return [loading, error, auction]
}

export default useAuctionDetail
