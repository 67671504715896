import React from "react"
import { Button } from "@mui/material"
import "./Button.scss"

const CustomButton = (props) => {
  return (
    <div>
      <Button
        className={props.className}
        onClick={props.onClick}
        disabled={props.disabled}
        variant={props.variant}
      >
        {props.title}
      </Button>
    </div>
  )
}

export default CustomButton
