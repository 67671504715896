import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { SALE_DELETE_RESET } from "../../constants/saleConstants"

const useSaleDelete = () => {
  const saleDelete = useSelector((state) => state.sale.saleDelete)
  const { loading, error, success, sale } = saleDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Event Deleted Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: SALE_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 4000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, sale]
}

export default useSaleDelete
