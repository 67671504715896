import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { SELLER_DELETE_RESET } from "../../constants/sellerContants"

const useSellerDelete = () => {
  const sellerDelete = useSelector((state) => state.seller.sellerDelete)

  const dispatch = useDispatch()

  const {
    loading: loadingSellerDelete,
    success: successSellerDelete,
    error: errorSellerDelete,
    seller: deletedSeller,
  } = sellerDelete

  useEffect(() => {
    if (successSellerDelete) {
      dispatch(setMessage("Seller Deleted Successfully"))
      setTimeout(() => {
        dispatch({ type: SELLER_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
    if (errorSellerDelete) {
      dispatch(setMessage(errorSellerDelete))
      setTimeout(() => {
        dispatch({ type: SELLER_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successSellerDelete, errorSellerDelete])

  return [
    loadingSellerDelete,
    errorSellerDelete,
    successSellerDelete,
    deletedSeller,
  ]
}

export default useSellerDelete
