import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAuctionList } from "../../actions/auctionsAction"
import useAuctionDelete from "./useAuctionDelete"

const useAuctionList = (status) => {
  const dispatch = useDispatch()

  const auctionList = useSelector((state) => state.auction.auctionList)
  const { loading, error, auctions, counts } = auctionList

  const [, , success] = useAuctionDelete()

  useEffect(() => {
    dispatch(getAuctionList(status))
    // eslint-disable-next-line
  }, [dispatch, status])
  useEffect(() => {
    if (success) {
      if (status) {
        dispatch(getAuctionList(status))
      }
    }
    // eslint-disable-next-line
  }, [dispatch, status, success])

  return [loading, error, auctions, counts]
}

export default useAuctionList
