import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import threedots from "../../images/dashboard/carsmpscreens/carlist/threedots.svg"
import "./CarCard.scss"
import StatusChangePopup from "./StatusChangePopup"
import { useDispatch } from "react-redux"
import { deleteCar, updateCar } from "../../state/actions/carMpActions"
import {
  OpenInNewTabLink,
  currencyWithCommas,
  hasPermission,
  numberWithCommas,
} from "../../utils/utilsFunctions"
import { Link, useNavigate } from "react-router-dom"
import TextInput from "../input/TextInput"
import CustomSwitch from "../selection/Switch"
import { baseURLWeb } from "../../utils/base"
import useAuthUser from "../../state/customHooks/user/useAuthUser"

const CarCard = ({ car, carUpdateId, loadingCarUpdate, detailOnly }) => {
  const [user] = useAuthUser()

  const dateStringCreatedAt = car.createdAt.substring(0, 10).split("-")
  const dateStringUpdatedAt = car.updatedAt.substring(0, 10).split("-")
  const [anchorEl, setAnchorEl] = useState(null)
  const [statusPopup, setStatusPopup] = useState(false)
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [soldPrice, setSoldPrice] = useState("")
  const [soldPricePopup, setSoldPricePopup] = useState(false)
  const [isCarmateContact, setIsCarmateContact] = useState(false)
  const [isRecommended, setIsRecommended] = useState(false)
  const [status, setStatus] = useState(car.status)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setIsCarmateContact(car.isCarmateContact)
    setIsRecommended(car.isRecommended)
  }, [])

  const statusUpdateHandler = (status) => {
    if (status === "SOLD") {
      setSoldPricePopup(true)
      if (soldPrice) {
        dispatch(updateCar(car._id, { status, soldPrice }))
        setSoldPricePopup(false)
        setStatusPopup(false)
      }
    } else {
      dispatch(updateCar(car._id, { status }))
      setStatusPopup(false)
    }
  }

  const cancelHandler = () => {
    setStatusPopup(false)
    setSoldPricePopup(false)
    setStatus(car.status)
    setSoldPrice("")
  }

  const updateCarContact = (e) => {
    setIsCarmateContact((prev) => {
      dispatch(updateCar(car._id, { isCarmateContact: !prev }))
      return !prev
    })
  }
  const updateCarRecommended = (e) => {
    setIsRecommended((prev) => {
      dispatch(updateCar(car._id, { isRecommended: !prev }))
      return !prev
    })
  }

  const carListingId = car.listingId ? `ID ${car.listingId}` : undefined
  const carMileage = car.mileage
    ? `${numberWithCommas(car.mileage)} ${car.mileageUnit}`
    : "Mileage not provided"
  const carWarranty = car.warranty
    ? car.warranty === 0
      ? `No Warranty`
      : car.warranty < 30 && car.warranty !== 0
      ? `${car.warranty / 7} week`
      : car.warranty < 365 && car.warranty >= 30
      ? `${car.warranty / 30} months`
      : `${car.warranty / 365} Years`
    : undefined
  const carInspection = car.isInspection ? `Pre-inspected` : undefined

  const optionsFirstLine = [
    carListingId,
    car?.condition,
    car?.taxStatus,
    car?.vin,
  ].filter(
    (option, i) => option !== undefined && option !== null && option !== ""
  )
  const optionsSecondLine = [
    carMileage,
    car?.fuel,
    car.vehicleOption,
    car?.color,
    carWarranty,
    carInspection,
  ].filter(
    (option, i) => option !== undefined && option !== null && option !== ""
  )

  return (
    <div className="car-card" key={car._id}>
      <div className="card-content-wrapper  ">
        <div className="car-image-detail-wrapper flex">
          <img src={car.image} alt="carimage" className="car-image" />
          <Link to={`/car/${car._id}`} target="_blank">
            <div className="text-wrapper">
              <p className="title">
                {`${car.year || car.otherYear || ""} ${
                  car.brand || car.otherBrand || ""
                } ${car.model || car.otherModel || "-"} ${
                  car.trim || car.otherTrim || ""
                } ${car.note || ""}`}
              </p>
              <p className="text">
                {`Created at:${dateStringCreatedAt[2]}/${dateStringCreatedAt[1]}/${dateStringCreatedAt[0]}`}
              </p>

              <p className="text">
                {optionsFirstLine.map((o, i) => (
                  <span className="flex inline" key={i}>
                    {o}
                    {i === optionsFirstLine.length - 1 ? (
                      ""
                    ) : (
                      <span className="inline"> / </span>
                    )}
                  </span>
                ))}
              </p>
              <p className="text">
                {optionsSecondLine.map((o, i) => (
                  <span className="flex inline" key={i}>
                    {o}
                    {i === optionsSecondLine.length - 1 ? (
                      ""
                    ) : (
                      <span className="inline"> / </span>
                    )}
                  </span>
                ))}
              </p>
            </div>
          </Link>
        </div>
        {!detailOnly && (
          <div className="col-2 flex jcc aic">
            <p className="text">
              Views: {car.views ? numberWithCommas(Number(car.views)) : 0}
            </p>
          </div>
        )}
        {!detailOnly && (
          <div className="col-3 flex col jcc">
            {car.isSale ? (
              <p className="text">
                Sale Price:{" "}
                {car.saleAmount
                  ? currencyWithCommas(car.price - car.saleAmount)
                  : currencyWithCommas(
                      car.price - (car.salePercentage * car.price) / 100
                    )}
              </p>
            ) : null}

            <p className="text">
              Secret Price:{" "}
              {car.secretPrice ? currencyWithCommas(car.secretPrice) : ""}
            </p>
            <p className="text">
              Commission:{" "}
              {car.commission ? currencyWithCommas(car.commission) : ""}
            </p>
          </div>
        )}
        {!detailOnly && (
          <div className="col-4 flex col jcc">
            {car.noteLatest ? <p className="text">{car.noteLatest}</p> : null}
          </div>
        )}
        {!detailOnly && hasPermission(user, "car", "update") && (
          <div className="col-5 flex col jcc">
            {
              <div className="">
                <CustomSwitch
                  label="Show Carmate Contact"
                  checked={isCarmateContact}
                  onChange={updateCarContact}
                />
                <CustomSwitch
                  label="Recommended"
                  checked={isRecommended}
                  onChange={updateCarRecommended}
                />
              </div>
            }
          </div>
        )}

        <div className="seller-detail-wrapper">
          <div className="seller-info-text flex col aie">
            <p className="text-price">{currencyWithCommas(car.price)}</p>
            {car.soldPrice && (
              <p className="text">
                Sold Price: ${numberWithCommas(car.soldPrice)}
              </p>
            )}

            <p className="text">
              {car?._sellerDealership
                ? `Dealership`
                : car?._sellerIndividualId
                ? "Individual Seller"
                : car?._sellerCompany
                ? "Company"
                : car?._sellerOfficialBrand
                ? "Official Brand"
                : null}
            </p>
            <p>
              <Link
                className="text text-seller-name"
                to={`/seller/${
                  car?._sellerDealership
                    ? `dealerships`
                    : car?._sellerIndividualId
                    ? "individuals"
                    : car?._sellerCompany
                    ? "companies"
                    : car?._sellerOfficialBrand
                    ? "officialBrands"
                    : null
                }/${
                  car?._sellerDealership
                    ? `${car._sellerDealershipId}`
                    : car?._sellerIndividualId
                    ? `${car?._sellerIndividualId}`
                    : car?._sellerCompany
                    ? `${car?._sellerCompanyId}`
                    : car?._sellerOfficialBrand
                    ? `${car?._sellerOfficialBrandId}`
                    : null
                }/show`}
                target="blank"
              >
                {car?._sellerDealership
                  ? `${car?._sellerDealership}`
                  : car?._sellerCompany
                  ? `${car?._sellerCompany}`
                  : car?._sellerIndividualId
                  ? `${car?.sellerUsername}`
                  : `${car?._sellerOfficialBrand}`}
              </Link>
            </p>
            <p className="text">
              {car?._auction && `Auction`}
              {car?._sale && `On Sale`}
            </p>
            {loadingCarUpdate && carUpdateId === car._id ? (
              <p className="text">Processing...</p>
            ) : (
              ""
            )}
            <p className="text">
              {`Created at ${dateStringCreatedAt[2]}/${dateStringCreatedAt[1]}/${dateStringCreatedAt[0]}`}
            </p>
            <p className="text">
              {`Updated at ${dateStringUpdatedAt[2]}/${dateStringUpdatedAt[1]}/${dateStringUpdatedAt[0]}`}
            </p>
          </div>
          {!detailOnly && (
            <div className="three-dots-button-wrapper">
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="details-button"
              >
                <img src={threedots} alt="three-dots" />
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                className="menu-item"
                style={{ width: "200px" }}
              >
                {hasPermission(user, "car", "update") && (
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      setStatusPopup(true)
                    }}
                  >
                    Status Change
                  </MenuItem>
                )}
                {hasPermission(user, "car", "update") && (
                  <MenuItem
                    onClick={() =>
                      navigate(
                        `/car/${car._id}/edit?type=${
                          car._sellerDealership
                            ? "dealerships"
                            : car._sellerCompany
                            ? "companies"
                            : car._sellerOfficialBrand
                            ? "officialBrands"
                            : car._sellerIndividualId
                            ? "individuals"
                            : null
                        }`
                      )
                    }
                  >
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    OpenInNewTabLink(`${baseURLWeb}/cars/${car._id}`)
                  }}
                >
                  View Website
                </MenuItem>
                {hasPermission(user, "car", "delete") && (
                  <MenuItem
                    onClick={() => {
                      setConfirmPopup(true)
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </div>
      </div>

      <StatusChangePopup trigger={statusPopup} title="Change Status">
        {!soldPricePopup ? (
          <select
            className="status-select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value)
            }}
          >
            <option value="PENDING">PENDING</option>
            <option value="LISTED">LISTED</option>
            <option value="SOLD">SOLD</option>
            <option value="ARCHIVE">ARCHIVE</option>
          </select>
        ) : (
          <TextInput
            value={soldPrice}
            setValue={setSoldPrice}
            label={"Sold Price"}
          />
        )}

        <div
          style={soldPricePopup ? { marginTop: 10 } : {}}
          className="status-button-wrapper"
        >
          <Button className="btn light" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button
            className="btn primary"
            disabled={soldPricePopup && !soldPrice}
            onClick={() => {
              statusUpdateHandler(status)
            }}
          >
            Save
          </Button>
        </div>
      </StatusChangePopup>
      <StatusChangePopup trigger={confirmPopup} title="Delete Car">
        <div className="text">Are you sure you want to delete?</div>
        <div className="status-button-wrapper">
          <Button
            className="btn light"
            onClick={() => {
              setConfirmPopup(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn primary"
            onClick={() => {
              dispatch(deleteCar(car._id))
              setConfirmPopup(false)
            }}
          >
            Delete
          </Button>
        </div>
      </StatusChangePopup>
    </div>
  )
}

export default CarCard
