import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { TRIM_DELETE_RESET } from "../../constants/trimConstants"

const useTrimDelete = () => {
  const dispatch = useDispatch()

  const trimDelete = useSelector((state) => state.carCatalogue.trimDelete)
  const {
    loading: loadingTrimDelete,
    error: errorTrimDelete,
    success: successTrimDelete,
    trim: trimDeleted,
  } = trimDelete

  useEffect(() => {
    if (successTrimDelete) {
      dispatch(setMessage("Trim deleted successfully"))

      setTimeout(() => {
        dispatch({ type: RESPONSE_RESET })
        dispatch({ type: TRIM_DELETE_RESET })
      }, 2000)
    }
  }, [dispatch, successTrimDelete])

  return [loadingTrimDelete, errorTrimDelete, successTrimDelete, trimDeleted]
}

export default useTrimDelete
