import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCarDetail } from "../../actions/carMpActions"
import { CAR_DETAIL_RESET } from "../../constants/carMPConstants"

const useCarDetail = (id, successCarUpdate) => {
  const dispatch = useDispatch()

  const carDetail = useSelector((state) => state.carMarketplace.carDetail)
  const { car, loading: loadingCarDetail, error: errorCarDetail } = carDetail

  useEffect(() => {
    dispatch({ type: CAR_DETAIL_RESET })
    id && dispatch(getCarDetail(id))
  }, [dispatch, id])

  useEffect(() => {
    if (successCarUpdate && id) {
      dispatch(getCarDetail(id))
    }
  }, [dispatch, id, successCarUpdate])

  return [loadingCarDetail, errorCarDetail, car]
}

export default useCarDetail
