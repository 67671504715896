import React from "react"
import PropTypes from "prop-types"
import CustomPopup from "../utils/CustomPopup"
import Checkbox from "@mui/material/Checkbox"
import TextInput from "../input/TextInput"
import "./ListMultiSelectModal.scss"
import { CustomButton } from "../utils/Button"

const ListMultiSelectModal = ({
  isOpen,
  setIsOpen,
  items = [],
  keyword = "",
  setKeyword,
  itemsSelected = [],
  setItemsSelected,
  ItemCard,
  searchPlaceholder = "Search",
  search,
  confirmListHandler,
}) => {
  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setItemsSelected([...itemsSelected, item._id])
    } else {
      setItemsSelected(itemsSelected.filter((id) => id !== item._id))
    }
  }

  return (
    <div className="multi-select-list">
      <CustomPopup trigger={isOpen} setTrigger={setIsOpen}>
        <div className="multi-select-list-r1 flex col jcsb">
          <div className="multi-select-list-r1-r1 flex col">
            <TextInput
              className="search-bar"
              placeholder={searchPlaceholder}
              value={keyword}
              setValue={setKeyword}
            />
            <div className="item-list">
              {items?.map((item) => (
                <div className="item-card-wrapper flex aic" key={item._id}>
                  <Checkbox
                    inputProps={{ "aria-label": "Checkbox demo" }}
                    checked={itemsSelected.includes(item._id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                  <ItemCard item={item} detailOnly />
                </div>
              ))}
            </div>
          </div>
          <div className="multi-select-list-r1-r2">
            <CustomButton
              label={"Done"}
              onClick={() => {
                setIsOpen(false)
                confirmListHandler && confirmListHandler()
              }}
            />
          </div>
        </div>
      </CustomPopup>
    </div>
  )
}

ListMultiSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func.isRequired,
  itemsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
  setItemsSelected: PropTypes.func.isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
  ItemCard: PropTypes.elementType.isRequired,
  searchPlaceholder: PropTypes.string,
}

export default ListMultiSelectModal
