import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import OutlinedInput from "@mui/material/OutlinedInput"
import Switch from "../selection/Switch"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import "./Forms.scss"
import { createModel } from "../../state/actions/modelActions"
import { Alert } from "@mui/material"
import { MODEL_CREATE_RESET } from "../../state/constants/modelConstants"

export default function ModelForm() {
  const [active, setActive] = useState(true)

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { brands } = brandList
  const carOptions = useSelector((state) => state.carMarketplace.carOptions)
  const { options } = carOptions

  const modelCreate = useSelector((state) => state.carCatalogue.modelCreate)
  const { success, error } = modelCreate

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: MODEL_CREATE_RESET })
  }, [dispatch])

  const [fields, setFields] = React.useState({
    brand: "",
    name: "",
    model: "",
    bodyType: "",
    active,
  })

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
      active,
    })
  }

  useEffect(() => {
    if (success) {
      setFields({
        brand: "",
        name: "",
        model: "",
        bodyType: "",
        active,
      })
    }
  }, [success])

  const modelSaveHandler = () => {
    dispatch(createModel(fields))
  }

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Brand
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fields.brand}
            name="brand"
            input={<OutlinedInput />}
            displayEmpty
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value={""} disabled>
              Select
            </MenuItem>
            {brands.popularBrands
              .filter((brand) => brand.active)
              .map((brand) => (
                <MenuItem value={brand._id}>{brand.name}</MenuItem>
              ))}
            {brands.otherBrands
              .filter((brand) => brand.active)
              .map((brand) => (
                <MenuItem value={brand._id}>{brand.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Name
          </label>
          <TextField
            placeholder="Name"
            name="name"
            value={fields.name}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Body Type
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput />}
            value={fields.bodyType}
            name="bodyType"
            displayEmpty
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value={0} disabled>
              Select
            </MenuItem>
            {options?.CAR_TYPES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="form-group pt-17 input-wrapper flex col">
        <label className="text-label-m0">Status</label>
        <Switch updateStatus={setActive} checked={active} />
      </div>

      <div className="r2 flex">
        <Button className="bg-green" onClick={modelSaveHandler}>
          SAVE
        </Button>
      </div>
      {success || error ? (
        <Alert
          severity={error ? "error" : "success"}
          color="info"
          onClose={() => dispatch({ type: MODEL_CREATE_RESET })}
        >
          {error ? error : "Model created sucessfully!"}
        </Alert>
      ) : (
        ""
      )}
    </div>
  )
}
