import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getSaleList } from "../../actions/saleAction"
import useSaleDelete from "./useSaleDelete"

const useSaleList = (queryObject) => {
  const dispatch = useDispatch()

  const saleList = useSelector((state) => state.sale.saleList)
  const { loading, error, sales, counts, page, pages, count } = saleList

  const [, , success] = useSaleDelete()

  useEffect(() => {
    if (queryObject.status && queryObject.pageNumber) {
      dispatch(getSaleList(queryObject))
    }
    // eslint-disable-next-line
  }, [dispatch, queryObject])
  useEffect(() => {
    if (success) {
      if (queryObject) {
        dispatch(getSaleList(queryObject))
      }
    }
    // eslint-disable-next-line
  }, [dispatch, queryObject, success])

  return [loading, error, sales, counts, page, pages, count]
}

export default useSaleList
