import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { NOTE_CREATE_RESET } from "../../constants/commonConstants"
import { setMessage } from "../../actions/responseAction"

import { SELLER_CREATE_RESET } from "../../constants/sellerContants"

const useSellerCreate = (sellerType) => {
  const sellerCreate = useSelector((state) => state.seller.sellerCreate)
  const { loading, error, success } = sellerCreate

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: SELLER_CREATE_RESET })
    if (success || error) {
      if (success) {
        dispatch(setMessage("Seller Created Successfully!"))
      }
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: NOTE_CREATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error, sellerType])

  return [loading, error, success]
}

export default useSellerCreate
