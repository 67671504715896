import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { USER_CREATE_RESET } from "../../constants/userContants"
import { RESPONSE_RESET } from "../../constants/responseConstants"

const useUserCreate = () => {
  const userCreate = useSelector((state) => state.users.userCreate)
  const {
    loaidng: loadingUserCreate,
    error: errorUserCreate,
    success: successUserCreate,
    user: userCreated,
  } = userCreate

  const dispatch = useDispatch()

  useEffect(() => {
    if (successUserCreate || errorUserCreate) {
      successUserCreate && dispatch(setMessage("User Created Successfully!"))
      errorUserCreate && dispatch(setMessage(errorUserCreate))
      setTimeout(() => {
        dispatch({ type: USER_CREATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successUserCreate, errorUserCreate])

  return [loadingUserCreate, errorUserCreate, successUserCreate, userCreated]
}

export default useUserCreate
