import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { USER_DELETE_RESET } from "../../constants/userContants"
import { RESPONSE_RESET } from "../../constants/responseConstants"

const useUserDelete = () => {
  const userDelete = useSelector((state) => state.users.userDelete)
  const {
    loaidng: loadingUserDelete,
    error: errorUserDelete,
    success: successUserDelete,
    user: userDeleted,
  } = userDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (successUserDelete || errorUserDelete) {
      successUserDelete && dispatch(setMessage("User Deleted Successfully!"))
      errorUserDelete && dispatch(setMessage(errorUserDelete))
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successUserDelete, errorUserDelete])

  return [loadingUserDelete, errorUserDelete, successUserDelete, userDeleted]
}

export default useUserDelete
