import { Button, Menu, MenuItem } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useState } from "react"
import threedots from "../../images/dashboard/carsmpscreens/carlist/threedots.svg"

const useStyles = makeStyles({
  menuItem: {
    width: "200px",
  },
})

const ThreeDotsMenu = ({ menuItem, data, type, title }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="details-button"
      >
        <img src={threedots} alt="three-dots" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="menu-item"
        style={{ width: "200px" }}
      >
        {menuItem.map((item, index) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              item.action(data)
              handleClose()
            }}
            key={index}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ThreeDotsMenu
