import React, { useState, useRef, useEffect } from "react"
import "./RichTextInput.scss"
import { Editor } from "@tinymce/tinymce-react"
import { uploadImage } from "../../apis/imageUpload"
import { Button } from "@mui/material"

const RichText = ({ richText, onSubmit }) => {
  const [body, setBody] = useState(richText)

  useEffect(() => {
    setBody(richText)
  }, [richText])

  const editorRef = useRef()

  const onClickHandlerUpdate = () => {
    setBody(editorRef.current.getContent())
    onSubmit(editorRef.current.getContent())
  }

  return (
    <div className="rich-text-input">
      <div className="button-wrapper">
        <Button onClick={onClickHandlerUpdate}> Update </Button>
      </div>

      <div className="flex jcsb">
        <div className="editor">
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={body}
            init={{
              height: 844,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "imagetools",
                "table",
                "preview",
                "help",
                "wordcount",
              ],
              toolbar: [
                "undo redo | blocks | bold italic | forecolor | alignleft | aligncenter | alignnone | alignright| blockquote |backcolor|copy|cut|fontselect|fontsizeselect |forecolor|formatselect|h1|h2|h3|h4|h5|code|image | media | link |indent | italic| language| lineheight|outdent |paste |redo |remove |removeformat | selectall| strikethrough| styleselect| subscript| superscript | underline| undo| visualaid | bullist |numlist | advtablerownumbering | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | editimage | visualblocks",
              ],
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              file_picker_types: "image",
              image_title: true,
              // enable automatic uploads of images represented by blob or data URIs
              automatic_uploads: true,
              // add custom filepicker only to Image dialog

              file_picker_callback: async function (cb, value, meta) {
                var input = document.createElement("input")
                input.setAttribute("type", "file")
                input.setAttribute("accept", "image/*")

                input.onchange = async function () {
                  var file = this.files[0]
                  var reader = new FileReader()

                  reader.onload = async function () {
                    var id = "blobid" + new Date().getTime()
                    var blobCache =
                      window.tinymce.activeEditor.editorUpload.blobCache
                    var base64 = reader.result

                    var blobInfo = blobCache.create(id, file, base64)

                    blobCache.add(blobInfo)

                    // call the callback and populate the Title field with the file name

                    const imageURLT = await uploadImage(base64)
                    imageURLT &&
                      cb(String(imageURLT.data.images[0]), {
                        title: file.name,
                      })
                  }
                  reader.readAsDataURL(file)
                }

                input.click()
              },
            }}
          />
        </div>
        <div className="live-view">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
      </div>
    </div>
  )
}
export default RichText
