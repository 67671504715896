import React, { useState, useEffect } from "react"
import "./TextInput.scss"

const TextInput = ({
  value,
  setValue,
  onChange,
  label,
  disabled,
  name,
  groupIndex,
  type,
  required,
  placeholder,
  fieldIndex,
  group,
}) => {
  const [isBlank, setIsBlank] = useState(!value) // Initialize isBlank based on value
  // const [hasFocused, setHasFocused] = useState(false)

  // Update isBlank when value changes
  useEffect(() => {
    setIsBlank(!value)
  }, [value])

  const handleBlur = () => {
    if (!value) {
      setIsBlank(true)
    } else {
      setIsBlank(false)
    }
  }

  const inputClasses = required && isBlank ? "input red-border" : "input"
  return (
    <div className={group ? "input-wrapper" : "input-wrapper-isolate"}>
      <label className="label">{label}</label>
      <input
        type={type || "text"}
        name={name}
        className={inputClasses}
        placeholder={placeholder}
        value={value || ""} // Ensure value is always defined
        disabled={disabled}
        onBlur={handleBlur}
        onClick={(e) => e.stopPropagation()}
        onChange={
          onChange
            ? onChange
            : (e) => {
                setValue && setValue(e.target.value, groupIndex, fieldIndex)
              }
        }
      />
    </div>
  )
}

export default TextInput
