import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { CAR_DELETE_RESET } from "../../constants/carMPConstants"

const useCarDelete = () => {
  const carDelete = useSelector((state) => state.carMarketplace.carDelete)
  const { loading, error, success } = carDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (loading || error) {
      success && dispatch(setMessage("Car Delete Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: CAR_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error, loading])

  return [loading, error, success]
}

export default useCarDelete
