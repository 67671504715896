import React from "react"
import { useDispatch } from "react-redux"
import actionbin from "../../images/dashboard/actionbin.svg"
import actionpencil from "../../images/dashboard/actionpencil.svg"
import { deleteNote } from "../../state/actions/commonActions"
import "./Note.scss"

const Note = ({ note, detail, setNote, setNoteId, id, type = "cars" }) => {
  const createTime = note.createdAt.split("T")[1].substring(0, 8)
  const createDate = `${note.createdAt.substring(0, 10).split("-")[2]}/${
    note.createdAt.substring(0, 10).split("-")[1]
  }/${note.createdAt.substring(0, 10).split("-")[0]}`
  const updateTime = note.updatedAt.split("T")[1].substring(0, 8)
  const updateDate = `${note.updatedAt.substring(0, 10).split("-")[2]}/${
    note.updatedAt.substring(0, 10).split("-")[1]
  }/${note.updatedAt.substring(0, 10).split("-")[0]}`

  const dispatch = useDispatch()

  return !detail ? (
    <div className="note-compact flex jcsb aic">
      <div className="note c1">
        <div className="note r1">
          <p className="time-stamp">{`C: ${createDate} ${createTime}`}</p>
          <p className="time-stamp">{`U: ${updateDate} ${updateTime}`}</p>
          <p className="user">{`${note.user.firstName} ${note.user.lastName}`}</p>
        </div>
        <div className="note r2">
          <p className="text">{note.note}</p>
        </div>
      </div>
      <div className="note c2 flex aic">
        <img
          src={actionpencil}
          alt=""
          onClick={() => {
            setNote(note.note)
            setNoteId(note._id)
          }}
          className="icon"
        />
        <img
          src={actionbin}
          alt=""
          className="icon"
          onClick={() => {
            dispatch(deleteNote(type, id, note._id))
            setNoteId("")
            setNote("")
          }}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Note
