import React, { forwardRef, useState } from "react"

const Item = forwardRef(
  ({ id, withOpacity, isDragging, style, ...props }, ref) => {
    const {
      imageDeleteHandler,
      downloadOriginalImageHandler,
      viewImageHandler,
      sideDrawer,
      ...rest
    } = props

    const stopPropagation = (e) => {
      e.stopPropagation()
    }
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

    const inlineStylesImage =
      imageSize.height < imageSize.width
        ? {
            opacity: withOpacity ? "0.5" : "1",
            transformOrigin: "50% 50%",
            aspectRatio: "281/184",
            objectFit: "cover",
            width: !sideDrawer ? 281 : 136,
            cursor: isDragging ? "grabbing" : "grab",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: isDragging
              ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
              : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
            transform: isDragging ? "scale(1.05)" : "scale(1)",
            ...style,
          }
        : {
            opacity: withOpacity ? "0.5" : "1",
            transformOrigin: "50% 50%",
            aspectRatio: "281/184",
            objectFit: "contain",
            width: !sideDrawer ? 281 : 136,
            cursor: isDragging ? "grabbing" : "grab",
            backgroundColor: "#000",
            display: "flex",
            zIndex: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: isDragging
              ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
              : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
            transform: isDragging ? "scale(1.05)" : "scale(1)",
            ...style,
          }

    const handleImageLoad = (e) => {
      const { naturalWidth, naturalHeight } = e.target
      setImageSize({ width: naturalWidth, height: naturalHeight })
    }

    return (
      <img
        ref={ref}
        style={inlineStylesImage}
        {...rest}
        src={props.image}
        onLoad={handleImageLoad}
        alt="Carmate Car"
        onClick={stopPropagation}
      />
    )
  }
)

export default Item
