import Axios from "../../utils/axios"
import {
  TAG_LIST_FAIL,
  TAG_LIST_REQUEST,
  TAG_LIST_SUCCESS,
} from "../constants/tagsContants"

export const getTagsList =
  (keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: TAG_LIST_REQUEST,
      })

      const { data } = await Axios.get(`/v1/tags?keyword=${keyword}`)
      dispatch({
        type: TAG_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TAG_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
