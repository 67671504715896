import React, { useEffect, useState } from "react"
import "./TrimList.scss"
import DataTable from "../utils/DataTable"
import { CustomButton } from "../utils/Button"
import ThreeDotsMenu from "../common/ThreeDotsMenu"
import { deleteTrim } from "../../state/actions/trimActions"
import { useDispatch } from "react-redux"
import Axios from "../../utils/axios"
import { setMessage } from "../../state/actions/responseAction"
import { RESPONSE_RESET } from "../../state/constants/responseConstants"
// import CustomPopup from "../utils/CustomPopup"
// import FileUploadGoogleDrive from "../utils/FileUploadGoogleDrive"
import CustomSwitch from "../selection/Switch"
import { Alert, LinearProgress } from "@mui/material"
import AlertCustom from "../common/AlertCustom"
import { formatDate, numberWithCommas } from "../../utils/utilsFunctions"

const TrimsList = ({
  years,
  onYearClick,
  activeYear,
  trims,
  count,
  setSelectedTrim,
  paginationHandler,
  serverFieldHandler,
  setIsUpdated,
  loading,
  error,
  activeTab,
  setModalShow,
}) => {
  const dispatch = useDispatch()

  const [trimsListBuffer, setTrimsListBuffer] = useState([])
  const [errorPopular, setErrorPopular] = useState("")
  const editHandler = (e) => {
    const trim = trims.find((trim) => trim._id === e.id)
    setSelectedTrim(trim)
    setModalShow(true)
  }

  // const [fileId, setFileId] = useState("")

  const deleteHandler = (e) => {
    dispatch(deleteTrim(e.id))
  }
  const duplicateHandler = (e) => {
    const trim = trims.find((trim) => trim._id === e.id)
    setSelectedTrim({ ...trim, isDuplicate: true })
    setModalShow(true)
  }

  const menuItem = [
    {
      title: "Edit",
      action: editHandler,
    },
    {
      title: "Delete",
      action: deleteHandler,
    },
    {
      title: "Duplicate",
      action: duplicateHandler,
    },
  ]

  useEffect(() => {
    setTrimsListBuffer(trims)
  }, [trims])

  const formattedTrims = trimsListBuffer?.length
    ? trimsListBuffer.map((item, i) => ({
        index: i,
        id: item._id,
        name: item.name,
        brandName: item.brandName,
        modelName: item.modelName,
        yearName: item.yearName,
        subStatus:
          item.subStatus === "IN_PREPARATION" ? "In Preparation" : "Active",
        status: item.status,
        isPopular: item.isPopular,
        price: item.price ? `$${numberWithCommas(item.price)}` : "-",
        priceUpdatedAt: item.priceUpdatedAt
          ? formatDate(item.priceUpdatedAt)
          : "-",
        salePrice: item.salePrice
          ? `$${numberWithCommas(item.salePrice)}`
          : "-",
        salePriceUpdatedAt: item.salePriceUpdatedAt
          ? formatDate(item.salePriceUpdatedAt)
          : "-",
        isPopularUpdatedAt: item.isPopularUpdatedAt
          ? formatDate(item.isPopularUpdatedAt)
          : "-",
      }))
    : []

  const columns = [
    { field: "brandName", headerName: "Brand", width: 130 },
    { field: "modelName", headerName: "Model", width: 130 },
    { field: "yearName", headerName: "Year", width: 130 },
    { field: "name", headerName: "Trim", width: 250 },
    { field: "subStatus", headerName: "Sub Status", width: 130 },
    { field: "status", headerName: "Status", width: 130 },

    {
      field: "isPopular",
      headerName: "Popular",
      width: 120,
      renderCell: (params) => (
        <CustomSwitch
          checked={params.row.isPopular}
          updateStatus={onStatusChange}
          status={params.row.isPopular}
          group={params.row}
          disabled={activeTab === "PENDING" ? true : false}
        />
      ),
    },
    {
      field: "price",
      headerName: "Price",
      width: 130,
      renderCell: (params) => (
        <div className="flex col">
          <p style={{ lineHeight: "14px", paddingBottom: "4px" }}>
            {params.row.price}
          </p>
          {params.row.priceUpdatedAt !== "-" ? (
            <>
              <p style={{ lineHeight: "14px", fontSize: 11 }}>Updated At</p>
              <p style={{ lineHeight: "14px", fontSize: 11 }}>
                {params.row.priceUpdatedAt}
              </p>
            </>
          ) : null}
        </div>
      ),
    },
    {
      field: "salePrice",
      headerName: "Sale Price",
      width: 130,
      renderCell: (params) => (
        <div className="flex col">
          <p style={{ lineHeight: "14px", paddingBottom: "4px" }}>
            {params.row.salePrice}
          </p>
          {params.row.salePriceUpdatedAt !== "-" ? (
            <>
              <p style={{ lineHeight: "14px", fontSize: 11 }}>Updated At</p>
              <p style={{ lineHeight: "14px", fontSize: 11 }}>
                {params.row.salePriceUpdatedAt}
              </p>{" "}
            </>
          ) : null}
        </div>
      ),
    },
    { field: "isPopularUpdatedAt", headerName: "Listed At", width: 130 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <ThreeDotsMenu data={params.row} menuItem={menuItem} />
      ),
    },
  ]

  const handleListActive = async () => {
    try {
      const activeTrims = trims.filter((trim) => trim.subStatus === "ACTIVE")

      // Call the API to update the status of active trims
      const response = await Axios.put("/v1/trims/status/listed", {
        trims: activeTrims,
      })

      // Check the response status and handle accordingly
      if (response.status === 200 && response.data.success) {
        setIsUpdated(true)
        dispatch(setMessage("Trims status updated successfully"))
        setTimeout(() => {
          dispatch({ type: RESPONSE_RESET })
          setIsUpdated(false)
        }, 2000)
      } else {
      }
    } catch (error) {
      console.error("Error occurred while updating trims status:", error)
    }
  }

  const onStatusChange = async (status, trimInfo) => {
    try {
      const { data } = await Axios.put(`/v1/trims/${trimInfo.id}/popular`)

      if (data.acknowledged) {
        setIsUpdated(true)
        setTimeout(() => {
          setIsUpdated(false)
        }, 2000)
      }
    } catch (error) {
      setErrorPopular(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

  return (
    <div className="trims-list">
      <div className="trims-list-r1">
        <div className="trims-list-r1-c1">
          {/* <CustomButton
            label="Upload CSV"
            variant="secondary"
            onClick={() => {
              setUploadCSVModal(true)
            }}
          /> */}
          {/* Hide  */}
          {activeTab === "PENDING" ? (
            <CustomButton
              label="List Active"
              variant="secondary"
              onClick={handleListActive}
            />
          ) : null}
        </div>
        <div className="trims-list-r1-c2">
          {years.map((year) => (
            <span
              className={
                year.name === activeYear
                  ? "link-wrapper link bold"
                  : "link-wrapper link"
              }
              onClick={() => onYearClick(year.name)}
              key={year.name}
            >
              {year.name}({year.count})
            </span>
          ))}
        </div>
      </div>
      <div className="">
        {errorPopular ? (
          <AlertCustom severity="error" error={errorPopular} />
        ) : null}
      </div>
      <div className="trims-list-r2">
        {loading ? (
          <LinearProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <DataTable
            data={formattedTrims}
            columns={columns}
            count={count}
            paginationHandler={paginationHandler}
            serverFieldHandler={serverFieldHandler}
          />
        )}
      </div>
      {/* <CustomPopup
        title="Upload File"
        trigger={uploadCSVModal}
        setTrigger={setUploadCSVModal}
        onCancelTitle="Close"
      >
        {!fileId ? (
          <FileUploadGoogleDrive setFileId={setFileId} />
        ) : (
          <>
            <iframe
              src={`https://docs.google.com/spreadsheets/d/${fileId.id}/edit#gid=621397859`}
              title="Trim Detail"
              width="600"
              height="400"
            ></iframe>
            <CustomButton label="Create Trims" onClick={trimsCreateHandler} />
            {trimCreateMessage}
          </>
        )}
      </CustomPopup> */}
    </div>
  )
}

export default TrimsList
