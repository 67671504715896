import React from "react"
import "./TaskCardActive.scss"

const TaskCardActive = ({ task }) => {
  const dueTime = task._id.dueDate.split("T")[1].substring(0, 8)
  const dueDate = `${task._id.dueDate.substring(0, 10).split("-")[2]}/${
    task._id.dueDate.substring(0, 10).split("-")[1]
  }/${task._id.dueDate.substring(0, 10).split("-")[0]}`

  return (
    <div className="task-card-active">
      <div className="task-detail flex jcsb">
        <div className="task-detail-c1">
          <div className="title-task">{task._id.taskTitle}</div>
          <div className="text-sb">{`Created By ${task._id.user.firstName} ${task._id.user.lastName}`}</div>
          <div>
            <span className="text-sb">Task Description:</span>
            <span className="text-light">{task._id.taskDescription}</span>
          </div>
        </div>
        <div className="task-detail-c2">
          <div className="title-task">{`Due: ${dueDate} ${dueTime}`}</div>
          <div className="text-light">
            Sales Opportunity: #
            <span className="link">{task._id._opportunity.customId}</span>
          </div>
        </div>
      </div>
      <div className="task-notes">
        <div className="notes-title flex jcsb">
          <div className="title-task">Notes({task.notes.length})</div>
          <div className="text-light">Add Notes</div>
        </div>

        {task.notes.length > 0 ? (
          <div className="notes-list">
            {task.notes.map((note) => (
              <div className="note">
                <div className="note-title">{`On ${
                  note.createdAt.substring(0, 10).split("-")[2]
                }/${note.createdAt.substring(0, 10).split("-")[1]}/${
                  note.createdAt.substring(0, 10).split("-")[0]
                } ${note.createdAt.split("T")[1].substring(0, 8)}`}</div>
                <div className="note-body">{note.note}</div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default TaskCardActive
