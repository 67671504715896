import React, { useState, useEffect } from "react"
import axios from "../../utils/axios"
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  Chip,
  Divider,
} from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import RoleForm from "./RolesForm"
import PermissionForm from "./PermissionForm"
import "./RolesAndPermission.scss"
import plus from "../../images/common/plus.svg"

const RoleList = () => {
  const [roles, setRoles] = useState([])
  const [openRoleForm, setOpenRoleForm] = useState(false)
  const [openPermissionForm, setOpenPermissionForm] = useState(false)
  const [editingRole, setEditingRole] = useState(null) // State to track which role is being edited

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get("/v1/roles")
        setRoles(response.data)
      } catch (error) {
        console.error("Error fetching roles:", error)
      }
    }

    fetchRoles()
  }, [])

  const toggleRoleDrawer = () => {
    setOpenRoleForm(!openRoleForm)
    setEditingRole(null) // Reset editing role when closing the drawer
  }

  const togglePermissionDrawer = () => {
    setOpenPermissionForm(!openPermissionForm)
  }

  const handleRoleCreated = (newRole) => {
    setRoles((prevRoles) => [...prevRoles, newRole])
  }

  const handleRoleUpdated = (updatedRole) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) =>
        role._id === updatedRole._id ? updatedRole : role
      )
    )
    setEditingRole(null) // Reset editing role after update
  }

  const startEditingRole = (role) => {
    setEditingRole(role)
    console.log(role)
    setOpenRoleForm(true)
  }

  return (
    <div className="roles-and-permission">
      <div className="roles-and-permission-r1">
        <h2 className="text-title">Roles and Permissions</h2>
        <div className="icon-wrapper" onClick={toggleRoleDrawer}>
          <img src={plus} alt="" className="icon" />
          <p className="link">Add Role</p>
        </div>
        <div className="icon-wrapper" onClick={togglePermissionDrawer}>
          <img src={plus} alt="" className="icon" />
          <p className="link">Add Permission</p>
        </div>
      </div>
      <div>
        <List>
          {roles.map((role) => (
            <ListItem key={role._id}>
              <ListItemText
                primary={role.name}
                secondary={
                  <div>
                    {role.permissions.map((perm) => (
                      <Chip
                        key={perm._id}
                        label={`${perm.resource}: ${perm.actions.join(", ")}`}
                        style={{ margin: "4px" }}
                      />
                    ))}
                  </div>
                }
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => startEditingRole(role)}
              >
                Edit
              </Button>
            </ListItem>
          ))}
        </List>
      </div>

      {/* Role Creation/Edit Drawer */}
      <Drawer
        anchor="right"
        open={openRoleForm}
        onClose={toggleRoleDrawer}
        PaperProps={{ style: { width: "500px" } }}
      >
        <div style={{ padding: "16px" }}>
          <IconButton
            onClick={toggleRoleDrawer}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {editingRole ? "Edit Role" : "Create New Role"}
          </Typography>
          <Divider />
          <RoleForm
            onClose={toggleRoleDrawer}
            onRoleCreated={handleRoleCreated}
            onRoleUpdated={handleRoleUpdated}
            role={editingRole} // Pass the role being edited
          />
        </div>
      </Drawer>

      {/* Permission Creation Drawer */}
      <Drawer
        anchor="right"
        open={openPermissionForm}
        onClose={togglePermissionDrawer}
        PaperProps={{ style: { width: "500px" } }}
      >
        <div style={{ padding: "16px" }}>
          <IconButton
            onClick={togglePermissionDrawer}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Create New Permission
          </Typography>
          <Divider />
          <PermissionForm onClose={togglePermissionDrawer} />
        </div>
      </Drawer>
    </div>
  )
}

export default RoleList
