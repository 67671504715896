import * as React from "react"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"

export default function MCheckboxes(props) {
  const labelStyle = {
    fontSize: "14px", // Adjust the font size as needed
    whiteSpace: "nowrap",
  }
  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup aria-label="position">
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                onChange={props.onChange}
                name={props.name}
                checked={props.checked || false}
              />
            }
            label={<span style={labelStyle}>{props.label}</span>} // Apply labelStyle to the label
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}
