import React, { useState } from "react"
import "./Select.scss"

const Select = ({
  options,
  value,
  setValue,
  name,
  label,
  disabled,
  required,
  onChange,
  defaultOption,
  isolate,
  className,
}) => {
  const [isBlank, setIsBlank] = useState(false)

  const handleBlur = () => {
    if (!value) {
      setIsBlank(true)
    } else {
      setIsBlank(false)
    }
  }

  const inputClasses = required && isBlank ? "select red-border" : "select"
  return (
    <div
      className={
        isolate ? `input-wrapper-isolate ` : `input-wrapper ${className}`
      }
      onClick={(e) => e.stopPropagation()}
    >
      <label className="label">{label}</label>
      <select
        className={inputClasses}
        value={value?.value || value}
        onChange={
          onChange ? onChange : (e) => setValue && setValue(e.target.value)
        }
        placeholder="Choose"
        onClick={(e) => e.stopPropagation()}
        name={name}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <option value="">{defaultOption || "Select"}</option>
        {options?.map((option, i) => (
          <option value={option.value || option._id} key={i}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
