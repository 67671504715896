import React, { useState } from "react"
import { Button } from "@mui/material"
import { useParams } from "react-router-dom"
import SellerDetailTab from "./SellerDetailTab"
import UserListTab from "./UserListTab"
import "./SellerDetailTabs.scss"

const SellerDetailsTabs = () => {
  const params = useParams()
  const { sellerType } = params

  const [activeTab, setActiveTab] = useState(
    sellerType !== "individuals" ? "FirstTab" : "SecondTab"
  )

  const tabClickHandler = (tab) => {
    setActiveTab(tab)
  }

  return (
    <div className="seller-detail-tabs">
      <div className="seller-tabs-header-wrapper flex">
        {sellerType !== "individuals" && (
          <Button
            onClick={() => tabClickHandler("FirstTab")}
            className={`tab-head ${activeTab === "FirstTab" ? "active" : ""}`}
          >
            {sellerType === "dealerships"
              ? `Dealership`
              : sellerType === "companies"
              ? `Company`
              : sellerType === "officialBrands"
              ? `Official Brand`
              : sellerType === "individuals"
              ? "Individual"
              : `Branch`}
          </Button>
        )}
        <Button
          onClick={() => tabClickHandler("SecondTab")}
          className={`tab-head ${activeTab === "SecondTab" ? "active" : ""}`}
        >
          USERS
        </Button>
        <Button
          onClick={() => tabClickHandler("ThirdTab")}
          className={`tab-head ${activeTab === "ThirdTab" ? "active" : ""}`}
        >
          COMMISSION
        </Button>
      </div>

      {activeTab === "FirstTab" && sellerType !== "individuals" && (
        <SellerDetailTab />
      )}
      {activeTab === "SecondTab" && <UserListTab />}
      {activeTab === "ThirdTab" && <div></div>}
    </div>
  )
}

export default SellerDetailsTabs
