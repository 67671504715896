import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import InnerHeader from "../../components/header/InnerHeader"
import OpportunityCarList from "../../components/userdetailscreen/OpportunityCarList"
import UserDetailCard from "../../components/userdetailscreen/UserDetailCard"
import UserDetailTabs from "../../components/userdetailscreen/UserDetailTabs"
import InnerContainer from "../../components/utils/InnerContainer"
import Loader from "../../components/utils/Loader"
import {
  getUserDetail,
  getUserOpportunityList,
} from "../../state/actions/userActions"
import { OPPORTUNITY_UPDATE_RESET } from "../../state/constants/opportunityConstants"
import "./UserDetailScreen.scss"

const UserDetailScreen = () => {
  const params = useParams()
  const { id } = params

  const [opportunitySelect, setOpportunitySelect] = useState("")

  const userDetail = useSelector((state) => state.users.userDetail)
  const {
    loading: loadingUserDetail,
    error: errorUserDetail,
    user,
  } = userDetail

  const opportunityUpdate = useSelector(
    (state) => state.opportunity.opportunityUpdate
  )
  const { success } = opportunityUpdate

  useEffect(() => {
    dispatch({ type: OPPORTUNITY_UPDATE_RESET })
    if (success) {
      dispatch(getUserOpportunityList(id))
    }
  }, [success])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserDetail(id))
    dispatch(getUserOpportunityList(id))
  }, [dispatch, id])

  return (
    <div className="user-detail-screen">
      {loadingUserDetail ? (
        <Loader />
      ) : errorUserDetail ? (
        <div>{errorUserDetail}</div>
      ) : (
        <>
          <InnerHeader
            className="inner-header"
            title={`${
              user._dealership
                ? `Dealer`
                : user._webUser
                ? `Customer`
                : user._individualSeller
                ? `Individual Seller`
                : `Admin`
            }: ${user.firstName || "-"} ${user.lastName || "-"} / ${
              user._dealership
                ? `Dealer ID`
                : user._webUser
                ? `Customer ID`
                : user._individualSeller
                ? `Individual Seller ID`
                : `Admin ID`
            } ${user.carmateUserId || "-"}`}
          >
            <Button className="inner-header-create-button">
              <Link to={`/opportunity/create/${id}`}>Create Opportunity</Link>
            </Button>
          </InnerHeader>
          <InnerContainer>
            <div className="body-wrapper">
              <div className="user-col-1">
                <UserDetailCard user={user} />
              </div>
              <div className="user-col-2">
                <UserDetailTabs setOpportunitySelect={setOpportunitySelect} />
                <OpportunityCarList opportunitySelect={opportunitySelect} />
              </div>
            </div>
          </InnerContainer>
        </>
      )}
    </div>
  )
}

export default UserDetailScreen
