import * as React from "react"
import { DataGrid } from "@mui/x-data-grid"

export default function DataTable({
  data,
  columns,
  serverFieldHandler,
  paginationHandler,
}) {
  const handlePageChange = (params) => {
    paginationHandler(params)
  }

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowHeight={70}
        stickyHeader
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        onPageChange={handlePageChange}
        checkboxSelection
        filterMode="server"
        onFilterModelChange={(filterModel) => {
          // Extract filter information
          const serverFilters = filterModel.items.map((item) => ({
            columnField: item.columnField, // Field name of the column being filtered
            operatorValue: item.operatorValue, // Filter operator (e.g., 'contains', 'equals')
            value: item.value, // Filter value
          }))
          serverFieldHandler(serverFilters)
          // Call your server-side filtering function with filter information
        }}
        onSelectionModelChange={(selectionModel) => {
          // selectionModel contains the array of selected row ids
          // You can get the actual rows using these ids
          // const selectedRows = selectionModel.map((selectedId) =>
          //   data.find((row) => row.id === selectedId)
          // )
        }}
      />
    </div>
  )
}
