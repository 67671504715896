import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import EventCard from "../../components/eventscreens/EventCard"
import EventEditForm from "../../components/eventscreens/EventEditForm"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import Loader from "../../components/utils/Loader"
import { getEventsList } from "../../state/actions/eventsActions"
import "./EventListScreen.scss"

const EventListScreen = () => {
  const [activeTab, setActiveTab] = useState("Desktop")

  const [eventSelected, setEventSelected] = useState({})

  const eventList = useSelector((state) => state.event.eventList)
  const { loading, events } = eventList
  const eventCreate = useSelector((state) => state.event.eventCreate)
  const { success: eventCreateSuccess } = eventCreate
  const eventUpdate = useSelector((state) => state.event.eventUpdate)
  const { success: eventUpdateSuccess } = eventUpdate
  const eventDelete = useSelector((state) => state.event.eventDelete)
  const { success: eventDeleteSuccess } = eventDelete

  console.log(events)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEventsList())
  }, [dispatch, eventCreateSuccess, eventUpdateSuccess, eventDeleteSuccess])

  return (
    <div className="event-screen">
      <InnerHeader title="Events" className="inner-header">
        <Button className="btn-blue" onClick={() => setEventSelected({})}>
          Create Event
        </Button>
      </InnerHeader>
      <InnerContainer>
        <div className="tabs flex">
          <Button
            className={activeTab === "Desktop" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Desktop")
            }}
          >
            DESKTOP
          </Button>
          <Button
            className={activeTab === "Mobile" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Mobile")
            }}
          >
            Mobile
          </Button>
        </div>
        <div className="body-wrapper">
          <div className="event-grid">
            {/* <div className="c1">Filters</div> */}
            {loading ? (
              <Loader />
            ) : (
              events.map((event, i) => (
                <EventCard
                  event={event}
                  setEvent={setEventSelected}
                  i={i + 1}
                  active={event?._id === eventSelected?._id}
                />
              ))
            )}
          </div>
          <div className="event-form">
            <EventEditForm event={eventSelected} tab={activeTab} />
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default EventListScreen
