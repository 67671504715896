import React, { useEffect, useState } from "react"
import "./PicturesTab.scss"
import uploadwait from "../../../images/dashboard/carsmpscreens/cardetail/imageuploadwait.svg"
import download from "../../../images/common/download.png"
import binwhite from "../../../images/dashboard/carsmpscreens/cardetail/binwhite.svg"
import ImageUpload from "../../utils/ImageUpload"
import { updateCar, updateCarImages } from "../../../state/actions/carMpActions"
import { useSelector, useDispatch } from "react-redux"
import { CAR_IMAGE_UPDATE_RESET } from "../../../state/constants/carMPConstants"
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd"
import { useWindowSize } from "../../utils/customHooks"
import { resizeFile } from "../../../utils/imageResize"

const PicturesTab = ({ car }) => {
  const [images, setImages] = useState([])
  const [imagesUpdated, setImagesUpdated] = useState([...car.images])
  const [imageUpload, setImageUpload] = useState([])
  const [update, setUpdate] = useState(false)
  const dispatch = useDispatch()

  const size = useWindowSize()

  const carImagesUpdate = useSelector(
    (state) => state.carMarketplace.carImagesUpdate
  )

  const { success: successCarImageUpdate, car: updatedCar } = carImagesUpdate

  const imageDeleteHandler = (imagePosition) => {
    setImagesUpdated(imagesUpdated.filter((image, i) => i !== imagePosition))
    setUpdate(true)
  }

  useEffect(async () => {
    dispatch({ type: CAR_IMAGE_UPDATE_RESET })
    if (images.length > 0 && imageUpload.length === 0) {
      for (const [i, image] of images.entries()) {
        const resizedImage = await resizeFile(image)
        imageUpload[i] = resizedImage
        setImageUpload([...imageUpload])

        if (i === images.length - 1) {
          dispatch(updateCarImages(car._id, { imageUpload }))
        }
      }
    }

    if (successCarImageUpdate) {
      setImageUpload([])
      setImages([])
      setImagesUpdated([...updatedCar.images])
    }
    if (update) {
      dispatch(updateCar(car._id, { images: imagesUpdated }))
      setUpdate(false)
    }
  }, [images, successCarImageUpdate, imagesUpdated, update])

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(imagesUpdated, sourceIndex, targetIndex)

    setImagesUpdated(nextState)
    setUpdate(true)
  }

  return (
    <div className="images-tab">
      <ImageUpload setImages={setImages} />
      <div>
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={size.width > 600 ? 6 : 1}
            rowHeight={160}
            style={{ height: "400px" }}
            className="images-wrapper"
          >
            {imagesUpdated.map((image, i) => (
              <GridItem key={i} className="image-card">
                <div className="image-card-inner">
                  <img src={image} alt="car" className="image" />
                  <div className="image-options">
                    <img
                      src={binwhite}
                      onClick={() => imageDeleteHandler(i)}
                      alt="icon"
                      className="icon"
                    />
                    {/* eslint-disable-next-line */}
                    <a href={image} target="_blank" download>
                      <img src={download} alt="icon" className="icon" />
                    </a>
                  </div>
                </div>
              </GridItem>
            ))}
            {images.map((image, i) => (
              <GridItem key={i} className="image-card">
                <img src={uploadwait} alt="icon" />
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </div>
      {/* <DragDropContext onDragEnd={onEnd}>
        <Droppable droppableId="drag">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <div container className="images-wrapper">
                {imagesUpdated.map((image, i) => (
                  <Draggable draggableId={`${i}`} key={i} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="image-card"
                      >
                        
                        
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </div>
  )
}

export default PicturesTab
