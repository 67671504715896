import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  getCarDetail,
  getFilterOptions,
} from "../../state/actions/carMpActions"
import InnerHeader from "../../components/header/InnerHeader"
import Loader from "../../components/utils/Loader"
import "./CarDetailScreen.scss"
import InnerContainer from "../../components/utils/InnerContainer"
import { Alert, Button } from "@mui/material"

import CarDetailCard from "../../components/carsmpscreens/CarDetailScreen/CarDetailCard"
import CarDetailTabs from "../../components/carsmpscreens/CarDetailScreen/CarDetailTabs"
import CarDetailNotes from "../../components/carsmpscreens/CarDetailScreen/CarDetailNotes"
import CarOpportunities from "../../components/carsmpscreens/CarDetailScreen/CarOpportunities"

const CarDetailScreen = () => {
  const { id: carId } = useParams()
  const navigate = useNavigate()

  const carDetail = useSelector((state) => state.carMarketplace.carDetail)
  const { loading, error, car } = carDetail

  const carOptions = useSelector((state) => state.carMarketplace.carOptions)
  const { options } = carOptions

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFilterOptions())
  }, [dispatch])
  useEffect(() => {
    if (carId !== car?._id) {
      dispatch(getCarDetail(carId))
    }
    //eslint-disable-next-line
  }, [dispatch, carId])
  return loading ? (
    <Loader />
  ) : error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    <div className="car-detail-screen">
      <InnerHeader
        title={`${car.status} / Car ID ${car.listingId} / ${
          car?._sellerDealership
            ? `Dealership: ${car?._sellerDealership?.name}`
            : car?._sellerCompany
            ? `Company: ${car?._sellerCompany?.name}`
            : car?._sellerIndividual
            ? `Individual: ${car?.seller?.username}`
            : "Official Brand"
        }`}
        className="inner-header"
      >
        <Button
          className="btn-create-car"
          onClick={() => navigate(`/car/${car._id}/edit`)}
        >
          Edit Car
        </Button>
      </InnerHeader>
      <InnerContainer>
        <CarDetailCard car={car} carOptions={options} />
        <div className="car-detail-tabs-wrapper flex">
          <CarDetailTabs car={car} />
          <div className="col-2">
            <CarDetailNotes car={car} />
            <CarOpportunities car={car} />
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default CarDetailScreen
