import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLinkList } from "../../actions/linkAction"
import useLinkDelete from "./useLinkDelete"

const useLinkList = (queryObject) => {
  const dispatch = useDispatch()

  const linkList = useSelector((state) => state.link.linkList)
  const { loading, error, links, page, pages, counts } = linkList

  const [, , success] = useLinkDelete()

  useEffect(() => {
    if (queryObject.pageNumber) {
      dispatch(getLinkList(queryObject))
    }

    // eslint-disable-next-line
  }, [dispatch, queryObject])

  useEffect(() => {
    if (success) {
      dispatch(getLinkList(queryObject))
    }
    // eslint-disable-next-line
  }, [dispatch, success, queryObject])

  return [loading, error, links, page, pages, counts]
}

export default useLinkList
