import {
  TRIM_CREATE_FAIL,
  TRIM_CREATE_REQUEST,
  TRIM_CREATE_RESET,
  TRIM_CREATE_SUCCESS,
  TRIM_DELETE_FAIL,
  TRIM_DELETE_REQUEST,
  TRIM_DELETE_RESET,
  TRIM_DELETE_SUCCESS,
  TRIM_DETAIL_FAIL,
  TRIM_DETAIL_REQUEST,
  TRIM_DETAIL_RESET,
  TRIM_DETAIL_SUCCESS,
  TRIM_LIST_FAIL,
  TRIM_LIST_FILTER_FAIL,
  TRIM_LIST_FILTER_REQUEST,
  TRIM_LIST_FILTER_RESET,
  TRIM_LIST_FILTER_SUCCESS,
  TRIM_LIST_REQUEST,
  TRIM_LIST_RESET,
  TRIM_LIST_SUCCESS,
  TRIM_UPDATE_FAIL,
  TRIM_UPDATE_REQUEST,
  TRIM_UPDATE_RESET,
  TRIM_UPDATE_SUCCESS,
  TRIM_YEAR_LIST_FAIL,
  TRIM_YEAR_LIST_REQUEST,
  TRIM_YEAR_LIST_RESET,
  TRIM_YEAR_LIST_SUCCESS,
} from "../constants/trimConstants"

export const trimListReducer = (
  state = { trims: [], loading: false },
  action
) => {
  switch (action.type) {
    case TRIM_LIST_REQUEST:
      return { loading: true, trims: [] }
    case TRIM_LIST_SUCCESS:
      return {
        loading: false,
        trims: action.payload.trims,
        count: action.payload.count,
      }
    case TRIM_LIST_RESET:
      return {
        loading: true,
        trims: [],
      }
    case TRIM_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const trimCreateReducer = (
  state = { success: false, loading: false, trim: {} },
  action
) => {
  switch (action.type) {
    case TRIM_CREATE_REQUEST:
      return { loading: true, success: false, trim: {} }
    case TRIM_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        trim: action.payload,
      }
    case TRIM_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TRIM_CREATE_RESET:
      return {
        loading: false,
        success: false,
        trim: {},
      }
    default:
      return state
  }
}

export const trimByYearListReducer = (
  state = { trims: [], loading: false },
  action
) => {
  switch (action.type) {
    case TRIM_YEAR_LIST_REQUEST:
      return { loading: true, trims: [] }
    case TRIM_YEAR_LIST_SUCCESS:
      return {
        loading: false,
        trims: action.payload,
      }
    case TRIM_YEAR_LIST_RESET:
      return {
        loading: true,
        trims: [],
      }
    case TRIM_YEAR_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const trimUpdateReducer = (
  state = { success: false, loading: false, trim: {} },
  action
) => {
  switch (action.type) {
    case TRIM_UPDATE_REQUEST:
      return { loading: true, success: false, trim: {} }
    case TRIM_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        trim: action.payload,
      }
    case TRIM_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TRIM_UPDATE_RESET:
      return {
        loading: false,
        success: false,
        trim: {},
      }
    default:
      return state
  }
}
export const trimDeleteReducer = (
  state = { success: false, loading: true },
  action
) => {
  switch (action.type) {
    case TRIM_DELETE_REQUEST:
      return { loading: true, success: false }
    case TRIM_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case TRIM_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case TRIM_DELETE_RESET:
      return {
        loading: false,
        success: false,
      }
    default:
      return state
  }
}

export const trimDetailReducer = (
  state = { trim: {}, loading: true },
  action
) => {
  switch (action.type) {
    case TRIM_DETAIL_REQUEST:
      return { loading: true, trim: {} }
    case TRIM_DETAIL_SUCCESS:
      return {
        loading: false,
        trim: action.payload,
      }
    case TRIM_DETAIL_RESET:
      return {
        loading: true,
        trim: {},
      }
    case TRIM_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const trimListFilterReducer = (
  state = { trims: [], loading: false, count: {} },
  action
) => {
  switch (action.type) {
    case TRIM_LIST_FILTER_REQUEST:
      return { loading: true, trims: [], count: {} }
    case TRIM_LIST_FILTER_SUCCESS:
      return {
        loading: false,
        trims: action.payload.trims,
        count: action.payload.count,
      }
    case TRIM_LIST_FILTER_RESET:
      return {
        loading: true,
        trims: [],
      }
    case TRIM_LIST_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
