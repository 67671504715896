import Axios from "../../utils/axios"
import {
  MODEL_ANALYTICS_FAIL,
  MODEL_ANALYTICS_REQUEST,
  MODEL_ANALYTICS_SUCCESS,
} from "../constants/analyticsContants"

export const getModelPriceTrend =
  (car, filter = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: MODEL_ANALYTICS_REQUEST,
      })

      const { data } = await Axios.get(
        `v1/analytics/carct/brand/${car.brandId}/model/${car.modelId}/year/${car.yearId}?month=${filter}`
      )
      dispatch({
        type: MODEL_ANALYTICS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MODEL_ANALYTICS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
