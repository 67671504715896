import {
  BRAND_CREATE_FAIL,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_RESET,
  BRAND_CREATE_SUCCESS,
  BRAND_DETAIL_FAIL,
  BRAND_DETAIL_REQUEST,
  BRAND_DETAIL_RESET,
  BRAND_DETAIL_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_REVIEW_FAIL,
  BRAND_LIST_REVIEW_REQUEST,
  BRAND_LIST_REVIEW_SUCCESS,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE_FAIL,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_RESET,
  BRAND_UPDATE_SUCCESS,
} from "../constants/brandConstants"

export const brandListReducer = (
  state = { brands: { popularBrands: [], otherBrands: [] }, loading: true },
  action
) => {
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return { loading: true, brands: { popularBrands: [], otherBrands: [] } }
    case BRAND_LIST_SUCCESS:
      return {
        loading: false,
        brands: action.payload,
      }
    case BRAND_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const brandListReviewReducer = (
  state = { brands: [], loading: true },
  action
) => {
  switch (action.type) {
    case BRAND_LIST_REVIEW_REQUEST:
      return { loading: true, brands: [] }
    case BRAND_LIST_REVIEW_SUCCESS:
      return {
        loading: false,
        brands: action.payload,
      }
    case BRAND_LIST_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const brandUpdateReducer = (
  state = { success: false, loading: false },
  action
) => {
  switch (action.type) {
    case BRAND_UPDATE_REQUEST:
      return { loading: true, success: false }
    case BRAND_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case BRAND_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case BRAND_UPDATE_RESET:
      return { loading: false, success: false }
    default:
      return state
  }
}
export const brandCreateReducer = (
  state = { success: false, loading: false },
  action
) => {
  switch (action.type) {
    case BRAND_CREATE_REQUEST:
      return { loading: true, success: false }
    case BRAND_CREATE_SUCCESS:
      return {
        loading: false,
        success: false,
      }
    case BRAND_CREATE_RESET:
      return { loading: false, success: false }
    case BRAND_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const brandDetailReducer = (
  state = { brand: {}, loading: true },
  action
) => {
  switch (action.type) {
    case BRAND_DETAIL_REQUEST:
      return { loading: true, brand: {} }
    case BRAND_DETAIL_SUCCESS:
      return {
        loading: false,
        brand: action.payload,
      }
    case BRAND_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    case BRAND_DETAIL_RESET:
      return { loading: false, brand: {} }
    default:
      return state
  }
}
