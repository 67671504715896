import { useSelector } from "react-redux"

const useOpportunity = () => {
  const opportunityList = useSelector(
    (state) => state.opportunity.opportunityList
  )
  const { opportunities, loading: loadingList } = opportunityList

  const opportunityCreate = useSelector(
    (state) => state.opportunity.opportunityCreate
  )
  const { success: successCreate } = opportunityCreate

  const opportunityUpdate = useSelector(
    (state) => state.opportunity.opportunityUpdate
  )
  const { success: successUpdate } = opportunityUpdate

  const opportunityDelete = useSelector(
    (state) => state.opportunity.opportunityDelete
  )
  const { success: successDelete } = opportunityDelete

  return [
    successCreate,
    successUpdate,
    successDelete,
    loadingList,
    opportunities,
  ]
}

export default useOpportunity
