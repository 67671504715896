import Axios from "../../utils/axios"
import {
  STATIC_DATA_LIST_FAIL,
  STATIC_DATA_LIST_REQUEST,
  STATIC_DATA_LIST_SUCCESS,
} from "../constants/staticDataConstants"

export const getStaticData = (type) => async (dispatch) => {
  try {
    dispatch({
      type: STATIC_DATA_LIST_REQUEST,
    })

    const { data } = await Axios.get(`/v1/data/${type}`)
    dispatch({
      type: STATIC_DATA_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STATIC_DATA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
