import { Button } from "@mui/material"
import React, { useState } from "react"
import "./DropDownPopup.scss"

const DropDownPopup = ({
  trigger,
  setTrigger,
  value,
  setValue,
  options,
  title,
  name,
}) => {
  const [innerValue, setInnerValue] = useState(value)

  return trigger ? (
    <div className="popup-status-change">
      <div className="popup-inner">
        <div className="popup-header flex jcsb">
          <div className="title">{title}</div>
        </div>
        <div className="popup-body">
          <select
            className="status-select"
            value={innerValue}
            onChange={(e) => {
              setInnerValue(e.target.value)
            }}
          >
            <option value="-">-</option>
            {options.map((option, i) => (
              <option value={option}>{option}</option>
            ))}
          </select>
          <div className="status-button-wrapper">
            <Button
              className="btn light"
              onClick={() => {
                setTrigger(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn primary"
              onClick={() => {
                setTrigger(false)
                setValue(name, innerValue)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default DropDownPopup
