import Button from "@mui/material/Button"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import InnerHeader from "../../components/header/InnerHeader"
import Select from "../../components/input/Select"
import TextInput from "../../components/input/TextInput"
import CustomSwitch from "../../components/selection/Switch"
import InnerContainer from "../../components/utils/InnerContainer"
import Loader from "../../components/utils/Loader"
import { createTrim, updateTrim } from "../../state/actions/trimActions"
import useTrimDetail from "../../state/customHooks/carct/useTrimDetail"
import useTrimUpdate from "../../state/customHooks/carct/useTrimUpdate"
import { toCamelCase } from "../../utils/utilsFunctions"
import "./TrimCreateEditScreen.scss"
import useYearDetail from "../../state/customHooks/carct/useYearDetail"
import ImageInput from "../../components/input/ImageInput"
import useTrimCreate from "../../state/customHooks/carct/useTrimCreate"
import useBrandDetail from "../../state/customHooks/carct/useBrandDetail"
import useModelDetail from "../../state/customHooks/carct/useModelDetail"
import useYearSpecTempDetail from "../../state/customHooks/spectemplate/useYearSpecTempDetail"
import ToggleButtonsMultiple from "../../components/input/MultiSelect"
import TextArea from "../../components/input/TextArea"
import Axios from "../../utils/axios"
import CarPictures from "../../components/carsmpscreens/CarPictures"

const TrimCreateEditScreen = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const brandQ = searchParams.get("brand")
  const modelQ = searchParams.get("model")
  const yearQ = searchParams.get("year")
  const nameQ = searchParams.get("name")

  const [trimBuffer, setTrimBuffer] = useState()

  const [images, setImages] = useState([])
  const [imagesOriginal, setImagesOriginal] = useState([])
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const [spec, setSpec] = useState({})

  const [name, setName] = useState("")
  const [isReview, setIsReview] = useState(false)
  const [yearCoverImage, setYearCoverImage] = useState("")

  const [, , successTrimUpdate, updatedTrim] = useTrimUpdate()
  const [, , successTrimCreate] = useTrimCreate()
  const [loadingTrimDetail, , trim] = useTrimDetail(id)

  const [, , brand] = useBrandDetail()
  const [, , model] = useModelDetail()

  const [loadingYearDetail, , year] = useYearDetail(yearQ)

  const [, , yearTemplate] = useYearSpecTempDetail(trimBuffer?.year?.name)

  const findValueInGroups = (key, trim) => {
    if (!trim || !trim.groups) {
      return null // Return null if trim or trim.groups is missing
    }

    let value = null

    trim.groups.forEach((group) => {
      if (group.fields) {
        const field = group.fields.find((field) => field.fieldName === key)
        if (field && field.value !== undefined) {
          value = field.value
          return // Break out of the loop if value is found
        }
      }
    })

    return value
  }
  const trimToSpec = (trim) => {
    const spec = {}

    yearTemplate?.groups?.forEach((yearGroup) => {
      const trimGroup = trim.groups?.find(
        (trimGroup) => trimGroup.name === toCamelCase(yearGroup.name)
      )

      if (trimGroup) {
        const groupData = {}

        yearGroup.fields.forEach((yearField) => {
          const trimField = trimGroup.fields.find(
            (field) => field.fieldName === toCamelCase(yearField.fieldName)
          )

          if (trimField) {
            groupData[toCamelCase(yearField.fieldName)] = trimField.value || "" // If value is undefined, set to empty string
          } else {
            groupData[toCamelCase(yearField.fieldName)] = findValueInGroups(
              toCamelCase(yearField.fieldName),
              trim
            ) // Field not found in trim, set to empty string
          }
        })

        const groupName = toCamelCase(yearGroup.name) // Convert group name to camelCase
        spec[groupName] = groupData
      }
    })

    return spec
  }

  // useEffect(() => {
  //   if (successTrimUpdate || successTrimCreate) {
  //     navigate(-2)
  //   }
  // }, [successTrimUpdate, successTrimCreate])

  useEffect(() => {
    if (!loadingTrimDetail && id) {
      setTrimBuffer({
        brand: trim.brand,
        model: trim.model,
        year: trim.year,
        name: trim.name,
      })
      setName(trim.name)
      setYearCoverImage(trim?.year?.generation?.image)
      setIsReview(trim?.isReview)
      setImages(trim.images)
      setImagesOriginal(trim.imagesOriginal)
      setIsImageLoaded(true)
    }
    if (successTrimUpdate) {
      setTrimBuffer(updatedTrim)
    }

    if (
      brand._id === brandQ &&
      model._id === modelQ &&
      year?.year?._id === yearQ
    ) {
      setTrimBuffer({
        brand: {
          name: brand.name,
          _id: brand._id,
        },
        model: { name: model.name, _id: model._id },
        year: {
          name: year.year.name,
          _id: year.year._id,
          coverImage: year.year.coverImage,
        },
        name: nameQ,
      })
      setName(nameQ)
      setYearCoverImage(year?.year?.coverImage)
    }
    if (successTrimCreate) {
    }
  }, [
    id,
    successTrimUpdate,
    trim,
    loadingTrimDetail,
    brandQ,
    modelQ,
    yearQ,
    year,
    loadingYearDetail,
  ])

  useEffect(() => {
    if (!id) {
      // Check if yearTemplate is available
      if (yearTemplate) {
        // Generate spec object based on yearTemplate
        const generatedSpec = {}
        yearTemplate.groups?.forEach((group) => {
          const groupName = toCamelCase(group.name) // Convert group name to camelCase
          generatedSpec[groupName] = {}
          group.fields?.forEach((field) => {
            const fieldName = toCamelCase(field.fieldName) // Convert field name to camelCase
            generatedSpec[groupName][fieldName] = ""
          })
        })
        setSpec(generatedSpec)
      }
    } else {
      setSpec(trimToSpec(trim))
    }
  }, [yearTemplate])

  const updateFieldValue = (e, i, j) => {
    let value

    if (e.target.type === "checkbox") {
      value = e.target.checked // For boolean switches like checkboxes
    } else {
      value = e.target.value // For other input types
    }

    setSpec((prevSpec) => {
      const updatedSpec = { ...prevSpec }
      const groupName = toCamelCase(yearTemplate.groups[i].name)
      const fieldName = toCamelCase(yearTemplate.groups[i].fields[j].fieldName)

      if (!updatedSpec[groupName]) {
        updatedSpec[groupName] = {}
      }

      updatedSpec[groupName][fieldName] = value

      return updatedSpec
    })
  }
  const updateFieldValueArray = (array, i, j) => {
    setSpec((prevSpec) => {
      const updatedSpec = { ...prevSpec }
      const groupName = toCamelCase(yearTemplate.groups[i].name)
      const fieldName = toCamelCase(yearTemplate.groups[i].fields[j].fieldName)

      if (!updatedSpec[groupName]) {
        updatedSpec[groupName] = {}
      }

      updatedSpec[groupName][fieldName] = array

      return updatedSpec
    })
  }

  const trimOne = {
    name: name,
    brand: trimBuffer?.brand?._id,
    model: trimBuffer?.model?._id,
    generation: trimBuffer?.generation?._id,
    year: trimBuffer?.year?._id,
    isReview,
    spec,
  }

  const trimUpdateHandler = () => {
    if (!id) {
      dispatch(createTrim(trimOne))
    } else {
      dispatch(updateTrim(id, trimOne))
    }
  }

  const disabled = !yearCoverImage

  const handleDuplicate = () => {
    // Create a copy of the trim object
    const copiedTrim = { ...trimOne }

    // Perform any necessary modifications to the copied trim

    // Redirect to the new trim's URL, passing the copied trim as a parameter
    navigate(`/catalogue/trim`, { trim: copiedTrim })
  }
  const trimDownloadHandler = async () => {
    try {
      const response = await Axios.get(
        `/v1/trims/download/${yearTemplate.name}/${trim._id}`,
        { responseType: "blob" } // Ensure the response is treated as a blob
      )

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = "data_report.xlsx"
      link.click()

      URL.revokeObjectURL(url) // Revoke temporary URL after download
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="trim-edit-screen">
      {id && loadingTrimDetail ? (
        <Loader />
      ) : (
        <>
          <InnerHeader title="Trim Edit" className="inner-header">
            <div className="buttons-wrapper">
              <Button
                style={{
                  backgroundColor: "#03a00c",
                  borderRadius: "0",
                  padding: "0 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  height: "100%",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
                onClick={trimDownloadHandler}
              >
                Download
              </Button>
              {id ? (
                <Button
                  style={{
                    backgroundColor: !disabled ? "#03a00c" : "#808080",
                    borderRadius: "0",
                    padding: "0 16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFFFFF",
                    height: "100%",
                    textTransform: "uppercase",
                    cursor: "pointer",
                    marginRight: 5,
                  }}
                  disabled={disabled}
                  onClick={handleDuplicate}
                >
                  Copy
                </Button>
              ) : null}

              <Button
                style={{
                  backgroundColor: disabled && isReview ? "#808080" : "#03a00c",
                  borderRadius: "0",
                  padding: "0 16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  height: "100%",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
                disabled={isReview && disabled}
                onClick={trimUpdateHandler}
              >
                Save
              </Button>
            </div>
          </InnerHeader>
          <InnerContainer>
            <div className="section">
              <p className="section-title">Trim Information</p>
              <div className="r grid-4">
                <TextInput
                  value={trimBuffer?.brand?.name}
                  label={"Brand"}
                  disabled="true"
                />
                <TextInput
                  value={trimBuffer?.model?.name}
                  label={"Model"}
                  disabled="true"
                />
                <TextInput
                  value={trimBuffer?.year?.name}
                  label={"Year"}
                  disabled="true"
                />
                <TextInput value={name} label={"Trim"} setValue={setName} />
              </div>
              <div className="r grid-4">
                <ImageInput
                  image={yearCoverImage}
                  setImage={setYearCoverImage}
                />
              </div>
            </div>

            <>
              {yearTemplate?.groups?.map((group, i) => (
                <div key={group.name} className="section">
                  <p className="section-title">{group.name}</p>
                  <div className="r grid-4">
                    {group.fields.map((field, j) =>
                      field.type === "text" &&
                      spec[toCamelCase(group.name)] &&
                      spec[toCamelCase(group.name)][
                        toCamelCase(field.fieldName)
                      ] !== undefined ? (
                        <TextInput
                          key={field.fieldName}
                          value={
                            spec[toCamelCase(group.name)][
                              toCamelCase(field.fieldName)
                            ]
                          }
                          label={field.fieldName}
                          name={field.fieldName}
                          onChange={(e) => updateFieldValue(e, i, j)}
                        />
                      ) : field.type === "number" &&
                        spec[toCamelCase(group.name)] &&
                        spec[toCamelCase(group.name)][
                          toCamelCase(field.fieldName)
                        ] !== undefined ? (
                        <TextInput
                          key={j}
                          type="number"
                          value={
                            spec[toCamelCase(group.name)][
                              toCamelCase(field.fieldName)
                            ]
                          }
                          label={field.fieldName}
                          name={field.fieldName}
                          onChange={(e) => updateFieldValue(e, i, j)}
                        />
                      ) : field.type === "select" &&
                        spec[toCamelCase(group.name)] &&
                        spec[toCamelCase(group.name)][
                          toCamelCase(field.fieldName)
                        ] !== undefined ? (
                        <Select
                          label={field.fieldName}
                          options={field.value.map((value) => ({
                            name: value,
                            value: value,
                          }))}
                          name={field.fieldName}
                          value={
                            spec[toCamelCase(group.name)][
                              toCamelCase(field.fieldName)
                            ]
                          }
                          onChange={(e) => updateFieldValue(e, i, j)}
                        />
                      ) : field.type === "multiSelect" &&
                        spec[toCamelCase(group.name)] &&
                        spec[toCamelCase(group.name)][
                          toCamelCase(field.fieldName)
                        ] !== undefined ? (
                        <>
                          <ToggleButtonsMultiple
                            chipArray={field.value}
                            label={field.fieldName}
                            onChange={updateFieldValueArray}
                            group={i}
                            index={j}
                            value={
                              spec[toCamelCase(group.name)][
                                toCamelCase(field.fieldName)
                              ]
                            }
                          />
                        </>
                      ) : field.type === "longText" &&
                        spec[toCamelCase(group.name)] &&
                        spec[toCamelCase(group.name)][
                          toCamelCase(field.fieldName)
                        ] !== undefined ? (
                        <TextArea
                          rows={3}
                          label={field.fieldName}
                          name={field.fieldName}
                          value={
                            spec[toCamelCase(group.name)][
                              toCamelCase(field.fieldName)
                            ]
                          }
                          onChange={(e) => updateFieldValue(e, i, j)}
                        />
                      ) : field.type === "boolean" &&
                        spec[toCamelCase(group.name)] &&
                        spec[toCamelCase(group.name)][
                          toCamelCase(field.fieldName)
                        ] !== undefined ? (
                        <CustomSwitch
                          label={field.fieldName}
                          group={i}
                          index={j}
                          checked={
                            spec[toCamelCase(group.name)][
                              toCamelCase(field.fieldName)
                            ]
                          }
                          onChange={updateFieldValue}
                        />
                      ) : null
                    )}
                  </div>
                </div>
              ))}
            </>

            <CarPictures
              id={id}
              images={images}
              setImages={setImages}
              imagesOriginal={imagesOriginal}
              setImagesOriginal={setImagesOriginal}
              loadingImages={isImageLoaded}
            />
          </InnerContainer>
        </>
      )}
    </div>
  )
}

export default TrimCreateEditScreen
