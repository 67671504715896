import { useSelector } from "react-redux"

export const useSellerDuplicateList = () => {
  const sellerDuplicateCheckList = useSelector(
    (state) => state.seller.sellerDuplicateCheckList
  )
  const { loading, error, sellers } = sellerDuplicateCheckList

  return [loading, error, sellers]
}
