import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getTrimListByFilter,
  getTrimListWithYearSlug,
} from "../../actions/trimActions"
import { baseURL } from "../../../utils/base"
import useTrimCreate from "./useTrimCreate"
import useTrimUpdate from "./useTrimUpdate"
import useTrimDelete from "./useTrimDelete"

export const useTrimListKeyword = (model, year, keyword) => {
  const [timeOutId, setTimeOutId] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          model &&
            year &&
            keyword &&
            dispatch(getTrimListWithYearSlug(model, year, keyword))
        },
        keyword ? 500 : 0
      )
    )
    // eslint-disable-next-line
  }, [dispatch, model, year, keyword])

  const trimList = useSelector((state) => state.carCatalogue.trimList)
  const { trims } = trimList

  return [trims]
}

export const useTrimListByFilter = (query, isUpdated) => {
  const dispatch = useDispatch()

  const [, , successTrimCreate] = useTrimCreate()
  const [, , successTrimUpdate] = useTrimUpdate()
  const [, , successTrimDelete] = useTrimDelete()

  useEffect(() => {
    // Dispatch the action to fetch trim list if the year is provided in the query
    if (query.year) {
      dispatch(getTrimListByFilter(query))
    }
  }, [dispatch, query])

  useEffect(() => {
    // Dispatch the action to fetch trim list if the year is provided in the query
    if (successTrimCreate || successTrimUpdate) {
      dispatch(getTrimListByFilter(query))
    }
  }, [dispatch, query, successTrimCreate, successTrimUpdate])

  useEffect(() => {
    // Dispatch the action to fetch trim list if the year is provided in the query
    if (successTrimDelete) {
      dispatch(getTrimListByFilter(query))
    }
  }, [dispatch, query, successTrimDelete])
  useEffect(() => {
    // Dispatch the action to fetch trim list if the year is provided in the query
    if (isUpdated) {
      dispatch(getTrimListByFilter(query))
    }
  }, [dispatch, query, isUpdated])

  // Select relevant state from the Redux store
  const trimListFilter = useSelector(
    (state) => state.carCatalogue.trimListFilter
  )
  const { loading, error, trims, count } = trimListFilter

  // Return the loading state, error state, trim list, and count
  return [loading, error, trims, count]
}

export const useTrimYearListDistinct = (query) => {
  const [years, setYears] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchYears = async () => {
      try {
        // Start loading
        setLoading(true)

        // Fetch years from the API
        const response = await fetch(
          `${baseURL}v1/trims/distinctYear?status=${query.status}`
        )
        if (!response.ok) {
          throw new Error("Failed to fetch years")
        }
        const data = await response.json()
        console.log(data)
        // Set the years data
        setYears(data.uniqueYearNamesWithCount)

        // Finish loading
        setLoading(false)
      } catch (error) {
        // Set error state
        setError(error.message)
        // Finish loading with error
        setLoading(false)
      }
    }

    // Call the fetchYears function if year is provided in the query

    fetchYears()
  }, [query])

  // Return the loading state, error state, and years list
  return { loading, error, years }
}
