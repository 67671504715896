import React, { useEffect, useState } from "react"
import "./SaleCard.scss"
import ThreeDotsMenu from "../common/ThreeDotsMenu"
import { useNavigate } from "react-router-dom"
import ConfirmDeletePopup from "../common/ConfirmDeletePopup"
import useSaleDelete from "../../state/customHooks/sale/useSaleDelete"
import { useDispatch } from "react-redux"
import { deleteSale } from "../../state/actions/saleAction"
import { baseURLWeb } from "../../utils/base"
import { convertToFormattedDate } from "../../utils/utilsFunctions"

const SaleCard = ({ sale, onClick }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [saleSelected, setSaleSelected] = useState({})

  const [deletePopup, setDeletePopup] = useState(false)

  const [, error, success] = useSaleDelete()

  const editHandler = (sale) => {
    navigate(`/sale/${sale._id}/edit`)
  }

  const changeStatusHandler = () => {}

  const deleteHandler = () => {
    dispatch(deleteSale(saleSelected._id))
  }
  const deletePopupHandler = (auction) => {
    setSaleSelected(auction)
    setDeletePopup(true)
  }

  useEffect(() => {
    if (success || error) {
      setDeletePopup(false)
    }
  }, [success, error])

  const menuItem = [
    {
      title: "Change Status",
      action: changeStatusHandler,
    },
    {
      title: "Edit",
      action: editHandler,
    },
    {
      title: "Delete",
      action: deletePopupHandler,
    },
  ]
  return (
    <div className="list-card">
      <div className="card-content">
        <div className="col c1">
          <p className="card-title" onClick={onClick}>
            {`#${sale.id} - ${sale.name}`}
          </p>
          <div className="flex label-wrapper">
            <p>URL: </p>
            <a
              href={`${baseURLWeb}cars/listing?group=${sale.slug}`}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              {baseURLWeb}cars/listing?group={sale.slug}
            </a>
          </div>
        </div>
        <div className="col c2">
          <p className="text">
            Created at {convertToFormattedDate(sale.createdAt)}
          </p>
          <p className="text">
            Updated at {convertToFormattedDate(sale.updatedAt)}
          </p>
        </div>
        <div className="col c3">
          <ThreeDotsMenu menuItem={menuItem} data={sale} />
        </div>
      </div>
      <ConfirmDeletePopup
        trigger={deletePopup}
        onCancel={() => {
          setDeletePopup(false)
        }}
        title={"Delete Auction"}
        onConfirm={deleteHandler}
      />
    </div>
  )
}

export default SaleCard
