import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import "./InnerHeader.scss"

const InnerHeader = (props) => {
  const carUpdate = useSelector((state) => state.carMarketplace.carUpdate)
  const { success: successCarUpdate } = carUpdate

  useEffect(() => {}, [successCarUpdate])

  return (
    <div className={props.className}>
      <p className="title-text">{props.title}</p>
      {props.children}
    </div>
  )
}

export default InnerHeader
