import { Alert } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCarOpportunityList } from "../../../state/actions/carMpActions"
import Loader from "../../utils/Loader"
import { Link } from "react-router-dom"
import "./CarOpportunities.scss"

const CarOpportunities = ({ car }) => {
  const carOpportunitiesList = useSelector(
    (state) => state.carMarketplace.carOpportunitiesList
  )
  const { loading, error, carOpportunities } = carOpportunitiesList

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCarOpportunityList(car._id))

    //eslint-disable-next-line
  }, [dispatch])

  return (
    <div className="car-opportunities">
      <h5 className="title">OPPORTUNITIES</h5>
      <div className="opportunity-list">
        {loading ? (
          <Loader />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          carOpportunities.map((opportunity) => (
            <div className="opportunity-card flex jcsb aic">
              <p className="text">
                Added to Opportunity
                <Link
                  className="link"
                  to={"/"}
                >{`#${opportunity.customId}`}</Link>
              </p>
              <p>
                {opportunity.status === "ACTIVE" ? (
                  <span className="active">Open</span>
                ) : (
                  <span className="close">Closed</span>
                )}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default CarOpportunities
