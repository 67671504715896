import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { updateCar } from "../../../state/actions/carMpActions"
import CustomSwitch from "../../selection/Switch"
import "./CarDetailManaged.scss"

const CarDetailManaged = ({ car }) => {
  const [isHot, setIsHot] = useState(car.isHot)
  const [isBestQuality, setIsBestQuality] = useState(car.isBestQuality)
  const [isTaxPaid, setIsTaxPaid] = useState(car.isTaxPaid)
  const [isPhotoPremium, setIsPhotoPremium] = useState(car.isPhotoPremium)
  const [photoByCarmate, setPhotoByCarmate] = useState(car.photoByCarmate)
  const [isInspection, setIsInspection] = useState(car.isInspection)

  const dispatch = useDispatch()

  const updateCarHot = () => {
    dispatch(updateCar(car._id, { isHot: !isHot }))
  }
  const updateCarBestQuality = () => {
    dispatch(updateCar(car._id, { isBestQuality: !isBestQuality }))
  }
  const updateCarTaxPaid = () => {
    dispatch(updateCar(car._id, { isTaxPaid: !isTaxPaid }))
  }
  const updateCarPhotoPremium = () => {
    dispatch(updateCar(car._id, { isPhotoPremium: !isPhotoPremium }))
  }
  const updateCarPhotoByCarmate = () => {
    dispatch(updateCar(car._id, { photoByCarmate: !photoByCarmate }))
  }
  const updateCarIsInspection = () => {
    dispatch(updateCar(car._id, { isInspection: !isInspection }))
  }
  return (
    <div className="managed-tab">
      <div className="switch flex aic">
        <p className="text blue">{isHot ? "On" : "Off"}</p>
        <CustomSwitch
          updateStatus={setIsHot}
          checked={isHot}
          label="Hot"
          onChange={updateCarHot}
        />
      </div>
      <div className="switch flex aic">
        <p className="text blue">{isBestQuality ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setIsBestQuality}
          checked={isBestQuality}
          label="Best Quality"
          onChange={updateCarBestQuality}
        />
      </div>
      <div className="switch flex aic">
        <p className="text blue">{isTaxPaid ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setIsTaxPaid}
          checked={isTaxPaid}
          label="Tax Paid"
          onChange={updateCarTaxPaid}
        />
      </div>
      <div className="switch flex aic">
        <p className="text blue">{isTaxPaid ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setIsPhotoPremium}
          checked={isPhotoPremium}
          label="Photo Premium"
          onChange={updateCarPhotoPremium}
        />
      </div>
      <div className="switch flex aic">
        <p className="text blue">{photoByCarmate ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setPhotoByCarmate}
          checked={photoByCarmate}
          label="Photo By Carmate"
          onChange={updateCarPhotoByCarmate}
        />
      </div>
      <div className="switch flex aic">
        <p className="text blue">{isInspection ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setIsInspection}
          checked={isInspection}
          label="Inspection By Carmate"
          onChange={updateCarIsInspection}
        />
      </div>
    </div>
  )
}

export default CarDetailManaged
