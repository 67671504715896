import React, { useState, useEffect } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"
import SideBar from "./components/sidebar/SideBar"
import SideDrawer from "./components/sidedrawer/SideDrawer"
import Backdrop from "./components/backdrop/Backdrop"
import Header from "./components/header/Header"
import LoginScreen from "./screens/authscreens/LoginScreen"
import Container from "./components/utils/Container"
import CarListScreen from "./screens/carsmpscreens/CarListScreen"
import SellerListScreen from "./screens/sellerscreens/SellerListScreen"
import CarCatalogueScreen from "./screens/carctscreens/CarCatalogueScreen"
import CarDetailScreen from "./screens/carsmpscreens/CarDetailScreen"
import OpportunityListScreen from "./screens/opportunityscreens/OpportunityListScreen"
import UserListScreen from "./screens/userscreens/UserListScreen"
import UserDetailScreen from "./screens/userscreens/UserDetailScreen"
import OpportunityCreateScreen from "./screens/opportunityscreens/OpportunityCreateScreen"
import EventListScreen from "./screens/eventscreens/EventListScreen"
import DashboardScreen from "./screens/dashboard/DashboardScreen"
import ErrorHandling from "./components/utils/ErrorHandling"
import CustomMessage from "./components/utils/CustomMessage"
import CarTrendScreen from "./screens/analytics/CarTrendScreen"
import SellerDetailScreen from "./screens/sellerscreens/SellerDetailScreen"
import { useDispatch, useSelector } from "react-redux"
import { createCar, updateCar } from "./state/actions/carMpActions"
import "./App.scss"
// import { io } from "socket.io-client"
// import { changeNotifications } from "./state/actions/notificationsAction"
import ReviewCreateEditScreen from "./screens/reviewscreens/ReviewCreateEditScreen"
import UserEditScreen from "./screens/userscreens/UserEditScreen"
import TrimCreateEditScreen from "./screens/carctscreens/TrimCreateEditScreen"
import YearCreateEditScreen from "./screens/carctscreens/YearCreateEditScreen"
// import { baseURL } from "./utils/base"
import AuctionListScreen from "./screens/auctionscreen/AuctionListScreen"
import AuctionCreateEditScreen from "./screens/auctionscreen/AuctionCreateEditScreen"
import CarCreateEditScreen from "./screens/carsmpscreens/CarCreateEditScreen"
import SellerCreateEditScreen from "./screens/sellerscreens/SellerCreateEditScreen"
import LogListingScreen from "./screens/logscreens/LogListingScreen"
import SaleListScreen from "./screens/salescreen/SaleListScreen"
import SaleCreateEditScreen from "./screens/salescreen/SaleCreateEditScreen"
import LinkCreateEditScreen from "./screens/linkscreens/LinkCreateEditScreen"
import LinkListScreen from "./screens/linkscreens/LinkListScreen"
import SpecDashboardScreen from "./screens/specificationscreens/SpecDashboardScreen"
import NewCarTrimsDashboard from "./screens/newcartrimscreens/NewCarTrimsDashboard"

const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  const [carId, setCarId] = useState(null)

  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.users.userLogin)
  const { userInfo } = userLogin

  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    !userInfo
      ? navigate("/login")
      : userInfo && location.pathname === "/login" && navigate("/dashboard")
  }, [userInfo, navigate, location.pathname])

  // const [time, setTime] = React.useState([])

  // const socket = useRef()

  // React.useEffect(() => {
  //   socket.current = io(baseURL)
  //   socket.current.on("connect", () => console.log(socket.id))
  //   socket.current.on("connect_error", () => {
  //     setTimeout(() => socket.connect(), 5000)
  //   })
  //   socket.current.on("msg:get", (data) =>
  //     dispatch(changeNotifications(data.msg))
  //   )
  //   socket.current.on("disconnect", () => setTime("server disconnected"))

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const uploadHandler = async (car) => {
    dispatch(createCar(car))
  }

  const updateHandler = async (id, carUpdated) => {
    dispatch(updateCar(id, carUpdated))
    setCarId(id)
  }

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen)
  }

  const backdropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  return (
    <>
      {!userInfo ? (
        <Routes>
          <Route path="/login" element={<LoginScreen />} exact />
        </Routes>
      ) : (
        <>
          <Container>
            <Header drawerClick={drawerToggleClickHandler} />
            <main className="screen-wrapper flex">
              <SideBar />
              {sideDrawerOpen && (
                <>
                  <SideDrawer closeDrawer={backdropClickHandler} />
                  <Backdrop click={backdropClickHandler} />
                </>
              )}

              <Routes>
                <>
                  <Route path="/" element={<DashboardScreen />} exact />
                  <Route
                    path="/dashboard"
                    element={<DashboardScreen />}
                    exact
                  />
                  <Route path="/link/" element={<LinkListScreen />} exact />
                  <Route
                    path="/trim/dashboard"
                    element={<NewCarTrimsDashboard />}
                    exact
                  />
                  <Route
                    path="/link/create"
                    element={<LinkCreateEditScreen />}
                    exact
                  />
                  <Route
                    path="/link/:id/edit"
                    element={<LinkCreateEditScreen />}
                    exact
                  />

                  <Route path="/catalogue" element={<CarCatalogueScreen />} />
                  <Route path="/seller/list/" element={<SellerListScreen />} />
                  <Route
                    path="/seller/:sellerType/create"
                    element={<SellerCreateEditScreen />}
                  />
                  <Route
                    path="/seller/:sellerType/:id/edit"
                    element={<SellerCreateEditScreen />}
                  />
                  <Route
                    path="/seller/:sellerType/:id/show"
                    element={<SellerDetailScreen />}
                  />
                  <Route
                    path="/car/list"
                    element={<CarListScreen carUpdateId={carId} />}
                  />
                  <Route path="/auction/list" element={<AuctionListScreen />} />
                  <Route
                    path="/auction/create"
                    element={<AuctionCreateEditScreen />}
                  />
                  <Route
                    path="/auction/:id/edit"
                    element={<AuctionCreateEditScreen />}
                  />
                  <Route path="/sale/list" element={<SaleListScreen />} />
                  <Route
                    path="/sale/create"
                    element={<SaleCreateEditScreen />}
                  />
                  <Route
                    path="/sale/:id/edit"
                    element={<SaleCreateEditScreen />}
                  />
                  <Route
                    path="/car/create"
                    element={
                      <CarCreateEditScreen uploadHandler={uploadHandler} />
                    }
                  />

                  <Route path="/car/:id" element={<CarDetailScreen />} />
                  <Route
                    path="/car/:id/edit"
                    element={
                      <CarCreateEditScreen updateHandler={updateHandler} />
                    }
                  />
                  <Route path="/user/list" element={<UserListScreen />} />
                  <Route path="/user/:id/show" element={<UserDetailScreen />} />
                  <Route path="/user/:id/edit" element={<UserEditScreen />} />
                  <Route
                    path="/opportunity/list"
                    element={<OpportunityListScreen />}
                  />
                  <Route
                    path="/opportunity/create/:id"
                    element={<OpportunityCreateScreen />}
                  />
                  <Route path="/events/list" element={<EventListScreen />} />
                  <Route
                    path="/review/create"
                    element={<ReviewCreateEditScreen />}
                  />
                  <Route
                    path="/:brandId/:modelId/review/edit"
                    element={<ReviewCreateEditScreen />}
                  />
                  <Route
                    path="/catalogue/trim/:id/edit"
                    element={<TrimCreateEditScreen />}
                  />
                  <Route
                    path="/catalogue/trim"
                    element={<TrimCreateEditScreen />}
                  />
                  <Route
                    path="/catalogue/year/:id/edit"
                    element={<YearCreateEditScreen />}
                  />
                  <Route
                    path="/catalogue/year/"
                    element={<YearCreateEditScreen />}
                  />
                  <Route path="/logs" element={<LogListingScreen />} />
                  <Route
                    path="/specification/dashboard"
                    element={<SpecDashboardScreen />}
                  />
                </>

                <Route path="/trends" element={<CarTrendScreen />} />
              </Routes>
            </main>
            <ErrorHandling setCarId={setCarId} />
            <CustomMessage />
          </Container>
        </>
      )}
    </>
  )
}

export default App
