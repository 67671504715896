import React, { useEffect, useState } from "react"
import "./YearInfomationForm.scss"
import ImageWithOrientation from "../utils/ImageWithOrientation"
import { Link } from "react-router-dom"

const YearInformationForm = ({ yearInfo }) => {
  const [yearBuffer, setYearBuffer] = useState({})
  const [images, setImages] = useState([])
  const [pros, setPros] = useState([])
  const [cons, setCons] = useState([])
  const [carmateReview, setCarmateReview] = useState({})
  const [videoLinks, setVideoLinks] = useState([])
  const [colors, setColors] = useState([])

  const rating =
    (carmateReview?.comfort +
      carmateReview?.fuelEconomy +
      carmateReview?.performance +
      carmateReview?.style +
      carmateReview?.valueForMoney) /
    5

  useEffect(() => {
    if (yearInfo?.year?._id) {
      setYearBuffer({
        brand: yearInfo.year.brand,
        model: yearInfo.year.model,
        name: yearInfo.year.name,
        _id: yearInfo.year._id,
        active: yearInfo.year.active,
        popular: yearInfo.year.popular,
      })
      setCarmateReview(yearInfo?.year?.carmateReview)
      setPros(yearInfo?.year?.carmateReview?.pros)
      setCons(yearInfo?.year?.carmateReview?.cons)
      setVideoLinks(yearInfo?.year?.videos)
      setColors(yearInfo?.year?.colors)
      setImages(yearInfo?.year?.images)
    }
  }, [yearInfo])

  return (
    <div className="year-information-form">
      <div className="section">
        <p className="section-title">Name: {yearInfo?.year?.name}</p>
        <p className="section-title">Cover Image</p>
        {yearInfo?.year?.generation?.image ? (
          <img
            src={yearInfo?.year?.generation?.image}
            alt=""
            className="cover-image"
          />
        ) : null}
      </div>
      <div className="section">
        <p className="section-title">Rating</p>
        <div className="table-values">
          <p className="flex jcsb mb-2">
            <span className="bold">Overall Rating</span>
            <p>{rating || "-"}</p>
          </p>
          <div className="flex jcsb mb-2">
            <p>{`Comfort`}</p>
            <p>{carmateReview?.comfort}</p>
          </div>
          <div className="flex jcsb mb-2">
            <p>{`Fuel Economy`}</p>
            <p>{carmateReview?.fuelEconomy}</p>
          </div>
          <div className="flex jcsb mb-2">
            <p>{`Performance`}</p>
            <p>{carmateReview?.performance}</p>
          </div>
          <div className="flex jcsb mb-2">
            <p>{`Style`}</p>
            <p>{carmateReview?.style}</p>
          </div>
          <div className="flex jcsb mb-2">
            <p>{`Value for Money`}</p>
            <p>{carmateReview?.valueForMoney}</p>
          </div>
        </div>
        <p className="section-title">Pros and Cons</p>
        <p className="section-title">Pros</p>

        {pros?.map((pro) => (
          <div className="r">
            <p>{pro}</p>
          </div>
        ))}
        <p className="section-title">Cons</p>

        {cons?.map((con) => (
          <div className="r">
            <p>{con}</p>
          </div>
        ))}
      </div>

      <div className="section">
        <p className="section-title">Video Embed Links</p>

        {videoLinks?.length > 0 &&
          videoLinks?.map((link) => (
            <div className="flex">
              <div
                className="embed-video"
                dangerouslySetInnerHTML={{ __html: link.embed }}
              ></div>
            </div>
          ))}
      </div>

      <div className="section">
        <p className="section-title">Colors</p>

        {colors?.map((color, i) => (
          <div className="color-list-item">
            <p>{color.colorName}</p>
            <div className="color" style={{ background: color.colorHex }}></div>
            <img src={color.image} alt="color" className="car-color-image" />
          </div>
        ))}
      </div>
      <div className="section">
        <p className="section-title">Car Images</p>
        <div className="images-list">
          {images.map((id) => (
            <ImageWithOrientation
              src={id}
              className="car-image"
              key={id}
              alt={`${yearBuffer.name}-${id}`}
              sideDrawer
            />
          ))}
        </div>
      </div>
      <div className="section">
        <p className="section-title">Sellers</p>
        <div className="images-list">
          {yearInfo?.year?.sellers.map((seller) => (
            <Link
              to={`/seller/officialBrands/${seller._id}/show`}
              className="link"
              target="blank"
            >
              {seller.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default YearInformationForm
