import React, { useContext, useEffect, useState } from "react"
import "./DealershipFilter.scss"
import crossIcon from "../../images/common/cross.svg"
import crossChip from "../../images/common/cross-chip.svg"
import CustomButton from "../common/Button"
import TextInput from "../input/TextInput"
import { useSearchParams } from "react-router-dom"
import { SellerListContext } from "../../screens/sellerscreens/SellerListScreen"

const DealershipFilter = (props) => {
  const { setTagsFilter } = useContext(SellerListContext)
  const [tag, setTag] = useState("")
  const [options, setOptions] = useState(props.tags)
  const [filteredOptions, setFilteredOptions] = useState(props.tags)
  const [searchParams] = useSearchParams()
  const tagsQuery = searchParams.get("tags")
  const [tagArray, setTagArray] = useState(
    tagsQuery ? tagsQuery.split(",") : []
  )

  const filterResults = (list, keyword) => {
    const expr = new RegExp(keyword, "gi")

    const wordList = list?.filter((elem, index) => expr.test(elem))
    return wordList
  }

  useEffect(() => {
    setOptions(
      options?.filter(function (el) {
        return tagArray?.indexOf(el) < 0
      })
    )
  }, [])

  const tagSearchHandler = (e) => {
    setTag(e)
    setFilteredOptions(filterResults(options, e))
    props.setIsClearFilter(false)
  }

  const handleTagChange = (e) => {
    setTag("")
    setOptions(
      options.filter(function (el) {
        return filteredOptions.indexOf(el) < 0
      })
    )
    setTagArray([...tagArray, e])
  }
  const handleTagRemove = (e) => {
    setTagArray(tagArray.filter((tag) => tag !== e))
    setOptions([...options, e])
  }

  const clearFilterHandler = () => {
    setTag("")
    setOptions(props.tags)
    setTagArray([])
    setTagsFilter([])
    props.setIsClearFilter(true)
    props.setTrigger(false)
  }

  const applyFilterHandler = () => {
    setTagsFilter(tagArray)
    props.setTrigger(false)
  }

  return (
    <div className="dealership-filter-wrapper">
      <div className="dealership-filter-header flex">
        <div
          className="left-col"
          onClick={() => {
            props.setTrigger(false)
          }}
        >
          <img src={crossIcon} alt="" />
          <p className="text">Filters</p>
        </div>
        <div className="right-col">
          <CustomButton
            className="secondary"
            title="Clear"
            onClick={clearFilterHandler}
          />
          <CustomButton
            title="Apply"
            onClick={applyFilterHandler}
            className="primary"
            disabled={tagArray.length === 0}
          />
        </div>
      </div>
      <div className="dealership-filter-body">
        <div className="dealership-filter-body-wrapper">
          <div className="dealership-filter-body-header">
            <p className="text-header">Dealership</p>
          </div>
          <div className="text-with-suggestions">
            <TextInput label="Tags" value={tag} setValue={tagSearchHandler} />
            {tag && (
              <div className="suggestions-wrapper">
                {filteredOptions?.map((option, i) => (
                  <div
                    className="suggestion"
                    key={i}
                    onClick={() => handleTagChange(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="tags-wrapper">
          {tagArray?.map((tag, i) => (
            <div className="tag-wrapper" key={i}>
              <p className="tag-text">{tag}</p>
              <img
                src={crossChip}
                alt=""
                onClick={() => handleTagRemove(tag)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DealershipFilter
