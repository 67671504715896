import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GENERATION_DETAIL_RESET } from "../../constants/generationConstants"
import { getGenerationDetail } from "../../actions/generationActions"

const useGenerationDetail = (id) => {
  const dispatch = useDispatch()

  const generationDetail = useSelector(
    (state) => state.carCatalogue.generationDetail
  )
  const {
    loading: loadingGenerationDetail,
    error: errorGenerationDetail,
    generation,
  } = generationDetail

  useEffect(() => {
    dispatch({ type: GENERATION_DETAIL_RESET })
    id && dispatch(getGenerationDetail(id))
  }, [dispatch, id])

  return [loadingGenerationDetail, errorGenerationDetail, generation]
}

export default useGenerationDetail
