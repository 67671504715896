import "./Forms.scss"
import React, { useEffect, useState } from "react"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select from "@mui/material/Select"
import CircularProgress from "@mui/material/CircularProgress"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getGenerationList } from "../../state/actions/generationActions"
import { useDispatch, useSelector } from "react-redux"
import { createYearRange } from "../../state/actions/yearActions"
import { YEAR_CREATE_RANGE_RESET } from "../../state/constants/yearConstants"

export default function YearsRangeForm(props) {
  const [generation, setGeneration] = useState("")

  const generationList = useSelector(
    (state) => state.carCatalogue.generationList
  )
  const { generations } = generationList

  const yearRangeCreate = useSelector(
    (state) => state.carCatalogue.yearRangeCreate
  )
  const {
    loading: loadingYearRangeCreate,
    error: errorYearRangeCreate,
    success: successYearRangeCreate,
  } = yearRangeCreate

  const dispatch = useDispatch()

  const [fields, setFields] = useState({
    yearFrom: "",
    yearTo: "",
    active: true,
  })

  const handleChange = (e, type) => {
    if (typeof e === "boolean") {
      setFields({
        ...fields,
        active: !e,
      })
    } else {
      setFields({
        ...fields,
        [type]: e,
      })
    }
  }

  useEffect(() => {
    dispatch(getGenerationList(props.data.model._id))
  }, [dispatch])

  useEffect(() => {
    if (successYearRangeCreate || errorYearRangeCreate) {
      setTimeout(() => {
        dispatch({ type: YEAR_CREATE_RANGE_RESET })
      }, 5000)
    }
  }, [dispatch, successYearRangeCreate, errorYearRangeCreate])

  const submitYear = async () => {
    let From = new Date(fields.yearFrom).getFullYear()
    let To = new Date(fields.yearTo).getFullYear()

    let params = {
      yearFrom: From,
      yearTo: To,
      generation,

      brand: props.data.model.brand,
      model: props.data.model._id,
      id: fields.id,
    }
    dispatch(createYearRange(params))
  }

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Year
          </label>
          <DatePicker
            selected={fields.yearFrom}
            onChange={(date) => handleChange(date, "yearFrom")}
            showYearPicker
            dateFormat="yyyy"
            placeholderText="From"
          />
        </FormControl>
      </div>
      <div className="form-group pt-17">
        <FormControl fullWidth>
          <DatePicker
            selected={fields.yearTo}
            onChange={(date) => handleChange(date, "yearTo")}
            showYearPicker
            dateFormat="yyyy"
            placeholderText="To"
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Generation
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select Generation"
            input={<OutlinedInput />}
            value={generation}
            name="generation"
            displayEmpty
            label="Select"
            onChange={(e) => {
              setGeneration(e.target.value)
            }}
          >
            <MenuItem value="" disabled>
              --
            </MenuItem>
            {generations.length > 0
              ? generations.map((gen) => {
                  return (
                    <MenuItem key={gen._id} value={gen._id}>
                      {gen.name}
                    </MenuItem>
                  )
                })
              : ""}
          </Select>
        </FormControl>
      </div>
      <div className="form-group pt-17 input-wrapper flex col"></div>
      <div className="r2 flex">
        {errorYearRangeCreate || successYearRangeCreate ? (
          <Alert
            severity={errorYearRangeCreate ? "error" : "success"}
            color="info"
            onClose={() => dispatch({ type: YEAR_CREATE_RANGE_RESET })}
          >
            {errorYearRangeCreate
              ? errorYearRangeCreate
              : "Years created successfully!"}
          </Alert>
        ) : (
          ""
        )}
        <Button className="bg-green" onClick={submitYear}>
          {loadingYearRangeCreate ? (
            <CircularProgress color="inherit" />
          ) : (
            "SAVE"
          )}
        </Button>
      </div>
    </div>
  )
}
