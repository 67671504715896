import React, { useEffect, useState } from "react"
import "./CarPicturesVertical.scss"
import CustomButton from "../common/Button"
import { saveAs } from "file-saver"
import SortableImages from "../common/sortableimages/SortableImages"
import ImageUpload from "../utils/ImageUpload"

const CarPicturesVertical = ({
  images = [],
  setImages,
  imagesOriginal = [],
  setImagesOriginal,
  loadingImages,
  noUpload,
  disableControl,
}) => {
  const [imagesURL, setImagesURL] = useState([])
  const [imagesURLOriginal, setImagesURLOriginal] = useState([])

  useEffect(() => {
    if (loadingImages) {
      setImagesURL(images)
      setImagesURLOriginal(imagesOriginal)
    }
  }, [loadingImages])

  const save = (image) => {
    saveAs(image, image.split("/").pop())
  }
  const delayLoop = (fn, delay) => {
    return (image, i) => {
      setTimeout(() => {
        fn(image)
      }, i * delay)
    }
  }

  const downloadOriginalImagesHandler = () => {
    imagesOriginal?.length > 0 && imagesOriginal.forEach(delayLoop(save, 3000))
  }

  return (
    <div className="car-pictures-vertical">
      <>
        {!noUpload ? (
          <ImageUpload
            imagesURL={imagesURL}
            setImagesURL={setImagesURL}
            imagesOriginal={imagesOriginal}
            setImagesOriginal={setImagesOriginal}
            imagesURLOriginal={imagesURLOriginal}
            setImagesURLOriginal={setImagesURLOriginal}
            images={images}
            setImages={setImages}
          />
        ) : null}

        <div className="button-wrapper">
          <CustomButton
            title="Download"
            onClick={downloadOriginalImagesHandler}
            variant="green"
            disabled={imagesOriginal?.length === 0 && images?.length === 0}
          />
        </div>
        {imagesURL.length ? (
          <SortableImages
            imagesURL={imagesURL}
            setImagesURL={setImagesURL}
            imagesOriginal={imagesOriginal}
            setImagesOriginal={setImagesOriginal}
            imagesURLOriginal={imagesURLOriginal}
            setImagesURLOriginal={setImagesURLOriginal}
            images={images}
            setImages={setImages}
            sideDrawer
            disableControl={disableControl}
          />
        ) : null}
      </>
    </div>
  )
}

export default CarPicturesVertical
