import {
  AUCTION_CREATE_FAIL,
  AUCTION_CREATE_REQUEST,
  AUCTION_CREATE_SUCCESS,
  AUCTION_DELETE_FAIL,
  AUCTION_DELETE_REQUEST,
  AUCTION_DELETE_SUCCESS,
  AUCTION_DETAIL_FAIL,
  AUCTION_DETAIL_REQUEST,
  AUCTION_DETAIL_SUCCESS,
  AUCTION_LIST_FAIL,
  AUCTION_LIST_REQUEST,
  AUCTION_LIST_SUCCESS,
  AUCTION_UPDATE_FAIL,
  AUCTION_UPDATE_REQUEST,
  AUCTION_UPDATE_SUCCESS,
} from "../constants/auctionConstants"
import Axios from "../../utils/axios"

export const getAuctionList =
  (status = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: AUCTION_LIST_REQUEST,
      })

      const { data } = await Axios.get(`/v1/auctions?status=${status}`)

      dispatch({
        type: AUCTION_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: AUCTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createAuction = (auction) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUCTION_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.post(`/v1/auctions/`, auction, config)
    dispatch({
      type: AUCTION_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAuctionDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AUCTION_DETAIL_REQUEST,
    })

    const { data } = await Axios.get(`/v1/auctions/${id}`)

    dispatch({
      type: AUCTION_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateAuction = (id, auction) => async (dispatch) => {
  try {
    dispatch({
      type: AUCTION_UPDATE_REQUEST,
    })

    const { data } = await Axios.put(`/v1/auctions/${id}`, auction)

    dispatch({
      type: AUCTION_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteAuction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUCTION_DELETE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.delete(`/v1/auctions/${id}`, config)

    dispatch({
      type: AUCTION_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
