import Axios from "../../utils/axios"
import {
  YEAR_BY_MODEL_GENERATION_LIST_FAIL,
  YEAR_BY_MODEL_GENERATION_LIST_REQUEST,
  YEAR_BY_MODEL_GENERATION_LIST_SUCCESS,
  YEAR_CREATE_FAIL,
  YEAR_CREATE_RANGE_FAIL,
  YEAR_CREATE_RANGE_REQUEST,
  YEAR_CREATE_RANGE_SUCCESS,
  YEAR_CREATE_REQUEST,
  YEAR_CREATE_SUCCESS,
  YEAR_DELETE_FAIL,
  YEAR_DELETE_REQUEST,
  YEAR_DELETE_SUCCESS,
  YEAR_DETAIL_FAIL,
  YEAR_DETAIL_REQUEST,
  YEAR_DETAIL_SUCCESS,
  YEAR_LIST_FAIL,
  YEAR_LIST_REQUEST,
  YEAR_LIST_REVIEW_FAIL,
  YEAR_LIST_REVIEW_REQUEST,
  YEAR_LIST_REVIEW_SUCCESS,
  YEAR_LIST_SUCCESS,
  YEAR_UPDATE_FAIL,
  YEAR_UPDATE_REQUEST,
  YEAR_UPDATE_SUCCESS,
} from "../constants/yearConstants"

export const getYearList = (modelId) => async (dispatch) => {
  try {
    dispatch({
      type: YEAR_LIST_REQUEST,
    })

    const { data } = await Axios.get(`/v1/years/model/${modelId}`)
    dispatch({
      type: YEAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getYearListReview = (modelId) => async (dispatch) => {
  try {
    dispatch({
      type: YEAR_LIST_REVIEW_REQUEST,
    })

    const { data } = await Axios.get(`/v1/years/review/${modelId}`)
    dispatch({
      type: YEAR_LIST_REVIEW_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_LIST_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getYearsByModelGenerationList = (modelId) => async (dispatch) => {
  try {
    dispatch({
      type: YEAR_BY_MODEL_GENERATION_LIST_REQUEST,
    })

    const { data } = await Axios.get(`/v1/years/generation/model/${modelId}`)
    dispatch({
      type: YEAR_BY_MODEL_GENERATION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_BY_MODEL_GENERATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteYear = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/years/${id}`, config)
    dispatch({
      type: YEAR_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: YEAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createYear = (year) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.post(`/v1/years/`, year, config)
    dispatch({
      type: YEAR_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createYearRange = (years) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_CREATE_RANGE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.post(`/v1/years/range`, years, config)
    dispatch({
      type: YEAR_CREATE_RANGE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: YEAR_CREATE_RANGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateYear = (id, year, detail) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_UPDATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.put(
      `/v1/years/${id}?detail=${detail}`,
      year,
      config
    )

    dispatch({
      type: YEAR_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getYearDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: YEAR_DETAIL_REQUEST,
    })

    const { data } = await Axios.get(`/v1/years/${id}`)
    dispatch({
      type: YEAR_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
