import React, { useEffect, useState } from "react"
import InnerHeader from "../../components/header/InnerHeader"
import "./SpecDashboardScreen.scss"
import InnerHeaderButton from "../../components/utils/Button"
import ButtonGroup from "../../components/utils/ButtonGroup"
import InnerContainer from "../../components/utils/InnerContainer"
import CarDatabase from "../../components/SpecDashboardScreen/CarDatabase"
import Sidebar from "../../components/SpecDashboardScreen/Sidebar"
import { useDispatch } from "react-redux"
import {
  createYearSpecTemplate,
  updateYearSpecTemplate,
} from "../../state/actions/carDbTemplateActions"
import useYearSpecTempList from "../../state/customHooks/spectemplate/useYearSpecTempList"
import useYearSpecTempCreate from "../../state/customHooks/spectemplate/useYearSpecTempCreate"
import useYearSpecTempUpdate from "../../state/customHooks/spectemplate/useYearSpecTempUpdate"
import { updateObjectInArray } from "../../utils/utilsFunctions"
import RolesAndPermission from "../../components/SpecDashboardScreen/RolesAndPermission"

const SpecDashboardScreen = () => {
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState({ value: "car-database" })

  const [show, setShow] = useState(false)
  const [yearsBuffer, setYearsBuffer] = useState([])
  const [activeYear, setActiveYear] = useState({})
  const [modalShowYear, setModalShowYear] = useState(false)
  const [yearName, setYearName] = useState("")
  const [duplicateYear, setDuplicateYear] = useState("")

  const addGroupHandler = () => {
    setShow(true)
  }
  const duplicateYearHandler = () => {
    setModalShowYear(true)
    setDuplicateYear(true)
  }

  const [loadingCreate, , successCreate, yearCreate] = useYearSpecTempCreate()
  const [loadingUpdate, , successUpdate, yearUpdate] = useYearSpecTempUpdate()

  const [loadingYearsList, , years] = useYearSpecTempList()

  useEffect(() => {
    if (!loadingYearsList) {
      setYearsBuffer(years)
    }
  }, [loadingYearsList])

  const saveActiveYearHandler = () => {
    const updatedGroups = activeYear.groups.map((group) => ({
      ...group,
      fields: group.fields.filter((field) => field.fieldName),
    }))

    // Create the updated year object
    const updatedYear = {
      ...activeYear,
      groups: updatedGroups,
    }

    setActiveYear(updatedYear)
    if (activeYear._id) {
      dispatch(updateYearSpecTemplate(activeYear._id, updatedYear))
    } else {
      dispatch(createYearSpecTemplate(updatedYear))
    }
  }

  useEffect(() => {
    if (successCreate) {
      setActiveYear(yearCreate)

      setYearsBuffer((prev) => {
        return updateObjectInArray(prev, "name", yearCreate.name, yearCreate)
      })
    }
    if (successUpdate) {
      setYearsBuffer((prev) => {
        return updateObjectInArray(prev, "_id", activeYear._id, yearUpdate)
      })
      setActiveYear(yearUpdate)
    }
  }, [successCreate, successUpdate])

  const TABS = [
    {
      name: "Car Database",
      value: "car-database",
    },
    {
      name: "Roles and Permissions",
      value: "role-and-permissions",
    },
  ]

  return (
    <div className="settingsScreen">
      <InnerHeader title={"Settings"} className="inner-header">
        <ButtonGroup>
          {activeTab.value === "car-database" ? (
            <>
              {activeYear.name ? (
                <InnerHeaderButton
                  color={"#1C86B8"}
                  onClick={duplicateYearHandler}
                >
                  Duplicate
                </InnerHeaderButton>
              ) : null}

              <InnerHeaderButton color={"#1C86B8"} onClick={addGroupHandler}>
                Add Group
              </InnerHeaderButton>
              <InnerHeaderButton
                className="btn-blue"
                onClick={saveActiveYearHandler}
                loading={loadingCreate || loadingUpdate}
              >
                Save
              </InnerHeaderButton>
            </>
          ) : null}
        </ButtonGroup>
      </InnerHeader>
      <InnerContainer>
        <div className="r1">
          <div className="c1">
            <Sidebar
              TABS={TABS}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
          <div className="c2">
            {activeTab.value === "car-database" ? (
              <CarDatabase
                years={yearsBuffer}
                modalShow={show}
                setModalShow={setShow}
                activeYear={activeYear}
                setActiveYear={setActiveYear}
                setYears={setYearsBuffer}
                modalShowYear={modalShowYear}
                setModalShowYear={setModalShowYear}
                yearName={yearName}
                setYearName={setYearName}
                duplicateYear={duplicateYear}
                setDuplicateYear={setDuplicateYear}
              />
            ) : (
              <RolesAndPermission />
            )}
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default SpecDashboardScreen
