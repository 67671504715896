import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { countryList } from "../../../assets/countriesList"
import Switch from "../../selection/Switch"
import "./BrandEdit.scss"
import Empty from "../../../images/dashboard/imageph.svg"
import Delete from "../../../assets/img/Delete.png"
import Button from "@mui/material/Button"
import { createBrand, updateBrand } from "../../../state/actions/brandActions"
import { CircularProgress } from "@mui/material"

const BrandEdit = () => {
  const brandDetail = useSelector((state) => state.carCatalogue.brandDetail)
  const { loading: loadingBrandDetail, brand: oneBrand } = brandDetail

  const brandCreate = useSelector((state) => state.carCatalogue.brandCreate)
  const { loading: loadingBrandCreate } = brandCreate

  const brandUpdate = useSelector((state) => state.carCatalogue.brandUpdate)
  const { loading: loadingBrandUpdate } = brandUpdate

  const [fields, setFields] = useState({
    id: "",
    brandName: "",
    make: "",
    image: "",
    popular: false,
    active: "",
    oldImage: "",
    createdAt: "",
    updatedAt: "",
  })

  const dispatch = useDispatch()

  const brandCreateUpdateHandler = () => {
    if (!fields.id) {
      dispatch(createBrand(fields))
    } else {
      dispatch(updateBrand(fields.id, fields))
    }
  }

  useEffect(() => {
    if (!loadingBrandDetail && oneBrand._id) {
      setFields({
        ...fields,
        id: oneBrand._id,
        brandName: oneBrand.name,
        make: oneBrand.make,
        image: oneBrand.image,
        popular: oneBrand.popular,
        active: oneBrand.active,
        createdAt: oneBrand.createdAt.substring(0, 10),
        updatedAt: oneBrand.updatedAt.substring(0, 10),
      })
    } else {
      setFields({
        id: "",
        brandName: "",
        make: "",
        image: "",
        popular: "",
        active: "",
        oldImage: "",
        createdAt: "",
        updatedAt: "",
      })
    }
  }, [oneBrand])

  const onInputChange = (e) => {
    if (typeof e === "boolean") {
      setFields({
        ...fields,
        active: !e,
      })
    } else {
      setFields({
        ...fields,
        [e.target.name]: e.target.value,
      })
    }
  }

  const uploadFileHandler = async (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setFields({
          ...fields,
          image: reader.result,
        })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const deleteImage = () => {
    let img = fields.image
    setFields({
      ...fields,
      image: "",
      oldImage: img,
    })
  }

  return (
    <div className="brand-edit">
      <form>
        <div className="flex jcsb">
          <div className="flex col c1">
            <div className="input-wrapper flex col">
              <label htmlFor="name" className="text-label">
                Name
              </label>
              <input
                type="text"
                id="brandName"
                name="brandName"
                className="text-input"
                value={fields.brandName}
                onChange={onInputChange}
              />
            </div>
            <div className="input-wrapper flex col">
              <label htmlFor="country" className="text-label">
                Country
              </label>
              <select
                className="select-input"
                name="make"
                value={fields.make}
                onChange={onInputChange}
              >
                {countryList.map((country, i) => (
                  <option key={i} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-wrapper flex col">
              <label className="text-label-m0">Status</label>
              <Switch updateStatus={onInputChange} checked={fields.active} />
            </div>
            <div className="stats">
              <p className="text">Number of Models: </p>
              <p className="text">{`Created at: ${fields.createdAt}`}</p>
              <p className="text">{`Updated at: ${fields.updatedAt}`}</p>
            </div>
          </div>
          {/* <div className="flex col c2">
            <div className="input-wrapper flex col">
              <label className="text-label">ID</label>
              <input
                type="text"
                value={fields.id}
                readOnly="true"
                disabled="true"
                className="text-input"
              />
            </div>
          </div> */}
          <div className="flex col c3">
            <div className="input-wrapper-image flex col">
              <img
                src={fields.image && fields.image !== "" ? fields.image : Empty}
                className="brand-image"
                alt="Brand"
              />
              <input
                type="file"
                name="image-upload"
                id="input"
                className="custom-file-input"
                accept="image/*"
                value=""
                onChange={(e) => {
                  uploadFileHandler(e)
                }}
              />
            </div>
            {fields.image && fields.image !== "" ? (
              <img
                src={Delete}
                className="icon-image"
                onClick={deleteImage}
                alt="Delete"
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="r2 flex">
          <Button onClick={brandCreateUpdateHandler} className="bg-green">
            {loadingBrandCreate || loadingBrandUpdate ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              "APPLY"
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BrandEdit
