import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InnerHeader from "../../components/header/InnerHeader"
import "./UserEditScreen.scss"
import Message from "../../components/utils/Message"
import { useParams } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import ImageInput from "../../components/input/ImageInput"
import { getUserDetail, updateUser } from "../../state/actions/userActions"
import { USER_UPDATE_RESET } from "../../state/constants/userContants"

const UserEditScreen = () => {
  const params = useParams()

  const sellerType = params.sellerType

  const [userBuffer, setUserBuffer] = useState({})
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber1, setPhoneNumber1] = useState("")
  const [telecom1, setTelecom1] = useState("")
  const [phoneNumber2, setPhoneNumber2] = useState("")
  const [telecom2, setTelecom2] = useState("")
  const [username, setUsername] = useState("")

  const [email, setEmail] = useState("")
  const [isPrimary, setIsPrimary] = useState(true)
  const [sellerImage, setSellerImage] = useState("")

  const userDetail = useSelector((state) => state.users.userDetail)
  const { loading: loadingUserDetail, user } = userDetail

  const userUpdate = useSelector((state) => state.users.userUpdate)
  const {
    success: userUpdateSuccess,
    loading: userUpdateLoading,
    error: userUpdateError,
    user: userUpdated,
  } = userUpdate

  useEffect(() => {
    if (userBuffer.contacts) {
      setFirstName(userBuffer?.firstName)
      setLastName(userBuffer?.lastName)
      setTelecom1(userBuffer?.contacts[0]?.telecom)
      setTelecom2(userBuffer?.contacts[1]?.telecom)
      setPhoneNumber1(userBuffer?.contacts[0]?.phoneNumber)
      setPhoneNumber2(userBuffer.contacts[1]?.phoneNumber)
      setUsername(userBuffer?.username)
      setEmail(userBuffer?.email)
      setSellerImage(userBuffer?.profileImage)
    }
  }, [user])

  const celluar = ["Smart", "Cellcard", "Metfone"]

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserDetail(params.id))
    !loadingUserDetail && setUserBuffer(user)
  }, [])

  const userObj = {
    contacts: [
      {
        phoneNumber: phoneNumber1,
        telecom: telecom1,
      },
      {
        phoneNumber: phoneNumber2,
        telecom: telecom2,
      },
    ],
    username,
    sellerImage,
    firstName,
    lastName,
    email,
    isPrimary,
  }

  useEffect(() => {
    dispatch({ type: USER_UPDATE_RESET })
    if (userUpdateSuccess) {
      dispatch(getUserDetail(params.id))
      setUserBuffer(userUpdated)
    }
  }, [userUpdateSuccess])

  const submitHandler = () => {
    dispatch(updateUser(params.id, userObj))
  }

  return (
    <div className="dealership-create-screen">
      <InnerHeader
        title={
          sellerType === "dealership"
            ? "Dealership"
            : sellerType === "companies"
            ? "Company"
            : "Official Brand"
        }
        className="inner-header"
      >
        <div disabled={!username} className="btn-save" onClick={submitHandler}>
          {userUpdateLoading ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            `Save`
          )}
        </div>
      </InnerHeader>
      {userUpdateError && <Message variant="error">{userUpdateError} </Message>}

      <div className="dealership-form-wrapper">
        <div className="dealership-form-inner">
          <div className="dealer-details">
            <p className="text">Dealer 1</p>
            <div className="r grid-3">
              <div className="input-wrapper">
                <label className="label">First Name</label>
                <input
                  type="text"
                  className="input"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                />
              </div>
              <div className="input-wrapper">
                <label className="label">Last Name</label>
                <input
                  type="text"
                  className="input"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                />
              </div>
              <div className="flex aic">
                <div className="input-wrapper" style={{ marginRight: "8px" }}>
                  <label className="label">Telecom</label>
                  <select
                    type="select"
                    className="input-telecom"
                    value={telecom1}
                    onChange={(e) => {
                      setTelecom1(e.target.value)
                    }}
                  >
                    <option value="-">-</option>
                    {celluar.map((telecom) => (
                      <option value={telecom}>{telecom}</option>
                    ))}
                  </select>
                </div>
                <div className="input-wrapper" style={{ width: "100%" }}>
                  <label className="label">Phone Number 1</label>
                  <input
                    type="text"
                    className="input-number"
                    value={phoneNumber1}
                    onChange={(e) => {
                      setPhoneNumber1(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="flex aic">
                <div className="input-wrapper" style={{ marginRight: "8px" }}>
                  <label className="label">Telecom</label>
                  <select
                    type="select"
                    className="input-telecom"
                    value={telecom2}
                    onChange={(e) => {
                      setTelecom2(e.target.value)
                    }}
                  >
                    <option value="-">-</option>
                    {celluar.map((telecom) => (
                      <option value={telecom}>{telecom}</option>
                    ))}
                  </select>
                </div>
                <div className="input-wrapper" style={{ width: "100%" }}>
                  <label className="label">Phone Number 2</label>
                  <input
                    type="text"
                    className="input-number"
                    value={phoneNumber2}
                    onChange={(e) => {
                      setPhoneNumber2(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="r grid-3">
              <div className="input-wrapper">
                <label className="label">Username</label>
                <input
                  type="text"
                  disabled={true}
                  className="input"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                  }}
                />
              </div>
              <div className="input-wrapper">
                <label className="label">Password</label>
                <input type="password" className="input" disabled={true} />
              </div>
              <div className="input-wrapper">
                <label className="label">Confirm Password</label>
                <input type="password" disabled={true} className="input" />
              </div>
            </div>
            <div className="r grid-1">
              <div className="input-wrapper">
                <label className="label">Email (Optional)</label>
                <input
                  type="email"
                  className="input"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </div>
              <div className="input-wrapper-checkbox flex aic">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isPrimary}
                  onChange={() => {
                    setIsPrimary(!isPrimary)
                  }}
                />
                <label className="label-checkbox">Set Primary</label>
              </div>
            </div>
            <div className="r grid-1">
              <ImageInput
                image={sellerImage}
                setImage={setSellerImage}
                label={"Profile Picture"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserEditScreen
