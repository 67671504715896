import {
  TRIM_CREATE_FAIL,
  TRIM_CREATE_REQUEST,
  TRIM_CREATE_SUCCESS,
  TRIM_DELETE_FAIL,
  TRIM_DELETE_REQUEST,
  TRIM_DELETE_SUCCESS,
  TRIM_DETAIL_FAIL,
  TRIM_DETAIL_REQUEST,
  TRIM_DETAIL_SUCCESS,
  TRIM_LIST_FAIL,
  TRIM_LIST_FILTER_FAIL,
  TRIM_LIST_FILTER_REQUEST,
  TRIM_LIST_FILTER_SUCCESS,
  TRIM_LIST_REQUEST,
  TRIM_LIST_SUCCESS,
  TRIM_UPDATE_FAIL,
  TRIM_UPDATE_REQUEST,
  TRIM_UPDATE_SUCCESS,
  TRIM_YEAR_LIST_FAIL,
  TRIM_YEAR_LIST_REQUEST,
  TRIM_YEAR_LIST_SUCCESS,
} from "../constants/trimConstants"
import Axios from "../../utils/axios"
import { generateQueryURL } from "../../utils/utilsFunctions"

export const getTrimList = (modelId, yearId) => async (dispatch) => {
  try {
    dispatch({
      type: TRIM_LIST_REQUEST,
    })

    const { data } = await Axios.get(
      `/v1/trims/year?model=${modelId}&year=${yearId}`
    )
    dispatch({
      type: TRIM_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTrimListWithYearSlug =
  (modelId, yearSlug, keyword) => async (dispatch) => {
    try {
      dispatch({
        type: TRIM_LIST_REQUEST,
      })

      const { data } = await Axios.get(
        `/v1/trims/year?model=${modelId}&year=${yearSlug}&keyword=${keyword}`
      )
      dispatch({
        type: TRIM_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TRIM_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getTrimListByModel = (modelId) => async (dispatch) => {
  try {
    dispatch({
      type: TRIM_YEAR_LIST_REQUEST,
    })

    const { data } = await Axios.get(`/v1/trims/year/model/${modelId}`)
    dispatch({
      type: TRIM_YEAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_YEAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createTrim = (trim) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRIM_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.post(`/v1/trims/`, trim, config)
    dispatch({
      type: TRIM_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const updateTrim = (id, trim) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRIM_UPDATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.put(`/v1/trims/${id}`, trim, config)
    dispatch({
      type: TRIM_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const deleteTrim = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRIM_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/trims/${id}`, config)
    dispatch({
      type: TRIM_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: TRIM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTrimDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TRIM_DETAIL_REQUEST,
    })

    const { data } = await Axios.get(`/v1/trims/${id}`)
    dispatch({
      type: TRIM_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTrimListByYear = (query) => async (dispatch) => {
  try {
    dispatch({
      type: TRIM_YEAR_LIST_REQUEST,
    })

    const { data } = await Axios.get(`/v1/trims?${generateQueryURL(query)}`)
    dispatch({
      type: TRIM_YEAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_YEAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getTrimListByFilter = (query) => async (dispatch) => {
  try {
    dispatch({
      type: TRIM_LIST_FILTER_REQUEST,
    })

    const { data } = await Axios.get(`/v1/trims?${generateQueryURL(query)}`)
    dispatch({
      type: TRIM_LIST_FILTER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRIM_LIST_FILTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
