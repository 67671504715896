import Axios from "../../utils/axios"
import {
  CAR_LIST_FAIL,
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
} from "../constants/carMPConstants"
import {
  OPPORTUNITY_CREATE_FAIL,
  OPPORTUNITY_CREATE_REQUEST,
  OPPORTUNITY_CREATE_SUCCESS,
  OPPORTUNITY_DELETE_FAIL,
  OPPORTUNITY_DELETE_REQUEST,
  OPPORTUNITY_DELETE_SUCCESS,
  OPPORTUNITY_LIST_FAIL,
  OPPORTUNITY_LIST_REQUEST,
  OPPORTUNITY_LIST_SUCCESS,
  OPPORTUNITY_UPDATE_FAIL,
  OPPORTUNITY_UPDATE_REQUEST,
  OPPORTUNITY_UPDATE_SUCCESS,
} from "../constants/opportunityConstants"

export const getOpportunityList =
  (status, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: OPPORTUNITY_LIST_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.get(
        `/v1/opportunities?status=${status}&pageNumber=${pageNumber}`,
        config
      )
      dispatch({
        type: OPPORTUNITY_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: OPPORTUNITY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateOpportunity =
  (id, opportunity) => async (dispatch, getState) => {
    try {
      dispatch({
        type: OPPORTUNITY_UPDATE_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.put(
        `/v1/opportunities/${id}`,
        opportunity,
        config
      )

      dispatch({
        type: OPPORTUNITY_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: OPPORTUNITY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getOpportunityCarList = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/opportunities/${id}/cars`, config)
    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createOpportunity =
  (opportunity) => async (dispatch, getState) => {
    try {
      dispatch({
        type: OPPORTUNITY_CREATE_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.post(
        `/v1/opportunities`,
        opportunity,
        config
      )

      dispatch({
        type: OPPORTUNITY_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: OPPORTUNITY_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// delete opportunity action

export const deleteOpportunity = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: OPPORTUNITY_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/opportunities/${id}`, config)

    dispatch({
      type: OPPORTUNITY_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: OPPORTUNITY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
