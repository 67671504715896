import Axios from "../../utils/axios"
import {
  YEAR_SPEC_CREATE_FAIL,
  YEAR_SPEC_CREATE_REQUEST,
  YEAR_SPEC_CREATE_SUCCESS,
  YEAR_SPEC_DETAIL_FAIL,
  YEAR_SPEC_DETAIL_REQUEST,
  YEAR_SPEC_DETAIL_SUCCESS,
  YEAR_SPEC_LIST_FAIL,
  YEAR_SPEC_LIST_REQUEST,
  YEAR_SPEC_LIST_SUCCESS,
  YEAR_SPEC_UPDATE_FAIL,
  YEAR_SPEC_UPDATE_REQUEST,
  YEAR_SPEC_UPDATE_SUCCESS,
} from "../constants/carDatabase"

export const createYearSpecTemplate =
  (activeYear) => async (dispatch, getState) => {
    try {
      dispatch({
        type: YEAR_SPEC_CREATE_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.post(
        `/v1/specifications/`,
        activeYear,
        config
      )
      dispatch({
        type: YEAR_SPEC_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: YEAR_SPEC_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getYearSpecTemplateList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_SPEC_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/specifications/`, config)

    dispatch({
      type: YEAR_SPEC_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_SPEC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getYearSpecTemplateDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: YEAR_SPEC_DETAIL_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/specifications/${id}`, config)

    dispatch({
      type: YEAR_SPEC_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: YEAR_SPEC_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateYearSpecTemplate =
  (id, activeYear) => async (dispatch, getState) => {
    try {
      dispatch({
        type: YEAR_SPEC_UPDATE_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.put(
        `/v1/specifications/${id}`,
        activeYear,
        config
      )
      dispatch({
        type: YEAR_SPEC_UPDATE_SUCCESS,
        payload: data,
      })
    
    } catch (error) {
      dispatch({
        type: YEAR_SPEC_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
