import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTrimDetail } from "../../actions/trimActions"
import { TRIM_DETAIL_RESET } from "../../constants/trimConstants"

const useTrimDetail = (id) => {
  const dispatch = useDispatch()

  const trimDetail = useSelector((state) => state.carCatalogue.trimDetail)
  const {
    trim,
    loading: loadingTrimDetail,

    error: errorTrimDetail,
  } = trimDetail

  useEffect(() => {
    dispatch({ type: TRIM_DETAIL_RESET })
    id && dispatch(getTrimDetail(id))
  }, [dispatch, id])

  return [loadingTrimDetail, errorTrimDetail, trim]
}

export default useTrimDetail
