import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { USER_UPDATE_RESET } from "../../constants/userContants"
import { RESPONSE_RESET } from "../../constants/responseConstants"

const useUserUpdate = () => {
  const dispatch = useDispatch()

  const userUpdate = useSelector((state) => state.users.userUpdate)
  const {
    loading: loadingUserUpdate,
    error: errorUserUpdate,
    success: successUserUpdate,
    user: updatedUser,
  } = userUpdate

  useEffect(() => {
    if (successUserUpdate) {
      dispatch(setMessage("User Updated Successfully"))
      setTimeout(() => {
        dispatch({ type: USER_UPDATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successUserUpdate])

  return [loadingUserUpdate, errorUserUpdate, successUserUpdate, updatedUser]
}

export default useUserUpdate
