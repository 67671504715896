import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getModelDetail } from "../../actions/modelActions"

const useModelDetail = (id) => {
  const dispatch = useDispatch()

  const modelDetail = useSelector((state) => state.carCatalogue.modelDetail)
  const {
    loading: loadingModelDetail,
    error: errorModelDetail,
    model,
  } = modelDetail

  useEffect(() => {
    id && dispatch(getModelDetail(id))
  }, [dispatch, id])

  return [loadingModelDetail, errorModelDetail, model]
}

export default useModelDetail
