import React, { useEffect, useState } from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import Axios from "../../utils/axios"
import "./AuctionMap.scss"

const AuctionMap = ({ auction, value }) => {
  const [key, setKey] = useState("")
  const [selected, setSelected] = useState({})
  const [zoom, setZoom] = useState(12)
  const defaultCenter = {
    lat: Number(auction?.location?.latitude),
    lng: Number(auction?.location?.longitude),
  }

  useEffect(() => {
    const getConfig = async () => {
      const { data } = await Axios.get("/v1/config/google")
      setKey(data)
    }
    getConfig()

    if (value) {
      setSelected(value)
      setZoom(16)
    }
  }, [key, value])

  return (
    key && (
      <LoadScript googleMapsApiKey={`${key}`}>
        <GoogleMap
          mapContainerClassName="map-style-auction"
          zoom={zoom}
          center={selected.location || defaultCenter}
          streetViewControl={false}
          options={{ mapTypeControl: false }}
        >
          <Marker
            position={{
              lat: Number(auction?.location?.latitude),
              lng: Number(auction?.location?.longitude),
            }}
          />
        </GoogleMap>
      </LoadScript>
    )
  )
}

export default AuctionMap
