import Axios from "../../utils/axios"
import {
  SELLER_LAST_ACTIVITY_ANALYTICS_FAIL,
  SELLER_LAST_ACTIVITY_ANALYTICS_REQUEST,
  SELLER_LAST_ACTIVITY_ANALYTICS_SUCCESS,
} from "../constants/sellerAnalyticsConstants"

export const getSellerLastActivityAnalytics =
  (type, days) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_LAST_ACTIVITY_ANALYTICS_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await Axios.get(
        `/v1/${type}/analytics?days=${days}`,
        config
      )
      dispatch({
        type: SELLER_LAST_ACTIVITY_ANALYTICS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_LAST_ACTIVITY_ANALYTICS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
