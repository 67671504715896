import { useSelector } from "react-redux"

const useYearCreate = () => {
  const yearCreate = useSelector((state) => state.carCatalogue.yearCreate)
  const {
    loading: loadingYearCreate,
    error: errorYearCreate,
    success: successYearCreate,
    year: yearCreated,
  } = yearCreate

  return [loadingYearCreate, errorYearCreate, successYearCreate, yearCreated]
}

export default useYearCreate
