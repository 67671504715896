import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { updateCar } from "../../../state/actions/carMpActions"
import CustomSwitch from "../../selection/Switch"
import "./VinReportTab.scss"

const VinReportTab = ({ car }) => {
  const [isHistoryCheck, setIsHistoryCheck] = useState(car.isHistoryCheck)
  const [isAccident, setIsAccident] = useState(car.historyCheck.isAccident)
  const [isFireDamage, setIsFireDamage] = useState(
    car.historyCheck.isFireDamage
  )
  const [isHailDamage, setIsHailDamage] = useState(
    car.historyCheck.isHailDamage
  )
  const [isFloodDamage, setIsFloodDamage] = useState(
    car.historyCheck.isFloodDamage
  )
  const [isAunctionJunk, setIsAunctionJunk] = useState(
    car.historyCheck.isAunctionJunk
  )
  const [isAuctionRebuilt, setIsAuctionRebuilt] = useState(
    car.historyCheck.isAuctionRebuilt
  )
  const [isSalvage, setIsSalvage] = useState(car.historyCheck.isSalvage)
  const [isOtherDamage, setIsOtherDamage] = useState(
    car.historyCheck.isOtherDamage
  )
  const [isStructuralDamage, setisStructuralDamage] = useState(
    car.historyCheck.isStructuralDamage
  )
  const [isRecycled, setIsRecycled] = useState(car.historyCheck.isRecycled)
  const [isCrashTest, setIsCrashTest] = useState(car.historyCheck.isCrashTest)
  const [isTaxi, setIsTaxi] = useState(car.historyCheck.isTaxi)
  const [isLease, setIsLease] = useState(car.historyCheck.isLease)
  const [isTheft, setIsTheft] = useState(car.historyCheck.isTheft)
  const [isFleet, setIsFleet] = useState(car.historyCheck.isFleet)

  const dispatch = useDispatch()
  const updateCarHistoryCheck = () => {
    dispatch(updateCar(car._id, { isHistoryCheck: !isHistoryCheck }))
    if (isHistoryCheck === false) {
      setIsAccident(false)
      setIsFireDamage(false)
      setIsHailDamage(false)
      setIsFloodDamage(false)
      setIsAunctionJunk(false)
      setIsAuctionRebuilt(false)
      setIsOtherDamage(false)
      setIsCrashTest(false)
      setIsTaxi(false)
      setIsLease(false)
      setIsTheft(false)
      setIsFleet(false)
      setIsSalvage(false)
      dispatch(
        updateCar(car._id, {
          historyCheck: {
            isAccident: false,
            isFireDamage: false,
            isHailDamage: false,
            isFloodDamage: false,
            isAunctionJunk: false,
            isAuctionRebuilt: false,
            isSalvage: false,
            isOtherDamage: false,
            isStructuralDamage: false,
            isRecycled: false,
            isCrashTest: false,
            isTaxi: false,
            isLease: false,
            isTheft: false,
            isFleet: false,
          },
        })
      )
    }
  }
  const updateCarHistoryCheckDetail = (e) => {
    dispatch(
      updateCar(car._id, {
        historyCheck: {
          isAccident: e === "Accident" ? !isAccident : isAccident,
          isFireDamage: e === "isFireDamage" ? !isFireDamage : isFireDamage,
          isHailDamage: e === "isHailDamage" ? !isAccident : isHailDamage,
          isFloodDamage: e === "isFloodDamage" ? !isFloodDamage : isFloodDamage,
          isAunctionJunk:
            e === "isAunctionJunk" ? !isAunctionJunk : isAunctionJunk,
          isAuctionRebuilt:
            e === "isAuctionRebuilt" ? !isAuctionRebuilt : isAuctionRebuilt,
          isSalvage: e === "isSalvage" ? !isSalvage : isSalvage,
          isOtherDamage: e === "isOtherDamage" ? !isOtherDamage : isOtherDamage,
          isStructuralDamage:
            e === "isStructuralDamage"
              ? !isStructuralDamage
              : isStructuralDamage,
          isRecycled: e === "isRecycled" ? !isRecycled : isRecycled,
          isCrashTest: e === "isCrashTest" ? !isCrashTest : isCrashTest,
          isTaxi: e === "isTaxi" ? !isTaxi : isTaxi,
          isLease: e === "isLease" ? !isLease : isLease,
          isTheft: e === "isTheft" ? !isTheft : isTheft,
          isFleet: e === "isFleet" ? !isFleet : isFleet,
        },
      })
    )
  }

  return (
    <div className="vin-tab">
      <div className="switch flex aic">
        <p className="text blue">{isHistoryCheck ? "On" : "Off"}</p>

        <CustomSwitch
          updateStatus={setIsHistoryCheck}
          checked={isHistoryCheck}
          label="History Check"
          onChange={updateCarHistoryCheck}
        />
      </div>
      {isHistoryCheck && (
        <>
          <div className="switch flex aic">
            <p className="text blue">{isAccident ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsAccident}
              checked={isAccident}
              label="Accident"
              onChange={() => updateCarHistoryCheckDetail("Accident")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isFireDamage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsFireDamage}
              checked={isFireDamage}
              label="Fire Damage"
              onChange={() => updateCarHistoryCheckDetail("isFireDamage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isHailDamage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsHailDamage}
              checked={isHailDamage}
              label="Hail Damage"
              onChange={() => updateCarHistoryCheckDetail("isHailDamage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isFloodDamage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsFloodDamage}
              checked={isFloodDamage}
              label="Flood Damage"
              onChange={() => updateCarHistoryCheckDetail("isFloodDamage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isAunctionJunk ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsAunctionJunk}
              checked={isAunctionJunk}
              label="Auction Junk"
              onChange={() => updateCarHistoryCheckDetail("isAunctionJunk")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isAuctionRebuilt ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsAuctionRebuilt}
              checked={isAuctionRebuilt}
              label="Auction Rebuilt"
              onChange={() => updateCarHistoryCheckDetail("isAuctionRebuilt")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isSalvage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsSalvage}
              checked={isSalvage}
              label="Salvage"
              onChange={() => updateCarHistoryCheckDetail("isSalvage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isOtherDamage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsOtherDamage}
              checked={isOtherDamage}
              label="Other Damage"
              onChange={() => updateCarHistoryCheckDetail("isOtherDamage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isStructuralDamage ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setisStructuralDamage}
              checked={isStructuralDamage}
              label="Structural Damage"
              onChange={() => updateCarHistoryCheckDetail("isStructuralDamage")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isRecycled ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsRecycled}
              checked={isRecycled}
              label="Recycled"
              onChange={() => updateCarHistoryCheckDetail("isRecycled")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isCrashTest ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsCrashTest}
              checked={isCrashTest}
              label="Crash Test"
              onChange={() => updateCarHistoryCheckDetail("isCrashTest")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isTaxi ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsTaxi}
              checked={isTaxi}
              label="Taxi"
              onChange={() => updateCarHistoryCheckDetail("isTaxi")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isLease ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsLease}
              checked={isLease}
              label="Lease"
              onChange={() => updateCarHistoryCheckDetail("isLease")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isTheft ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsTheft}
              checked={isTheft}
              label="Theft"
              onChange={() => updateCarHistoryCheckDetail("isTheft")}
            />
          </div>
          <div className="switch flex aic">
            <p className="text blue">{isFleet ? "On" : "Off"}</p>

            <CustomSwitch
              updateStatus={setIsFleet}
              checked={isFleet}
              label="Fleet"
              onChange={() => updateCarHistoryCheckDetail("isFleet")}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default VinReportTab
