import "./BrandList.scss";
import React from "react";
import { useDispatch } from "react-redux";
import { SortBrandOrder } from "../../../state/actions/brandActions";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SortButtons = ({ type, brands = [], brandId = "" }) => {
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if(brands.length > 0){

  //   }
  // },[brands]);

  const movePosition = (position) => {
    if (brandId) {
      dispatch(SortBrandOrder({ position, type, brands, brandId }));
    }
  };

  return (
    <div>
      <div className="sort-buttons set-sizes">
        <div>
          <button>
            <KeyboardDoubleArrowUpIcon
              color="primary"
              onClick={() => movePosition("top")}
            />
          </button>
        </div>
        <div>
          <button>
            <KeyboardArrowUpIcon
              color="primary"
              onClick={() => movePosition("up")}
            />
          </button>
        </div>
        <div>
          <button>
            <KeyboardArrowUpIcon
              color="primary"
              onClick={() => movePosition("down")}
            />
          </button>
        </div>
        <div>
          <button>
            <KeyboardDoubleArrowUpIcon
              color="primary"
              onClick={() => movePosition("bottom")}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SortButtons;
