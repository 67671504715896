import Axios from "../../utils/axios"
import { generateQueryURL } from "../../utils/utilsFunctions"
import {
  SALE_CREATE_FAIL,
  SALE_CREATE_REQUEST,
  SALE_CREATE_SUCCESS,
  SALE_DELETE_FAIL,
  SALE_DELETE_REQUEST,
  SALE_DELETE_SUCCESS,
  SALE_DETAIL_FAIL,
  SALE_DETAIL_REQUEST,
  SALE_DETAIL_SUCCESS,
  SALE_LIST_FAIL,
  SALE_LIST_REQUEST,
  SALE_LIST_SUCCESS,
  SALE_UPDATE_FAIL,
  SALE_UPDATE_REQUEST,
  SALE_UPDATE_SUCCESS,
} from "../constants/saleConstants"

export const getSaleList = (queryObject) => async (dispatch) => {
  try {
    dispatch({
      type: SALE_LIST_REQUEST,
    })

    const { data } = await Axios.get(
      `/v1/sales?${generateQueryURL(queryObject)}`
    )

    dispatch({
      type: SALE_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SALE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createSale = (sale) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SALE_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.post(`/v1/sales/`, sale, config)
    dispatch({
      type: SALE_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SALE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getSaleDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SALE_DETAIL_REQUEST,
    })

    const { data } = await Axios.get(`/v1/sales/${id}`)

    dispatch({
      type: SALE_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SALE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateSale = (id, sale) => async (dispatch) => {
  try {
    dispatch({
      type: SALE_UPDATE_REQUEST,
    })

    const { data } = await Axios.put(`/v1/sales/${id}`, sale)

    dispatch({
      type: SALE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SALE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteSale = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SALE_DELETE_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.delete(`/v1/sales/${id}`, config)

    dispatch({
      type: SALE_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SALE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
