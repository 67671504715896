import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getYearDetail } from "../../actions/yearActions"
import { YEAR_DETAIL_RESET } from "../../constants/yearConstants"

const useYearDetail = (id) => {
  const dispatch = useDispatch()

  const yearDetail = useSelector((state) => state.carCatalogue.yearDetail)
  const {
    year,
    loading: loadingYearDetail,

    error: errorYearDetail,
  } = yearDetail

  useEffect(() => {
    dispatch({ type: YEAR_DETAIL_RESET })
    id && dispatch(getYearDetail(id))
  }, [dispatch, id])

  return [loadingYearDetail, errorYearDetail, year]
}

export default useYearDetail
