import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { currencyWithCommas } from "../../utils/utilsFunctions"
import StatusChangePopup from "../carsmpscreens/StatusChangePopup"
import "./OpportunityCard.scss"
import { Button } from "@mui/material"
import {
  deleteOpportunity,
  updateOpportunity,
} from "../../state/actions/opportunityActions"
import { DropDownMenu } from "../common/DropDownMenu"
import { status as statusList } from "../../screens/opportunityscreens/OpportunityListScreen"

const OpportunityCard = ({ opportunity, setOpportunitySelect }) => {
  const dateString = opportunity.createdAt.substring(0, 10).split("-")

  const [statusPopup, setStatusPopup] = useState(false)
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [status, setStatus] = useState(opportunity.status)

  const dispatch = useDispatch()

  const statusUpdateHandler = () => {
    dispatch(updateOpportunity(opportunity._id, { status }))
  }

  const statusUpdateModalHandler = () => {
    setStatusPopup(!statusPopup)
  }

  const deleteModalHandler = () => {
    setConfirmPopup(!confirmPopup)
  }

  const menuItems = [
    {
      title: "Change Status",
      action: statusUpdateModalHandler,
    },

    {
      title: "Delete",
      action: deleteModalHandler,
    },
  ]

  return (
    <div
      className={
        opportunity.status === "PENDING"
          ? "opportunity-card yellow"
          : opportunity.status === "ACTIVE"
          ? "opportunity-card green"
          : "opportunity-card red"
      }
    >
      <div className="card-body-wrapper flex jcsb">
        <div className="c1">
          <h5
            className="title"
            onClick={() => {
              setOpportunitySelect({
                _id: opportunity._id,
                customId: opportunity.customId,
              })
            }}
          >{`${opportunity.year} ${opportunity.brand.name} ${opportunity.model.name}`}</h5>
          <p className="text">
            {`Created at:${dateString[2]}/${dateString[1]}/${dateString[0]}`}
          </p>
          <p className="text">{`${
            opportunity.color
          } / ${opportunity.condition.map(
            (condition, i) => `${condition} `
          )} / ${opportunity.fuel.map((fuel, i) => `${fuel} `)}`}</p>
        </div>
        <div className="card-c2 flex">
          <div className="customer-info">
            <h5 className="title">
              {currencyWithCommas(opportunity.budget[0])} -{" "}
              {currencyWithCommas(opportunity.budget[1])}
            </h5>
            <p className="text">{`${opportunity.firstName} ${opportunity.lastName}`}</p>
            <p className="text"></p>
          </div>

          <div className="card-options">
            <DropDownMenu menuItem={menuItems} data={opportunity} />
            <StatusChangePopup trigger={statusPopup} title="Change Status">
              <select
                className="status-select"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                {statusList.map((status, i) => (
                  <option key={i} value={status}>
                    {status}
                  </option>
                ))}
              </select>
              <div className="status-button-wrapper">
                <Button
                  className="btn light"
                  onClick={() => {
                    setStatusPopup(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn primary"
                  onClick={() => {
                    statusUpdateHandler()
                    setStatusPopup(false)
                  }}
                >
                  Save
                </Button>
              </div>
            </StatusChangePopup>
            <StatusChangePopup
              trigger={confirmPopup}
              title="Delete Opportunity"
            >
              <div className="text">Are you sure you want to delete?</div>

              <div className="status-button-wrapper">
                <Button
                  className="btn light"
                  onClick={() => {
                    setConfirmPopup(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="btn primary"
                  onClick={() => {
                    dispatch(deleteOpportunity(opportunity._id))
                    setConfirmPopup(false)
                  }}
                >
                  Delete
                </Button>
              </div>
            </StatusChangePopup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpportunityCard
