import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomButton from "../../components/common/Button"
import InnerHeader from "../../components/header/InnerHeader"
import Select from "../../components/input/Select"
import InnerContainer from "../../components/utils/InnerContainer"
import { getModelPriceTrend } from "../../state/actions/analyticsAction"
import { getBrandList } from "../../state/actions/brandActions"
import { getModelList } from "../../state/actions/modelActions"
import { getYearList } from "../../state/actions/yearActions"
import "./CarTrendScreen.scss"
import LineChart from "../../components/charts/LineChart/LineChart"
import { MODEL_ANALYTICS_RESET } from "../../state/constants/analyticsContants"

const CarTrendScreen = () => {
  const dispatch = useDispatch()

  const rangeOption = [
    { name: "3 months", value: 3 },
    { name: "6 months", value: 6 },
    { name: "Max", value: "" },
  ]
  const [brand, setBrand] = useState("")
  const [model, setModel] = useState("")
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")

  const [data, setData] = useState([])

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading: loadingBrands, brands } = brandList

  const modelList = useSelector((state) => state.carCatalogue.modelList)
  const { models } = modelList

  const yearList = useSelector((state) => state.carCatalogue.yearList)
  const { years } = yearList

  const modelPriceTrend = useSelector(
    (state) => state.analytics.modelPriceTrend
  )
  const { modelPriceTrendData, carCount } = modelPriceTrend

  const car = {
    brandId: brand,
    modelId: model,
    yearId: year,
  }

  useEffect(() => {
    dispatch({ type: MODEL_ANALYTICS_RESET })
  }, [])

  useEffect(() => {
    if (!brand) {
      dispatch(getBrandList("cars", "admin"))
    }
    if (brand !== null && brand !== undefined && brand !== "") {
      dispatch(getModelList(brand, "available", "all"))
    }
    if (model !== null && model !== undefined && model !== "") {
      dispatch(getYearList(model))
    }
  }, [brand, model])

  useEffect(() => {
    setData(modelPriceTrendData)
  }, [modelPriceTrendData])

  const handleSearch = (monthRange) => {
    dispatch(getModelPriceTrend(car, monthRange))
    setMonth(monthRange || "")
  }

  return (
    <div className="car-trend-screen">
      <InnerHeader className="inner-header" title={`Car Trend Weekly`} />
      <InnerContainer>
        <div className="row-1">
          <div className="left-col-wrapper">
            <div className="input-form-wrapper r grid-4">
              <Select
                options={
                  loadingBrands
                    ? []
                    : [...brands.popularBrands, ...brands.otherBrands]
                }
                value={brand}
                setValue={setBrand}
                label="Brand"
              />
              <Select
                options={models}
                value={model}
                setValue={setModel}
                label="Model"
                disabled={!brand}
              />
              <Select
                options={years}
                value={year}
                setValue={setYear}
                label="Year"
                disabled={!model}
              />
              <div className="button-wrapper">
                <CustomButton
                  variant="green"
                  title="Submit"
                  onClick={() => handleSearch()}
                />
              </div>
            </div>
            {carCount && (
              <div className="inner-row-1">
                <div className="car-count">
                  <p>Car Count : {carCount}</p>
                </div>
                <div className="range-select">
                  {rangeOption.map((option) => (
                    <div
                      className={
                        option.value === month ? "chip selected" : "chip"
                      }
                      onClick={() => {
                        handleSearch(option.value)
                      }}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {data?.length > 0 && <LineChart data={data} month={month} />}
          </div>
          {/* <div className="right-col-wrapper">
            <div className="month-selector-wrapper">
              <Select
                options={range}
                setValue={setMonthYear}
              />
            </div>
          </div> */}
        </div>
      </InnerContainer>
    </div>
  )
}

export default CarTrendScreen
