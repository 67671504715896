import Axios from "../../utils/axios"
import {
  LOG_LIST_FAIL,
  LOG_LIST_GROUP_BY_CAR_FAIL,
  LOG_LIST_GROUP_BY_CAR_REQUEST,
  LOG_LIST_GROUP_BY_CAR_SUCCESS,
  LOG_LIST_REQUEST,
  LOG_LIST_SUCCESS,
} from "../constants/logConstants"

export const getLogsList = (queryObject) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOG_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(
      `/v1/utils/logs?keyword=${queryObject.keyword}&page=${queryObject.pageNumber}`,
      config
    )
    dispatch({
      type: LOG_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLogsListCars =
  (status, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOG_LIST_GROUP_BY_CAR_REQUEST,
      })
      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.get(`/v1/utils/log/cars`, config)
      dispatch({
        type: LOG_LIST_GROUP_BY_CAR_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LOG_LIST_GROUP_BY_CAR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
