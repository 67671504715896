import React, { useEffect, useState } from "react"
import "./YearCreateEditScreen.scss"
import InnerHeader from "../../components/header/InnerHeader"
import useYearDetail from "../../state/customHooks/carct/useYearDetail"
import { useParams, useSearchParams } from "react-router-dom"
import InnerContainer from "../../components/utils/InnerContainer"
import TextInput from "../../components/input/TextInput"
import CarPictures from "../../components/carsmpscreens/CarPictures"
import ImageInput from "../../components/input/ImageInput"
import { Button, CircularProgress } from "@mui/material"
import Select from "../../components/input/Select"
import actionbin from "../../images/dashboard/actionbin.svg"
import TextArea from "../../components/input/TextArea"
import useYearUpdate from "../../state/customHooks/carct/useYearUpdate"
import { useDispatch } from "react-redux"
import { createYear, updateYear } from "../../state/actions/yearActions"
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { SortableItem } from "../../components/catalogue/year/yeareditscreen/SortableItem"
import { useNavigate } from "react-router-dom"
import useBrandDetail from "../../state/customHooks/carct/useBrandDetail"
import useModelDetail from "../../state/customHooks/carct/useModelDetail"
import useYearCreate from "../../state/customHooks/carct/useYearCreate"
import InnerHeaderButton from "../../components/utils/Button"
import useGenerationDetail from "../../state/customHooks/carct/useGenerationDetail"
import ListMultiSelectModal from "../../components/modals/ListMultiSelectModal"
import { useOfficialBrandList } from "../../state/customHooks/seller/useSellerList"
import CustomSwitch from "../../components/selection/Switch"

const YearCreateEditScreen = () => {
  const { id } = useParams()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const nameQ = searchParams.get("name")

  const brandQ = searchParams.get("brand")
  const modelQ = searchParams.get("model")
  const generationQ = searchParams.get("generation")

  const [, , year] = useYearDetail(id)
  const [yearBuffer, setYearBuffer] = useState({})
  const [coverImage, setCoverImage] = useState("")
  const [images, setImages] = useState([])
  const [imagesOriginal, setImagesOriginal] = useState([])
  const [pros, setPros] = useState([])
  const [prosInput, setProsInput] = useState("")
  const [cons, setCons] = useState([])
  const [consInput, setConsInput] = useState("")
  const [carmateReview, setCarmateReview] = useState({})
  const [videoLinks, setVideoLinks] = useState([])
  const [videoLinkInput, setVideoLinkInput] = useState("")
  const [videoId, setVideoId] = useState("")
  const [isFeatured, setIsFeatured] = useState("")

  const [loadingBrand, , brand] = useBrandDetail(brandQ)

  const [loadingModel, , model] = useModelDetail(modelQ)

  const [loadingGeneration, , generation] = useGenerationDetail(generationQ)

  const [loadingYearUpdate, , successYearUpdate] = useYearUpdate()
  const [loadingYearCreate, , successYearCreate] = useYearCreate()

  const [sellers] = useOfficialBrandList()

  const [colors, setColors] = useState([])
  const [colorName, setColorName] = useState("")
  const [colorHex, setColorHex] = useState("")
  const [image, setImage] = useState("")

  const [socialTitle, setSocialTitle] = useState("")
  const [socialDescription, setSocialDescription] = useState("")

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const [sellerSelected, setSellerSelected] = useState([])
  const [isOpen, setIsOpen] = useState("")

  const addColorHandler = () => {
    setColors([
      ...colors,
      { colorName: colorName, colorHex: colorHex, image: image },
    ])
    setColorName("")
    setColorHex("")
    setImage("")
  }
  const removeColorHandler = (i) => {
    setColors(colors.filter((color, index) => index !== i))
  }

  const rating =
    (carmateReview?.comfort +
      carmateReview?.fuelEconomy +
      carmateReview?.performance +
      carmateReview?.style +
      carmateReview?.valueForMoney) /
    5

  useEffect(() => {
    if (id && id === year?.year?._id) {
      setYearBuffer({
        brand: year.year.brand,
        model: year.year.model,
        name: year.year.name,
        generation: year.year.generation,
        _id: year.year._id,
        active: year.year.active,
        popular: year.year.popular,
      })
      setCoverImage(year?.year?.coverImage)
      setCarmateReview(year?.year?.carmateReview)
      setPros(year?.year?.carmateReview?.pros)
      setCons(year?.year?.carmateReview?.cons)
      setVideoLinks(year?.year?.videos)
      setColors(year?.year?.colors)
      setSocialTitle(year?.year?.socialTitle)
      setImages(year?.year?.images)
      setImagesOriginal(year?.year?.imagesOriginal)
      setIsImageLoaded(true)
      setSellerSelected(year?.year?.sellers)
    } else {
      setYearBuffer({
        brand: brand,
        model: model,
        generation: generation,
        name: nameQ,
      })
    }
  }, [id, year, loadingBrand, loadingModel, loadingGeneration])

  useEffect(() => {
    setCarmateReview({
      ...carmateReview,
      rating,
      pros,
      cons,
    })
  }, [rating, pros, cons])

  useEffect(() => {
    if (successYearUpdate) {
      navigate(-1)
    }
  }, [successYearUpdate])

  const onRatingChange = (e) => {
    setCarmateReview({
      ...carmateReview,
      [e.target.name]: Number(e.target.value),
    })
  }

  const addProHandler = () => {
    setPros([...pros, prosInput])
    setProsInput("")
  }

  const removeProHandler = (val) => {
    setPros(pros.filter((pro) => val !== pro))
  }

  const addConHandler = () => {
    setCons([...cons, consInput])
    setConsInput("")
  }

  const removeConHandler = (val) => {
    setCons(cons.filter((con) => val !== con))
  }

  const addVideoLinkHandler = () => {
    setVideoLinks([
      ...videoLinks,
      { embed: videoLinkInput, id: videoId, isFeatured },
    ])
    setVideoId("")
    setVideoLinkInput("")
    setIsFeatured(false)
  }

  const removeVideoLinkHandler = (val) => {
    setVideoLinks(videoLinks.filter((link) => val !== link))
  }

  const score = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
  ]

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  )

  const yearOne = {
    name: yearBuffer?.name,
    brand: yearBuffer?.brand?._id,
    model: yearBuffer?.model?._id,
    generation: yearBuffer?.generation?._id,
    active: yearBuffer.active,
    popular: yearBuffer.popular,
    socialTitle,
    socialDescription,
    images,
    videos: videoLinks,
    carmateReview,
    colors,
    imagesOriginal,
    coverImage,
    sellers: sellerSelected,
  }

  const yearUpdateHandler = () => {
    if (id) {
      dispatch(updateYear(id, yearOne, true))
    } else {
      dispatch(createYear(yearOne))
    }
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setVideoLinks((items) => {
        const activeIndex = items
          .map(function (e) {
            return e.id
          })
          .indexOf(active.id)

        const overIndex = items
          .map(function (e) {
            return e.id
          })
          .indexOf(over.id)

        return arrayMove(items, activeIndex, overIndex)
      })
    }
  }

  useEffect(() => {
    if (successYearUpdate || successYearCreate) {
      navigate(-1)
    }
  }, [successYearUpdate, successYearCreate])

  const generateHeaderTitle = () => {
    if (yearBuffer._id) {
      return `Save`
    } else {
      return `Create`
    }
  }

  const filteredItemsBySeller = sellerSelected.flatMap((seller) =>
    sellers.filter((item) => item._id === seller)
  )

  const CustomItemCard = ({ item }) => {
    return <div>{item.name}</div>
  }

  const highlightUpdate = (e) => {
    setCarmateReview((prev) => ({
      ...prev,
      highlight: e.target.value,
    }))
  }

  const onChangeFeatured = (e, index) => {
    const updatedVideos = [...videoLinks]
    updatedVideos[index].isFeatured = e.target.checked
    setVideoLinks(updatedVideos)
  }
  return (
    <div className="year-edit-screen">
      <InnerHeader title="Year Edit" className="inner-header">
        <InnerHeaderButton
          onClick={yearUpdateHandler}
          disabled={loadingYearCreate || loadingYearUpdate}
        >
          {loadingYearCreate || loadingYearUpdate ? (
            <div style={{ width: 50, height: 25 }}>
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            generateHeaderTitle()
          )}
        </InnerHeaderButton>
      </InnerHeader>
      <InnerContainer>
        <div className="section">
          <p className="section-title">Year Information</p>
          <div className="r grid-4">
            <TextInput
              value={yearBuffer?.brand?.name}
              label={"Brand"}
              disabled="true"
            />
            <TextInput
              value={yearBuffer?.model?.name}
              label={"Model"}
              disabled="true"
            />
            <TextInput
              value={yearBuffer?.generation?.name}
              label={"Generation"}
              disabled="true"
            />
            <TextInput
              value={yearBuffer?.name}
              label={"Year"}
              disabled="true"
            />
          </div>
        </div>
        {year?.year?._id && year?.year?.generation?.image ? (
          <div className="section">
            <p className="section-title">Cover Image</p>
            <div className="r grid-4">
              <img
                src={year?.year?.generation?.image}
                alt=""
                className="cover-image"
              />
            </div>
          </div>
        ) : null}
        <div className="section">
          <p className="section-title">SEO</p>
          <div className="r grid-2">
            <TextInput
              value={socialTitle}
              setValue={setSocialTitle}
              label="SEO Title"
            />
          </div>
          <div className="r grid-2">
            <TextArea
              value={socialDescription}
              setValue={setSocialDescription}
              label="SEO Description"
            />
          </div>
        </div>

        <div className="section">
          <p className="section-title">Rating</p>
          <div className="r grid-4">
            <TextInput
              value={carmateReview?.highlight}
              label={"Highlight"}
              onChange={highlightUpdate}
            />
          </div>
          <p>Overall Rating {rating || "-"}</p>
          <form className="r grid-4" onChange={onRatingChange}>
            <Select
              value={carmateReview?.comfort}
              name="comfort"
              label="Comfort"
              options={score}
            />
            <Select
              value={carmateReview?.fuelEconomy}
              name="fuelEconomy"
              label="Fuel Economy"
              options={score}
            />
            <Select
              value={carmateReview?.performance}
              name="performance"
              label="Performance"
              options={score}
            />
            <Select
              value={carmateReview?.style}
              name="style"
              label="Style"
              options={score}
            />
            <Select
              value={carmateReview?.valueForMoney}
              name="valueForMoney"
              label="Value for Money"
              options={score}
            />
          </form>
          <p className="section-title">Pros and Cons</p>
          <p className="section-title">Pros</p>
          <div className="r grid-4">
            <TextInput
              value={prosInput}
              setValue={setProsInput}
              name="pros"
              label="Input"
            />
            <Button onClick={addProHandler}>Add</Button>
          </div>
          <p className="section-title">Pros List</p>
          {pros?.map((pro) => (
            <div className="r grid-4">
              <p>{pro}</p>
              <img
                src={actionbin}
                className="remove-icon"
                alt="remove"
                onClick={() => removeProHandler(pro)}
              />
            </div>
          ))}
          <p className="section-title">Cons</p>
          <div className="r grid-4">
            <TextInput
              value={consInput}
              setValue={setConsInput}
              name="cons"
              label="Input"
            />
            <Button onClick={addConHandler}>Add</Button>
          </div>
          <p className="section-title">Cons List</p>
          {cons?.map((con) => (
            <div className="r grid-4">
              <p>{con}</p>
              <img
                src={actionbin}
                className="remove-icon"
                alt="remove"
                onClick={() => removeConHandler(con)}
              />
            </div>
          ))}
        </div>

        <div className="section">
          <p className="section-title">Video Embed Links</p>
          <div className="r grid-4">
            <TextArea
              value={videoLinkInput}
              rows={6}
              setValue={setVideoLinkInput}
              name="videoLink"
              label="Video Link Embed"
            />
            <TextInput
              value={videoId}
              setValue={setVideoId}
              name="videoId"
              label="Video ID"
            />
            <div className="">
              <div className="mb-5"></div>
              <CustomSwitch
                label="Featured"
                updateStatus={setIsFeatured}
                checked={isFeatured}
              />
            </div>

            <div className="video-link-button">
              <Button onClick={addVideoLinkHandler}>Add</Button>
            </div>
          </div>
          {videoLinks?.length > 0 && (
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={videoLinks}
                strategy={verticalListSortingStrategy}
              >
                {/* We need components that use the useSortable hook */}
                {videoLinks?.map((link, i) => (
                  <SortableItem
                    key={link.id}
                    link={link}
                    id={link.id}
                    removeVideoLinkHandler={removeVideoLinkHandler}
                    onChange={onChangeFeatured}
                    index={i}
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </div>

        <div className="section">
          <p className="section-title">Colors</p>
          <form className="r grid-4">
            <TextInput
              value={colorName}
              label={"Color Name"}
              setValue={setColorName}
            />
            <TextInput
              value={colorHex}
              label={"Color Hex"}
              setValue={setColorHex}
            />

            {!image && <ImageInput value={image} setImage={setImage} />}
            {image && <img src={image} alt="car" className="car-color-image" />}
            <Button
              onClick={addColorHandler}
              disabled={!colorName || !colorHex || !image}
            >
              Add Color
            </Button>
          </form>
          {colors?.map((color, i) => (
            <div className="r grid-4">
              <p>{color.colorName}</p>
              <div
                className="color"
                style={{ background: color.colorHex }}
              ></div>
              <img src={color.image} alt="color" className="car-color-image" />
              <img
                src={actionbin}
                className="remove-icon"
                alt="remove"
                onClick={() => removeColorHandler(i)}
              />
            </div>
          ))}
        </div>
        <div className="section">
          <p className="section-title">Car Images</p>
          <CarPictures
            id={id}
            images={images}
            setImages={setImages}
            imagesOriginal={imagesOriginal}
            setImagesOriginal={setImagesOriginal}
            loadingImages={isImageLoaded}
          />
        </div>
        <div className="section">
          <p className="section-title">Sellers</p>
          <div className="seller-list-wrapper">
            <div className="flex jcsb">
              <div className=""></div>
              <Button onClick={() => setIsOpen(true)}>Add</Button>
            </div>
            <div className="">
              {filteredItemsBySeller.map((item) => (
                <div className="item-card-wrapper" key={item._id}>
                  <CustomItemCard item={item} detailOnly />
                </div>
              ))}
            </div>
          </div>
        </div>

        <ListMultiSelectModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          items={sellers}
          itemsSelected={sellerSelected}
          setItemsSelected={setSellerSelected}
          ItemCard={CustomItemCard}
          searchPlaceholder="Search items"
        />
      </InnerContainer>
    </div>
  )
}

export default YearCreateEditScreen
