//import * as React from 'react';
import React, { useState } from "react"
import Button from "@mui/material/Button"
import "./UpcomingAuction.scss"
import AuctionMap from "./AuctionMap"
import { Link } from "react-router-dom"
import Loader from "../utils/Loader"
import { formatTime } from "../../utils/utilsFunctions"
import CarCard from "../../components/carsmpscreens/CarCard"
import { baseURLWeb } from "../../utils/base"
import useAuctionList from "../../state/customHooks/auction/useAuctionList"
import CustomPopup from "../utils/CustomPopup"

const UpcomingAuction = () => {
  const [openModalIndex, setOpenModalIndex] = useState(-1)

  const handleOpenModal = (index) => {
    setOpenModalIndex(index)
  }

  const handleCloseModal = () => {
    setOpenModalIndex(-1)
  }

  const [loading, error, auctions] = useAuctionList()

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="upcoming-auction">
      <div className="meeting-list">
        <div className="auctionText">
          <h2>Upcoming Auctions</h2>
        </div>
        {auctions.map((auction, index) => (
          <div key={index}>
            <div className="meeting-item">
              <div onClick={() => handleOpenModal(index)}>
                <div className="flex">
                  <p className="link">{` ${auction.name} ${auction.location.city}`}</p>
                </div>
              </div>
              <div>
                <p>{formatTime(auction.startDate, auction.endDate)}</p>
              </div>
              <div className="blue">
                {`${auction?.attendee?.length || 0}`} Attendees
              </div>

              <div>{auction._company.name}</div>
              <div className="orange">
                {auction.cars.length ? (
                  <p
                    style={{
                      color: "#f2b459",
                      lineHeight: "17px",
                      fontSize: "12px",
                    }}
                  >
                    {auction.status}
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#cc0000",
                      lineHeight: "17px",
                      fontSize: "12px",
                    }}
                  >
                    No Cars
                  </p>
                )}
              </div>
              <div>
                <a
                  href={`${baseURLWeb}cars/listing?auction=${auction.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="link">Link</p>
                </a>
              </div>
            </div>
            <CustomPopup
              trigger={openModalIndex === index} // Only open the modal for the corresponding auction index
              onClose={handleCloseModal}
              setTrigger={handleCloseModal}
              title="Auction Information"
              closeLg
            >
              <div className="mainModal">
                <div className="leftDiv">
                  <AuctionMap auction={auction} value={auction.location} />
                  <div className="geoLocation">
                    GeoLocation: {auction.location.latitude}, &nbsp;
                    {auction.location.longitude}
                  </div>
                </div>

                <div className="rightDiv">
                  <div className="rightUpperDiv">
                    <div className="rightUpperDiv1">
                      {formatTime(auction.startDate, auction.endDate)}
                    </div>

                    <div className="rightUpperDiv2">
                      Name: {auction.name} <br />
                      Address: {auction.location.address} <br />
                      City: {auction.location.city} <br />
                      Description:
                      <div className="description">{auction.location.desc}</div>
                      Organizer: {auction?._company.name} <br />
                      Phone Number:{" "}
                      <span>
                        {auction?._company._primaryUser?.contacts
                          .map(
                            (contact, i) =>
                              ` ${contact.phoneNumber} (${contact.telecom})`
                          )
                          .join(" / ")}
                      </span>
                    </div>
                  </div>
                  <div className="title">
                    <p>Attendees ({auction?.attendee?.length || 0})</p>
                  </div>
                  <div className="rightLowerDiv">
                    {auction.attendee.map((attendee, index) => (
                      <div className="atteendeDiv" key={index}>
                        <p>
                          Name: {attendee.name} Phone: {attendee.phoneNumber}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="title">
                    <p>Cars ({auction?.cars.length || 0})</p>
                  </div>
                  <div className="rightLowerMain">
                    <div className="carCardDiv">
                      {auction?.cars?.length
                        ? auction?.cars?.map((car, i) => (
                            <CarCard
                              car={car}
                              loadingCarUpdate={null}
                              carUpdateId={car._id}
                              key={i}
                              detailOnly
                            />
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/auction/${auction._id}/edit`} className="editButton">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#03A00C",
                    color: "#fff",
                    borderRadius: "0px",
                    width: "118px",
                  }}
                >
                  Edit
                </Button>
              </Link>
            </CustomPopup>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UpcomingAuction
