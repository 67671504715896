import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { LINK_DELETE_RESET } from "../../constants/linkConstants"

const useLinkDelete = () => {
  const linkDelete = useSelector((state) => state.link.linkDelete)
  const { loading, error, success, link } = linkDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Link Deleted Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: LINK_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 4000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, link]
}

export default useLinkDelete
