import React, { useState } from "react"

const ImageWithOrientation = ({ src, sideDrawer, key, alt }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

  const inlineStylesImage =
    imageSize.height < imageSize.width
      ? {
          transformOrigin: "50% 50%",
          aspectRatio: "281/184",
          objectFit: "cover",
          maxWidth: "100%",
          width: !sideDrawer ? 281 : 136,
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }
      : {
          transformOrigin: "50% 50%",
          aspectRatio: "281/184",
          objectFit: "contain",
          maxWidth: "100%",
          width: !sideDrawer ? 281 : 136,
          backgroundColor: "#000",
          display: "flex",
          zIndex: 1,
          justifyContent: "center",
          alignItems: "center",
        }

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target
    setImageSize({ width: naturalWidth, height: naturalHeight })
  }

  return (
    <img
      style={inlineStylesImage}
      key={key}
      src={src}
      onLoad={handleImageLoad}
      alt={alt ? alt : "Carmate Car"}
    />
  )
}

export default ImageWithOrientation
