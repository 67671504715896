import "./Forms.scss";
import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Switch from "../selection/Switch";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button"

export default function BrandForm() {
    
  const [active, setActive] = useState(true)

  const [fields, setFields] = React.useState({
    brand: 0,
    name: "",
    model: 0,
  });

  const handleChange = (e) => {
    setFields({
      ...setFields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Brand
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fields.brand}
            name="brand"
            input={<OutlinedInput />}
            displayEmpty
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value={0} disabled>
              Select
            </MenuItem>
            <MenuItem value={10}>Toyota</MenuItem>
            <MenuItem value={20}>Honda</MenuItem>
            <MenuItem value={30}>Mazda</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Name
          </label>
          <TextField
            placeholder="Name"
            name="name"
            value={fields.name}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Body Type
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput />}
            value={fields.model}
            name="model"
            displayEmpty
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value={0} disabled>
              Select
            </MenuItem>
            <MenuItem value={10}>Toyota</MenuItem>
            <MenuItem value={20}>Honda</MenuItem>
            <MenuItem value={30}>Mazda</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="form-group pt-17 input-wrapper flex col">
        <label className="text-label-m0">Status</label>
        <Switch updateStatus={setActive} checked={active} />
      </div>
      <div className="r2 flex">
          <Button className="bg-green">
            SAVE
          </Button>
        </div>
    </div>
  );
}
