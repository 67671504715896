import React, { useEffect, useState } from "react"
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import markerIcon from "../../assets/icon/marker.svg"
import Axios from "../../utils/axios"
import { Link } from "react-router-dom"
import "./SellerMap.scss"

const SellerMap = ({ sellers, value }) => {
  const [key, setKey] = useState("")
  const [selected, setSelected] = useState({})
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 11.562108,
    lng: 104.8885354,
  })

  const [zoom, setZoom] = useState(5)
  const [mapMarker, setMapMarker] = useState()

  useEffect(() => {
    setDefaultCenter({
      lat: 11.562108,
      lng: 104.8885354,
    })
  }, [])

  useEffect(() => {
    const getConfig = async () => {
      const { data } = await Axios.get("/v1/config/google")
      setKey(data)
    }
    getConfig()

    if (value) {
      setSelected(value)

      setZoom(12)
    }
  }, [key, value])

  const onSelect = (seller) => {
    setSelected({
      location: {
        lat: Number(seller?.location?.latitude),
        lng: Number(seller?.location?.longitude),
      },
      name: seller.name,
      _id: seller._id,
    })
    setZoom(16)
  }

  const googleMapHandler = () => {
    window.open(
      `https://maps.google.com?q=${selected?.location?.lat},${selected?.location?.lng}`,
      "_blank"
    )
  }

  return (
    key && (
      <LoadScript googleMapsApiKey={`${key}`}>
        <GoogleMap
          mapContainerClassName="map-style"
          zoom={zoom}
          onLoad={() => {
            let iconMarker = new window.google.maps.MarkerImage(
              markerIcon,
              null /* size is determined at runtime */,
              null /* origin is 0,0 */,
              null /* anchor is bottom center of the scaled image */,
              new window.google.maps.Size(32, 32)
            )
            setMapMarker(iconMarker)
          }}
          center={defaultCenter}
          streetViewControl="false"
          options={{ mapTypeControl: false }}
        >
          {sellers?.map((seller, i) => (
            <Marker
              icon={mapMarker}
              key={i}
              position={{
                lat: Number(seller?.location?.latitude),
                lng: Number(seller?.location?.longitude),
              }}
              onClick={() => onSelect(seller)}
            />
          ))}
          {selected.location && (
            <InfoWindow
              position={selected.location}
              options={{
                pixelOffset: new window.google.maps.Size(0, -30),
              }}
              clickable={true}
              onCloseClick={() => setSelected({})}
            >
              <div className="info-window">
                <Link to={`/seller/dealership/${selected._id}/show`}>
                  {selected.name}
                </Link>
                <p
                  className="link google-map"
                  onClick={() => googleMapHandler(selected)}
                >
                  View Google Map
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    )
  )
}

export default SellerMap
