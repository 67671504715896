import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserList } from "../../state/actions/userActions"
import UserListTab from "./UserListTab"
import "./UsersTabs.scss"

const UsersTabs = () => {
  const [firstTab, setFirstTab] = useState(true)
  const [secondTab, setSecondTab] = useState(false)
  const [thirdTab, setThirdTab] = useState(false)
  const [fourthTab, setFourthTab] = useState(false)
  const [activeTab, setActiveTab] = useState("dealers")

  const usersList = useSelector((state) => state.users.userList)
  const { loading: loadingUserList, users, counts, page, pages } = usersList

  const [pageNumber, setPageNumber] = useState(page)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserList(activeTab))
  }, [dispatch, activeTab])

  const tabClickHandler = (click) => {
    if (click === "FirstTab") {
      setFirstTab(true)
      setSecondTab(false)
      setThirdTab(false)
      setFourthTab(false)
    } else if (click === "SecondTab") {
      setFirstTab(false)
      setSecondTab(true)
      setThirdTab(false)
      setFourthTab(false)
    } else if (click === "ThirdTab") {
      setFirstTab(false)
      setSecondTab(false)
      setThirdTab(true)
      setFourthTab(false)
    } else {
      setFirstTab(false)
      setSecondTab(false)
      setThirdTab(false)
      setFourthTab(true)
    }
  }

  return (
    <div className="users-tabs">
      <div className="tabs-header-wrapper flex">
        <h5
          onClick={() => {
            tabClickHandler("FirstTab")
            setActiveTab("dealers")
          }}
          className={firstTab ? "tab-head active" : "tab-head"}
        >
          DEALERS {counts?.dealersCount && `(${counts.dealersCount})`}
        </h5>
        <h5
          onClick={() => {
            tabClickHandler("SecondTab")
            setActiveTab("webusers")
          }}
          className={secondTab ? "tab-head active" : "tab-head"}
        >
          CUSTOMERS {counts?.webUserCount && `(${counts.webUserCount})`}
        </h5>
        <h5
          onClick={() => {
            tabClickHandler("ThirdTab")
            setActiveTab("individual")
          }}
          className={thirdTab ? "tab-head active" : "tab-head"}
        >
          INDIVIDUAL SELLER{" "}
          {counts?.individualUserCount && `(${counts.individualUserCount})`}
        </h5>
        <h5
          onClick={() => {
            tabClickHandler()
            setActiveTab("admin")
          }}
          className={fourthTab ? "tab-head active" : "tab-head"}
        >
          ADMINS {counts?.adminUserCount && `(${counts.adminUserCount})`}
        </h5>
      </div>

      <div>
        <UserListTab
          loadingUserList={loadingUserList}
          users={users}
          page={pageNumber}
          pages={pages}
          setPageNumer={setPageNumber}
          activeTab={activeTab}
        />
      </div>
    </div>
  )
}

export default UsersTabs
