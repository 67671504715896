import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import TextInput from "../input/TextInput"
import Select from "../input/Select"
import { fieldTypes } from "./CarDatabase"
import { ChipsInput } from "../input/ChipIntput"
import "./SortableItem.scss"
import sandwich from "../../images/common/sandwich.svg"
import MCheckboxes from "../input/MCheckBox"

export function Item(props) {
  const {
    i,
    j,
    updateFieldNameHandler,
    updateTypeHandler,
    updateValueHandlerArray,
    updateBolValHandler,
    field,
    inputRef,
  } = props

  return (
    <div className="inputs-wrapper">
      <div className="inputs-wrapper-r1">
        <TextInput
          value={field.fieldName}
          placeholder={"Name"}
          setValue={updateFieldNameHandler}
          groupIndex={i}
          fieldIndex={j}
          inputRef={inputRef}
        />
        <Select
          options={fieldTypes}
          value={field.type}
          onChange={(e) => updateTypeHandler(i, j, e.target.value)}
          defaultOption={"Choose"}
        />
        <MCheckboxes
          label="Key feature"
          name="isKeyFeature"
          checked={field.isKeyFeature}
          onChange={(e) => updateBolValHandler(i, j, e.target)}
        />
      </div>
      <div className="inputs-wrapper-r2">
        {field.type === "multiSelect" ? (
          <ChipsInput
            getOptionLabel={(option) => option.name}
            options={field.options || [""]}
            i={i}
            j={j}
            field={field}
            onChange={updateValueHandlerArray}
            defaultValue={field.options}
            placeholder={"Values"}
          />
        ) : field.type === "select" ? (
          <ChipsInput
            getOptionLabel={(option) => option.name}
            options={field.options || [""]}
            i={i}
            j={j}
            field={field}
            onChange={updateValueHandlerArray}
            defaultValue={field.options}
            placeholder={"Values"}
          />
        ) : (
          ""
        )}
        <div className="input-wrapper-isolate"></div>
      </div>
    </div>
  )
}

export default function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} className="sortable-item">
      <img
        {...attributes}
        {...listeners}
        src={sandwich}
        alt="sandwich"
        className="icon"
      />
      <Item {...props} id={props.id} />
    </div>
  )
}
