import React, { useContext, useEffect, useState } from "react"
import "./SellerDetailTab.scss"
import CustomSwitch from "../selection/Switch"
import { SellerContext } from "../../screens/sellerscreens/SellerDetailScreen"
import { useDispatch } from "react-redux"
import { updateSeller } from "../../state/actions/sellerActions"
import EditableLabelInputPopup from "../input/EditableLabelInputPopup"

const SellerDetailTab = () => {
  const { sellerBuffer: seller, sellerType } = useContext(SellerContext)
  const initials = seller?.name?.charAt(0)
  const [isRecommended, setIsRecommended] = useState(seller?.isRecommended)
  const [isTrustworthy, setIsTrustworthy] = useState(seller?.isTrustworthy)
  const [logoImage, setLogoImage] = useState(seller?.logoImage)

  const dispatch = useDispatch()

  const updatesellerRec = () => {
    dispatch(
      updateSeller(sellerType, seller?._id, {
        isRecommended: !isRecommended,
      })
    )
  }

  const updatesellerTrus = () => {
    dispatch(
      updateSeller(sellerType, seller?._id, {
        isTrustworthy: !isTrustworthy,
      })
    )
  }

  const uploadFileHandler = async (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setLogoImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const updateDealershipName = (key, value) => {
    dispatch(updateSeller(sellerType, seller._id, { [key]: value }))
  }

  useEffect(() => {
    setLogoImage(seller.logoImage)
  }, [seller])

  useEffect(() => {
    if (logoImage && logoImage !== seller.logoImage) {
      dispatch(updateSeller(sellerType, seller._id, { logoImage }))
    }
  }, [logoImage])

  return (
    <div className="seller-detail-tab">
      <div className="seller-detail-tab-r1 flex">
        <div className="r1-c1">
          <div className="profile-image">
            {seller?.logoImage ? (
              <img src={seller?.logoImage} alt="logo" className="logo" />
            ) : (
              <p className="text-bold">{initials?.toUpperCase()}</p>
            )}
          </div>
          <div className="link">
            <input
              type="file"
              name="image-upload"
              id="input"
              className="custom-file-input"
              accept="image/*"
              value=""
              onChange={(e) => {
                uploadFileHandler(e)
              }}
            />
            <p>Change Logo</p>
          </div>
        </div>
        <div className="r1-c2">
          <div className="r1-c2-r1 flex jcsb">
            <div className="r1-c2-r1-c1">
              <EditableLabelInputPopup
                value={seller.name}
                setValue={updateDealershipName}
                className="title"
                name="name"
              />
              <p className="text">{`Status: ${seller?.status}`}</p>
            </div>
            <div className="r1-c2-r1-c2">
              <CustomSwitch
                updateStatus={setIsRecommended}
                checked={seller?.isRecommended}
                label="Recommended"
                onChange={updatesellerRec}
              />
              <CustomSwitch
                updateStatus={setIsTrustworthy}
                checked={seller?.isTrustworthy}
                label="Trustworthy"
                onChange={updatesellerTrus}
              />
            </div>
          </div>
          <div className="r1-c2-r2"></div>
        </div>
      </div>
    </div>
  )
}

export default SellerDetailTab
