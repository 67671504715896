import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  createEvent,
  deleteEvent,
  updateEvent,
} from "../../state/actions/eventsActions"
import ImageInput from "../input/ImageInput"
import Select from "../input/Select"
import TextInput from "../input/TextInput"
import "./EventEditForm.scss"

const EventEditForm = ({ event, tab }) => {
  const [title, setTitle] = useState("")
  const [status, setStatus] = useState({ value: true, name: "Active" })
  const [backgroundColor, setBackgroundColor] = useState("")
  const [url, setUrl] = useState("")
  const [bannerImageUrlEN, setBannerImageUrlEN] = useState("")
  const [bannerImageUrlKH, setBannerImageUrlKH] = useState("")
  const [bannerImageUrlZH, setBannerImageUrlZH] = useState("")
  const [bannerImageUrlKO, setBannerImageUrlKO] = useState("")
  const [bannerMobileImageUrlEN, setBannerMobileImageUrlEN] = useState("")
  const [bannerMobileImageUrlKH, setBannerMobileImageUrlKH] = useState("")
  const [bannerMobileImageUrlZH, setBannerMobileImageUrlZH] = useState("")
  const [bannerMobileImageUrlKO, setBannerMobileImageUrlKO] = useState("")

  useEffect(() => {
    if (event) {
      setTitle(event.title)
      setUrl(event.pageUrl)
      setBannerImageUrlEN(event.bannerImageUrlEN)
      setBannerMobileImageUrlEN(event.bannerMobileImageUrlEN)
      setBannerImageUrlKH(event.bannerImageUrlKH)
      setBannerMobileImageUrlKH(event.bannerMobileImageUrlKH)
      setBannerImageUrlZH(event.bannerImageUrlZH)
      setBannerMobileImageUrlZH(event.bannerMobileImageUrlZH)
      setBannerImageUrlKO(event.bannerImageUrlKO)
      setBannerMobileImageUrlKO(event.bannerMobileImageUrlKO)
      setBackgroundColor(event.backgroundColor)
    }
  }, [event])

  const dispatch = useDispatch()

  const eventNew = {
    title,
    pageUrl: url,
    isActive: status.value,
    backgroundColor,
    bannerImageUrlEN,
    bannerMobileImageUrlEN,
    bannerImageUrlKH,
    bannerMobileImageUrlKH,
    bannerImageUrlZH,
    bannerMobileImageUrlZH,
    bannerImageUrlKO,
    bannerMobileImageUrlKO,
  }

  console.log(eventNew)

  const submitHandler = () => {
    if (!event?._id) {
      dispatch(createEvent(eventNew))
    } else {
      dispatch(updateEvent(event._id, eventNew))
    }
  }
  const deleteHandler = () => {
    dispatch(deleteEvent(event._id))
  }

  return (
    <div className="event-edit-form">
      <Select
        options={[
          { value: true, name: "Active" },
          { value: false, name: "Inactive" },
        ]}
        value={status}
        setValue={setStatus}
        label="Status"
      />
      <TextInput value={title} setValue={setTitle} label="Title" />
      <TextInput value={url} setValue={setUrl} label="URL" />
      <TextInput
        value={backgroundColor}
        setValue={setBackgroundColor}
        label="Background Color (HEX)"
      />
      <div className="save-button-wrapper">
        {event._id && (
          <Button className="delete-button" onClick={deleteHandler}>
            Delete
          </Button>
        )}

        <Button className="save-button" onClick={submitHandler}>
          {event._id ? "Update" : "Save"}
        </Button>
      </div>
      {tab === "Desktop" ? (
        <>
          <ImageInput
            image={bannerImageUrlEN}
            setImage={setBannerImageUrlEN}
            label="Image - EN (*Required)"
          />
          <ImageInput
            image={bannerImageUrlKH}
            setImage={setBannerImageUrlKH}
            label="Image - KH "
          />
          <ImageInput
            image={bannerImageUrlZH}
            setImage={setBannerImageUrlZH}
            label="Image - ZH"
          />
          <ImageInput
            image={bannerImageUrlKO}
            setImage={setBannerImageUrlKO}
            label="Image - KO"
          />
        </>
      ) : (
        <>
          <ImageInput
            image={bannerMobileImageUrlEN}
            setImage={setBannerMobileImageUrlEN}
            label="Image - EN (*Required)"
          />
          <ImageInput
            image={bannerMobileImageUrlKH}
            setImage={setBannerMobileImageUrlKH}
            label="Image - KH "
          />
          <ImageInput
            image={bannerMobileImageUrlZH}
            setImage={setBannerMobileImageUrlZH}
            label="Image - ZH"
          />
          <ImageInput
            image={bannerMobileImageUrlKO}
            setImage={setBannerMobileImageUrlKO}
            label="Image - KO"
          />
        </>
      )}
    </div>
  )
}

export default EventEditForm
