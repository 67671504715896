import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getYearList, getYearListReview } from "../../actions/yearActions"
import useYearUpdate from "./useYearUpdate"

export const useYearList = (modelId) => {
  const dispatch = useDispatch()

  useEffect(() => {
    modelId && dispatch(getYearList(modelId))

    // eslint-disable-next-line
  }, [dispatch, modelId])

  const yearList = useSelector((state) => state.carCatalogue.yearList)
  const { loading, error, years } = yearList

  return [loading, error, years]
}

export const useYearListReview = (modelId) => {
  const dispatch = useDispatch()

  const [, , successYearUpdate] = useYearUpdate()

  useEffect(() => {
    if (modelId || successYearUpdate) {
      dispatch(getYearListReview(modelId))
    }

    // eslint-disable-next-line
  }, [dispatch, modelId, successYearUpdate])

  const yearListReview = useSelector(
    (state) => state.carCatalogue.yearListReview
  )
  const { loading, error, years } = yearListReview

  return [loading, error, years]
}
