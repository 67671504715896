import { bisector, max, pointer, scaleLinear } from "d3"
import { AxisBottom } from "./AxisBottom"
import { AxisLeft } from "./AxisLeft"
import "./LineChart.scss"
import Marks from "./Marsks"
import { useCallback, useEffect, useState } from "react"
import { numberWithCommas } from "../../../utils/utilsFunctions"

export const LineChart = ({ data, month }) => {
  const width = 872
  const height = 500
  const initialMousePosition = {
    x: width / 2,
    y: height / 2,
  }

  const [mousePosition, setMousePosition] = useState(initialMousePosition)
  const [opacity, setOpacity] = useState(0)
  const [monthRange, setMonthRange] = useState([])

  const margin = { top: 40, right: 50, bottom: 50, left: 70 }

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  const line = document.getElementById("line")

  const xAxisLabelOffset = 40

  const toMonthName = (monthNumber) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString("en-US", {
      month: "short",
    })
  }

  const date = new Date()
  const currentMonth = date.getMonth() + 1

  useEffect(() => {
    if (month) {
      setMonthRange([currentMonth - month, currentMonth])
    } else {
      setMonthRange([data[0].month, data[0].month])
    }
  }, [month])

  const yAxisTickFormat = (n) => "$" + numberWithCommas(Number(n))
  const xAxisTickFormat = (n) => toMonthName(n) + " 2022"

  const yValue = (d) => d.price
  const xValue = (d) => d.month

  const yScale =
    data &&
    scaleLinear()
      .domain([0, max(data, yValue) + max(data, yValue) * 0.5])
      .range([innerHeight, 0])

  const xScale = data && scaleLinear().domain(monthRange).range([0, innerWidth])

  const handleMouseMove = useCallback(
    (event) => {
      const point = pointer(event)
      setMousePosition({ x: point[0], y: point[1] })
    },

    [setMousePosition]
  )

  const traslate = (d) => {
    let xDate = xScale.invert(mousePosition.x - 20),
      bisect = bisector(function (d) {
        return d.month
      }).right
    const i = bisect(d, xDate)

    return {
      x:
        xScale(data.length > 1 ? data[i]?.month : data[0]?.month) + margin.left,
      y: yScale(data.length > 1 ? data[i]?.price : data[0]?.price) || -100,
    }
  }

  if (data) {
    return (
      <svg
        width={width}
        height={height}
        className="line-chart"
        onMouseLeave={() => setOpacity(0)}
        onMouseMove={() => setOpacity(1)}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <AxisLeft
            yScale={yScale}
            innerWidth={innerWidth}
            yAxisTickFormat={yAxisTickFormat}
          />
          <AxisBottom
            xScale={xScale}
            innerHeight={innerHeight}
            xAxisLabelOffset={xAxisLabelOffset}
            tickFormat={xAxisTickFormat}
          />
          <Marks
            data={data}
            xScale={xScale}
            yScale={yScale}
            xValue={xValue}
            yValue={yValue}
            tooltipFormat={yAxisTickFormat}
            circleRadius={3}
            mousePosition={mousePosition}
          />
          <rect
            height={innerHeight}
            width={innerWidth}
            opacity={0}
            onMouseMove={handleMouseMove}
          />
        </g>
        {opacity && (
          <line
            x1={traslate(data, 0).x}
            y1={margin.top}
            z1={-1}
            x2={traslate(data, 0).x}
            y2={innerHeight + margin.top}
            className="vertical-line"
          />
        )}

        {opacity && (
          <>
            <circle
              r={opacity && 5}
              fill="#1f77b4"
              opacity={opacity}
              cy={margin.top}
              transform={`translate(${traslate(data, 0).x}, ${
                traslate(data, 0).y
              })`}
            ></circle>
            <text
              opacity={opacity}
              x={traslate(data, 0).x - 30}
              y={traslate(data, 0).y + 20}
            >
              {yAxisTickFormat(
                Number.parseFloat(yScale.invert(traslate(data, 0).y)).toFixed(2)
              )}
            </text>
          </>
        )}
      </svg>
    )
  } else {
    return <div>"Loading..."</div>
  }
}

export default LineChart
