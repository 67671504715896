import React, { useEffect, useState } from "react"
import "./AuctionCard.scss"
import ThreeDotsMenu from "../common/ThreeDotsMenu"
import { useNavigate } from "react-router-dom"
import ConfirmDeletePopup from "../common/ConfirmDeletePopup"
import { deleteAuction } from "../../state/actions/auctionsAction"
import { useDispatch } from "react-redux"
import useAuctionDelete from "../../state/customHooks/auction/useAuctionDelete"

const AuctionCard = ({ auction, onClick }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [auctionSelected, setAuctionSelected] = useState({})

  const [deletePopup, setDeletePopup] = useState(false)

  const [, error, success] = useAuctionDelete()

  const editAuctionHandler = (auction) => {
    navigate(`/auction/${auction._id}/edit`)
  }

  const changeStatusHandler = () => {}

  const deleteAuctionHandler = () => {
    dispatch(deleteAuction(auctionSelected._id))
  }
  const deletePopupHandler = (auction) => {
    setAuctionSelected(auction)
    setDeletePopup(true)
  }

  useEffect(() => {
    if (success || error) {
      setDeletePopup(false)
    }
  }, [success, error])

  const menuItem = [
    {
      title: "Change Status",
      action: changeStatusHandler,
    },
    {
      title: "Edit",
      action: editAuctionHandler,
    },
    {
      title: "Delete",
      action: deletePopupHandler,
    },
  ]
  return (
    <div className="list-card">
      <div className="card-content flex jcsb">
        <div className="c1 card-title" onClick={onClick}>
          <p> {`#${auction.id} ${auction.name} ${auction.location.city}`}</p>
        </div>
        <div className="c2">
          <div className="three-dots-button-wrapper"></div>
          <ThreeDotsMenu menuItem={menuItem} data={auction} />
        </div>
      </div>
      <ConfirmDeletePopup
        trigger={deletePopup}
        onCancel={() => {
          setDeletePopup(false)
        }}
        title={"Delete Auction"}
        onConfirm={deleteAuctionHandler}
      />
    </div>
  )
}

export default AuctionCard
