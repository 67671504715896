import React, { useContext, useEffect, useState } from "react"
import { Button } from "@mui/material"
import { SellerContext } from "../../screens/sellerscreens/SellerDetailScreen"
import "./UserListTab.scss"
import UserCard from "../sellerscreens/UserCard"
import CustomPopup from "../utils/CustomPopup"
import { useDispatch, useSelector } from "react-redux"

import Message from "../utils/Message"
import { createUser, updateUser } from "../../state/actions/userActions"
import useUserUpdate from "../../state/customHooks/user/useUserUpdate"
import useUserCreate from "../../state/customHooks/user/useUserCreate"
import ListMultiSelectModal from "../modals/ListMultiSelectModal"
import { getSellerDetail } from "../../state/actions/sellerActions"
import { useRolesList } from "../../state/customHooks/rolesandpermissions/useRolesList"
import Select from "../input/Select"

const UserListTab = () => {
  const dispatch = useDispatch()

  const {
    sellerBuffer: seller,
    users,
    sellerType,
    parentUsers,
  } = useContext(SellerContext)

  const [user, setUser] = useState({ contacts: [] })
  const [userSelected, setUserSelected] = useState([])

  const [popup, setPopup] = useState(false)
  const [userSelectPopup, setUserSelectPopup] = useState(false)

  const sellerCreate = useSelector((state) => state.seller.sellerCreate)

  const { success, error } = sellerCreate

  const [, , successUserUpdate] = useUserUpdate()

  const [, , successUserCreate] = useUserCreate()

  const [, roles] = useRolesList()

  console.log(roles)

  useEffect(() => {
    if (success || successUserUpdate || successUserCreate) {
      setPopup(false)
    }
    setUser((prev) => {
      return { sellerId: seller._id, ...prev }
    })
  }, [success, successUserUpdate, successUserCreate])

  useEffect(() => {
    setUserSelected(users.map((user) => user._id))
  }, [])

  const userCreateUpdateHandler = () => {
    if (!user._id) {
      if (sellerType === "branches") {
        setUser((prev) => {
          dispatch(
            createUser(
              {
                ...prev,
                branchId: seller._id,
                officalBrandId: seller._officalBrand,
              },
              sellerType
            )
          )
          return { contacts: [] }
        })
      } else {
        dispatch(createUser(user, sellerType))
        setUser({ contacts: [] })
      }
    } else {
      dispatch(updateUser(user._id, user))
    }
  }

  const userEditHandler = (user) => {
    setPopup(true)
    const { password, ...rest } = user
    setUser(rest)
  }

  const clearUserHandler = (user) => {
    setUser({ contacts: [], sellerId: seller._id })
  }

  const celluar = ["Smart", "Cellcard", "Metfone"]

  const userSelectHandler = () => {
    const parentUserIds = parentUsers.map((user) => user._id)
    const selectedUserIds = userSelected

    const addedUsers = selectedUserIds
    const removedUsers = parentUserIds.filter(
      (userId) => !selectedUserIds.includes(userId)
    )

    for (const userId of addedUsers) {
      dispatch(updateUser(userId, { _branch: seller._id }))
    }

    for (const userId of removedUsers) {
      dispatch(updateUser(userId, { _branch: null }))
    }

    setUserSelectPopup(false)
  }

  useEffect(() => {
    successUserUpdate && dispatch(getSellerDetail(sellerType, seller._id))
  }, [successUserUpdate])

  const handleRoleChange = (e) => {
    setUser((prev) => {
      return { ...prev, role: e.target.value }
    })
  }

  console.log(user)

  return (
    <div className="user-list-tab">
      {sellerType !== "individuals" && sellerType !== "branches" ? (
        <Button
          className="link button bold"
          onClick={() => {
            setPopup(true)
          }}
        >
          Create New User
        </Button>
      ) : sellerType === "branches" ? (
        <Button
          className="link button bold"
          onClick={() => {
            setUserSelectPopup(true)
          }}
        >
          Select Users
        </Button>
      ) : null}

      <div className="users-list">
        {users.map((user) => (
          <UserCard
            item={user}
            primaryUser={
              sellerType !== "individuals" ? seller._primaryUser : users[0]
            }
            userEditHandler={userEditHandler}
            detailOnly={sellerType === "branches" ? true : false}
          />
        ))}
      </div>
      <CustomPopup
        trigger={popup}
        setTrigger={setPopup}
        onClose={clearUserHandler}
      >
        <div className="user-details">
          <div className="r1 flex">
            <div className="input-wrapper">
              <label className="label">First Name</label>
              <input
                type="text"
                className="input"
                value={user?.firstName}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, firstName: e.target.value }
                  })
                }}
              />
            </div>
            <div className="input-wrapper">
              <label className="label">Last Name</label>
              <input
                type="text"
                className="input"
                value={user?.lastName}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, lastName: e.target.value }
                  })
                }}
              />
            </div>
            <div className="input-wrapper">
              <label className="label">Telecom</label>
              <select
                type="select"
                value={user?.contacts[0]?.telecom}
                className="input-telecom"
                onChange={(e) => {
                  setUser((prev) => {
                    const updatedContacts = [...prev.contacts]
                    updatedContacts[0] = {
                      ...prev.contacts[0],
                      telecom: e.target.value,
                    }
                    return {
                      ...prev,
                      contacts: updatedContacts,
                    }
                  })
                }}
              >
                <option value="-">-</option>
                {celluar.map((telecom) => (
                  <option value={telecom}>{telecom}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Phone Number 1</label>
              <input
                type="text"
                className="input-number"
                value={user?.contacts[0]?.phoneNumber}
                onChange={(e) => {
                  setUser((prev) => {
                    const updatedContacts = [...prev.contacts]
                    updatedContacts[0] = {
                      ...prev.contacts[0],
                      phoneNumber: e.target.value,
                    }
                    return {
                      ...prev,
                      contacts: updatedContacts,
                    }
                  })
                }}
              />
            </div>
          </div>
          <div className="r2 flex">
            <div className="input-wrapper">
              <label className="label">Telecom</label>
              <select
                type="select"
                value={user?.contacts[1]?.telecom}
                className="input-telecom"
                onChange={(e) => {
                  setUser((prev) => {
                    const updatedContacts = [...prev.contacts]
                    updatedContacts[1] = {
                      ...prev.contacts[1],
                      telecom: e.target.value,
                    }
                    return {
                      ...prev,
                      contacts: updatedContacts,
                    }
                  })
                }}
              >
                <option value="-">-</option>
                {celluar.map((telecom) => (
                  <option value={telecom}>{telecom}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Phone Number 2</label>
              <input
                type="text"
                className="input-number"
                value={user?.contacts[1]?.phoneNumber}
                onChange={(e) => {
                  setUser((prev) => {
                    const updatedContacts = [...prev.contacts]
                    updatedContacts[1] = {
                      ...prev.contacts[1],
                      phoneNumber: e.target.value,
                    }
                    return {
                      ...prev,
                      contacts: updatedContacts,
                    }
                  })
                }}
              />
            </div>
            <div className="input-wrapper" style={{ marginLeft: 25 }}>
              <label className="label">Username</label>
              <input
                type="text"
                className="input"
                value={user?.username}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, username: e.target.value }
                  })
                }}
              />
            </div>
            <div className="input-wrapper">
              <label className="label">Password</label>
              <input
                type="password"
                className="input"
                value={user?.password}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, password: e.target.value }
                  })
                }}
              />
            </div>
          </div>
          <div className="r3 flex">
            <div className="input-wrapper">
              <label className="label">Confirm Password</label>
              <input
                type="password"
                className="input"
                value={user?.confirmPassword}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, confirmPassword: e.target.value }
                  })
                }}
              />
            </div>
            <div className="input-wrapper ">
              <label className="label">Email (Optional)</label>
              <input
                type="email"
                className="input"
                value={user?.email}
                onChange={(e) => {
                  setUser((prev) => {
                    return { ...prev, email: e.target.value }
                  })
                }}
              />
            </div>
            <Button
              onClick={() => {
                userCreateUpdateHandler()
              }}
              disabled={
                !user._id &&
                (user.password !== user.confirmPassword ||
                  !user.password ||
                  !user.confirmPassword)
              }
            >
              {!user._id ? `Create` : `Save`}
            </Button>
            {error && <Message variant="error">{error} </Message>}
          </div>
          <div className="r4 flex">
            <Select
              options={roles}
              label={"Role"}
              onChange={handleRoleChange}
              value={user.role}
            />
          </div>
        </div>
      </CustomPopup>
      <ListMultiSelectModal
        isOpen={userSelectPopup}
        setIsOpen={setUserSelectPopup}
        items={parentUsers}
        itemsSelected={userSelected}
        setItemsSelected={setUserSelected}
        ItemCard={UserCard}
        searchPlaceholder="Search items"
        confirmListHandler={userSelectHandler}
      />
    </div>
  )
}

export default UserListTab
