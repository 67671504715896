import "./Forms.scss"
import React, { useEffect, useState } from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"

export const TrimForm = (props) => {
  const [name, setName] = useState(props.trim ? props.trim.name : "")
  const [year, setYear] = useState(props.year ? props.year._id : "")

  const navigate = useNavigate()

  useEffect(() => {
    if (props.year) {
      setYear(props.year._id)
    }
  }, [])

  const saveTrimHandler = () => {
    if (props.trim && props.trim._id) {
      navigate(`/catalogue/trim/${props.trim._id}/edit`)
    } else {
      navigate(
        `/catalogue/trim?name=${name}&brand=${year.brand}&model=${
          year.model
        }&generation=${year.generation || ""}&year=${year._id}`
      )
    }
  }

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Name
          </label>
          <TextField
            placeholder="Name"
            name="name"
            value={name}
            disabled={props.trim && props.trim._id ? true : false}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Model
          </label>
          <TextField
            placeholder="toyota-prius"
            name="id"
            value={props.data.model.name}
            disabled
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Year
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput />}
            value={year?._id}
            disabled={props.trim && props.trim._id ? true : false}
            name="year"
            displayEmpty
            label="Select"
            onChange={(e) => {
              props.data.years.map((entry, i) => {
                if (entry._id === e.target.value) {
                  setYear(entry)
                }
                return true
              })
            }}
          >
            <MenuItem disabled>Select</MenuItem>
            {props.data.years.map((year) => (
              <MenuItem value={year._id}>{year.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="form-group pt-17 input-wrapper flex col"></div>

      <div className="r2 flex">
        <Button
          className={!name || !year ? `bg-green disabled` : "bg-green"}
          onClick={() => {
            saveTrimHandler()
          }}
          disabled={!name || !year}
        >
          {props.trim && props.trim._id ? `EDIT` : `SAVE`}
        </Button>
      </div>
    </div>
  )
}
