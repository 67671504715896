import "./Forms.scss"
import React, { useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"

import SwipeableViews from "react-swipeable-views"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import actionbin from "../../images/dashboard/actionbin.svg"
import actionpencil from "../../images/dashboard/actionpencil.svg"
import carEmpty from "../../assets/img/car-empty.png"
import Alert from "@mui/material/Alert"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useDispatch, useSelector } from "react-redux"
import { getTrimListByModel } from "../../state/actions/trimActions"
import { getModelDetail, updateModel } from "../../state/actions/modelActions"
import { MODEL_UPDATE_RESET } from "../../state/constants/modelConstants"
import {
  TRIM_DELETE_RESET,
  TRIM_LIST_RESET,
  TRIM_UPDATE_RESET,
} from "../../state/constants/trimConstants"
import {
  deleteYear,
  getYearList,
  getYearsByModelGenerationList,
  updateYear,
} from "../../state/actions/yearActions"
import {
  getGenerationList,
  deleteGeneration,
} from "../../state/actions/generationActions"
import AutoCompleteInput from "../input/AutoCompleteInput"
import { useTagList } from "../../state/customHooks/utils/useTagsList"
import { Link } from "react-router-dom"
import { AntTab, AntTabs, TabPanel } from "../utils/Tabs"
import { Box } from "@mui/material"

export const ModelDetailForm = (props) => {
  const theme = useTheme()

  const [value, setValue] = useState(0)
  const [addLabel, setAddLabel] = useState("Generation")
  const [fields, setFields] = useState({
    name: "",
    id: "",
    brandId: "",
    bodyType: "",
    tags: [],
  })

  const [, , tags] = useTagList()

  const carOptions = useSelector((state) => state.carMarketplace.carOptions)
  const { options } = carOptions

  const modelUpdate = useSelector((state) => state.carCatalogue.modelUpdate)
  const { error: errorModelUpdate, success: successModelUpdate } = modelUpdate

  const generationList = useSelector(
    (state) => state.carCatalogue.generationList
  )
  const { generations } = generationList

  const generationDelete = useSelector(
    (state) => state.carCatalogue.generationDelete
  )
  const { success: successGenerationDelete } = generationDelete

  const yearList = useSelector((state) => state.carCatalogue.yearList)
  const { years } = yearList

  const yearByGenList = useSelector((state) => state.carCatalogue.yearByGenList)
  const { years: yearsByModelGeneration } = yearByGenList

  const yearDelete = useSelector((state) => state.carCatalogue.yearDelete)
  const { success: successYearDelete } = yearDelete

  const yearCreate = useSelector((state) => state.carCatalogue.yearCreate)
  const { success: successYearCreate } = yearCreate

  const yearUpdate = useSelector((state) => state.carCatalogue.yearUpdate)
  const { success: successYearUpdate } = yearUpdate

  // Sorting Years and Gen
  const compare = (a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const compareGen = (a, b) => {
    if (a && b !== null) {
      if (a._id.name < b._id.name) {
        return -1
      }
      if (a._id.name > b._id.name) {
        return 1
      }
      return 0
    }
  }

  // Sorting Generations

  const genList = []

  yearsByModelGeneration.map((gen) => genList.push(gen._id))

  const jointArray = [...genList, ...generations]
  const genWithoutYears = jointArray.filter(
    (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  )
  const filterArray = genWithoutYears.filter(function (val) {
    return genList.indexOf(val) === -1
  })

  // Redux
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: MODEL_UPDATE_RESET })
    dispatch({ type: TRIM_LIST_RESET })
    dispatch({ type: TRIM_UPDATE_RESET })
    dispatch({ type: TRIM_DELETE_RESET })
    if (props.data) {
      async function fetchData() {
        let params = props.data.model
        dispatch(getGenerationList(props.data.model._id))
        dispatch(getYearsByModelGenerationList(props.data.model._id))
        dispatch(getTrimListByModel(props.data.model._id))
        dispatch(getYearList(props.data.model._id))
        setFields({
          ...fields,
          name: params.name,
          id: params._id,
          brandId: params.brand,
          bodyType: params.bodyType,
          tags: params.tags.map((tag) => tag.name),
        })

        props.setReload({
          genCount: generations.length,
          trimsCount: props.data.trims,
          years,
          modelId: params._id,
          generations,
        })
      }
      fetchData()
    }
  }, [props.data, successYearCreate, successYearUpdate])

  // Update Year List and Generation List after Deletion

  const changeGenYear = (e, year) => {
    dispatch(
      updateYear(year._id, {
        name: year.name,
        active: year.active,
        id: year._id,
        generation: e.target.value,
        brand: year.brand,
        model: year.model,
      })
    )
  }

  useEffect(() => {
    if (successYearDelete || successYearUpdate) {
      dispatch(getYearsByModelGenerationList(props.data.model._id))
      dispatch(getYearList(props.data.model._id))
    }
  }, [dispatch, successYearDelete, successYearUpdate])

  // Update Generation List after Deletion

  // Delete Generation and Update List

  const deleteGen = (id) => {
    dispatch(deleteGeneration(id))
  }

  useEffect(() => {
    if (successGenerationDelete) {
      dispatch(getGenerationList(props.data.model._id))
      dispatch(getYearsByModelGenerationList(props.data.model._id))
    }
  }, [dispatch, successGenerationDelete])

  const saveModel = async () => {
    dispatch(updateModel(fields.id, fields))
  }

  useEffect(() => {
    if (props.selected) {
      const label =
        props.selected.charAt(0).toUpperCase() + props.selected.slice(1)
      setAddLabel(label)
      setValue(
        props.selected === "generation"
          ? 0
          : props.selected === "year" || props.selected === "years"
          ? 1
          : props.selected === "trim"
          ? 2
          : 0
      )
    }
  }, [props.selected])

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    dispatch(getModelDetail(props.data.model._id))
  }, [props.data.model._id])

  const updateTags = (tags) => {
    setFields((prev) => {
      return { ...prev, tags: tags }
    })
  }

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Name
          </label>
          <TextField
            placeholder="Name"
            name="name"
            value={fields.name}
            onChange={handleChange}
          />
        </FormControl>
      </div>

      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            ID
          </label>
          <TextField
            placeholder="Name"
            name="name"
            disabled
            value={fields.id}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Body Type
          </label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            input={<OutlinedInput />}
            value={fields.bodyType}
            name="bodyType"
            displayEmpty
            label="Select"
            onChange={handleChange}
          >
            <MenuItem value={0} disabled>
              Select
            </MenuItem>
            {options?.CAR_TYPES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="form-group pt-51">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Tags
          </label>
          <AutoCompleteInput
            getOptionLabel={(option) => option.name}
            options={tags}
            onChange={updateTags}
            defaultValue={fields.tags}
          />
        </FormControl>
      </div>

      <div className="form-group pt-17 pb-18 input-wrapper flex col">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <AntTabs
            value={value}
            onChange={(e, val) => {
              setAddLabel(e.target.innerText)
              setValue(val)
            }}
            aria-label="ant example"
          >
            <AntTab label="Generation" />
            <AntTab label="Year" />
            {addLabel === "Year" || addLabel === "Years" ? (
              <AntTab
                label={
                  <Link to={`/trim/dashboard`}>
                    <span className="add-button">
                      <AddCircleIcon
                        color="primary"
                        className="add-button-icon"
                      />
                      Add {addLabel === "Year" && "Trim"}
                    </span>
                  </Link>
                }
                style={{
                  marginLeft:
                    addLabel === "Years" || addLabel === "Year"
                      ? "20%"
                      : addLabel === "Generation"
                      ? "50%"
                      : "50%",
                }}
              />
            ) : null}

            <AntTab
              label={
                <span className="add-button">
                  <AddCircleIcon color="primary" className="add-button-icon" />
                  Add {addLabel === "Years" ? "Year" : addLabel}
                </span>
              }
              onClick={() => {
                let next =
                  addLabel === "Years" ? "year" : addLabel.toLowerCase()
                props.goNext("add-" + next)
                props.setSelected(next)
                props.setPropsData({
                  generations: generations,
                })
              }}
              style={{
                marginLeft: addLabel === "Generation" && "50%",
              }}
            />
            {addLabel === "Year" || addLabel === "Years" ? (
              <AntTab
                label={
                  <span className="add-button">
                    <AddCircleIcon
                      color="primary"
                      className="add-button-icon"
                    />
                    Add Years
                  </span>
                }
                onClick={() => {
                  let next = "years"
                  props.goNext("add-" + next)
                  props.setSelected(next)
                }}
              />
            ) : (
              ""
            )}
          </AntTabs>

          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={(val) => setValue(val)}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <table className="main-table">
                {yearsByModelGeneration.sort(compareGen).map((gen, index) => {
                  return (
                    <tr className="table-row" key={gen._id._id}>
                      <td className="table-column">
                        <img
                          src={
                            typeof gen._id.image !== "undefined" &&
                            gen._id.image !== ""
                              ? gen._id.image
                              : carEmpty
                          }
                          className="generation-image"
                          alt="pencil"
                        />
                      </td>
                      <td className="table-column">
                        <div className="form-group">
                          {`${gen._id.name} (${
                            gen.years.sort(compare)[0].name
                          } - ${
                            gen.years.sort(compare)[gen.years.length - 1].name
                          })`}
                        </div>
                      </td>
                      <td className="table-column">
                        <img
                          src={actionpencil}
                          className="icon"
                          alt="pencil"
                          onClick={() => {
                            let next = "generation"
                            props.goNext("add-" + next)
                            props.setSelected(next)
                            props.setPropsData(gen._id)
                          }}
                        />
                        <img
                          src={actionbin}
                          className="icon"
                          alt="bin"
                          onClick={() => deleteGen(gen._id._id)}
                        />
                      </td>
                    </tr>
                  )
                })}
                {filterArray.map((gen) => (
                  <tr className="table-row" key={gen._id}>
                    <td className="table-column">
                      <img
                        src={
                          typeof gen.image !== "undefined" && gen.image !== ""
                            ? gen.image
                            : carEmpty
                        }
                        className="generation-image"
                        alt="pencil"
                      />
                    </td>
                    <td className="table-column">
                      <div className="form-group">{gen.name}</div>
                    </td>
                    <td className="table-column">
                      <img
                        src={actionpencil}
                        className="icon"
                        alt="pencil"
                        onClick={() => {
                          let next = "generation"
                          props.goNext("add-" + next)
                          props.setSelected(next)
                          props.setPropsData(gen)
                        }}
                      />
                      <img
                        src={actionbin}
                        className="icon"
                        alt="bin"
                        onClick={() => deleteGen(gen._id)}
                      />
                    </td>
                  </tr>
                ))}
              </table>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <table className="main-table">
                {years?.length &&
                  years.map((year, i) => {
                    return (
                      <tr className="table-row" key={i}>
                        <td className="table-column">{year.name}</td>
                        <td className="table-column">
                          <div className="form-group">
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ width: "197px" }}
                                input={<OutlinedInput />}
                                value={
                                  year.generation !== null &&
                                  typeof year.generation !== "undefined"
                                    ? year.generation._id
                                    : ""
                                }
                                name="generation"
                                displayEmpty
                                label="Select"
                                onChange={(e) => changeGenYear(e, year)}
                              >
                                <MenuItem value={""} disabled>
                                  Select Generation
                                </MenuItem>
                                {generations.map((gen) => {
                                  return (
                                    <MenuItem key={gen._id} value={gen._id}>
                                      {gen.name}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </td>

                        <td className="table-column">
                          <img
                            src={actionpencil}
                            className="icon"
                            alt="pencil"
                            onClick={() => {
                              let next = "year"
                              props.goNext("add-" + next)
                              props.setSelected(next)
                              props.setPropsData({ year, generations })
                            }}
                          />
                          <img
                            src={actionbin}
                            className="icon"
                            alt="bin"
                            onClick={() => dispatch(deleteYear(year._id))}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </table>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </div>
      <div className="r2 flex">
        {successModelUpdate || errorModelUpdate ? (
          <Alert
            severity="success"
            color="info"
            onClose={() => dispatch({ type: MODEL_UPDATE_RESET })}
          >
            {errorModelUpdate ? errorModelUpdate : `Model updated sucessfully!`}
          </Alert>
        ) : (
          ""
        )}
        <Button className="bg-green" onClick={saveModel}>
          SAVE
        </Button>
      </div>
    </div>
  )
}
