import React, { useState } from "react"
import { Alert, Button } from "@mui/material"
import "./SellerNotes.scss"
import TextArea from "../input/TextArea"
import Note from "../common/Note"
import { useEffect } from "react"
import { createNote, updateNote } from "../../state/actions/commonActions"
import { useDispatch } from "react-redux"
import Loader from "../utils/Loader"
import { CircularProgress } from "@mui/material"
import useNoteCreate from "../../state/customHooks/common/notes/useNoteCreate"
import useNoteList from "../../state/customHooks/common/notes/useNoteList"
import useNoteUpdate from "../../state/customHooks/common/notes/useNoteUpdate"
import useNoteDelete from "../../state/customHooks/common/notes/useNoteDelete"

const SellerNotes = ({ seller, sellerType }) => {
  const [note, setNote] = useState("")
  const [noteId, setNoteId] = useState("")

  const [loadingNoteCreate, , successNoteCreate] = useNoteCreate(
    sellerType,
    seller._id
  )

  const [loadingNotesUpdate, , successNoteUpdate] = useNoteUpdate(
    sellerType,
    seller._id
  )

  const [loadingNotesList, errorNotesList, notesList] = useNoteList(
    sellerType,
    seller._id
  )

  const [, ,] = useNoteDelete(sellerType, seller._id)

  const dispatch = useDispatch()

  const noteHandler = () => {
    if (noteId) {
      dispatch(updateNote(sellerType, seller._id, noteId, note))
      setNoteId("")
    } else {
      dispatch(createNote(sellerType, seller._id, note))
      setNoteId("")
    }
  }

  useEffect(() => {
    if (successNoteCreate) {
      setNote("")
    } else if (successNoteUpdate) {
      setNoteId("")
      setNote("")
    }
  }, [successNoteCreate, successNoteUpdate])

  return (
    <div className="notes flex col">
      <p className="title">NOTES</p>
      <TextArea
        style={{ width: "449px", height: "81px" }}
        rows={4}
        placeholder="Enter notes"
        value={note}
        setValue={setNote}
      />
      <div className="align-right">
        <Button
          variant="green"
          onClick={() => {
            noteHandler()
          }}
        >
          {loadingNoteCreate || loadingNotesUpdate ? (
            <CircularProgress color="inherit" size={15} />
          ) : noteId ? (
            "Update"
          ) : (
            "Enter"
          )}
        </Button>
      </div>
      <div className="notes-list">
        {loadingNotesList ? (
          <Loader />
        ) : errorNotesList ? (
          <Alert severity="error">{errorNotesList}</Alert>
        ) : (
          notesList
            .slice(0)
            .reverse()
            .map((note) => (
              <Note
                note={note}
                setNote={setNote}
                setNoteId={setNoteId}
                id={seller._id}
                type={sellerType}
              />
            ))
        )}
      </div>
    </div>
  )
}

export default SellerNotes
