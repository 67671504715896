import React, { useEffect, useState } from "react"
import "./ImageUpload.scss"
import imageupload from "../../images/dashboard/carsmpscreens/cardetail/imageupload.svg"
import { useDropzone } from "react-dropzone"
import { resizeFile } from "../../utils/imageResize"

const ImageUpload = ({
  imagesURL,
  setImagesURL,
  setImagesURLOriginal,
  images,
  setImages,
  imagesURLOriginal,
  imagesOriginal,
  setImagesOriginal,
}) => {
  const [imageUpload, setImageUpload] = useState([])
  const [imagesIn, setImagesIn] = useState([])
  const [buffer, setBuffer] = useState([])
  const [urls, setUrls] = useState([])
  const [urlsOriginal, setUrlsOriginal] = useState([])

  useEffect(async () => {
    if (imagesIn.length > 0) {
      for (const [i, image] of imagesIn.entries()) {
        const resizedImage = await resizeFile(image)
        imageUpload[i] = resizedImage

        if (i === imagesIn.length - 1) {
          setImageUpload(
            [...images, ...imageUpload].filter(function (item, pos, self) {
              return self.indexOf(item) === pos
            })
          )
        }
      }
      const files = imageUpload.filter(
        (file) => typeof file === "string" && file.includes("https") !== true
      )
      setImageUpload([])
      setBuffer(files)
      setUrls(() => {
        setUrlsOriginal(
          imagesOriginal.filter(
            (url) => typeof url === "string" && url.includes("https") === true
          )
        )
        return images.filter(
          (url) => typeof url === "string" && url.includes("https") === true
        )
      })
    }
  }, [imagesIn])

  useEffect(() => {
    if (buffer.length > 0) {
      const files = imagesURL.filter(
        (file) => typeof file === "string" && file.includes("https") !== true
      )
      setImagesURL(
        [...urls, ...files, ...buffer].filter(function (item, pos, self) {
          return self.indexOf(item) === pos
        })
      )

      setImagesURLOriginal(
        [...urlsOriginal, ...files, ...buffer].filter(function (
          item,
          pos,
          self
        ) {
          return self.indexOf(item) === pos
        })
      )
    }
    setBuffer([])
    imagesURL?.length > 0 && setImages(imagesURL)
    imagesURLOriginal?.length > 0 && setImagesOriginal(imagesURLOriginal)
  }, [imagesURL, urls, imagesURLOriginal, urlsOriginal])

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setImagesIn(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  return (
    <div className="image-upload-area ">
      <div className="drop-area" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="input">.</div>
      </div>

      <div className="inner-text flex col jcc aic">
        <img src={imageupload} alt="upload-icon" />
        <div className="text-wrapper">
          <p className="text">
            Drag and drop images <br />
            or <span className="upload-button-text">select image(s)</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ImageUpload
