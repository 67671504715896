import React from "react"
import { useSellerSpecificList } from "../../state/customHooks/seller/useSellerList"
import SellerCard from "../sellerscreens/SellerCard"
import "./SellerCardList.scss"

const SellerCardList = ({ seller, sellerType }) => {
  const [sellerList] = useSellerSpecificList(
    sellerType === "officialBrands" ? "branches" : sellerType,
    seller._id
  )

  return (
    <div>
      <div className="seller-detail">
        <div className="row-1 flex jcsb">
          Branch List ({sellerList?.length})
        </div>
        <div className="seller-list">
          {sellerList?.map((seller) => (
            <SellerCard
              seller={seller}
              sellerType={
                sellerType === "officialBrands" ? "branches" : sellerType.type
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SellerCardList
