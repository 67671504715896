import React from "react"
import { ToggleButtonGroup, ToggleButton } from "@mui/material"
import { makeStyles } from "@material-ui/core"
import "./MultiSelect.scss"

const useStyles = makeStyles({
  root: {
    border: 0,
    backgroundColor: "#f4f4f4",
    borderRadius: "20px !important",
    padding: "6px 10px 6px 10px",
    marginRight: "10px",
    fontSize: "15px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "18px",
    textTransform: "none",
  },
  selected: {
    border: 0,
    backgroundColor: "#2e95d2 !important",
    borderRadius: "20px !important",
    padding: "6px 10px 6px 10px",
    color: "#FFFFFF !important",
    marginRight: "10px",
    fontSize: "15px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "18px",
    textTransform: "none",
  },
})

export default function ToggleButtonsMultiple({
  chipArray,
  label,
  onChange,
  group,
  index,
  value,
  key,
}) {
  const classes = useStyles()

  const handleChips = (event, newChip) => {
    onChange(newChip, group, index)
  }

  return (
    <div className="input-wrapper flex col" key={key}>
      <p className="chipLabel">{label}</p>
      <ToggleButtonGroup
        value={value}
        onChange={handleChips}
        aria-label="text formatting"
        className="py-1"
      >
        {chipArray.map((type) => (
          <ToggleButton
            className={value?.includes(type) ? classes.selected : classes.root}
            value={type}
            aria-label="bold"
            key={type}
            onClick={(e) => e.stopPropagation()}
          >
            {type}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}
