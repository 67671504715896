import React from "react"
import "./UserDetailCard.scss"

const UserDetailCard = ({ user }) => {
  const createdDateString = user?.createdAt?.substring(0, 10).split("-")

  const initials = user?.firstName?.charAt(0) + user?.lastName?.charAt(0)
  return (
    <div className="user-card">
      <div className="user-type">
        {user._dealership
          ? `Dealer`
          : user._webUser
          ? `Customer`
          : user._admin
          ? `Admin`
          : `-`}
      </div>
      <div className="user-card-body">
        <div className="user-card-body-col-1">
          {" "}
          <div className="profile-image">
            {user.profileImage ? (
              <img src={user.profileImage} alt="profile" className="image" />
            ) : (
              <p className="text-bold">{initials.toUpperCase()}</p>
            )}
          </div>
        </div>
        <div className="user-card-body-col-2">
          <div className="user-card-row-1">
            <div className="user-detail">
              <h5 className="title">
                {user.firstName} {user.lastName}{" "}
              </h5>
              <p className="status">Status: {user.status}</p>
            </div>
          </div>
          <div className="user-card-row-2">
            <div className="user-card-row-2-col-1">
              <p className="light-text">
                {`Created at: ${createdDateString[2]}/${createdDateString[1]}/${createdDateString[0]}`}
              </p>
              {user._webUser && (
                <p className="light-text">
                  {`Login Method: ${user._webUser.signupMethod}`}
                </p>
              )}
              {user._webUser && (
                <p className="light-text">
                  {user.contacts.map((contact) => (
                    <>Phone Number: {contact.phoneNumber}</>
                  ))}
                </p>
              )}

              {user._dealership && user.contacts.length > 0 && (
                <div className="span-container">
                  {user.contacts.map((contact, i) => (
                    <span className="contact-text" key={i}>
                      {contact.phoneNumber} {contact.telecom}{" "}
                      {user.contacts.length - 1 !== i && "/"}
                    </span>
                  ))}
                </div>
              )}

              {user.languages.length > 0 && (
                <div className="span-container">
                  {user.languages.map((language, i) => (
                    <span className="contact-text" key={i}>
                      {(language === "kh" && "Khmer") ||
                        (language === "en" && "English") ||
                        (language === "zh" && "Chinese") ||
                        (language === "ko" && "Korean") ||
                        (language === "ja" && "Japanese")}
                      {user.languages.length - 1 !== i && `, `}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className="user-card-row-2-col-2">
              <p className="light-text">
                {`Username: ${user.username || "--"}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDetailCard
