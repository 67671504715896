import "./CustomTabs.scss"
import { Button } from "@mui/material"
import { toCamelCase } from "../../utils/utilsFunctions"

const CustomTabs = ({
  tabs,
  tabsBody,
  activeTab,
  tabClickHandler,
  setSideDrawerShow,
  filter,
  status,
}) => {
  return (
    <>
      <div className="tabs">
        <div className="tabs-header">
          <div className="tabs-header-wrapper flex">
            {tabs?.map((tab) => (
              <Button
                onClick={() => {
                  tabClickHandler(tab)
                }}
                key={tab.tabName}
                className={
                  (status ? tab.tabName : toCamelCase(tab.tabName)) ===
                  activeTab
                    ? "tab-head active"
                    : "tab-head"
                }
              >
                {tab.tabName} ({tab.count})
              </Button>
            ))}
          </div>
          {filter && (
            <div
              className="tabs-header-filter"
              onClick={() => {
                setSideDrawerShow(true)
              }}
            >
              <img src={filter} alt="filter" />
              <p className="filter-text">Filter</p>
            </div>
          )}
        </div>

        {tabs.map(
          (tab, index) =>
            toCamelCase(activeTab) === toCamelCase(tab.tabName) && (
              <div key={tab.tabName}>{tabsBody[index]}</div>
            )
        )}
      </div>
    </>
  )
}

export default CustomTabs
