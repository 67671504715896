import React, { useRef, useEffect } from "react"
import "./CustomPopup.scss"
import closeicon from "../../images/dealership/closeicon.svg"
import crossIcon from "../../assets/icon/cross.svg"

const CustomPopup = (props) => {
  const popupRef = useRef(null)

  const closeHandler = () => {
    props.setTrigger(false)
    props?.onClose && props?.onClose()
  }

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closeHandler()
    }
  }

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleOutsideClick)

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return props.trigger ? (
    <div className="popup-custom">
      <div
        className={
          props.className ? `popup-inner ${props.className}` : `popup-inner`
        }
        ref={popupRef}
      >
        <div className="popup-header flex jcsb">
          <div className="title">{props.title}</div>
          <div onClick={closeHandler} className="close-button">
            <img src={props.closeLg ? crossIcon : closeicon} alt="close-icon" />
          </div>
        </div>

        <div className="popup-children">{props.children}</div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default CustomPopup
