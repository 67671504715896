import React, { useState } from "react"
import "./SaleList.scss"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import { formatTimeStartEndTime } from "../../utils/utilsFunctions"
import AuctionMap from "../dashboard/AuctionMap"
import SaleCard from "./SaleCard"
import { BasicPagination } from "../utils/Pagination"
import CarCard from "../carsmpscreens/CarCard"
import CustomPopup from "../utils/CustomPopup"

const SaleList = ({
  saleList,
  page,
  pages,
  count,
  pageNumber,
  setPageNumber,
  queryObject,
  setQueryObject,
}) => {
  const [eventSelected, setEventSelected] = useState({})
  const [show, setShow] = useState(false)

  const handleOpenModal = (auction) => {
    setEventSelected(auction)
    setShow(true)
  }

  const handleCloseModal = () => {
    setEventSelected({})
    setShow(false)
  }

  return (
    <div className="list-with-pagination">
      <div className="list">
        {saleList?.map((sale, index) => (
          <>
            <SaleCard
              sale={sale}
              key={sale._id}
              onClick={() => handleOpenModal(sale)}
            />
          </>
        ))}
      </div>
      <div className="pagination">
        <BasicPagination
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pages={pages}
          queryObject={queryObject}
          setQueryObject={setQueryObject}
        />
      </div>

      <CustomPopup
        trigger={show} // Only open the modal for the corresponding auction index
        setTrigger={handleCloseModal}
        onClose={handleCloseModal}
        title="Event Information"
      >
        <div className="mainModal">
          <div className="leftDiv">
            <AuctionMap
              auction={eventSelected}
              value={eventSelected.location}
            />
            <div className="geoLocation">
              Geolocation: {eventSelected.location?.latitude}, &nbsp;
              {eventSelected.location?.longitude}
            </div>
          </div>

          <div className="rightDiv">
            <div className="rightUpperMain">
              {eventSelected.isLimitedTime && (
                <div className="rightUpperDiv1">
                  {formatTimeStartEndTime(
                    eventSelected.startDate,
                    eventSelected.endDate
                  )}
                </div>
              )}

              <div className="rightUpperDiv2">
                Name: {eventSelected.name} <br />
                Address: {eventSelected.location?.address} <br />
                City: {eventSelected.location?.city} <br />
                Description:
                <div className="description">
                  {eventSelected.location?.desc}
                </div>
                Organizer: {eventSelected?._company?.name} <br />
                Phone Number:{" "}
                <span>
                  {eventSelected?._company?._primaryUser?.contacts
                    .map(
                      (contact, i) =>
                        ` ${contact.phoneNumber} (${contact.telecom})`
                    )
                    .join(" / ")}
                </span>
              </div>
            </div>
            <div className="rightLowerMain">
              <div className="title">
                <p>Cars ({eventSelected?.cars?.length || 0})</p>
              </div>
              <div className="car-list">
                {eventSelected.cars?.map((car, index) => (
                  <CarCard car={car} key={car._id} detailOnly />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Link to={`/sale/${eventSelected._id}/edit`} className="editButton">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#03A00C",
              color: "#fff",
              borderRadius: "0px",
              width: "118px",
            }}
          >
            Edit
          </Button>
        </Link>
      </CustomPopup>
    </div>
  )
}

export default SaleList
