import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getYearSpecTemplateDetail } from "../../actions/carDbTemplateActions"
import { YEAR_SPEC_DETAIL_RESET } from "../../constants/carDatabase"

const useYearSpecTempDetail = (id) => {
  const dispatch = useDispatch()

  const yearSpecTemplateDetail = useSelector(
    (state) => state.carDbTemplate.yearSpecTemplateDetail
  )
  const { loading, error, year } = yearSpecTemplateDetail

  useEffect(() => {
    dispatch({ type: YEAR_SPEC_DETAIL_RESET })
    id && dispatch(getYearSpecTemplateDetail(id))
  }, [dispatch, id])

  return [loading, error, year]
}

export default useYearSpecTempDetail
