export const convertTimeToOptionString = (startTime) => {
  const date = new Date(startTime)
  const hour = date.getHours()
  const minute = date.getMinutes()
  const timeString = `${String(hour).padStart(2, "0")}:${String(
    minute
  ).padStart(2, "0")}`
  return timeString
}

export const extractDateValue = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const dateValue = `${year}-${month}-${day}`
  return dateValue
}
