import Axios from "../../utils/axios"
import {
  SELLER_SPECIFIC_LIST_FAIL,
  SELLER_SPECIFIC_LIST_REQUEST,
  SELLER_SPECIFIC_LIST_SUCCESS,
} from "../constants/sellerSpecificConstants"

export const getSellerSpecificList =
  (type, parentSeller, keyword = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_SPECIFIC_LIST_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.get(
        `/v1/${type}?parentSeller=${parentSeller}&keyword=${keyword}`,
        config
      )
      dispatch({
        type: SELLER_SPECIFIC_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_SPECIFIC_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
