import React, { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import InnerHeader from "../../components/header/InnerHeader"
import InnerHeaderButton from "../../components/utils/Button"
import "./AuctionCreateEditScreen.scss"
import InnerContainer from "../../components/utils/InnerContainer"
import { useSellerList } from "../../state/customHooks/seller/useSellerList"
import { useDispatch } from "react-redux"
import { SELLER_LIST_RESET } from "../../state/constants/sellerContants"
import TextInput from "../../components/input/TextInput"
import Select from "../../components/input/Select"
import TextArea from "../../components/input/TextArea"
import useAuctionCreate from "../../state/customHooks/auction/useAuctionCreate"
import {
  createAuction,
  updateAuction,
} from "../../state/actions/auctionsAction"
import useAuctionDetail from "../../state/customHooks/auction/useAuctionDetail"
import useAuctionUpdate from "../../state/customHooks/auction/useAuctionUpdate"
import Loader from "../../components/utils/Loader"
import Message from "../../components/utils/Message"
import { CircularProgress } from "@mui/material"

const AuctionCreateEditScreen = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams()

  const dispatch = useDispatch()

  const auctionTypeQ = searchParams.get("type")

  const [auctionType, setAuctionType] = useState("")

  const [loading, error, auctionDetail] = useAuctionDetail(id)

  const [sellerName, setSellerName] = useState("")
  const [seller, setSeller] = useState("")

  const [sellers] = useSellerList(auctionType, sellerName)

  const [auction, setAuction] = useState({})

  const [selectedDate, setSelectedDate] = useState("")
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")

  const [loadingAuctionCreate, , success] = useAuctionCreate()
  const [loadingAuctionUpdate, , successAuctionUpdate, updatedAuction] =
    useAuctionUpdate()

  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      setAuctionType(auctionTypeQ)
    } else {
      setAuctionType(auctionDetail._company ? "companies" : null)
    }
  }, [id])

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value)
  }

  const handleStartTimeChange = (event) => {
    setSelectedStartTime(event.target.value)
  }

  const handleEndTimeChange = (event) => {
    setSelectedEndTime(event.target.value)
  }
  const convertTimeToOptionString = (startTime) => {
    const date = new Date(startTime)
    const hour = date.getHours()
    const minute = date.getMinutes()
    const timeString = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`
    return timeString
  }

  const extractDateValue = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateValue = `${year}-${month}-${day}`
    return dateValue
  }

  useEffect(() => {
    if (id && auctionDetail) {
      setAuction(auctionDetail)
      setSelectedStartTime(convertTimeToOptionString(auctionDetail.startDate))
      setSelectedEndTime(convertTimeToOptionString(auctionDetail.endDate))
      setSelectedDate(extractDateValue(auctionDetail.startDate))
      setSeller(auctionDetail._company)
    } else {
      setAuction({})
    }
  }, [id, auctionDetail])

  useEffect(() => {
    if (successAuctionUpdate) {
      setAuction(updatedAuction)
      setSelectedStartTime(convertTimeToOptionString(updatedAuction.startDate))
      setSelectedEndTime(convertTimeToOptionString(updatedAuction.endDate))
      setSelectedDate(extractDateValue(updatedAuction.startDate))
      setSeller(updatedAuction._company)
    }
  }, [successAuctionUpdate])

  const auctionUpdateHandler = (e) => {
    if (
      ["latitude", "longitude", "address", "city", "desc"].includes(
        e.target.name
      )
    ) {
      setAuction((prev) => {
        return {
          ...prev,
          location: { ...auction.location, [e.target.name]: e.target.value },
        }
      })
    } else {
      setAuction((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
        }
      })
    }
  }

  useEffect(() => {
    setAuction((prev) => {
      // Concatenate the date and time strings
      const startDateTimeStr = `${selectedDate}T${selectedStartTime}`
      const endDateTimeStr = `${selectedDate}T${selectedEndTime}`

      // Create Date objects from the concatenated strings
      const startDate = new Date(startDateTimeStr)
      const endDate = new Date(endDateTimeStr)
      return { ...prev, startDate, endDate }
    })
  }, [selectedDate, selectedStartTime, selectedEndTime])

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }
    return options
  }

  const generateEndTimeOptions = () => {
    const options = []

    const [startHour, startMinute] = selectedStartTime?.split(":")?.map(Number)
    for (let hour = startHour; hour < 24; hour++) {
      const minuteStart =
        hour === startHour ? Math.ceil(startMinute / 30) * 30 : 0
      for (let minute = minuteStart; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }

    return options
  }

  const auctionCreateUpdateHandler = () => {
    if (!id) {
      dispatch(createAuction(auction))
    } else {
      dispatch(updateAuction(id, auction))
    }
  }

  useEffect(() => {
    if (success) {
      navigate(-1)
    }
  }, [success])

  return (
    <div className="auction-create-edit-screen">
      <InnerHeader
        title={!id ? "Create Auction" : "Update Auction"}
        className="inner-header"
      >
        <InnerHeaderButton onClick={auctionCreateUpdateHandler}>
          {loadingAuctionUpdate || loadingAuctionCreate ? (
            <CircularProgress color="inherit" size={15} />
          ) : id ? (
            `Update`
          ) : (
            `Save`
          )}
        </InnerHeaderButton>
      </InnerHeader>
      <InnerContainer>
        {id && loading ? (
          <Loader />
        ) : error ? (
          <Message variant="error">{error}</Message>
        ) : (
          <form onChange={auctionUpdateHandler}>
            <div className="section grid-4">
              <TextInput
                name="name"
                label="Auction name"
                value={auction.name}
              />
              <div className="input-with-dropdown">
                <div className="input-wrapper">
                  {auctionType === "dealerships" ? (
                    <label className="label">Dealership Name</label>
                  ) : auctionType === "companies" ? (
                    <label className="label">Company Name</label>
                  ) : (
                    <label className="label">OfficialBrand</label>
                  )}

                  <input
                    type="text"
                    className="input"
                    value={seller?.name}
                    name="_company"
                    onChange={(e) => {
                      setSellerName(e.target.value)
                    }}
                  />
                </div>
                {sellers?.length > 0 && (
                  <div className="dropdown-dealership">
                    {sellers?.map((seller) => (
                      <div
                        className="dealership-card"
                        key={seller._id}
                        onClick={() => {
                          setSeller({
                            _id: seller._id,
                            name: seller.name,
                            _primaryUser: seller._primaryUser._id,
                          })
                          setAuction((prev) => {
                            return {
                              ...prev,
                              _company: seller._id,
                            }
                          })
                          setSellerName("")
                          dispatch({ type: SELLER_LIST_RESET })
                        }}
                      >
                        <p className="text">{seller.name}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="section grid-4">
              <TextInput
                type="date"
                name="selectedDate"
                value={selectedDate}
                label={"Date:"}
                onChange={handleDateChange}
                id="date"
              />

              <Select
                id="start-time"
                value={selectedStartTime}
                name="selectedStartTime"
                onChange={handleStartTimeChange}
                label="Start Time:"
                options={generateTimeOptions()}
              />
              <Select
                id="end-time"
                value={selectedEndTime}
                name="selectedEndTime"
                onChange={handleEndTimeChange}
                label="End Time:"
                options={generateEndTimeOptions()}
              />
            </div>
            <div className="section">
              <p className="section-title">Location</p>
              <div className="section grid-4">
                <TextInput
                  name="latitude"
                  label="Latitude"
                  value={auction?.location?.latitude}
                />
                <TextInput
                  name="longitude"
                  label="Longitude"
                  value={auction?.location?.longitude}
                />
                <TextInput
                  name="address"
                  label="Address"
                  value={auction?.location?.address}
                />
                <TextInput
                  name="city"
                  label="City"
                  value={auction?.location?.city}
                />
              </div>
              <div className="section grid-4">
                <TextArea
                  rows={3}
                  label="Description"
                  name="desc"
                  value={auction?.location?.desc}
                />
              </div>
            </div>
          </form>
        )}
      </InnerContainer>
    </div>
  )
}

export default AuctionCreateEditScreen
