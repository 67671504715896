import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStaticData } from "../../state/actions/staticDataActions"
import EditableLabelInputPopup from "../input/EditableLabelInputPopup"
import EditableLabelSelect from "../input/EditableLabelSelect"
import "./SellerGeoCard.scss"

const SellerGeoCard = ({ seller, updateSellerHandler }) => {
  const dispatch = useDispatch()

  const staticDataList = useSelector(
    (state) => state?.staticData?.staticDataList
  )

  const { staticData: dealershipOptions } = staticDataList

  useEffect(() => {
    dispatch(getStaticData("dealership"))
  }, [dispatch])

  return (
    <div className="dealership-geo-card flex aic">
      <div className="tags flex aic">
        {seller.isRecommended && <span className="tag">Recommended</span>}
        {seller.currentStage && (
          <EditableLabelSelect
            options={dealershipOptions.currentStage}
            value={seller?.currentStage}
            setValue={updateSellerHandler}
            title="Current Stage"
            name="stage"
          />
        )}
        {seller.reach && (
          <EditableLabelSelect
            options={dealershipOptions.reach}
            value={seller.reach}
            setValue={updateSellerHandler}
            title="How did you find them?"
          />
        )}
      </div>
      <div className="labels flex aic">
        <div className="labels c1">
          <EditableLabelInputPopup
            labelTitle="Geolocation: "
            name="geoLocation"
            value={seller.geoLocation || "N/A"}
            setValue={updateSellerHandler}
          />
          <EditableLabelInputPopup
            labelTitle="Address: "
            name="address"
            value={seller?.location?.address}
            setValue={updateSellerHandler}
          />
        </div>
        <div className="labels c2">
          <EditableLabelInputPopup
            labelTitle="City: "
            name="city"
            value={seller?.location?.city}
            setValue={updateSellerHandler}
          />
          <EditableLabelInputPopup
            labelTitle="Facebook URL: "
            value={seller?.facebookURL}
            name="facebookURL"
            setValue={updateSellerHandler}
            link="true"
          />
        </div>
      </div>
    </div>
  )
}

export default SellerGeoCard
