import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getOpportunityCarList } from "../../state/actions/opportunityActions"
import "./OpportunityCarList.scss"

const OpportunityCarList = ({ opportunitySelect }) => {
  const dispatch = useDispatch()

  const carList = useSelector((state) => state.carMarketplace.carList)

  const { cars, loading } = carList

  useEffect(() => {
    if (opportunitySelect) {
      dispatch(getOpportunityCarList(opportunitySelect._id))
    }
  }, [opportunitySelect])

  return (
    <div className="opportunity-car-list">
      <h5>
        {" "}
        {!loading && cars.length} Cars Suggested for Opportunity #
        {opportunitySelect.customId}
      </h5>
    </div>
  )
}

export default OpportunityCarList
