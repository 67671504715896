import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBrandDetail } from "../../actions/brandActions"

const useBrandDetail = (id) => {
  const dispatch = useDispatch()

  const brandDetail = useSelector((state) => state.carCatalogue.brandDetail)
  const {
    brand,
    loading: loadingBrandDetail,

    error: errorBrandDetail,
  } = brandDetail

  useEffect(() => {
    id && dispatch(getBrandDetail(id))
  }, [dispatch, id])

  return [loadingBrandDetail, errorBrandDetail, brand]
}

export default useBrandDetail
