import React, { useState } from "react"
import "./AuctionList.scss"
import AuctionCard from "./AuctionCard"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import { formatTime } from "../../utils/utilsFunctions"
import AuctionMap from "../dashboard/AuctionMap"
import CustomPopup from "../utils/CustomPopup"
import CarCard from "../carsmpscreens/CarCard"

const AuctionList = ({ auctionList }) => {
  const [auctionSelected, setAuctionSelected] = useState({})
  const [show, setShow] = useState(false)

  const handleOpenModal = (auction) => {
    setAuctionSelected(auction)
    setShow(true)
  }

  const handleCloseModal = () => {
    setAuctionSelected({})
    setShow(false)
  }

  return (
    <div className="list-wrapper">
      {auctionList?.map((auction, index) => (
        <>
          <AuctionCard
            auction={auction}
            key={auction._id}
            onClick={() => handleOpenModal(auction)}
          />
        </>
      ))}
      <CustomPopup
        trigger={show} // Only open the modal for the corresponding auction index
        setTrigger={handleCloseModal}
        onClose={handleCloseModal}
        title="Auction Information"
      >
        <div className="mainModal">
          <div className="leftDiv">
            <AuctionMap
              auction={auctionSelected}
              value={auctionSelected.location}
            />
            <div className="geoLocation">
              Geolocation: {auctionSelected.location?.latitude}, &nbsp;
              {auctionSelected.location?.longitude}
            </div>
          </div>

          <div className="rightDiv">
            <div className="rightUpperDiv">
              <div className="rightUpperDiv1">
                {formatTime(auctionSelected.startDate, auctionSelected.endDate)}
              </div>

              <div className="rightUpperDiv2">
                Name: {auctionSelected.name} <br />
                Address: {auctionSelected.location?.address} <br />
                City: {auctionSelected.location?.city} <br />
                Description:
                <div className="description">
                  {auctionSelected.location?.desc}
                </div>
                Organizer: {auctionSelected?._company?.name} <br />
                Phone Number:{" "}
                <span>
                  {auctionSelected?._company?._primaryUser?.contacts
                    .map(
                      (contact, i) =>
                        ` ${contact.phoneNumber} (${contact.telecom})`
                    )
                    .join(" / ")}
                </span>
              </div>
            </div>
            <div className="">
              <p className="title">
                Attendees ({auctionSelected?.attendee?.length || 0})
              </p>
            </div>
            <div className="rightLowerDiv">
              {auctionSelected.attendee?.map((attendee, index) => (
                <div className="atteendeDiv" key={index}>
                  {attendee}
                </div>
              ))}
            </div>
            <div className="rightLowerMain">
              <div className="title">
                <p>Cars ({auctionSelected?.cars?.length || 0})</p>
              </div>
              <div className="carCardDiv">
                {auctionSelected?.cars?.length
                  ? auctionSelected?.cars?.map((car, i) => (
                      <CarCard
                        car={car}
                        loadingCarUpdate={null}
                        carUpdateId={car._id}
                        key={i}
                        detailOnly
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <Link
          to={`/auction/${auctionSelected._id}/edit`}
          className="editButton"
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#03A00C",
              color: "#fff",
              borderRadius: "0px",
              width: "118px",
            }}
          >
            Edit
          </Button>
        </Link>
      </CustomPopup>
    </div>
  )
}

export default AuctionList
