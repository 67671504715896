import React, { useEffect, useState } from "react"
import InnerHeader from "../../components/header/InnerHeader"
import "./CarListScreen.scss"
import { Button, LinearProgress } from "@mui/material"
import { useDispatch } from "react-redux"
import { getCarList } from "../../state/actions/carMpActions"
import CarCard from "../../components/carsmpscreens/CarCard"
import { useNavigate, useSearchParams } from "react-router-dom"
import { BasicPagination } from "../../components/utils/Pagination"
import CustomTreeView from "../../components/utils/Tree"
import { DropDownMenu } from "../../components/common/DropDownMenu"
import useCarList from "../../state/customHooks/carmp/useCarList"
import useCarUpdate from "../../state/customHooks/carmp/useCarUpdate"
import Message from "../../components/utils/Message"
import CheckBox from "../../components/input/CheckBox"
import useAuthUser from "../../state/customHooks/user/useAuthUser"
import { hasPermission } from "../../utils/utilsFunctions"

const CarListScreen = ({ carUpdateId }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [user] = useAuthUser()

  const statusQuery = searchParams.get("status")
  const subStatusQuery = searchParams.get("subStatus")
  const activeTabQuery = searchParams.get("activeTab")
  const keywordQuery = searchParams.get("keyword")
  const keywordTypeQuery = searchParams.get("keywordType")
  const pageNumberQuery = searchParams.get("pageNumber")
  const isRecommendedQuery = searchParams.get("isRecommended")

  const [pagesTotal, setPagesTotal] = useState(0)
  const [carsBuffer, setCarsBuffer] = useState([])
  const [countBuffer, setCountBuffer] = useState({})
  const [loadingBuffer, setLoadingBuffer] = useState(false)

  const [carType, setCarType] = useState("")
  const [keyword, setKeyword] = useState(
    keywordQuery === null ? "" : keywordQuery
  )
  const [keywordType, setKeywordType] = useState(
    keywordTypeQuery === null ? "" : keywordTypeQuery
  )

  const [queryObject, setQueryObject] = useState({})

  const [timeOutId, setTimeOutId] = useState("")

  useEffect(() => {
    setQueryObject({
      status: statusQuery,
      pageNumber: pageNumberQuery,
      activeTab: activeTabQuery,
      subStatus: subStatusQuery,
      keyword: keywordQuery,
      keywordType: keywordTypeQuery,
      isRecommended: isRecommendedQuery === "true" && true,
    })
  }, [])
  useEffect(() => {
    if (keywordQuery) {
      setKeyword(keywordQuery)
    }
  }, [keywordQuery])

  const [loading, error, , pages, staticCount, cars] = useCarList(queryObject)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loadingCarUpdate, ,] = useCarUpdate()

  useEffect(() => {
    if (loading) {
      setLoadingBuffer(true)
    }

    if (carType) {
      navigate(`/car/create?type=${carType}`)
    }

    if (!queryObject.keyword) {
      setKeyword("")
    }
  }, [queryObject, loading, carType])

  const keywordHandler = (e) => {
    setKeyword(e.target.value)
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      //this delay is called debounce as we call it after
      setTimeout(() => {
        setQueryObject((prev) => {
          dispatch(
            getCarList({
              ...prev,
              status: statusQuery,
              pageNumber: 1,
              keywordType,
              keyword: e.target.value,
            })
          )
          return {
            ...prev,
            status: statusQuery,
            pageNumber: 1,
            keywordType,
            keyword: e.target.value,
          }
        })
        setLoadingBuffer(true)

        setPagesTotal(pages)
      }, 1000)
    )
  }

  useEffect(() => {
    if (!loading) {
      setPagesTotal(pages)
      setCarsBuffer(cars)
      setCountBuffer(staticCount)
      setLoadingBuffer(false)
    }
  }, [loading])

  useEffect(() => {
    setSearchParams({
      ...queryObject,
    })
  }, [queryObject])

  const dealershipCreateHandler = () => {
    setCarType("dealerships")
  }
  const companyCreateHandler = () => {
    setCarType("companies")
  }
  const officialBrandCreateHandler = () => {
    setCarType("officialBrands")
  }
  const individualCreateHandler = () => {
    setCarType("individuals")
  }

  const menuItem = [
    {
      title: "Dealership",
      action: dealershipCreateHandler,
    },
    {
      title: "Company",
      action: companyCreateHandler,
    },
    {
      title: "Official Brand",
      action: officialBrandCreateHandler,
    },
    {
      title: "Individual",
      action: individualCreateHandler,
    },
  ]

  const filterUpdateHandler = () => {
    setQueryObject((prev) => {
      return { ...prev, isRecommended: !prev.isRecommended, pageNumber: 1 }
    })
  }

  const clearFilterHandler = () => {
    setQueryObject((prev) => {
      return { ...prev, isRecommended: false }
    })
  }

  return (
    <div className="car-list-screen">
      <InnerHeader title="Cars" className="inner-header">
        {user._admin && hasPermission(user, "car", "create") && (
          <div className="buttons-wrapper">
            <DropDownMenu
              type="inner-header"
              menuItem={menuItem}
              title="Create Car"
            />
          </div>
        )}
        {user._officialBrand && hasPermission(user, "car", "create") && (
          <Button
            variant="contained"
            sx={{ borderRadius: 0, height: "100%", boxShadow: "none" }}
            onClick={() => officialBrandCreateHandler()}
          >
            Create Car
          </Button>
        )}
      </InnerHeader>
      <div className="body-container">
        <div className="tabs flex">
          <Button
            className={queryObject.activeTab === "All" ? "tab active" : "tab"}
            onClick={() => {
              if (!loading) {
                setQueryObject({
                  activeTab: "All",
                  status: "PENDING",
                  pageNumber: 1,
                  keywordType: keywordTypeQuery,
                  keyword: "",
                })
              }
            }}
            disabled={loading}
          >
            All(
            {countBuffer?.allCarsCount || "0"})
          </Button>
          <Button
            className={
              queryObject.activeTab === "Auction" ? "tab active" : "tab"
            }
            onClick={() => {
              setQueryObject({
                activeTab: "Auction",
                status: "LISTED",
                pageNumber: 1,
                keywordType: keywordTypeQuery,
                keyword: "",
                subStatus: "SCHEDULED",
              })
            }}
            disabled={loading}
          >
            Auction(
            {countBuffer?.auctionCarsCount || 0})
          </Button>
          <Button
            className={
              queryObject.activeTab === "OnSale" ? "tab active" : "tab"
            }
            onClick={() => {
              setQueryObject({
                activeTab: "OnSale",
                status: "LISTED",
                pageNumber: 1,
                keywordType: keywordTypeQuery,
                keyword: "",
              })
            }}
            disabled={loading}
          >
            OnSale(
            {countBuffer?.onSaleCarsCount || 0})
          </Button>
        </div>
        <div className="body-wrapper">
          <div className="r1">
            <div className="input-wrapper flex aic">
              <select
                name="search-type"
                className="select"
                value={keywordType}
                onChange={(e) => {
                  setKeywordType(e.target.value)
                  setQueryObject((prev) => {
                    return {
                      ...prev,
                      pageNumber: 1,
                      keywordType: e.target.value,
                      keyword: "",
                    }
                  })
                }}
              >
                <option value="ID">ID</option>
                <option value="BRAND">Brand/Model/Year</option>
                <option value="DEALERSHIP">Dealership Name</option>
                <option value="INDIVIDUAL">
                  Individual Seller Number/Name
                </option>
              </select>
              <input
                className="search-bar"
                placeholder="Search"
                value={keyword}
                onChange={(e) => keywordHandler(e)}
              />
            </div>
          </div>
          <div className="r2 flex">
            <div className="c1">
              <div className="title-wrapper flex aic jcsb">
                <p className="title">Filter</p>
                <Button className="clear-button" onClick={clearFilterHandler}>
                  Clear Filters
                </Button>
              </div>

              <div className="content-wrapper">
                <CheckBox
                  checked={queryObject.isRecommended}
                  label="Recommended"
                  onChange={filterUpdateHandler}
                />
              </div>
            </div>
            <div className="c2">
              <div className="tree">
                <CustomTreeView
                  countBuffer={countBuffer}
                  queryObject={queryObject}
                  setQueryObject={setQueryObject}
                  loading={loading}
                />
              </div>
            </div>
            <div className="c3">
              <div className="car-list">
                {loadingBuffer && !carUpdateId ? (
                  <LinearProgress />
                ) : error ? (
                  <Message variant="error">{error}</Message>
                ) : (
                  carsBuffer?.map((car, i) => (
                    <CarCard
                      car={car}
                      loadingCarUpdate={loadingCarUpdate}
                      carUpdateId={carUpdateId}
                      key={i}
                    />
                  ))
                )}
              </div>

              <BasicPagination
                pages={pagesTotal}
                queryObject={queryObject}
                setQueryObject={setQueryObject}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarListScreen
