import React, { useEffect, useState } from "react"
import Alert from "@mui/material/Alert"

const Message = ({ variant, error, width, setError }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (error) {
      setVisible(true)
      const timer = setTimeout(() => {
        setVisible(false)
        setError("")
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [error])

  return (
    visible && (
      <Alert severity={variant} sx={{ mt: 2, width: `${width}` }}>
        {error}
      </Alert>
    )
  )
}

export default Message
