import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCarList, getCarListKeyword } from "../../actions/carMpActions"
import useCarCreate from "./useCarCreate"
import useCarUpdate from "./useCarUpdate"
import useCarDelete from "./useCarDelete"

const useCarList = (queryObject) => {
  const dispatch = useDispatch()

  const carList = useSelector((state) => state.carMarketplace.carList)
  const { loading, error, pageNumber, pages, cars, staticCount } = carList

  const [, , successCarCreate] = useCarCreate()
  const [, , successCarUpdate] = useCarUpdate()
  const [, , successCarDelete] = useCarDelete()

  useEffect(() => {
    if (queryObject.status && queryObject.pageNumber && queryObject.activeTab) {
      dispatch(getCarList(queryObject))
    }
    // eslint-disable-next-line
  }, [dispatch, queryObject])

  useEffect(() => {
    if (successCarCreate || successCarUpdate || successCarDelete) {
      dispatch(getCarList(queryObject))
    }
    // eslint-disable-next-line
  }, [dispatch, successCarCreate, successCarUpdate, successCarDelete])

  return [loading, error, pageNumber, pages, staticCount, cars]
}

export const useCarListKeyword = (keyword) => {
  const dispatch = useDispatch()

  const [timeOutId, setTimeOutId] = useState(null)

  const carList = useSelector((state) => state.carMarketplace.carList)
  const { loading, error, pageNumber, pages, cars, staticCount } = carList

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          dispatch(getCarListKeyword(keyword))
        },
        keyword ? 500 : 0
      )
    )

    // eslint-disable-next-line
  }, [keyword])

  return [loading, error, pageNumber, pages, staticCount, cars]
}

export default useCarList
