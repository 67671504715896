import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { YEAR_SPEC_CREATE_RESET } from "../../constants/carDatabase"

const useYearSpecTempCreate = () => {
  const yearSpecTemplateCreate = useSelector(
    (state) => state.carDbTemplate.yearSpecTemplateCreate
  )
  const { loading, error, success, year } = yearSpecTemplateCreate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Template Create Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: RESPONSE_RESET })
        dispatch({ type: YEAR_SPEC_CREATE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, year]
}

export default useYearSpecTempCreate
