import React from "react"
import "./TextArea.scss"

const TextArea = ({
  label,
  rows,
  name,
  placeholder,
  style,
  value,
  onChange,
  setValue,
}) => {
  return (
    <div className="input-wrapper-isolate">
      <label className="label">{label}</label>
      <textarea
        className="text-area"
        rows={rows}
        name={name}
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={
          onChange
            ? onChange
            : (e) => {
                setValue && setValue(e.target.value)
              }
        }
      />
    </div>
  )
}

export default TextArea
