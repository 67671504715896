import React from "react"
import "./Sidebar.scss"

const Sidebar = ({ TABS, setActiveTab, activeTab }) => {
  return (
    <div className="sidebar">
      {TABS.map((tab) => (
        <p
          key={tab.value}
          onClick={() => {
            setActiveTab(tab)
          }}
          className={activeTab.value === tab.value ? "link active" : "link"}
        >
          {tab.name}
        </p>
      ))}
    </div>
  )
}

export default Sidebar
