import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { CAR_UPDATE_RESET } from "../../constants/carMPConstants"

const useCarUpdate = () => {
  const carUpdate = useSelector((state) => state.carMarketplace.carUpdate)
  const {
    loading: loadingCarUpdate,
    error: errorCarUpdate,
    success: successCarUpdate,
    car,
  } = carUpdate
  const dispatch = useDispatch()

  useEffect(() => {
    if (successCarUpdate || errorCarUpdate) {
      successCarUpdate && dispatch(setMessage("Car Updated Successfully!"))
      errorCarUpdate && dispatch(setMessage(errorCarUpdate))
      setTimeout(() => {
        dispatch({ type: CAR_UPDATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, successCarUpdate, errorCarUpdate])

  return [loadingCarUpdate, errorCarUpdate, successCarUpdate, car]
}

export default useCarUpdate
