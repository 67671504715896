import React, { useEffect, useState } from "react"
import "./TrimCreateEditForm.scss"
import Select from "../input/Select"
import InnerHeaderButton from "../utils/Button"
import { useBrandList } from "../../state/customHooks/carct/useBrandList"
import { useModelList } from "../../state/customHooks/carct/useModelList"
import { useYearList } from "../../state/customHooks/carct/useYearList"
import TextInput from "../input/TextInput"
import ToggleButtonsMultiple from "../input/MultiSelect"
import CustomSwitch from "../selection/Switch"
import TextArea from "../input/TextArea"
import useYearSpecTempDetail from "../../state/customHooks/spectemplate/useYearSpecTempDetail"
import { useDispatch } from "react-redux"
import { createTrim, updateTrim } from "../../state/actions/trimActions"
import useTrimDetail from "../../state/customHooks/carct/useTrimDetail"
import CarPicturesVertical from "../carsmpscreens/CarPicturesVertical"
import useTrimCreate from "../../state/customHooks/carct/useTrimCreate"

import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@mui/material/styles"
import { CircularProgress, LinearProgress } from "@mui/material"

import chevronup from "../../images/common/chevronup.svg"
import chevrondown from "../../images/common/chevrondown.svg"
import YearInformationForm from "./YearInformationForm"
import useYearDetail from "../../state/customHooks/carct/useYearDetail"

import useTrimUpdate from "../../state/customHooks/carct/useTrimUpdate"
import { AntTab, CenteredTabs, TabPanel } from "../utils/Tabs"
import { StyledAlert } from "../utils/Alert"

const TrimCreateEditForm = ({ trim }) => {
  const theme = useTheme()

  const [, , trimDetail] = useTrimDetail(trim._id)

  const [formData, setFormData] = useState({})
  const [isImagesLoaded, setIsImagesLoaded] = useState(false)
  const [value, setValue] = useState(0)
  const [expandedGroups, setExpandedGroups] = useState([])

  const [selectedDateStart, setSelectedDateStart] = useState("")
  const [selectedDateEnd, setSelectedDateEnd] = useState("")
  const [selectedStartTime, setSelectedStartTime] = useState("")
  const [selectedEndTime, setSelectedEndTime] = useState("")

  const [isLimitedTime, setIsLimitedTime] = useState("")

  const [, , brands] = useBrandList()
  const [, , models] = useModelList(formData.brand)
  const [, , years] = useYearList(formData.model)

  const dispatch = useDispatch()

  const [spec, setSpec] = useState([])

  const [images, setImages] = useState([])
  const [imagesOriginal, setImagesOriginal] = useState([])

  const [loadingTrimCreate, , successTrimCreate] = useTrimCreate()
  const [loadingTrimUpdate, ,] = useTrimUpdate()

  const [loadingYearTemplate, error, yearTemplate] = useYearSpecTempDetail(
    formData.yearName
  )

  const [, , yearDetail] = useYearDetail(formData.year)
  const trimOne = {
    ...formData,
    groups: spec,
    isReview: true,
    images,
    imagesOriginal,
    yearName: formData.yearName,
  }

  const trimToSpec = (trim) => {
    const spec = []

    yearTemplate?.groups?.forEach((yearGroup) => {
      const trimGroup = trim.groups?.find(
        (trimGroup) =>
          trimGroup.specificationGroup.toString() === yearGroup._id.toString()
      )

      const groupData = { _id: yearGroup._id, name: yearGroup.name, fields: [] }

      yearGroup.fields.forEach((yearField) => {
        const trimField = trimGroup?.fields.find(
          (field) =>
            field.specificationField.toString() === yearField._id.toString()
        )

        if (trimField) {
          groupData.fields.push({
            _id: yearField._id,
            fieldName: yearField.fieldName,
            value: trimField.value,
            type: yearField.type,
            options: yearField.options,
          })
        }
      })

      spec.push(groupData)
    })

    return spec
  }

  useEffect(() => {
    setSpec([])
    if (!trim?._id) {
      // Check if yearTemplate is available
      if (yearTemplate) {
        // Generate spec object based on yearTemplate
        const generatedSpec = []
        yearTemplate.groups?.forEach((group, i) => {
          const { _id, name } = group
          generatedSpec[i] = { _id, name, fields: [] }
          group.fields?.forEach((field, j) => {
            const { _id, fieldName, type, options, isKeyFeature } = field
            if (type !== "boolean") {
              generatedSpec[i].fields[j] = {
                _id,
                fieldName,
                isKeyFeature,
                value: "",
                type,
                options,
              }
            } else {
              generatedSpec[i].fields[j] = {
                _id,
                fieldName,
                value: false,
                isKeyFeature,
                type,
                options,
              }
            }
          })
        })
        setSpec((prev) => [...prev, ...generatedSpec])
      }
    } else if (trim.isDuplicate) {
      setSpec(trimToSpec(trimDetail))
    } else {
      setSpec(trimToSpec(trimDetail))
    }
  }, [yearTemplate, trim])

  const trimCreateUpdateHandler = () => {
    if (value === 0) {
      if (trimDetail._id && !trim.isDuplicate) {
        dispatch(updateTrim(trimDetail._id, trimOne))
      } else {
        dispatch(createTrim(trimOne))
      }
    } else {
      const url = `/catalogue/year/${yearDetail.year._id}/edit`
      const newWindow = window.open(
        url,
        "_blank",
        "noopener,noreferrer,width=1200,height=saleCreated1200"
      )
      if (newWindow) {
        newWindow.opener = null
      }
    }
  }

  console.log(trimOne)

  const trimViewFullHandler = () => {
    if (value === 0) {
      const url = `/catalogue/trim/${trim._id}/edit`
      const newWindow = window.open(
        url,
        "_blank",
        "noopener,noreferrer,width=1200,height=1200"
      )
      if (newWindow) {
        newWindow.opener = null
      }
    } else {
    }
  }

  const convertTimeToOptionString = (startTime) => {
    const date = new Date(startTime)
    const hour = date.getHours()
    const minute = date.getMinutes()
    const timeString = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`
    return timeString
  }

  const extractDateValue = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateValue = `${year}-${month}-${day}`
    return dateValue
  }

  useEffect(() => {
    if (trimDetail && trimDetail._id) {
      setFormData({
        ...trimDetail,
        brand: trimDetail?.brand?._id,
        model: trimDetail?.model?._id,
        year: trimDetail?.year?._id,
      })
      setSelectedStartTime(
        convertTimeToOptionString(trimDetail?._sale?.startDate)
      )
      setSelectedEndTime(convertTimeToOptionString(trimDetail._sale?.endDate))
      setSelectedDateStart(extractDateValue(trimDetail?._sale?.startDate))
      setSelectedDateEnd(extractDateValue(trimDetail?._sale?.endDate))
      setIsLimitedTime(trimDetail.isLimitedTime)
      setImages(trimDetail?.images)
      setImagesOriginal(trimDetail?.imagesOriginal)
      setValue(0)
      setIsImagesLoaded(true)
    } else if (trim.isDuplicate) {
      const { _id, ...others } = trimDetail
      setFormData({
        ...others,
        brand: trimDetail?.brand?._id,
        model: trimDetail?.model?._id,
        year: trimDetail?.year?._id,
        yearName: trimDetail?.year?.name,
      })
      setImages(trimDetail?.images)
      setImagesOriginal(trimDetail?.imagesOriginal)
      setValue(0)
      setIsImagesLoaded(true)
    } else {
      setFormData({
        subStatus: "",
      })
    }
  }, [trimDetail])

  useEffect(() => {
    if (successTrimCreate) {
      setFormData({
        subStatus: "",
        brand: "",
        model: "",
        year: "",
        name: "",
        backgroundColor: "",
        price: "",
        salePrice: "",
      })
    }
  }, [successTrimCreate])

  const formUpdateHandler = (e) => {
    const { name, value, type, checked } = e.target
    const inputValue = type === "checkbox" ? checked : value

    if (name === "year") {
      const year = years.find((year) => year._id === e.target.value)
      setFormData((prev) => ({
        ...prev,
        yearName: year.name,
        year: year._id,
      }))
    }

    setFormData((prev) => ({
      ...prev,

      [name]: inputValue,
    }))
  }

  const PROCESS_STATUS = [
    { value: "IN_PREPARATION", name: "In Preparation" },
    { value: "ACTIVE", name: "Active" },
  ]

  const updateFieldValue = (e, groupIndex, fieldIndex) => {
    const { type, checked, value: inputValue } = e.target
    const value = type === "checkbox" ? checked : inputValue

    setSpec((prevSpec) => {
      const updatedSpec = [...prevSpec] // Create a shallow copy of the spec array

      if (!updatedSpec[groupIndex]) {
        updatedSpec[groupIndex] = { fields: [] }
      }

      if (!updatedSpec[groupIndex].fields) {
        updatedSpec[groupIndex].fields = []
      }

      updatedSpec[groupIndex].fields[fieldIndex] = {
        ...updatedSpec[groupIndex].fields[fieldIndex],
        value,
      }

      return updatedSpec
    })
  }
  const updateFieldValueArray = (array, groupIndex, fieldIndex) => {
    setSpec((prevSpec) => {
      const updatedSpec = [...prevSpec] // Create a shallow copy of the spec array

      if (!updatedSpec[groupIndex]) {
        updatedSpec[groupIndex] = { fields: [] }
      }

      if (!updatedSpec[groupIndex].fields) {
        updatedSpec[groupIndex].fields = []
      }

      updatedSpec[groupIndex].fields[fieldIndex] = {
        ...updatedSpec[groupIndex].fields[fieldIndex],
        value: array,
      }

      return updatedSpec
    })
  }
  const disabled =
    (!formData.brand && !formData.model && !formData.year) ||
    loadingTrimCreate ||
    loadingTrimUpdate ||
    (trim.isDuplicate && trimOne.name === trimDetail.name)

  const toggleGroup = (groupName) => {
    if (expandedGroups.includes(groupName)) {
      setExpandedGroups(expandedGroups.filter((group) => group !== groupName))
    } else {
      setExpandedGroups([...expandedGroups, groupName])
    }
  }

  const generateHeaderTitle = () => {
    if (value === 0) {
      if (trim._id && !trim.isDuplicate) {
        return `Save`
      } else {
        return `Create`
      }
    } else {
      return `Edit`
    }
  }

  const handleDateChangeStart = (event) => {
    setSelectedDateStart(event.target.value)
  }
  const handleDateChangeEnd = (event) => {
    setSelectedDateEnd(event.target.value)
  }

  const handleStartTimeChange = (event) => {
    setSelectedStartTime(event.target.value)
  }

  const handleEndTimeChange = (event) => {
    setSelectedEndTime(event.target.value)
  }

  useEffect(() => {
    setFormData((prev) => {
      // Concatenate the date and time strings
      const startDateTimeStr = `${selectedDateStart}T${selectedStartTime}`
      const endDateTimeStr = `${selectedDateEnd}T${selectedEndTime}`

      // Create Date objects from the concatenated strings
      const startDate = new Date(startDateTimeStr)
      const endDate = new Date(endDateTimeStr)
      return { ...prev, startDate, endDate }
    })
  }, [selectedDateStart, selectedDateEnd, selectedStartTime, selectedEndTime])

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }
    return options
  }

  const generateEndTimeOptions = () => {
    const options = []

    const [startHour, startMinute] = selectedStartTime?.split(":")?.map(Number)
    for (let hour = 0; hour < 24; hour++) {
      const minuteStart =
        hour === startHour ? Math.ceil(startMinute / 30) * 30 : 0
      for (let minute = minuteStart; minute < 60; minute += 30) {
        const timeString = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`
        options.push({ value: timeString, name: timeString })
      }
    }

    return options
  }

  const updateIsLimitedTime = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        isLimitedTime: !isLimitedTime,
      }
    })
  }

  return (
    <div className="trim-create-edit-form">
      <header className="trim-create-edit-form-header flex">
        {value === 0 ? (
          <InnerHeaderButton
            onClick={trimViewFullHandler}
            disabled={disabled}
            className="pr-1"
          >
            View Full
          </InnerHeaderButton>
        ) : null}

        <InnerHeaderButton
          onClick={trimCreateUpdateHandler}
          disabled={disabled}
        >
          {loadingTrimCreate || loadingTrimUpdate ? (
            <div style={{ width: 50, height: 25 }}>
              <CircularProgress color="inherit" size={25} />
            </div>
          ) : (
            generateHeaderTitle()
          )}
        </InnerHeaderButton>
      </header>
      <div className="trim-create-edit-form-body">
        <CenteredTabs value={value} aria-label="ant example">
          <AntTab label="Trim Information" onClick={() => setValue(0)} />
          <AntTab label="Year Information" onClick={() => setValue(1)} />
        </CenteredTabs>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={(val) => setValue(val)}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <form onChange={formUpdateHandler}>
              <Select
                options={PROCESS_STATUS}
                label="Status"
                value={formData.subStatus}
                name="subStatus"
              />
              <div className="trim-create-edit-form-body-r trim-create-edit-form-body-r2">
                <Select
                  value={formData.brand}
                  label={"Brand*"}
                  required={true}
                  options={
                    brands?.popularBrands && brands?.otherBrands
                      ? brands?.popularBrands.concat(brands?.otherBrands)
                      : []
                  }
                  name="brand"
                />

                <Select
                  value={formData.model}
                  label={"Model*"}
                  options={models}
                  name="model"
                  required={true}
                />
              </div>
              <div className="trim-create-edit-form-body-r trim-create-edit-form-body-r3">
                <Select
                  label="Year"
                  options={years}
                  name="year"
                  value={formData.year}
                />
                <TextInput
                  value={formData.name}
                  label="Trim"
                  name="name"
                  group
                />
              </div>
              <div className="trim-create-edit-form-body-r trim-create-edit-form-body-r4">
                <TextInput
                  value={formData.price}
                  label="Price"
                  name="price"
                  group
                />
                <TextInput
                  value={formData.salePrice}
                  label="Sale Price"
                  name="salePrice"
                  group
                />
              </div>

              <TextInput
                value={formData.promotionTitle}
                label="Promotion Title"
                name="promotionTitle"
              />
            </form>

            {
              <CustomSwitch
                label="Limited Time"
                checked={isLimitedTime}
                updateStatus={setIsLimitedTime}
                onChange={updateIsLimitedTime}
              />
            }

            {isLimitedTime ? (
              <div className="section grid-4">
                <TextInput
                  type="date"
                  name="selectedDateStart"
                  value={selectedDateStart}
                  label={"Start Date:"}
                  onChange={handleDateChangeStart}
                  id="date"
                />

                <Select
                  id="start-time"
                  value={selectedStartTime}
                  name="selectedStartTime"
                  onChange={handleStartTimeChange}
                  label="Start Time:"
                  options={generateTimeOptions()}
                />
                <TextInput
                  type="date"
                  name="selectedDateEnd"
                  value={selectedDateEnd}
                  label={"End Date:"}
                  onChange={handleDateChangeEnd}
                  id="date"
                />
                <Select
                  id="end-time"
                  value={selectedEndTime}
                  name="selectedEndTime"
                  onChange={handleEndTimeChange}
                  label="End Time:"
                  options={generateEndTimeOptions()}
                />
              </div>
            ) : null}

            <div className="spec-wrapper-section">Specifications</div>
            {loadingYearTemplate ? (
              <LinearProgress />
            ) : formData.brand && formData.model && formData.year && error ? (
              <StyledAlert severity="error">{error}</StyledAlert>
            ) : formData.brand &&
              formData.model &&
              formData.year &&
              spec?.length ? (
              spec?.map((group, i) => (
                <div
                  className="spec-wrapper"
                  key={group.name}
                  onClick={() => toggleGroup(group.name)}
                >
                  <div className="group-header-wrapper">
                    <div className="title-wrapper">
                      <p className="sub-title">{group.name}</p>
                    </div>
                    <div className="icons-wrapper">
                      <div className="chevron-wrapper">
                        <img
                          src={
                            expandedGroups.includes(group.name)
                              ? chevronup
                              : chevrondown
                          }
                          alt=""
                          className="icon"
                        />
                      </div>
                    </div>
                  </div>
                  {expandedGroups.includes(group.name) && (
                    <div key={group.name}>
                      <div className="">
                        {group.fields.map((field, j) =>
                          field.type === "text" ? (
                            <TextInput
                              group
                              key={field.fieldName}
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              name={field.fieldName}
                              value={field.value}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => updateFieldValue(e, i, j)}
                            />
                          ) : field.type === "number" ? (
                            <TextInput
                              group
                              key={j}
                              type="number"
                              value={field.value}
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              name={field.fieldName}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => updateFieldValue(e, i, j)}
                            />
                          ) : field.type === "select" ? (
                            <Select
                              key={field.fieldName}
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              options={field.options.map((value) => ({
                                name: value,
                                value: value,
                              }))}
                              name={field.fieldName}
                              value={field.value}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => updateFieldValue(e, i, j)}
                            />
                          ) : field.type === "multiSelect" ? (
                            <ToggleButtonsMultiple
                              chipArray={field.options}
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              onChange={updateFieldValueArray}
                              group={i}
                              index={j}
                              value={field.value}
                              onClick={(e) => e.stopPropagation()}
                              key={field.fieldName}
                            />
                          ) : field.type === "longText" ? (
                            <TextArea
                              key={field.fieldName}
                              rows={3}
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              name={field.fieldName}
                              value={field.options}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => updateFieldValue(e, i, j)}
                            />
                          ) : field.type === "boolean" ? (
                            <CustomSwitch
                              label={`${field.fieldName} ${
                                field.isKeyFeature ? "*" : ""
                              }`}
                              group={i}
                              index={j}
                              checked={field.value || false}
                              onClick={(e) => e.stopPropagation()}
                              onChange={updateFieldValue}
                            />
                          ) : null
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : null}
          </TabPanel>

          <TabPanel value={value} index={1} dir={theme.direction}>
            <YearInformationForm yearInfo={yearDetail} />
          </TabPanel>
        </SwipeableViews>
        {value === 0 ? (
          <CarPicturesVertical
            images={images}
            imagesOriginal={imagesOriginal}
            loadingImages={isImagesLoaded}
            setImages={setImages}
            setImagesOriginal={setImagesOriginal}
          />
        ) : null}
      </div>
    </div>
  )
}

export default TrimCreateEditForm
