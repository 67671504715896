import React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import { Chip } from "@material-ui/core"
import TextField from "@mui/material/TextField"
import "./ChipInput.scss"

export const ChipsInput = ({
  placeholder,
  onChange,
  i,
  j,
  field,
  defaultValue,
}) => {
  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-filled"
      className="input-multi"
      options={[]}
      defaultValue={defaultValue}
      freeSolo
      onChange={(event, newValue) => onChange(i, j, field, newValue)} // Call onChange with the new value
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          )
        })
      }
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} fullWidth />
      )}
    />
  )
}
