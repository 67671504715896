import React, { useState } from "react"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect } from "react"

export const AlertCustom = ({ error }) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(true)
  }, [error])

  return (
    <>
      {open && (
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {/* Split the error message into multiple lines using newline character */}
          {error.split("\n").map((line, index) => (
            <div dangerouslySetInnerHTML={{ __html: line }} />
          ))}
        </Alert>
      )}
    </>
  )
}

export default AlertCustom
