import * as React from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import "./DropDownMenu.scss"
import threedots from "../../images/dealership/threedotsblue.svg"
import threedotsWhite from "../../images/dealership/threedots.svg"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  menuItem: {
    width: "200px",
  },
})

export const DropDownMenu = ({ menuItem, data, type, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const classes = useStyles()

  return (
    <div
      className={
        type === "inner-header" ? "dropdown-menu-dealership" : "drop-down"
      }
    >
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={type === "inner-header" ? "dropdown" : "drop-down-button"}
      >
        {type === "inner-header" ? (
          <>
            <h5 className="button-text">{title}</h5>
            <img src={threedotsWhite} alt="" />
          </>
        ) : (
          <img src={threedots} alt="" />
        )}
      </Button>
      <Menu
        id="basic-menu"
        className={classes.menuItem}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ width: "200px" }}
      >
        {menuItem.map((item, index) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              item.action(data)
              handleClose()
            }}
            key={index}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
