import React from "react"
import { Link } from "react-router-dom"

const LogListGroupByCars = ({ logs }) => {
  return (
    <div className="list">
      <table className="grid">
        <tbody>
          <tr className="table-head">
            <th className="table-head-text name"> Seller</th>
            <th className="table-head-text">Car name</th>
            <th className="table-head-text cars">Call count</th>
          </tr>
          {logs?.map((log, i) => (
            <tr className="table-data" key={i}>
              {log.sellerCompanyName ? (
                <td className="table-data-text">
                  <Link
                    to={`/seller/companies/${log.sellerCompanyId}/show`}
                    className="link"
                    target="blank"
                  >
                    {log.sellerCompanyName}
                  </Link>
                </td>
              ) : log.sellerDealershipName ? (
                <td className="table-data-text">
                  <Link
                    to={`/seller/dealerships/${log.sellerDealershipId}/show`}
                    className="link"
                    target="blank"
                  >
                    {log.sellerDealershipName}
                  </Link>
                </td>
              ) : (
                <td className="table-data-text">
                  <Link
                    to={`/seller/individuals/${log.sellerIndividualId}/show`}
                    className="link"
                    target="blank"
                  >
                    {`${log.sellerIndividualFirstName} ${log.sellerIndividualLastName} ${log.sellerIndividualName}`}
                  </Link>
                </td>
              )}
              <td className="table-data-text">
                <Link to={`/car/${log.car}`} className="link" target="blank">
                  {log.brand} {log.model} {log.year}
                </Link>
              </td>
              <td className="table-data-text">{log.numberOfLogs}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LogListGroupByCars
