import Axios from "../../utils/axios"
import {
  MODEL_CREATE_FAIL,
  MODEL_CREATE_REQUEST,
  MODEL_CREATE_SUCCESS,
  MODEL_DELETE_FAIL,
  MODEL_DELETE_REQUEST,
  MODEL_DELETE_SUCCESS,
  MODEL_DETAIL_FAIL,
  MODEL_DETAIL_LIST_FAIL,
  MODEL_DETAIL_LIST_REQUEST,
  MODEL_DETAIL_LIST_SUCCESS,
  MODEL_DETAIL_REQUEST,
  MODEL_DETAIL_SUCCESS,
  MODEL_LIST_FAIL,
  MODEL_LIST_REQUEST,
  MODEL_LIST_REVIEW_FAIL,
  MODEL_LIST_REVIEW_REQUEST,
  MODEL_LIST_REVIEW_SUCCESS,
  MODEL_LIST_SUCCESS,
  MODEL_UPDATE_FAIL,
  MODEL_UPDATE_REQUEST,
  MODEL_UPDATE_SUCCESS,
} from "../constants/modelConstants"

export const getModelList =
  (brandId, keyword = "", filter) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: MODEL_LIST_REQUEST,
      })

      const {
        users: {
          userLogin: { userInfo },
        },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await Axios.get(
        `/v1/models/brand/${brandId}?cars=${keyword}&keyword=${filter}`,
        config
      )
      dispatch({
        type: MODEL_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MODEL_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const getModelListReview = (brandId) => async (dispatch) => {
  try {
    dispatch({
      type: MODEL_LIST_REVIEW_REQUEST,
    })

    const { data } = await Axios.get(`/v1/models/review/${brandId}`)
    dispatch({
      type: MODEL_LIST_REVIEW_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MODEL_LIST_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getModelDetailList = (brandId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_DETAIL_LIST_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/modelInfo?brand=${brandId}`, config)
    dispatch({
      type: MODEL_DETAIL_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MODEL_DETAIL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createModel = (model) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.post(`/v1/models/`, model, config)
    dispatch({
      type: MODEL_CREATE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: MODEL_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteModelById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/models/${id}`, config)
    dispatch({
      type: MODEL_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: MODEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateModel = (id, model) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_UPDATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.put(`/v1/models/${id}`, model, config)
    dispatch({
      type: MODEL_UPDATE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: MODEL_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getModelDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_DETAIL_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/models/${id}`, config)
    dispatch({
      type: MODEL_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MODEL_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
