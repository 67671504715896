import React, { useState, useRef, useEffect } from "react"
import "./ReviewCreateEditScreen.scss"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import { useDispatch, useSelector } from "react-redux"
import { Editor } from "@tinymce/tinymce-react"
import { uploadImage } from "../../apis/imageUpload"
import { getModelList } from "../../state/actions/modelActions"
import {
  createReview,
  getReviewDetail,
  updateReview,
} from "../../state/actions/reviewAction"
import { Link, useParams } from "react-router-dom"
import { getYearList } from "../../state/actions/yearActions"

function Add() {
  const [brand, setBrand] = useState("")
  const [model, setModel] = useState("")
  const [year, setYear] = useState("")
  const [coverImage, setCoverImage] = useState("")

  const [body, setBody] = useState("")

  const params = useParams()
  const { brandId, modelId } = params

  const dispatch = useDispatch()

  const editorRef = useRef()

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading: loadingBrands, brands } = brandList

  const modelList = useSelector((state) => state.carCatalogue.modelList)
  const { loading: loadingModels, models } = modelList
  const yearList = useSelector((state) => state.carCatalogue.yearList)
  const { loading: loadingYears, years } = yearList

  const reviewDetail = useSelector((state) => state.review.reviewDetail)
  const { loading: loadingReview, review } = reviewDetail

  const reviewUpdated = {
    brand,
    model,
    year,
    body,
    coverImage,
  }

  const onClickHandlerUpdate = () => {
    setBody(editorRef.current.getContent())
  }
  const onClickHandlerSave = () => {
    onClickHandlerUpdate()
    if (!brandId && !modelId) {
      dispatch(createReview({ brand, model, body, coverImage }))
    } else {
      dispatch(updateReview(review._id, reviewUpdated))
    }
  }
  const onClickHandlerPublish = () => {
    reviewUpdated.status = "PUBLISHED"
    dispatch(updateReview(review._id, reviewUpdated))
  }

  useEffect(() => {
    if (brand) {
      dispatch(getModelList(brand, "", "ACTIVE"))
    }
    if (brand && model) {
      dispatch(getYearList(model))
    }
    if (brandId || modelId) {
      dispatch(getReviewDetail(modelId))
    }
  }, [brand])

  useEffect(() => {
    if (!loadingReview && brandId && modelId) {
      setBrand(review.brand._id)
      setModel(review.model._id)
      setYear(review.year?._id)
      setBody(review.body)
      setCoverImage(review.coverImage)
    }
  }, [review])

  const coverImageUploadHandler = (e) => {
    var file = e.target.files[0]
    var reader = new FileReader()

    reader.onload = async function () {
      var id = "blobid" + new Date().getTime()
      var blobCache = window.tinymce.activeEditor.editorUpload.blobCache
      var base64 = reader.result

      var blobInfo = blobCache.create(id, file, base64)

      blobCache.add(blobInfo)

      // call the callback and populate the Title field with the file name

      const { data } = await uploadImage(base64)
      setCoverImage(data.images[0])
    }
    reader.readAsDataURL(file)
  }

  return (
    <div className="review-create-edit-screen">
      <InnerHeader title="Create Car Review" className="inner-header">
        <div className="button-container">
          <div className="btn-save" onClick={onClickHandlerUpdate}>
            Update
          </div>
          <div className="btn-save" onClick={onClickHandlerSave}>
            Save
          </div>
          {review?._id && (
            <div className="btn-save" onClick={onClickHandlerPublish}>
              Publish
            </div>
          )}
        </div>
      </InnerHeader>
      <InnerContainer>
        <div className="section">
          <p className="section-title">Car Information</p>
          <div className="r grid-4">
            <div className="input-wrapper">
              <label className="label">Brand</label>
              <select
                name="brand"
                className="select"
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value)
                }}
              >
                <option value="">-</option>
                {!loadingBrands &&
                  brands?.popularBrands?.map((brand, i) => (
                    <option value={brand._id} key={i}>
                      {brand.name}
                    </option>
                  ))}
                {!loadingBrands &&
                  brands?.otherBrands?.map((brand, i) => (
                    <option value={brand._id} key={i}>
                      {brand.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Model</label>
              <select
                name="model"
                className="select"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value)
                }}
              >
                <option value="">-</option>
                {!loadingModels &&
                  models?.length > 0 &&
                  models?.map((model, i) => (
                    <option value={model._id} key={i}>
                      {model.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Year</label>
              <select
                name="year"
                className="select"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
              >
                <option value="">-</option>
                {!loadingYears &&
                  years?.length > 0 &&
                  years?.map((year, i) => (
                    <option value={year._id} key={i}>
                      {year.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label className="label">Cover Image</label>
              <Link className="link" to={coverImage}>
                {coverImage.slice(0, 30)}...
              </Link>
              <input
                name="image"
                className="image"
                type="file"
                onChange={coverImageUploadHandler}
              />
            </div>
          </div>
        </div>
        <div className="flex jcsb">
          <div className="editor">
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={body}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "imagetools",
                  "table",
                  "preview",
                  "help",
                  "wordcount",
                ],
                toolbar: [
                  "undo redo | blocks | bold italic | forecolor | alignleft | aligncenter | alignnone | alignright| blockquote |backcolor|copy|cut|fontselect|fontsizeselect |forecolor|formatselect|h1|h2|h3|h4|h5|code",
                  "image | media | link |indent | italic| language| lineheight|outdent |paste |redo |remove |removeformat | selectall| strikethrough| styleselect| subscript| superscript | underline| undo| visualaid | bullist |numlist | advtablerownumbering ",
                  "table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | editimage",
                  "visualblocks",
                ],
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                file_picker_types: "image",
                image_title: true,
                // enable automatic uploads of images represented by blob or data URIs
                automatic_uploads: true,
                // add custom filepicker only to Image dialog

                file_picker_callback: async function (cb, value, meta) {
                  var input = document.createElement("input")
                  input.setAttribute("type", "file")
                  input.setAttribute("accept", "image/*")

                  input.onchange = async function () {
                    var file = this.files[0]
                    var reader = new FileReader()

                    reader.onload = async function () {
                      var id = "blobid" + new Date().getTime()
                      var blobCache =
                        window.tinymce.activeEditor.editorUpload.blobCache
                      var base64 = reader.result

                      var blobInfo = blobCache.create(id, file, base64)

                      blobCache.add(blobInfo)

                      // call the callback and populate the Title field with the file name

                      const imageURLT = await uploadImage(base64)
                      imageURLT &&
                        cb(String(imageURLT.data.images[0]), {
                          title: file.name,
                        })
                    }
                    reader.readAsDataURL(file)
                  }

                  input.click()
                },
              }}
            />
          </div>
          <div className="live-view">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}
export default Add
