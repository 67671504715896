import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import searchicon from "../../images/dashboard/carsmpscreens/carlist/search.svg"
import { useSearchParams } from "react-router-dom"
import "./LogListingScreen.scss"
import { useLogList } from "../../state/customHooks/logs/useLogsList"
import { useLogListGroupByCars } from "../../state/customHooks/logs/useLogsList"
import LogListGroupByCars from "../../components/logscreen/LogListGroupByCars"
import LogList from "../../components/logscreen/LogList"

export const status = ["SMS", "CAR", "SALE", "AUCTION"]

const LogListScreen = () => {
  const [searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState("")
  const [queryObject, setQueryObject] = useState({ pageNumber: 1, keyword: "" })
  const statusQuery = searchParams.get("status")

  const [logsGroupByCars] = useLogListGroupByCars()
  const [loading, error, page, pages, count, logs] = useLogList(queryObject)

  useEffect(() => {
    statusQuery ? setActiveTab(statusQuery) : setActiveTab("SMS")
  }, [])

  return (
    <div className="log-list-screen">
      <InnerHeader title="Logs" className="inner-header"></InnerHeader>
      <InnerContainer>
        <div className="tabs flex">
          {status.map((tab) => (
            <Button
              className={activeTab === tab ? "tab active" : "tab"}
              onClick={() => {
                setActiveTab(tab)
              }}
            >
              {tab}
            </Button>
          ))}
        </div>
        <div className="body-wrapper">
          <div className="input-wrapper flex">
            <img src={searchicon} alt="search-icon" className="search-icon" />
            <input
              type="text"
              className="search-bar"
              placeholder="Search"
              onChange={(e) =>
                setQueryObject((prev) => {
                  return { ...prev, keyword: e.target.value }
                })
              }
            />
          </div>
          <div className="list-grid flex">
            <LogListGroupByCars logs={logsGroupByCars} />
            <LogList
              logs={logs}
              loading={loading}
              error={error}
              page={page}
              pages={pages}
              count={count}
              queryObject={queryObject}
              setQueryObject={setQueryObject}
            />
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default LogListScreen
