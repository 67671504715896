import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTaskList } from "../../../state/actions/commonActions"
import { Alert, Button } from "@mui/material"

import Loader from "../../utils/Loader"
import "./TasksTab.scss"
import TaskCardActive from "./TaskCardActive"

const TasksTab = ({ car }) => {
  const taskList = useSelector((state) => state.common.taskList)
  const { loading: loadingTasks, error: errorTasks, tasks } = taskList

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTaskList("cars", car._id))

    //eslint-disable-next-line
  }, [dispatch])

  return (
    <div className="car-task-tab flex jcsb">
      <div className="car-task-tab-c1">
        <div className="tab-header flex jcsb">
          <h4 className="title">All Open Task</h4>
          <Button className="btn blue">Create</Button>
        </div>
        <div className="tab-body">
          {loadingTasks ? (
            <Loader />
          ) : errorTasks ? (
            <Alert severity="error">{errorTasks}</Alert>
          ) : (
            <div className="tasks-list">
              {tasks.map((task) => (
                <TaskCardActive task={task} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="divider-wrapper flex col jcsb">
        <div className="divider"></div>
      </div>
      <div className="car-task-tab-c2">
        <h4 className="title">History</h4>
      </div>
    </div>
  )
}

export default TasksTab
