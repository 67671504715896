import React, { useEffect, useState } from "react"
import InnerHeader from "../../components/header/InnerHeader"
import Popup from "../../components/sellerscreens/Popup"
import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  duplicateCheckSeller,
  getSellerTags,
} from "../../state/actions/sellerActions"
import { Link, useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import "./SellerListScreen.scss"
import { SELLER_DUPLICATE_CHECK_RESET } from "../../state/constants/sellerContants"
import { DropDownMenu } from "../../components/common/DropDownMenu"
import { useSeller } from "../../state/customHooks/seller/useSellerList"
import SellerTab from "../../components/sellerscreens/SellerTab"
import SideDrawer from "../../components/utils/MSideDrawer"
import DealershipFilter from "../../components/sellerscreens/DealershipFilter"
import CustomTabs from "../../components/common/CustomTabs"
import { toCamelCase } from "../../utils/utilsFunctions"
import { useSellerDuplicateList } from "../../state/customHooks/seller/useSellerDuplicateCheck"
import { setMessage } from "../../state/actions/responseAction"

export const SellerListContext = React.createContext({})

const SellerListScreen = () => {
  const [searchParams] = useSearchParams()
  const [popup, setPopup] = useState(false)
  const [sellerName, setSellerName] = useState("")
  const [duplicateCheck, setDuplicateCheck] = useState(false)
  const [sellerType, setSellerType] = useState("")
  const [sellerTypeCreate, setSellerTypeCreate] = useState("")
  const [keyword, setKeyword] = useState("")
  const [tagsFilter, setTagsFilter] = useState([])
  const [filterStatus, setFilterStatus] = useState("")
  const [pageNumber, setPageNumber] = useState("")

  // Query Constants

  const sellerTypeQuery = searchParams.get("sellerType")
  const keywordQuery = searchParams.get("keyword")
  const tagsQuery = searchParams.get("tags")
  const statusQuery = searchParams.get("status")
  const pageNumberQuery = searchParams.get("pageNumber")

  // Tabs Constants

  const [countBuffer, setCountBuffer] = useState(0)
  const [sideDrawerShow, setSideDrawerShow] = useState(false)
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [activeTab, setActiveTab] = useState(sellerTypeQuery)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loadingSellerDuplicate, , duplicateSellersList] =
    useSellerDuplicateList()

  const sellerTagsList = useSelector((state) => state.seller.sellerTagsList)
  const { tags } = sellerTagsList

  const [sellers, loading, page, pages, count, allSellers] = useSeller(
    sellerType,
    pageNumber,
    filterStatus,
    keyword,
    tagsFilter
  )

  useEffect(() => {
    keywordQuery && setKeyword(keywordQuery)
    if (tagsQuery) {
      setTagsFilter(tagsQuery ? tagsQuery.split(",") : [])
    }
    if (statusQuery) {
      setFilterStatus(statusQuery)
    }
    if (pageNumberQuery) {
      setPageNumber(pageNumberQuery)
    }
    if (sellerTypeQuery) {
      setSellerType({ type: sellerTypeQuery })
    }
  }, [])

  useEffect(() => {
    !loading && setCountBuffer(count)
  }, [count])

  useEffect(() => {
    if (!loadingSellerDuplicate) {
      if (
        duplicateSellersList?.length > 0 &&
        sellerTypeCreate.type !== "individuals"
      ) {
        setDuplicateCheck(true)
      } else if (
        duplicateSellersList?.length > 0 &&
        sellerTypeCreate.type === "individuals"
      ) {
        dispatch(
          setMessage(
            `${sellerName} ${duplicateSellersList[0].firstName || ""} ${
              duplicateSellersList[0].lastName || ""
            } already exisit`
          )
        )
        dispatch({ type: SELLER_DUPLICATE_CHECK_RESET })
      } else if (sellerName) {
        navigate(
          `/seller/${sellerTypeCreate.type}/create?${
            sellerTypeCreate.type === "individuals"
              ? "sellerNumber"
              : "sellerName"
          }=${sellerName}`
        )
      }
    }
    sellerType && dispatch(getSellerTags(sellerType.type))
  }, [navigate, loadingSellerDuplicate, duplicateSellersList, sellerType])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(duplicateCheckSeller(sellerTypeCreate.type, sellerName))
  }

  const dealershipCreateHandler = () => {
    setPopup(true)
    setSellerTypeCreate({ name: "Dealership", type: "dealerships" })
  }
  const companyCreateHandler = () => {
    setPopup(true)
    setSellerTypeCreate({ name: "Company", type: "companies" })
  }
  const officalBrandHandler = () => {
    setPopup(true)
    setSellerTypeCreate({ name: "Official Brand", type: "officialBrands" })
  }
  const individualCreateHandler = () => {
    setPopup(true)
    setSellerTypeCreate({ name: "Individuals", type: "individuals" })
  }

  const menuItem = [
    {
      title: "Dealership",
      action: dealershipCreateHandler,
    },
    {
      title: "Company",
      action: companyCreateHandler,
    },
    {
      title: "Official Brand",
      action: officalBrandHandler,
    },
    {
      title: "Individual",
      action: individualCreateHandler,
    },
  ]

  const sellerTabs = [
    { tabName: "Dealerships", count: countBuffer?.dealership || 0 },
    { tabName: "Companies", count: countBuffer?.company || 0 },
    { tabName: "Official Brands", count: countBuffer?.officialBrand || 0 },
    { tabName: "Individuals", count: countBuffer?.individual || 0 },
  ]
  const sellerTabsBody = [
    <>
      <SellerTab
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
      />
      <SideDrawer trigger={sideDrawerShow}>
        <DealershipFilter
          tags={tags}
          setTrigger={setSideDrawerShow}
          setIsClearFilter={setIsClearFilter}
        />
      </SideDrawer>
    </>,
    <>
      <SellerTab
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
      />
      <SideDrawer trigger={sideDrawerShow}>
        <DealershipFilter
          tags={tags}
          setTrigger={setSideDrawerShow}
          setIsClearFilter={setIsClearFilter}
        />
      </SideDrawer>
    </>,
    <>
      <SellerTab
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
      />
      <SideDrawer trigger={sideDrawerShow}>
        <DealershipFilter
          tags={tags}
          setTrigger={setSideDrawerShow}
          setIsClearFilter={setIsClearFilter}
        />
      </SideDrawer>
    </>,
    <>
      <SellerTab
        isClearFilter={isClearFilter}
        setIsClearFilter={setIsClearFilter}
      />
      <SideDrawer trigger={sideDrawerShow}>
        <DealershipFilter
          tags={tags}
          setTrigger={setSideDrawerShow}
          setIsClearFilter={setIsClearFilter}
        />
      </SideDrawer>
    </>,
  ]

  const tabClickHandler = (click) => {
    setActiveTab(toCamelCase(click.tabName))
    setSellerType({ type: toCamelCase(click.tabName), name: click.tabName })
  }

  return (
    <div className="seller-dashboard">
      <InnerHeader
        title="Dealerships / Official Brands / Companies / Individual Sellers"
        className="inner-header"
      >
        <div className="buttons-wrapper">
          <DropDownMenu
            type="inner-header"
            menuItem={menuItem}
            title="Create"
          />
        </div>
      </InnerHeader>

      {/* Seller List Tabs */}

      <div className="body-container flex">
        <SellerListContext.Provider
          value={{
            sellers,
            page,
            pages,
            loading,
            allSellers,
            sellerType,
            setSellerType,
            keyword,
            setKeyword,
            tagsFilter,
            setTagsFilter,
            filterStatus,
            setFilterStatus,
            pageNumber,
            setPageNumber,
            count,
            tags,
          }}
        >
          <CustomTabs
            tabs={sellerTabs}
            tabsBody={sellerTabsBody}
            activeTab={activeTab}
            tabClickHandler={tabClickHandler}
            setSideDrawerShow={setSideDrawerShow}
            count={count}
          />
        </SellerListContext.Provider>

        {/* Seller Create Popup */}

        <Popup
          trigger={popup}
          setTrigger={setPopup}
          title={
            duplicateCheck
              ? "Duplicate Check"
              : `Enter ${sellerTypeCreate.name}  ${
                  sellerTypeCreate.name === "Individual" ? "Number" : "Name"
                }`
          }
          className={duplicateCheck && "duplicate-check"}
          resetOption={setDuplicateCheck}
        >
          {duplicateCheck ? (
            <div className="duplicate-check-wrapper">
              <p className="text">
                It looks like there are duplicates. Please review possible
                matches.
              </p>
              <Link
                to={`/seller/${sellerType.type}/create?sellerName=${sellerName}`}
                className="link"
                onClick={() => {
                  dispatch({ type: SELLER_DUPLICATE_CHECK_RESET })
                  setDuplicateCheck(false)
                }}
              >
                THIS SELLER IS NOT A DUPLICATE
              </Link>
              <div className="duplicate-check-list">
                {duplicateSellersList?.map((seller, i) => (
                  <div className="card flex jcsb" key={i}>
                    <div className="c1 flex">
                      {seller.profileImage ? (
                        <img src={seller.profileImage} alt="profileImage" />
                      ) : (
                        <p className="dealership-name-initials">
                          {seller.name
                            .match(/\b(\w)/g)
                            .join("")
                            .toUpperCase()}
                        </p>
                      )}
                      <div className="text-wrapper">
                        <p className="bold-text">{seller.name}</p>
                        <div className="r2 flex">
                          {/* <div className="text margin-r">
                            {dealership._primaryUser.firstName}{" "}
                            {dealership._primaryUser.lastName}
                          </div> */}
                          <div className="text">{seller?.location?.city}</div>
                        </div>
                      </div>
                    </div>
                    <div className="c2">
                      <p className="text margin-b">ID# {seller.id}</p>
                      {seller.status === "ACTIVE" ? (
                        <p className="text">Active</p>
                      ) : (
                        <p className="text">Suspended</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <form onSubmit={submitHandler} className="form-wrapper">
              <div className="input-wrapper flex col">
                <label className="input-label">
                  {sellerTypeCreate.name}{" "}
                  {sellerTypeCreate.name === "Individuals" ? "Number" : "Name"}
                </label>
                <input
                  type="text"
                  name="dealership-name"
                  className="input-text"
                  onChange={(e) => {
                    setSellerName(e.target.value)
                  }}
                />
              </div>
              <div className="button-wrapper flex">
                <Button
                  className="btn light"
                  onClick={() => {
                    setPopup(false)
                    setDuplicateCheck(false)
                  }}
                >
                  Cancel
                </Button>
                <input
                  type="submit"
                  value="Save"
                  className="btn primary"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </form>
          )}
        </Popup>
      </div>
    </div>
  )
}

export default SellerListScreen
