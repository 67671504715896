import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { LINK_CREATE_RESET } from "../../constants/linkConstants"

const useLinkCreate = () => {
  const linkCreate = useSelector((state) => state.link.linkCreate)
  const { loading, error, success, link } = linkCreate

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Link Create Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: LINK_CREATE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 2000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, link]
}

export default useLinkCreate
