import React, { useState, useEffect } from "react"

const Grid = ({ children, sideDrawer }) => {
  const [columns, setColumns] = useState(5)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setColumns(1)
      } else if (window.innerWidth < 768) {
        setColumns(2)
      } else if (window.innerWidth < 992) {
        setColumns(3)
      } else if (window.innerWidth < 1200) {
        setColumns(4)
      } else if (window.innerWidth < 1400) {
        setColumns(4)
      } else if (window.innerWidth < 1600) {
        setColumns(5)
      } else if (window.innerWidth < 1800) {
        setColumns(6)
      } else {
        setColumns(6)
      }
    }

    handleResize() // Set initial columns based on window size

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: !sideDrawer
          ? `repeat(${columns}, 1fr)`
          : `repeat(4, 1fr)`,
        gridGap: 10,
        maxWidth: "800px",
        marginTop: "25px",
      }}
    >
      {children}
    </div>
  )
}

export default Grid
