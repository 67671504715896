import { Button } from "@mui/material"
import React from "react"
import { useSearchParams } from "react-router-dom"
import "./RecentActivity.scss"

import { options } from "../../assets/utils"

const RecentActivity = ({
  activeTab,
  setActiveTab,
  data,
  duration,
  setDuration,
}) => {
  const [, setSearchParams] = useSearchParams()

  return (
    <div className="recent-activity-component">
      <div className="tabs flex">
        <Button
          className={activeTab === "Customer" ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab("Customer")
            setSearchParams({
              status: "Customer",
              pageNumber: 1,
            })
          }}
        >
          CUSTOMERS
        </Button>
        <Button
          className={activeTab === "dealerships" ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab("dealerships")
            setSearchParams({
              status: "dealerships",
              pageNumber: 1,
            })
          }}
        >
          DEALERSHIPS({data?.length})
        </Button>
      </div>
      <div className="select">
        <div className="input-wrapper">
          <select
            className="select"
            value={duration.value}
            onChange={(e) => setDuration(e.target.value)}
          >
            {options.map((option, i) => (
              <option value={option.value} key={i}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="list">
        <table className="grid">
          <tbody>
            <tr className="table-head">
              <th className="table-head-text name">Name</th>
              <th className="table-head-text">Username</th>
              <th className="table-head-text cars">Cars</th>
              <th className="table-head-text">Last Activity</th>
            </tr>

            {data?.map((seller, i) => (
              <tr className="table-data" key={i}>
                <td className="table-data-text">{seller.name}</td>
                <td>{seller._primaryUser?.username}</td>
                <td>{seller.cars.length}</td>
                <td>{`${seller?.lastActivity.substring(0, 10).split("-")[2]}/${
                  seller?.lastActivity.substring(0, 10).split("-")[1]
                }/${
                  seller?.lastActivity.substring(0, 10).split("-")[0]
                } ${seller?.lastActivity.split("T")[1].substring(0, 8)}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RecentActivity
