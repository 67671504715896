import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getTagsList } from "../../actions/tagsActions"

export const useTagList = (keyword) => {
  const dispatch = useDispatch()

  const [timeOutId, setTimeOutId] = useState(null)

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          dispatch(getTagsList(keyword))
        },
        keyword ? 500 : 0
      )
    )

    // eslint-disable-next-line
  }, [dispatch, keyword])

  const tagsList = useSelector((state) => state.tags.tagsList)
  const { loading, error, tags } = tagsList

  return [loading, error, tags]
}
