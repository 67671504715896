import "./ModelList.scss"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomSwitch from "../../selection/Switch"
import actionBin from "../../../images/dashboard/actionbin.svg"
import actionPencil from "../../../images/dashboard/actionpencil.svg"
import {
  deleteModelById,
  getModelDetailList,
  getModelList,
} from "../../../state/actions/modelActions"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import Axios from "../../../utils/axios"
import { setYear } from "../../../utils/utilsFunctions"
import { useSearchParams } from "react-router-dom"

const ModelList = (props) => {
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const brandQ = searchParams.get("brand")
  const modelQ = searchParams.get("model")

  const [modelList, setModelList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const brandDetail = useSelector((state) => state.carCatalogue.brandDetail)

  const { loading: loadingBrand, brand } = brandDetail

  const modelInfoList = useSelector((state) => state.carCatalogue.modelInfoList)
  const { loading: loadingModel, modelDetailList } = modelInfoList

  const modelDelete = useSelector((state) => state.carCatalogue.modelDelete)
  const { success: deleteModelSuccess } = modelDelete

  const modelUpdate = useSelector((state) => state.carCatalogue.modelUpdate)
  const { success: successModelUpdate } = modelUpdate

  const modelCreate = useSelector((state) => state.carCatalogue.modelCreate)
  const { success: successModelCreate } = modelCreate

  const yearCreate = useSelector((state) => state.carCatalogue.yearCreate)
  const { success: successYearCreate } = yearCreate
  const yearUpdate = useSelector((state) => state.carCatalogue.yearUpdate)
  const { success: successYearUpdate } = yearUpdate

  const generationDelete = useSelector(
    (state) => state.carCatalogue.generationDelete
  )
  const { success: successGenerationDelete } = generationDelete

  const trimCreate = useSelector((state) => state.carCatalogue.trimCreate)
  const { success: successTrimCreate } = trimCreate

  const trimDelete = useSelector((state) => state.carCatalogue.trimDelete)
  const { success: successTrimDelete } = trimDelete

  const yearRangeCreate = useSelector(
    (state) => state.carCatalogue.yearRangeCreate
  )
  const { success: successYearRangeCreate } = yearRangeCreate

  const years = []

  const yearFull = []

  useEffect(() => {
    if (modelQ) {
      modelDetailList?.forEach((modelInfo) => {
        if (modelInfo.model._id === modelQ) {
          props.openSlider("year", `Edit ${modelInfo.model.name}`, modelInfo)
        }
      })
    }
  }, [modelQ, successYearCreate])

  modelList.map((modelInfo) => {
    years.push(modelInfo.years)
    return true
  })

  years.map((year, i) => {
    let y = []

    year.forEach((year) => {
      y.push(year.name)
    })
    yearFull[i] = y
    return true
  })

  const deleteModelHandler = (id) => {
    dispatch(deleteModelById(id))
  }

  useEffect(() => {
    if (typeof modelDetailList !== "undefined") {
      if (modelDetailList.length > 0) {
        setModelList(modelDetailList)
      } else {
        setModelList([])
      }
    } else {
      setModelList([])
    }
  }, [modelDetailList])

  useEffect(() => {
    if (brandQ === brand._id) {
      if (
        brand._id ||
        successModelUpdate ||
        successModelCreate ||
        deleteModelSuccess ||
        successYearCreate ||
        successYearRangeCreate ||
        successYearUpdate ||
        successGenerationDelete ||
        successTrimDelete ||
        successTrimCreate
      ) {
        dispatch(getModelList(brandQ))
        dispatch(getModelDetailList(brandQ))
      }
    }
  }, [
    loadingBrand,
    successModelUpdate,
    successModelCreate,
    deleteModelSuccess,
    successYearCreate,
    successYearRangeCreate,
    successYearUpdate,
    successGenerationDelete,
    successTrimDelete,
    successTrimCreate,
    brand,
    brandQ,
  ])

  const onStatusChange = async (status, modelInfo, index) => {
    setIsLoading(true)
    let shadow = modelList
    shadow[index].active = !modelInfo.active
    await Axios.patch(`/v1/models/status`, {
      modelId: modelInfo.model._id,
      status: shadow[index].active,
    })
    setModelList([...shadow])
    setIsLoading(false)
  }
  return (
    <div className="model-list">
      <div className="r2 table-container">
        <table className="table">
          <thead>
            <tr className="table-header row">
              <th>
                {/* <input type="checkbox" className="checkbox" name="" id="" /> */}
              </th>
              <th>Name</th>
              <th>Years</th>
              <th></th>
              <th>Generation</th>
              <th>Trims</th>
              <th>Body Type</th>
              <th></th>
              <th>Issues</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loadingModel && brand?._id ? (
              <tr className="row">
                <td colspan="12">
                  <LinearProgress />
                </td>
              </tr>
            ) : modelList.length > 0 ? (
              modelList.map((modelInfo, index) => (
                <tr className="row" key={modelInfo.model._id}>
                  <td>
                    <input type="checkbox" className="checkbox" name="" id="" />
                  </td>
                  <td
                    className="text link"
                    onClick={() => {
                      props.openSlider(
                        "year",
                        `Edit ${modelInfo.model.name}`,
                        modelInfo
                      )
                      setSearchParams({
                        brand: modelInfo.model.brand,
                        model: modelInfo.model._id,
                      })
                    }}
                  >
                    {modelInfo.model.name}
                  </td>
                  <td
                    className="text"
                    colspan="2"
                    style={{ wordWrap: "break-word", paddingRight: "20px" }}
                  >
                    {setYear(yearFull[index]).map((year, i) => (
                      <span>
                        {`${year}`}
                        {i === setYear(yearFull[index]).length - 1 ? `` : `, `}
                      </span>
                    ))}
                  </td>
                  <td className="text">{modelInfo.generation}</td>
                  <td className="text">{modelInfo.trims}</td>
                  <td className="text">{modelInfo.model.bodyType}</td>
                  <td className="switch">
                    <Tooltip
                      title={
                        isLoading ? (
                          <CircularProgress color="inherit" />
                        ) : modelInfo.active ? (
                          "Disable"
                        ) : (
                          "Enable"
                        )
                      }
                      arrow
                      placement="right"
                    >
                      <Button>
                        <CustomSwitch
                          updateStatus={(status) =>
                            onStatusChange(status, modelInfo, index)
                          }
                          checked={modelInfo.active}
                        />
                      </Button>
                    </Tooltip>
                  </td>
                  <td></td>
                  <td>
                    <img
                      src={actionPencil}
                      className="icon"
                      alt="pencil"
                      onClick={() => {
                        props.openSlider(
                          "year",
                          `Edit ${modelInfo.model.name}`,
                          modelInfo
                        )
                        setSearchParams({
                          brand: modelInfo.model.brand,
                          model: modelInfo.model._id,
                        })
                      }}
                    />
                    <img
                      src={actionBin}
                      className="icon"
                      onClick={() => {
                        deleteModelHandler(modelInfo.model._id)
                      }}
                      alt="bin"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="row">
                <td colspan="12" className="message">
                  <h2>No data to display</h2>
                  <br />
                  {brand?._id ? (
                    <p>There are no models in the selected brand</p>
                  ) : (
                    <p>Please select brand to view models</p>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="r1 flex jcsb">
          {modelList.length > 0 ? (
            <>
              <div className="c2">
                <IconButton size="large" className="icon-button active mr-5">
                  10
                </IconButton>
                <IconButton size="large" className="icon-button mr-5">
                  20
                </IconButton>
                <IconButton size="large" className="icon-button">
                  40
                </IconButton>
              </div>
              <div
                className="c1"
                style={{ fontSize: "11px", fontWeight: "600" }}
              >
                <p style={{ paddingTop: "11px", paddingLeft: "700px" }}>
                  page 1 of 1 ({modelList.length} total)
                </p>
              </div>
              <div className="c1">
                <Pagination count={pages} defaultPage={page} />
              </div>
            </>
          ) : (
            ""
          )}
        </div> */}
      </div>
    </div>
  )
}

export default ModelList
