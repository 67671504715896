import {
  GENERATION_DELETE_FAIL,
  GENERATION_DELETE_REQUEST,
  GENERATION_DELETE_SUCCESS,
  GENERATION_DETAIL_FAIL,
  GENERATION_DETAIL_REQUEST,
  GENERATION_DETAIL_SUCCESS,
  GENERATION_LIST_FAIL,
  GENERATION_LIST_REQUEST,
  GENERATION_LIST_SUCCESS,
} from "../constants/generationConstants"
import Axios from "../../utils/axios"

export const getGenerationList = (modelId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GENERATION_LIST_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/generations/model/${modelId}`, config)
    dispatch({
      type: GENERATION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GENERATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteGeneration = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GENERATION_DELETE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.delete(`/v1/generations/${id}`, config)
    dispatch({
      type: GENERATION_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: GENERATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getGenerationDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GENERATION_DETAIL_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/generations/${id}`, config)
    dispatch({
      type: GENERATION_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GENERATION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
