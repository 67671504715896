import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import Item from "./Item"
import download from "../../../images/common/download.png"
import binwhite from "../../../images/dashboard/carsmpscreens/cardetail/binwhite.svg"
import eye from "../../../images/dashboard/carsmpscreens/cardetail/eye.svg"
import "./SortableItem.scss"

const SortableItem = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  }

  return (
    <div className="overlay" key={props.image}>
      <Item
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        {...props}
        {...attributes}
        {...listeners}
      />

      {!props.disableControl ? (
        <div className="overlay-icon-wrapper">
          <img
            src={binwhite}
            alt=""
            className="overlay-icon"
            onClick={(e) => props.imageDeleteHandler(props.index)}
          />

          <img
            src={download}
            alt=""
            className="overlay-icon"
            onClick={(e) => props.downloadOriginalImageHandler(props.index)}
          />
          <img
            src={eye}
            alt=""
            className="overlay-icon"
            onClick={(e) => props.viewImageHandler(props.image)}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SortableItem
