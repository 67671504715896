import { useSelector } from "react-redux"

const useTrimCreate = () => {
  const trimCreate = useSelector((state) => state.carCatalogue.trimCreate)
  const {
    loading: loadingTrimCreate,
    error: errorTrimCreate,
    success: successTrimCreate,
    trim: trimCreated,
  } = trimCreate

  return [loadingTrimCreate, errorTrimCreate, successTrimCreate, trimCreated]
}

export default useTrimCreate
