import { Button } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import "./UserCard.scss"
import { useDispatch } from "react-redux"
import { deleteUser, updateUser } from "../../state/actions/userActions"
import { updateSeller } from "../../state/actions/sellerActions"
import { SellerContext } from "../../screens/sellerscreens/SellerDetailScreen"

const UserCard = ({ item, primaryUser, userEditHandler, detailOnly }) => {
  const user = item
  const { sellerBuffer: seller, sellerType } = useContext(SellerContext)
  const [image, setImage] = useState("")
  const initials = user?.firstName?.charAt(0) + user?.lastName?.charAt(0)
  const uploadFileHandler = async (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setImage(user.profileImage)
  }, [user])

  useEffect(() => {
    if (image && image !== user.profileImage) {
      dispatch(updateUser(user._id, { profileImage: image }))
    }
  }, [image])

  const setPrimaryHandler = () => {
    dispatch(updateSeller(sellerType, seller._id, { _primaryUser: user._id }))
  }

  const userDeleteHandler = () => {
    dispatch(deleteUser(user._id))
  }

  return (
    <div className="user-card flex">
      <div className="user-card-col-1">
        <div className="profile-image">
          {image ? (
            <img src={image} className="image" alt="Brand" />
          ) : (
            <p className="text-bold">{initials?.toUpperCase()}</p>
          )}
          <input
            type="file"
            name="image-upload"
            id="input"
            className="custom-file-input"
            accept="image/*"
            value=""
            onChange={(e) => {
              uploadFileHandler(e)
            }}
          />
        </div>
        <div className="user-detail">
          <div className="name-wrapper flex aic">
            <h5 className="title">
              {user.firstName} {user.lastName}
            </h5>
            {primaryUser === user._id && <p className="link">Primary</p>}
          </div>
          <div className="text">{`Username: ${user.username}`}</div>

          {user.contacts.length > 0 && (
            <div className="span-container">
              {user.contacts.map((contact, i) => (
                <span className="contact-text" key={i}>
                  {contact.phoneNumber} {contact.telecom}{" "}
                  {user.contacts.length - 1 !== i && "/"}
                </span>
              ))}
            </div>
          )}
          {user.languages.length > 0 && (
            <div className="span-container">
              {user.languages.map((language, i) => (
                <span className="contact-text" key={i}>
                  {(language === "kh" && "Khmer") ||
                    (language === "en" && "English") ||
                    (language === "zh" && "Chinese") ||
                    (language === "ko" && "Korean") ||
                    (language === "ja" && "Japanese")}
                  {user.languages.length - 1 !== i && `, `}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="user-card-col-2">
        <div className="text">
          <p className="text-light">{`user Id # ${user.carmateUserId}`}</p>
          <p className="text-light">
            {user.status === "ACTIVE" ? "Active" : "Suspended"}
          </p>
        </div>
        {!detailOnly && (
          <div className="flex jce">
            {!primaryUser?._individual && primaryUser !== user._id && (
              <Button className="button link" onClick={setPrimaryHandler}>
                Set Primary
              </Button>
            )}
            <Button
              className="button link"
              onClick={(e) => userEditHandler(user)}
            >
              EDIT
            </Button>
            {!primaryUser?._individual && primaryUser !== user._id && (
              <Button
                className="button link"
                onClick={userDeleteHandler}
                disabled={primaryUser === user._id}
              >
                Remove
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default UserCard
