import React, { useEffect, useState } from "react"
import "./CarList.scss"
import { Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getCarList } from "../../state/actions/carMpActions"
import CarCard from "../carsmpscreens/CarCard"
import Loader from "../utils/Loader"
import Message from "../utils/Message"

const CarList = ({ seller, sellerType }) => {
  const [activeTab, setActiveTab] = useState("Pending")

  const carList = useSelector((state) => state.carMarketplace.carList)

  const { cars, loading, pageNumber, error, count } = carList

  const carUpdate = useSelector((state) => state.carMarketplace.carUpdate)

  const { success } = carUpdate

  const dispatch = useDispatch()

  useEffect(() => {
    if (sellerType && seller._id) {
      dispatch(
        getCarList({
          status: activeTab,
          pageNumber: pageNumber || 1,
          type: sellerType,
          id: seller._id,
        })
      )
    }
  }, [dispatch, activeTab, pageNumber, success, seller._id])

  return (
    <div className="car-list">
      <div className="body-container">
        <div className="tabs flex">
          <Button
            className={activeTab === "Pending" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Pending")
            }}
          >
            PENDING({count?.pendingCars || "0"})
          </Button>
          <Button
            className={activeTab === "Listed" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Listed")
            }}
          >
            LISTED({count?.listedCars || "0"})
          </Button>
          <Button
            className={activeTab === "Sold" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Sold")
            }}
          >
            Sold({count?.soldCars || "0"})
          </Button>
          <Button
            className={activeTab === "Archived" ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab("Archived")
            }}
          >
            Archived({count?.archivedCars || "0"})
          </Button>
        </div>
        <div className="body-wrapper">
          <p className="sort-button link">Sort Date Added(Newest)</p>
          <div className="list">
            {loading ? (
              <Loader />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              cars.map((car, i) => <CarCard car={car} detailOnly key={i} />)
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarList
