import React from "react"
import CustomPopup from "../utils/CustomPopup"
import Checkbox from "@mui/material/Checkbox"
import CarCard from "../carsmpscreens/CarCard"
import TextInput from "../input/TextInput"
import "./CarMultiSelectModal.scss"

const CarMultiSelectModal = ({
  isOpen,
  setIsOpen,
  cars,
  keyword,
  setKeyword,
  carsSelected,
  setCarsSelected,
  sale,
  setSale,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } }
  return (
    <div className="multi-select-car-list">
      <CustomPopup trigger={isOpen} setTrigger={setIsOpen}>
        <TextInput
          className="search-bar"
          placeholder="Search"
          value={keyword}
          setValue={setKeyword}
        />
        <div className="car-list">
          {cars?.map((car) => (
            <div className="car-card-wrapper flex aic" key={car._id}>
              <Checkbox
                {...label}
                defaultChecked={carsSelected?.find((o) => o._id === car._id)}
                onChange={(e) => {
                  // add to list
                  if (e.target.checked) {
                    setCarsSelected([...carsSelected, { ...car, id: car._id }])
                    setSale((prev) => {
                      return {
                        ...prev,
                        cars: prev?.cars?.length
                          ? [...prev.cars, car._id]
                          : [car._id],
                      }
                    })
                  } else {
                    // remove from list
                    setCarsSelected(
                      carsSelected.filter((o) => o._id !== car._id)
                    )
                    setSale((prev) => {
                      return {
                        ...prev,
                        cars: sale.cars?.filter((o) => o !== car._id),
                      }
                    })
                  }
                }}
              />
              <CarCard car={car} detailOnly />
            </div>
          ))}
        </div>
      </CustomPopup>
    </div>
  )
}

export default CarMultiSelectModal
