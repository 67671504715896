import { Button } from "@mui/material"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./CarBrandModelYearEditPopup.scss"
import { getBrandList } from "../../../state/actions/brandActions"
import { getModelList } from "../../../state/actions/modelActions"
import _ from "lodash"

const CarBrandModelYearEditPopup = ({
  trigger,
  setTrigger,
  brand,
  setBrand,

  model,

  setYear,
  otherYear,
}) => {
  const dispatch = useDispatch()

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading: loadingBrands, brands } = brandList

  const modelList = useSelector((state) => state.carCatalogue.modelList)
  const { loading: loadingModels, models } = modelList

  const yearsList = _.range(new Date().getFullYear(), 1989).map((item) =>
    item.toString()
  )

  useEffect(() => {
    if (brand !== null && brand !== undefined && brand !== "") {
      dispatch(getModelList(brand))
    }
    if (!brands) {
      dispatch(getBrandList())
    }
  }, [brand])

  return trigger ? (
    <div className="popup-car-info">
      <div className="popup-inner">
        <div className="popup-header flex jcsb"></div>
        <div className="popup-body">
          <label className="label">Brand</label>
          <select
            className="status-select"
            value={brand}
            onChange={(e) => {
              setBrand(e.target.vaue)
            }}
          >
            <option value="">-</option>
            {!loadingBrands &&
              brands.popularBrands.map((brand, i) => (
                <option value={brand._id} key={i}>
                  {brand.name}
                </option>
              ))}
            {!loadingBrands &&
              brands.otherBrands.map((brand, i) => (
                <option value={brand._id} key={i}>
                  {brand.name}
                </option>
              ))}
          </select>
          <label className="label">Model</label>
          <select
            className="status-select"
            value={model}
            onChange={(e) => {
              setBrand(e.target.vaue)
            }}
          >
            <option value="-">-</option>
            {!loadingModels &&
              models.map((model, i) => (
                <option value={model._id} key={i}>
                  {model.name}
                </option>
              ))}
          </select>
          <label className="label">Year</label>
          <select
            name="year"
            className="status-select"
            value={otherYear}
            onChange={(e) => {
              setYear(e.target.value)
            }}
          >
            <option value="">-</option>
            {yearsList.map((year, i) => (
              <option value={year} key={i}>
                {year}
              </option>
            ))}
          </select>
          <div className="status-button-wrapper">
            <Button
              className="btn light"
              onClick={() => {
                setTrigger(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn primary"
              onClick={() => {
                setTrigger(false)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default CarBrandModelYearEditPopup
