import React from "react"
import "./SellerCard.scss"
import { Link } from "react-router-dom"

const SellerCard = ({ seller, sellerType }) => {
  return (
    <Link to={`/seller/${sellerType}/${seller._id}/show`}>
      <div className="seller-card">{seller.name}</div>
    </Link>
  )
}

export default SellerCard
