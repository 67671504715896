import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { Typography } from "@mui/material"
import "./Switch.scss"

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14.55,
    height: 14.55,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}))
export default function CustomSwitch({
  updateStatus,
  checked,
  label,
  onChange,
  group,
  index,
  disabled,
}) {
  const [status, setStatus] = useState(true)
  const changeHandler = (e) => {
    setStatus(!status)
    updateStatus && updateStatus(status, group, index)
    onChange && onChange(e, group, index)
  }
  const stopPropagation = (e) => {
    e.stopPropagation()
  }
  return (
    <div className="switch-component" onClick={stopPropagation}>
      <FormGroup>
        {checked ? (
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={checked}
                onChange={changeHandler}
                disabled={disabled}
              />
            }
            label={
              label ? (
                <Typography variant="body2" color="#707779" fontSize="13px">
                  {label}
                </Typography>
              ) : (
                ""
              )
            }
          />
        ) : (
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={false}
                onChange={changeHandler}
                disabled={disabled}
              />
            }
            label={
              label ? (
                <Typography variant="body2" color="#707779" fontSize="13px">
                  {label}
                </Typography>
              ) : (
                ""
              )
            }
          />
        )}
      </FormGroup>
    </div>
  )
}
