import React, { useEffect, useState } from "react"
import "./LinkCard.scss"
import { useNavigate } from "react-router-dom"
import ThreeDotsMenu from "../common/ThreeDotsMenu"
import ConfirmDeletePopup from "../common/ConfirmDeletePopup"
import { deleteLink } from "../../state/actions/linkAction"
import { useDispatch } from "react-redux"
import useLinkDelete from "../../state/customHooks/link/useLinkDelete"
import { baseURLWeb } from "../../utils/base"
import { convertToFormattedDate } from "../../utils/utilsFunctions"

const LinkCard = ({ link, onClick }) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [deletePopup, setDeletePopup] = useState(false)
  const [linkSelected, setLinkSelected] = useState({})

  const [, , success] = useLinkDelete()

  const editAuctionHandler = (link) => {
    navigate(`/link/${link._id}/edit`)
  }

  const deleteHandler = () => {
    dispatch(deleteLink(linkSelected._id))
  }
  const deletePopupHandler = (link) => {
    setLinkSelected(link)
    setDeletePopup(true)
  }

  useEffect(() => {
    if (success) {
      setDeletePopup(false)
    }
  }, [success])

  const menuItem = [
    {
      title: "Edit",
      action: editAuctionHandler,
    },
    {
      title: "Delete",
      action: deletePopupHandler,
    },
  ]
  return (
    <div className="list-card-link">
      <div className="card-content flex jcsb">
        <div className="c1">
          <img src={link.image} alt="" className="card-image" />
        </div>
        <div className="c2">
          <p className="card-title" onClick={onClick}>
            {`${link.name}`}
          </p>
          <div className="flex label-wrapper">
            <p>URL: </p>
            <a
              href={`${baseURLWeb}redirect?to=${link.slug}`}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              {baseURLWeb}redirect?to={link.slug}
            </a>
          </div>

          <div className="flex label-wrapper">
            <p>URL Redirect: </p>
            <a
              href={`${baseURLWeb}redirect?to=${link.slug.slice(1)}`}
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              {link.url}
            </a>
          </div>
        </div>
        <div className="c3">
          <p className="text">
            Created at {convertToFormattedDate(link.createdAt)}
          </p>
          <p className="text">
            Updated at {convertToFormattedDate(link.updatedAt)}
          </p>
        </div>
        <div className="c4">
          <div className="three-dots-button-wrapper"></div>
          <ThreeDotsMenu menuItem={menuItem} data={link} />
        </div>
      </div>
      <ConfirmDeletePopup
        trigger={deletePopup}
        onCancel={() => {
          setDeletePopup(false)
        }}
        title={"Delete Link"}
        onConfirm={deleteHandler}
      />
    </div>
  )
}

export default LinkCard
