import { useEffect, useState } from "react"
import axios from "../../../utils/axios"

export const useRolesList = () => {
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true) // Start loading
      try {
        const response = await axios.get("/v1/roles")
        setRoles(response.data)
      } catch (error) {
        console.error("Error fetching roles:", error)
        setIsLoading(false) // End loading regardless of success or failure
      }
    }

    fetchRoles()
  }, [])

  return [isLoading, roles] // Return loading state
}
