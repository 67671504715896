import React, { useState, useCallback } from "react"
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable"
import Grid from "./Grid"
import SortableItem from "./SortableItem"
import Item from "./Item"
import { saveAs } from "file-saver"

const SortableImages = ({
  imagesURL,
  setImagesURL,
  setImagesURLOriginal,
  images,
  setImages,
  imagesURLOriginal,
  setImagesOriginal,
  viewImageHandler,
  sideDrawer,
  disableControl,
}) => {
  const [activeId, setActiveId] = useState(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id)
  }, [])
  const handleDragEnd = useCallback((event) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setImagesURL((images) => {
        const oldIndex = images.indexOf(active.id)
        const newIndex = images.indexOf(over.id)
        setImagesURLOriginal((images) => {
          setImagesOriginal(arrayMove(images, oldIndex, newIndex))
          return arrayMove(images, oldIndex, newIndex)
        })
        setImages(arrayMove(images, oldIndex, newIndex))
        return arrayMove(images, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }, [])
  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  const imageDeleteHandler = (imageId) => {
    setImages((prev) => prev.filter((image, i) => i !== imageId))
    setImagesURL((prev) => prev.filter((image, i) => i !== imageId))
    setImagesOriginal((prev) => prev.filter((image, i) => i !== imageId))
    setImagesURLOriginal((prev) => prev.filter((image, i) => i !== imageId))
  }
  const downloadOriginalImageHandler = (index) => {
    saveAs(imagesURLOriginal[index], imagesURLOriginal[index].split("/").pop())
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={images} strategy={rectSortingStrategy}>
        <Grid columns={10} sideDrawer={sideDrawer}>
          {imagesURL?.length &&
            imagesURL.map((id, i) => (
              <SortableItem
                key={id}
                id={id}
                image={id}
                imageDeleteHandler={imageDeleteHandler}
                downloadOriginalImageHandler={downloadOriginalImageHandler}
                viewImageHandler={viewImageHandler}
                index={i}
                sideDrawer={sideDrawer}
                disableControl={disableControl}
              />
            ))}
        </Grid>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <Item
            id={activeId}
            isDragging
            image={activeId}
            sideDrawer={sideDrawer}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

export default SortableImages
