import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import actionbin from "../../../../images/dashboard/actionbin.svg"
import "./SortableItem.scss"
import CustomSwitch from "../../../selection/Switch"

export function SortableItem({
  link,
  removeVideoLinkHandler,
  id,
  onChange,
  index,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="flex aic">
        <div dangerouslySetInnerHTML={{ __html: link.embed }}></div>
        <p className="videoId">{link.id}</p>
        <CustomSwitch
          label="Featured"
          checked={link.isFeatured}
          onChange={(e) => onChange(e, index)}
        />
        <img
          src={actionbin}
          className="remove-icon"
          alt="remove"
          onClick={() => removeVideoLinkHandler(link)}
        />
      </div>
    </div>
  )
}
