import React from "react"
import OpportunityCard from "../opportunityscreens/OpportunityCard"
import Loader from "../utils/Loader"
import "./UserOpportunitiesCard.scss"

const UserOpportunitiesCard = ({
  loading,
  opportunities,
  setOpportunitySelect,
}) => {
  return (
    <div className="tab-wrapper">
      {loading ? (
        <Loader />
      ) : opportunities?.length > 0 ? (
        opportunities.map((opportunity) => (
          <OpportunityCard
            opportunity={opportunity}
            setOpportunitySelect={setOpportunitySelect}
            screen="user-detail"
          />
        ))
      ) : null}
    </div>
  )
}

export default UserOpportunitiesCard
