import React, { useState } from "react"
import axios from "../../utils/axios"
import { TextField, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { CustomButton } from "../utils/Button"

const PermissionForm = ({ open, onClose }) => {
  const [resource, setResource] = useState("")
  const [actions, setActions] = useState({
    create: false,
    read: false,
    update: false,
    delete: false,
  })

  const handleResourceChange = (e) => {
    setResource(e.target.value)
  }

  const handleActionChange = (action) => (e) => {
    setActions({ ...actions, [action]: e.target.checked })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Extract selected actions
    const selectedActions = Object.keys(actions).filter(
      (action) => actions[action]
    )

    try {
      await axios.post("/v1/permissions", {
        resource,
        actions: selectedActions,
      })
      onClose() // Close drawer after successful submission
      setResource("")
      setActions({
        create: false,
        read: false,
        update: false,
        delete: false,
      })
    } catch (error) {
      console.error("Error creating permission:", error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Resource"
        value={resource}
        onChange={handleResourceChange}
        fullWidth
        required
        margin="normal"
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={actions.create}
              onChange={handleActionChange("create")}
            />
          }
          label="Create"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={actions.read}
              onChange={handleActionChange("read")}
            />
          }
          label="Read"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={actions.update}
              onChange={handleActionChange("update")}
            />
          }
          label="Update"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={actions.delete}
              onChange={handleActionChange("delete")}
            />
          }
          label="Delete"
        />
      </FormGroup>

      <CustomButton label={"Create"} type="submit" drawer />
    </form>
  )
}

export default PermissionForm
