import { Button } from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import UserOpportunitiesCard from "./UserOpportunitiesCard"
import "./UserDetailTabs.scss"
import Loader from "../utils/Loader"

const UserDetailTabs = ({ setOpportunitySelect }) => {
  const opportunityList = useSelector(
    (state) => state.opportunity.opportunityList
  )
  const {
    opportunities,
    loading: loadingOpportunities,
    count,
  } = opportunityList

  const [firstTab, setFirstTab] = useState(true)
  const [secondTab, setSecondTab] = useState(false)

  const tabClickHandler = (click) => {
    if (click === "FirstTab") {
      setFirstTab(true)
      setSecondTab(false)
    } else if (click === "SecondTab") {
      setFirstTab(false)
      setSecondTab(true)
    }
  }

  return (
    <div className="user-detail-tabs">
      <div className="user-detail-tabs-header-wrapper flex">
        <Button
          onClick={() => {
            tabClickHandler("FirstTab")
          }}
          className={firstTab ? "tab-head active" : "tab-head"}
        >
          {loadingOpportunities ? (
            <Loader />
          ) : (
            <div className="block">
              <span className="title">Opportunites</span> <br />
              Active:{count.activeOpportunites}/ Pending:{" "}
              {count.pedingOpportunites}/ Closed: {count.closedOpportunites}
            </div>
          )}
        </Button>
        <Button
          onClick={() => {
            tabClickHandler("SecondTab")
          }}
          className={secondTab ? "tab-head active" : "tab-head"}
        >
          Purchased
        </Button>
      </div>

      {firstTab && (
        <UserOpportunitiesCard
          opportunities={opportunities}
          setOpportunitySelect={setOpportunitySelect}
          loading={loadingOpportunities}
        />
      )}
      {secondTab && <div></div>}
    </div>
  )
}

export default UserDetailTabs
