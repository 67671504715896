import React from "react"
import "./ImageInput.scss"
import bin from "../../images/common/bin.svg"

const ImageInput = ({ image, setImage, label }) => {
  const uploadFileHandler = async (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const deleteImage = () => {
    setImage("")
  }
  return (
    <div className="image-input">
      <p className="label">{label}</p>
      <div className="input-wrapper-image flex col">
        <div className="image-container">
          {image ? (
            <img src={image} className="brand-image" alt="Brand" />
          ) : (
            <p>Drag and drop image or select image</p>
          )}
        </div>

        <input
          type="file"
          name="image-upload"
          id="input"
          className="custom-file-input"
          accept="image/*"
          value=""
          onChange={(e) => {
            uploadFileHandler(e)
          }}
        />
      </div>
      {image && image !== "" ? (
        <div className="delete-cotainer flex">
          <p className="image-name">{image.split("/images/")[1]}</p>
          <img
            src={bin}
            className="icon-image"
            onClick={deleteImage}
            alt="Delete"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ImageInput
