import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { getSellerList } from "../../actions/sellerActions"
import { getSellerSpecificList } from "../../actions/sellerSpecificActions"
import useSellerUpdate from "./useSellerUpdate"
import useSellerDelete from "./useSellerDelete"
import { SELLER_LIST_RESET } from "../../constants/sellerContants"

export const useSeller = (
  sellerType,
  pageNumber,
  filterStatus = "ACTIVE",
  keyword = "",
  tagsFilter,
  trigger
) => {
  const [timeOutId, setTimeOutId] = useState(null)
  const [, setSearchParams] = useSearchParams()

  const [, , successSellerUpdate, ,] = useSellerUpdate()
  const [successSellerDelete] = useSellerDelete()

  const dispatch = useDispatch()

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          dispatch(
            getSellerList(
              sellerType.type,
              keyword ? 1 : pageNumber,
              filterStatus,
              keyword,
              "",
              tagsFilter
            )
          )
        },
        keyword ? 500 : 0
      )
    )
    setSearchParams({
      sellerType: sellerType.type,
      keyword,
      pageNumber,
      status: filterStatus,
      tags: tagsFilter,
    })
    // eslint-disable-next-line
  }, [
    keyword,
    pageNumber,
    filterStatus,
    tagsFilter,
    sellerType,
    dispatch,
    successSellerUpdate,
    successSellerDelete,
  ])

  const sellerList = useSelector((state) => state.seller.sellerList)
  const { sellers, loading, page, pages, count, allSellers } = sellerList

  return [sellers, loading, page, pages, count, allSellers]
}

export const useSellerList = (sellerType, keyword = "") => {
  const [timeOutId, setTimeOutId] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          if (sellerType && keyword) {
            dispatch(getSellerList(sellerType, "", "", keyword, "", ""))
          }
        },
        keyword ? 500 : 0
      )
    )
    if (keyword === "") {
      dispatch({ type: SELLER_LIST_RESET })
    }

    // eslint-disable-next-line
  }, [keyword, sellerType, dispatch])

  const sellerList = useSelector((state) => state.seller.sellerList)
  const { sellers } = sellerList

  return [sellers]
}

export const useSellerSpecificList = (sellerType, parentSeller, keyword) => {
  const dispatch = useDispatch()

  useEffect(() => {
    sellerType &&
      parentSeller &&
      dispatch(getSellerSpecificList(sellerType, parentSeller, keyword))

    // eslint-disable-next-line
  }, [dispatch, sellerType, parentSeller])

  const sellerSpecificList = useSelector(
    (state) => state.seller.sellerSpecificList
  )
  const { sellers } = sellerSpecificList

  return [sellers]
}
export const useSellerSpecificListKeyword = (
  sellerType,
  parentSeller,
  keyword
) => {
  const [timeOutId, setTimeOutId] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          sellerType &&
            parentSeller &&
            keyword &&
            dispatch(getSellerSpecificList(sellerType, parentSeller, keyword))
        },
        keyword ? 500 : 0
      )
    )
    // eslint-disable-next-line
  }, [dispatch, sellerType, parentSeller, keyword])

  const sellerSpecificList = useSelector(
    (state) => state.seller.sellerSpecificList
  )
  const { sellers } = sellerSpecificList

  return [sellers]
}

export const useOfficialBrandList = (sellerType) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSellerList("officialBrands", 1, "ACTIVE", "", "", []))

    // eslint-disable-next-line
  }, [sellerType])

  const sellerList = useSelector((state) => state.seller.sellerList)
  const { sellers, loading, page, pages, count, allSellers } = sellerList

  return [sellers, loading, page, pages, count, allSellers]
}
