import React from "react"
import { useDroppable } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import SortableItem from "./SortableItem"
import plus from "../../images/common/plus.svg"
import actionbin from "../../images/dashboard/actionbin.svg"
import actionpencil from "../../images/dashboard/actionpencil.svg"
import chevronup from "../../images/common/chevronup.svg"
import chevrondown from "../../images/common/chevrondown.svg"
import Select from "../input/Select"
import "./Container.scss"

export default function Container(props) {
  const {
    id,
    items,
    group,
    addFieldHandler,
    i,
    editGroupHandler,
    deleteGroupHandler,
    expandedGroups,
    toggleGroup,
    updateFieldNameHandler,
    updateTypeHandler,
    updateBolValHandler,
    updateValueHandlerArray,
    inputRef,
    handleIndexChange,
    options,
  } = props

  const { setNodeRef } = useDroppable({
    id,
  })

  const handleIndexChangeInner = (id, event) => {
    handleIndexChange(id, parseInt(event.target.value, 10) - 1) // Update index
  }

  return (
    <div className="container-wrapper flex">
      <Select
        options={options}
        value={i + 1}
        onChange={(e) => {
          handleIndexChangeInner(group.name, e)
        }}
        className="isolate"
      />
      <SortableContext
        id={id}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef}>
          <div className="group-wrapper" key={group.name}>
            <div className="group-header-wrapper">
              <div className="title-wrapper">
                <p className="sub-title">{group.name}</p>
                <div className="icon-wrapper">
                  <img src={plus} alt="" className="icon" />
                  <p className="link" onClick={() => addFieldHandler(i)}>
                    Add Field
                  </p>
                </div>
              </div>
              <div className="icons-wrapper">
                <div className="action-icons">
                  <img
                    src={actionpencil}
                    alt=""
                    onClick={() => editGroupHandler(i, group.name)}
                    className="icon"
                  />
                  <img
                    src={actionbin}
                    alt=""
                    onClick={() => deleteGroupHandler(i, group.name)}
                    className="icon"
                  />
                </div>
                <div className="chevron-wrapper">
                  <img
                    src={
                      expandedGroups.includes(group.name)
                        ? chevronup
                        : chevrondown
                    }
                    alt=""
                    className="icon"
                    onClick={() => toggleGroup(group.name)}
                  />
                </div>
              </div>
            </div>
            {expandedGroups.includes(group.name) && (
              <div className="group-body-wrapper">
                <div>
                  {items?.length &&
                    items?.map((id, j) => (
                      <SortableItem
                        key={id.fieldName}
                        id={id.fieldName}
                        i={i}
                        j={j}
                        updateFieldNameHandler={updateFieldNameHandler}
                        updateTypeHandler={updateTypeHandler}
                        updateValueHandlerArray={updateValueHandlerArray}
                        updateBolValHandler={updateBolValHandler}
                        field={id}
                        inputRef={inputRef}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </SortableContext>
    </div>
  )
}
