import { useSelector } from "react-redux"

const useTrimUpdate = () => {
  const trimUpdate = useSelector((state) => state.carCatalogue.trimUpdate)
  const {
    loading: loadingTrimUpdate,

    error: errorTrimUpdate,
    success: successTrimUpdate,
    trim: updatedTrim,
  } = trimUpdate

  return [loadingTrimUpdate, errorTrimUpdate, successTrimUpdate, updatedTrim]
}

export default useTrimUpdate
