import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSellerDetail } from "../../actions/sellerActions"
import { SELLER_DETAIL_RESET } from "../../constants/sellerContants"

const useSellerDetail = (sellerType, id) => {
  const dispatch = useDispatch()

  const sellerDetail = useSelector((state) => state.seller.sellerDetail)
  const {
    loading: loadingSellerDetail,
    error: errorSellerDetail,
    seller,
    analytics,
    users,
    parentUsers,
  } = sellerDetail

  useEffect(() => {
    dispatch({ type: SELLER_DETAIL_RESET })
    id && dispatch(getSellerDetail(sellerType, id))
  }, [dispatch, id, sellerType])

  return [
    loadingSellerDetail,
    errorSellerDetail,
    seller,
    analytics,
    users,
    parentUsers,
  ]
}

export default useSellerDetail
