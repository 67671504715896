import React, { useState } from "react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import "./Dropdown.scss"

import dropdownarrow from "../../images/header/dropdownarrow.svg"
import { useDispatch } from "react-redux"
import { logout } from "../../state/actions/userActions"

const DropDownMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const logOutHandler = () => {
    dispatch(logout())
  }

  return (
    <div className="dropdown-menu">
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="dropdown"
      >
        {user.firstName}
        <img src={dropdownarrow} alt="icon" className="arrow" />
        {/* <img
          src={user.profileImage}
          alt="profile-img"
          className="profile-image"
        /> */}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            logOutHandler()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}

export default DropDownMenu
