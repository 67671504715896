import "./Forms.scss"
import React, { useEffect, useState } from "react"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import Empty from "../../images/dashboard/imageph.svg"
import Delete from "../../assets/img/Delete.png"
import Axios from "../../utils/axios"
import { config } from "../../utils/globals"

export default function GenerationForm(props) {
  const [fields, setFields] = useState({
    generation: "",
    image: "",
    oldImage: "",
    id: "",
  })

  const [error, setError] = useState(false)
  const [show, setShow] = useState(false)
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (props.propsData) {
      setFields({
        ...fields,
        generation: props.propsData.name,
        image: props.propsData.image,
        id: props.propsData._id,
      })
    }
  }, [props.propsData])

  const handleChange = (e) => {
    if (e.target.value) {
      setError(false)
    }
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const uploadFileHandler = async (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setFields({
          ...fields,
          image: reader.result,
        })
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const deleteImage = () => {
    let img = fields.image
    setFields({
      ...fields,
      image: "",
      oldImage: img,
    })
  }
  const submitGeneration = async () => {
    if (fields.generation) {
      setLoader(true)
      const conf = config()
      let params = {
        name: fields.generation,
        image: fields.image,
        oldImage: fields.oldImage,
        brand: props.data.model.brand,
        model: props.data.model._id,
        id: fields.id,
      }
      let response = {}

      if (fields.id) {
        response = await Axios.put(`/v1/generations/${fields.id}`, params, conf)
      } else {
        response = await Axios.post(`/v1/generations`, params, conf)
      }
      const data = response.data

      if (typeof data.message === "undefined") {
        if (typeof data.name !== "undefined") {
          setShow(true)
          if (fields.id === "" || typeof fields.id === "undefined") {
            setFields({
              ...fields,
              generation: "",
              image: "",
            })
          }
          setTimeout(() => {
            setShow(false)
          }, 5000)
        }
      } else {
        setError(true)
        setMessage(data.message)
      }
      setLoader(false)
    } else {
      setError(true)
    }
  }

  return (
    <div className="forms-wrapper">
      <div className="form-group">
        <FormControl fullWidth>
          <label id="demo-simple-select-label" className="pb-18">
            Generation
          </label>
          <TextField
            placeholder="Generation"
            name="generation"
            value={fields.generation}
            onChange={handleChange}
            error={error}
            helperText={
              error ? (message ? message : "Generation name is required") : ""
            }
          />
        </FormControl>
      </div>
      <div className="form-group pt-17 input-wrapper flex col">
        <div className="flex col c3">
          <div className="input-wrapper-image flex col">
            <img
              src={fields.image ? fields.image : Empty}
              className="brand-image"
              alt="Brand"
            />
            <input
              type="file"
              name="image-upload"
              id="input"
              className="custom-file-input"
              accept="image/*"
              value=""
              onChange={(e) => {
                uploadFileHandler(e)
              }}
            />
            {fields.image ? (
              <img
                src={Delete}
                className="icon-image"
                onClick={deleteImage}
                alt="Delete"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="r2 flex">
        {show ? (
          <Alert severity="success" color="info" onClose={() => setShow(false)}>
            Generation {fields.id ? "updated" : "created"} sucessfully!
          </Alert>
        ) : (
          ""
        )}
        <Button className="bg-green" onClick={submitGeneration}>
          {loader ? <CircularProgress color="inherit" /> : "SAVE"}
        </Button>
      </div>
    </div>
  )
}
