import React, { useState } from "react"
import { useNavigate } from "react-router"

import "./SideDrawer.scss"

const SideDrawer = ({ closeDrawer }) => {
  const [active, setActive] = useState("carct")
  const navigate = useNavigate()

  const navigationHandler = (location) => {
    navigate(location)
  }

  return (
    <nav className="side-drawer">
      <div className="profile-wrapper"></div>
      <ul>
        <li
          className={active === "Dashboard" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("Dashboard")
            navigationHandler("/dashboard")
            closeDrawer()
          }}
        >
          Dashboard
        </li>
        <li
          className={active === "carmp" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("carmp")
            navigationHandler("/car/list")
            closeDrawer()
          }}
        >
          Cars
        </li>
        <li
          className={active === "seller" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("seller")
            navigationHandler(
              "/seller/list?sellerType=dealerships&keyword=&tags=&status=ACTIVE&pageNumber=1"
            )
            closeDrawer()
          }}
        >
          Dealerships
        </li>
        <li
          className={active === "users" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("users")
            navigationHandler("/user/list")
            closeDrawer()
          }}
        >
          Users
        </li>
        <li
          className={active === "checklist" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("checklist")
            navigationHandler("/")
            closeDrawer()
          }}
        >
          Tasks
        </li>
        <li
          className={
            active === "opportunity" ? "list-item active" : "list-item"
          }
          onClick={() => {
            setActive("opportunity")
            navigationHandler("/opportunity/list")
            closeDrawer()
          }}
        >
          Opportunities
        </li>
        <li
          className={active === "events" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("events")
            navigationHandler("/events")
            closeDrawer()
          }}
        >
          Events
        </li>
        <li
          className={active === "databse" ? "list-item active" : "list-item"}
          onClick={() => {
            setActive("databse")
            navigationHandler("/catalogue")
            closeDrawer()
          }}
        >
          Database
        </li>
      </ul>
    </nav>
  )
}

export default SideDrawer
