import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import "./TextPopup.scss"
import TextInput from "./TextInput"

const TextPopup = ({
  trigger,
  setTrigger,
  value,
  setValue,
  options,
  title,
  name,
}) => {
  const [innerValue, setInnerValue] = useState(value)

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  return trigger ? (
    <div className="popup-text">
      <div className="popup-inner">
        <div className="popup-header flex jcsb">
          <div className="title">{title}</div>
        </div>
        <div className="popup-body">
          <div className="text-input">
            <TextInput value={innerValue} setValue={setInnerValue} />
          </div>
          <div className="status-button-wrapper">
            <Button
              className="btn light"
              onClick={() => {
                setTrigger(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn primary"
              onClick={() => {
                setTrigger(false)
                setValue(name, innerValue)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )
}

export default TextPopup
