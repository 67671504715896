import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import InnerHeader from "../../components/header/InnerHeader"
import OpportunityCard from "../../components/opportunityscreens/OpportunityCard"
import InnerContainer from "../../components/utils/InnerContainer"
import { getOpportunityList } from "../../state/actions/opportunityActions"
import searchicon from "../../images/dashboard/carsmpscreens/carlist/search.svg"
import "./OpportunityListScreen.scss"
import { Link, useSearchParams } from "react-router-dom"
import useOpportunity from "../../state/customHooks/useOpportunity"
import LinearProgress from "@mui/material/LinearProgress"

export const status = ["PENDING", "ACTIVE", "CLOSED", "ARCHIVED"]

const OpportunityListScreen = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState("")
  const statusQuery = searchParams.get("status")

  const [
    successCreate,
    successUpdate,
    successDelete,
    loadingList,
    opportunities,
  ] = useOpportunity()

  useEffect(() => {
    statusQuery ? setActiveTab(statusQuery) : setActiveTab("PENDING")
  }, [])

  useEffect(() => {
    activeTab && dispatch(getOpportunityList(activeTab))
    setSearchParams({ status: activeTab })
  }, [dispatch, activeTab, successDelete, successCreate, successUpdate])

  return (
    <div className="opportunity-list-screen">
      <InnerHeader title="Opportunities" className="inner-header">
        <Button className="btn-create-car">
          <Link to="/opportunity/create">Create Opportunity</Link>
        </Button>
      </InnerHeader>
      <InnerContainer>
        <div className="tabs flex">
          {status.map((tab) => (
            <Button
              className={activeTab === tab ? "tab active" : "tab"}
              onClick={() => {
                setActiveTab(tab)
              }}
            >
              {tab}
            </Button>
          ))}
        </div>
        <div className="body-wrapper">
          <div className="input-wrapper flex">
            <img src={searchicon} alt="search-icon" className="search-icon" />
            <input type="text" className="search-bar" placeholder="Search" />
          </div>

          <div className="list">
            {loadingList ? (
              <LinearProgress />
            ) : (
              opportunities?.map((opportunity, i) => (
                <OpportunityCard opportunity={opportunity} key={i} />
              ))
            )}
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default OpportunityListScreen
