import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import sandwich from "../../images/common/sandwich.svg"
import "./SortableItem.scss"
import { Checkbox } from "@mui/material"
import CarCard from "../../components/carsmpscreens/CarCard"

export function SortableItem({
  carsSelected,
  setCarsSelected,
  id,
  car,
  sale,
  setSale,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const label = { inputProps: { "aria-label": "Checkbox demo" } }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="card-wrapper flex aic">
        <img src={sandwich} alt="" />
        <Checkbox
          {...label}
          defaultChecked={carsSelected?.find((o) => o._id === car._id)}
          onChange={(e) => {
            // add to list
            if (e.target.checked) {
              setCarsSelected([...carsSelected, car])
              setSale((prev) => {
                return {
                  ...prev,
                  cars: prev?.cars?.length
                    ? [...prev.cars, car._id]
                    : [car._id],
                }
              })
            } else {
              // remove from list
              setCarsSelected(carsSelected.filter((o) => o._id !== car._id))
              setSale((prev) => {
                return {
                  ...prev,
                  cars: sale.cars?.filter((o) => o !== car._id),
                }
              })
            }
          }}
        />
        <CarCard car={car} detailOnly />
      </div>
    </div>
  )
}
