import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "../../actions/responseAction"
import { RESPONSE_RESET } from "../../constants/responseConstants"
import { AUCTION_DELETE_RESET } from "../../constants/auctionConstants"

const useAuctionDelete = () => {
  const auctionDelete = useSelector((state) => state.auction.auctionDelete)
  const { loading, error, success, auction } = auctionDelete

  const dispatch = useDispatch()

  useEffect(() => {
    if (success || error) {
      success && dispatch(setMessage("Auction Deleted Successfully!"))
      error && dispatch(setMessage(error))
      setTimeout(() => {
        dispatch({ type: AUCTION_DELETE_RESET })
        dispatch({ type: RESPONSE_RESET })
      }, 4000)
    }
  }, [dispatch, success, error])

  return [loading, error, success, auction]
}

export default useAuctionDelete
