import React from "react"
import "./CheckBox.scss"

const CheckBox = ({ checked, onChange, id, label }) => {
  return (
    <div className="checkbox-component flex aic">
      <input type="checkbox" checked={checked} onChange={onChange} id={id} />
      <label htmlFor={id} className="label">
        {" "}
        {label}
      </label>
    </div>
  )
}

export default CheckBox
