import React, { useEffect, useState } from "react"
import imagePlaceHolder from "../../images/dealership/placeholder.png"
import threedots from "../../images/dealership/threedotsblue.svg"
import StatusChangePopup from "../carsmpscreens/StatusChangePopup"
import { Button, LinearProgress } from "@mui/material"
import { Link } from "react-router-dom"
import { BasicPagination } from "../utils/Pagination"
import "./UserListTab.scss"

const UserListTab = ({
  loadingUserList,
  users,
  page,
  pages,
  setPageNumber,
  activeTab,
}) => {
  const [statusPopup, setStatusPopup] = useState(false)
  const [status, setStatus] = useState()
  const [userList, setUserList] = useState([])
  const { dealers, webUsers, adminUsers, individualUsers } = users

  useEffect(() => {
    if (!loadingUserList && dealers?.length > 0) {
      setUserList(dealers)
    } else if (!loadingUserList && webUsers?.length > 0) {
      setUserList(webUsers)
    } else if (!loadingUserList && adminUsers?.length > 0) {
      setUserList(adminUsers)
    } else if (!loadingUserList && individualUsers?.length > 0) {
      setUserList(individualUsers)
    }
  }, [loadingUserList, users])

  return (
    <div className="dealerList">
      <div className="input-wrapper flex aic">
        <input type="text" className="search-bar" placeholder="Search" />
      </div>
      <div className="filter-select">
        <select name="select" className="select" onChange={(e) => {}}>
          <option value="ACTIVE">Active</option>
          <option value="SUSPENDED">Suspended</option>
          <option value="ARCHIVED">Archived</option>
        </select>
      </div>
      <div className="table-wrapper">
        {loadingUserList ? (
          <LinearProgress />
        ) : (
          <table className="table">
            <thead>
              <tr className="table-headers">
                <th></th>
                <th>Name</th>
                <th>Phone Numbers</th>
                <th>Username</th>
                {/* <th>Email</th> */}
                {activeTab === "dealers" && <th>Dealership</th>}
                {activeTab === "webusers" && <th>Login Method</th>}

                <th>Speaks</th>
                {/* <th>Tags</th> */}
                {/* <th>Status</th> */}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {userList?.length > 0
                ? userList.map((user, i) => (
                    <tr className="table-body" key={i}>
                      <td className="image-field">
                        <img
                          src={imagePlaceHolder}
                          alt="placeholder"
                          className="dealership-logo"
                        />
                      </td>
                      <td className="table-field">
                        <Link to={`/user/${user._id}/show`}>
                          {user.firstName} {user.lastName}
                        </Link>
                      </td>
                      <td className="table-field">
                        {user.contacts?.length > 0 &&
                          user.contacts?.map((contact) => (
                            <div>
                              {`${contact.phoneNumber}(${contact?.telecom})`}
                            </div>
                          ))}
                      </td>
                      <td className="table-field">{user.username}</td>
                      {/* <td className="table-field">{user.email}</td> */}
                      {activeTab === "dealers" && (
                        <td className="table-field">
                          {user?._dealership?.name}
                        </td>
                      )}
                      {activeTab === "webusers" && (
                        <td className="table-field">
                          {user?._webUser?.signupMethod}
                        </td>
                      )}

                      <td className="table-field">
                        {user?.languages?.length > 0 &&
                          user.languages.map((language) => (
                            <div>{language}</div>
                          ))}
                      </td>
                      {/* <td className="table-field"></td> */}

                      <td className="table-field">
                        <img
                          src={threedots}
                          alt=""
                          onClick={() => {
                            setStatusPopup(true)
                          }}
                          className="three-dots"
                        />
                      </td>
                    </tr>
                  ))
                : "No Dealerships"}
            </tbody>
          </table>
        )}

        <StatusChangePopup trigger={statusPopup} title="Change Status">
          <select
            className="status-select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value)
            }}
          >
            <option value="ACTIVE">Active</option>
            <option value="SUSPENDED">Suspended</option>
            <option value="ARCHIVED">Archived</option>
          </select>
          <div className="status-button-wrapper">
            <Button
              className="btn light"
              onClick={() => {
                setStatusPopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn primary"
              onClick={() => {
                setStatusPopup(false)
              }}
            >
              Save
            </Button>
          </div>
        </StatusChangePopup>
      </div>
      <BasicPagination
        pageNumber={page}
        setPageNumber={setPageNumber}
        pages={pages}
      />
    </div>
  )
}

export default UserListTab
