import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getUserListByDealership } from "../../actions/userActions"

export const useUserList = (dealershipId) => {
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.users.userList)
  const { users, loading } = userList

  useEffect(() => {
    dealershipId && dispatch(getUserListByDealership(dealershipId))
    // eslint-disable-next-line
  }, [dispatch, dealershipId])

  return [users, loading]
}
