import * as React from "react"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"

export default function AutoCompleteInput({
  label,
  placeholder,
  options,
  defaultValue,
  getOptionLabel,
  onChange,
}) {
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={options.map((option) => option.name)}
      value={defaultValue}
      defaultValue={defaultValue}
      freeSolo
      onChange={(event, newValue) => onChange(newValue)} // Call onChange with the new value
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  )
}
