import React, { useContext, useEffect, useState } from "react"
import imagePlaceHolder from "../../images/dealership/placeholder.png"
import StatusChangePopup from "../carsmpscreens/StatusChangePopup"
import { Button, LinearProgress } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { deleteSeller, updateSeller } from "../../state/actions/sellerActions"
import { BasicPagination } from "../utils/Pagination"
import SellerMap from "./SellerMap"
import "./SellerTab.scss"
import { useWindowSize } from "../utils/customHooks"
import { DropDownMenu } from "../common/DropDownMenu"
import Modal from "../utils/Modal"
import cross from "../../images/dealership/cross.svg"
import plus from "../../images/dealership/plus.svg"
import { SellerListContext } from "../../screens/sellerscreens/SellerListScreen"

const SellerTab = () => {
  const {
    loading,
    sellers,
    pageNumber,
    setPageNumber,
    allSellers,
    sellerType,
    pages,
    keyword,
    setKeyword,
    filterStatus,
    setFilterStatus,
  } = useContext(SellerListContext)

  const [statusPopup, setStatusPopup] = useState(false)
  const [status, setStatus] = useState("ACTIVE")
  const [id, setId] = useState()

  const navigate = useNavigate()

  const [dealershipList, setDealershipList] = useState(true)
  const [dealershipMap, setDealershipMap] = useState(true)
  const [changeTagsModal, setChangeTagsModal] = useState(false)
  const [dealershipTags, setDealershipTags] = useState([""])
  const [newTag, setNewTag] = useState()
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [dealershipDetail, setDealershipDetail] = useState({})
  const [dealershipBuffer, setDealershipBuffer] = useState([])
  const [loadingBuffer, setLoadingBuffer] = useState(true)

  const [value, setValue] = useState({})

  const [pagesTotal, setPagesTotal] = useState(0)

  const dispatch = useDispatch()

  const statusUpdateHandler = () => {
    const dealership = {
      status: status,
    }

    dispatch(updateSeller(sellerType.type, id, dealership))
  }

  const saveTagsHandler = () => {
    const data = {
      tags: dealershipTags,
    }
    dispatch(updateSeller(sellerType.type, id, data))
  }

  const changeStatusHandler = (dealership) => {
    setStatusPopup(true)
    setId(dealership._id)
  }

  const updateHandler = (dealership) => {
    navigate(`/seller/${sellerType.type}/${dealership._id}/edit`)
  }

  const editTagsHandler = (dealership) => {
    setChangeTagsModal(true)
    setDealershipTags(dealership.tags || [])
    setId(dealership._id)
  }

  const deleteTagHandler = (tag) => {
    const newTags = dealershipTags.filter((t) => t !== tag)
    setDealershipTags(newTags)
  }

  const addTagHandler = () => {
    setDealershipTags((prev) => [...prev, newTag])
    setNewTag("")
  }

  const googleMapHandler = (dealership) => {
    window.open(
      `https://maps.google.com?q=${dealership.location.latitude},${dealership.location.longitude}`,
      "_blank"
    )
  }

  const deleteHandler = (dealership) => {
    setConfirmPopup(true)
    setDealershipDetail(dealership)
  }

  const menuItems = [
    {
      title: "Change Status",
      action: changeStatusHandler,
    },
    {
      title: "Edit",
      action: updateHandler,
    },
    {
      title: "Edit Tags",
      action: editTagsHandler,
    },
    {
      title: "Google Map",
      action: googleMapHandler,
    },
    {
      title: "Delete",
      action: deleteHandler,
    },
  ]

  const size = useWindowSize()

  useEffect(() => {
    if (size.width > 600) {
      setDealershipList(true)
      setDealershipMap(true)
    } else if (size.width < 600) {
      setDealershipList(false)
      setDealershipMap(true)
    }
  }, [dispatch, size])

  useEffect(() => {
    if (!loading) {
      setDealershipBuffer(sellers)
      setPagesTotal(pages)
      setLoadingBuffer(false)
    }
  }, [loading])

  return (
    <>
      <div className="sellerList">
        <div className="input-wrapper flex aic">
          <input
            type="text"
            className="search-bar"
            value={keyword}
            placeholder="Search"
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
          />
        </div>
        <div className="filter-select flex aic jcsb">
          <select
            name="select"
            className="select"
            value={filterStatus}
            onChange={(e) => {
              setFilterStatus(e.target.value)
            }}
          >
            <option value="ACTIVE">Active</option>
            <option value="SUSPENDED">Suspended</option>
            <option value="ARCHIVED">Archived</option>
          </select>
          <div className="view-style-buttons-wrapper">
            <button
              onClick={() => {
                setDealershipList(true)
                setDealershipMap(false)
              }}
            >
              List
            </button>
            <button
              onClick={() => {
                setDealershipList(false)
                setDealershipMap(true)
              }}
            >
              Map
            </button>
          </div>
        </div>
        {sellerType.type !== "individuals" ? (
          <div className="flex jcsb">
            {dealershipList && (
              <div
                className={
                  sellerType.type === "dealerships"
                    ? "table-wrapper"
                    : "table-wrapper w100"
                }
              >
                {loadingBuffer ? (
                  <LinearProgress />
                ) : (
                  <table className="table">
                    <thead>
                      <tr className="table-headers">
                        <th className="first-col sticky-col"></th>
                        <th className="second-col sticky-col">Name</th>
                        <th className="third-col">Stage</th>
                        {/* <th>Manager</th> */}
                        {/* <th className="fouth-col">Location</th> */}
                        <th className="fifth-col table-header-cars">Cars</th>
                        <th className="sixth-col">Tasks</th>
                        <th className="seventh-col">Notes</th>
                        <th className="eight-col">Tags</th>
                        <th className="nineth-col"></th>
                        <th className="nineth-col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {dealershipBuffer?.length > 0
                        ? dealershipBuffer?.map((dealership, i) => (
                            <tr className="table-body" key={i}>
                              <td className="first-col sticky-col image-field">
                                <img
                                  src={
                                    dealership.logoImage
                                      ? dealership.logoImage
                                      : imagePlaceHolder
                                  }
                                  alt="placeholder"
                                  className="dealership-logo"
                                />
                              </td>
                              <td className="table-field table-field-dealership-name second-col sticky-col">
                                <Link
                                  to={`/seller/${sellerType.type}/${dealership._id}/show`}
                                >
                                  {dealership.name}
                                </Link>
                              </td>
                              <td className="table-field table-field-stage third-col">
                                {dealership.currentStage}
                              </td>

                              {/* <td className="table-field">
                      {dealership._primaryUser.firstName}{" "}
                      {dealership._primaryUser.lastName}
                    </td> */}
                              {/* <td className="table-field table-field-location">
                              {dealership.location.city &&
                                dealership.location.city}
                            </td> */}
                              <td className="table-field table-field-cars">
                                {dealership.carsCount}
                              </td>
                              <td className="table-field table-field-tasks">
                                {dealership?.task?.name}
                              </td>
                              <td className="table-field table-field-notes">
                                {dealership?.note?.note}
                              </td>
                              <td className="table-field table-field-tags">
                                {dealership?.tags?.map((tag, i) => (
                                  <span className="tags-field" key={i}>
                                    {tag}
                                  </span>
                                ))}
                              </td>
                              <td className="table-field table-field-map nineth-col">
                                {dealership?.location?.latitude &&
                                dealership?.location?.longitude ? (
                                  <div
                                    className="map"
                                    onClick={() =>
                                      setValue({
                                        location: {
                                          lat: dealership?.location?.latitude,
                                          lng: dealership?.location?.longitude,
                                        },
                                        name: dealership?.name,
                                        _id: dealership._id,
                                        zoom: 14,
                                      })
                                    }
                                  >
                                    Map
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="table-field table-field-three-dots">
                                <div className="three-dots-wrapper flex jcc aic">
                                  <DropDownMenu
                                    menuItem={menuItems.filter(
                                      (item) => item.title !== "Edit"
                                    )}
                                    data={dealership}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        : "No Seller"}
                    </tbody>
                  </table>
                )}

                <StatusChangePopup trigger={statusPopup} title="Change Status">
                  <select
                    className="status-select"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="SUSPENDED">Suspended</option>
                    <option value="ARCHIVED">Archived</option>
                  </select>
                  <div className="status-button-wrapper">
                    <Button
                      className="btn light"
                      onClick={() => {
                        setStatusPopup(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn primary"
                      onClick={() => {
                        statusUpdateHandler()
                        setStatusPopup(false)
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </StatusChangePopup>

                {/*
              // 
                Edit Tags Modal
                //
              */}

                <Modal
                  title="Edit Tags"
                  trigger={changeTagsModal}
                  setTrigger={setChangeTagsModal}
                  onSubmit={saveTagsHandler}
                  onCancelTitle="Close"
                >
                  <div className="add-tag-wrapper flex">
                    <input
                      className="tags-input"
                      type="text"
                      placeholder="Create New"
                      value={newTag}
                      onChange={(e) => {
                        setNewTag(e.target.value)
                      }}
                    />
                    <Button
                      onClick={newTag?.length > 0 ? addTagHandler : () => {}}
                    >
                      <img src={plus} alt="" />
                    </Button>
                  </div>
                  <div className="tags-wrapper">
                    {dealershipTags?.map((tag, i) => (
                      <div className="tag-wrapper" key={i}>
                        <span className="tag">{tag}</span>
                        <span
                          className="delete-tag"
                          onClick={() => {
                            deleteTagHandler(tag)
                          }}
                        >
                          <img src={cross} alt="" />
                        </span>
                      </div>
                    ))}
                  </div>
                </Modal>
              </div>
            )}
            {sellerType.type === "dealerships" && dealershipMap && (
              <SellerMap
                sellers={allSellers}
                value={value}
                dealershipmapshow={dealershipMap && "true"}
                isMarkerShown
              />
            )}
          </div>
        ) : (
          <div className="table-wrapper w100">
            <table className="table">
              <thead>
                <tr className="table-headers">
                  <th></th>
                  <th>Name</th>
                  <th>Phone Numbers</th>
                  <th>Username</th>
                  {/* <th>Email</th> */}
                  {/* <th>Speaks</th> */}
                  {/* <th>Tags</th> */}
                  {/* <th>Status</th> */}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {sellers?.length > 0
                  ? sellers.map((seller, i) => (
                      <tr className="table-body" key={i}>
                        <td className="image-field" height="72px">
                          <img
                            src={imagePlaceHolder}
                            alt="placeholder"
                            className="dealership-logo"
                          />
                        </td>
                        <td className="table-field">
                          <Link
                            to={`/seller/${sellerType.type}/${seller._id}/show`}
                          >
                            {seller?._primaryUser?.firstName}{" "}
                            {seller?._primaryUser?.lastName}
                          </Link>
                        </td>
                        <td className="table-field">
                          {seller?._primaryUser?.contacts?.length > 0 &&
                            seller?._primaryUser?.contacts?.map((contact) => (
                              <div>
                                {`${contact.phoneNumber}(${contact?.telecom})`}
                              </div>
                            ))}
                        </td>
                        <td className="table-field">
                          <Link to={`/seller/individuals/${seller._id}/show`}>
                            {seller?._primaryUser?.username}
                          </Link>
                        </td>
                        {/* <td className="table-field">{user.email}</td> */}

                        {/* <td className="table-field"></td> */}

                        <td className="table-field table-field-three-dots">
                          <div className="three-dots-wrapper flex jcc aic">
                            <DropDownMenu menuItem={menuItems} data={seller} />
                          </div>
                        </td>
                      </tr>
                    ))
                  : "No Seller"}
              </tbody>
            </table>
          </div>
        )}
        {dealershipList && (
          <BasicPagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pages={pagesTotal}
          />
        )}

        <StatusChangePopup trigger={confirmPopup} title="Delete Dealership">
          <div className="text">Are you sure you want to delete?</div>
          <div className="text">
            Seller: {dealershipDetail.name || dealershipDetail.username}
          </div>
          <div className="status-button-wrapper">
            <Button
              className="btn light"
              onClick={() => {
                setConfirmPopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn primary"
              onClick={() => {
                dispatch(deleteSeller(sellerType.type, dealershipDetail._id))
                setConfirmPopup(false)
              }}
            >
              Delete
            </Button>
          </div>
        </StatusChangePopup>
      </div>
    </>
  )
}

export default SellerTab
