import {
  BRAND_CREATE_FAIL,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_DETAIL_FAIL,
  BRAND_DETAIL_REQUEST,
  BRAND_DETAIL_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_REVIEW_FAIL,
  BRAND_LIST_REVIEW_REQUEST,
  BRAND_LIST_REVIEW_SUCCESS,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE_FAIL,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
} from "../constants/brandConstants"
import Axios from "../../utils/axios"

export const getBrandList =
  (keyword = "", platform, isActive) =>
  async (dispatch) => {
    try {
      dispatch({
        type: BRAND_LIST_REQUEST,
      })

      const { data } = await Axios.get(
        `/v1/brands/${keyword}?platform=${platform}&isActive=${isActive}`
      )
      dispatch({
        type: BRAND_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BRAND_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getBrandListReview = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BRAND_LIST_REVIEW_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/brands/review`, config)
    dispatch({
      type: BRAND_LIST_REVIEW_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BRAND_LIST_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const SortBrandOrder = (params) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: BRAND_LIST_REQUEST,
    // })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.patch(`/v1/brands/sort`, params, config)
    dispatch({
      type: BRAND_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BRAND_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const moveBrandPostion = (params) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: BRAND_LIST_REQUEST,
    // })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await Axios.patch(`/v1/brands/`, params, config)
    dispatch({
      type: BRAND_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BRAND_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createBrand = (brand) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BRAND_CREATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.post(`/v1/brands/`, brand, config)
    dispatch({
      type: BRAND_CREATE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: BRAND_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateBrand = (id, brand) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BRAND_UPDATE_REQUEST,
    })
    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await Axios.put(`/v1/brands/${id}`, brand, config)
    dispatch({
      type: BRAND_UPDATE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: BRAND_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getBrandDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BRAND_DETAIL_REQUEST,
    })

    const {
      users: {
        userLogin: { userInfo },
      },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await Axios.get(`/v1/brands/${id}`, config)
    dispatch({
      type: BRAND_DETAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BRAND_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
