import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBrandList, getBrandListReview } from "../../actions/brandActions"

export const useBrandList = (keyword, platform, isActive) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrandList(keyword, platform, isActive))

    // eslint-disable-next-line
  }, [dispatch])

  const brandList = useSelector((state) => state.carCatalogue.brandList)
  const { loading, error, brands } = brandList

  return [loading, error, brands]
}

export const useBrandListReview = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrandListReview())

    // eslint-disable-next-line
  }, [dispatch])

  const brandListReview = useSelector(
    (state) => state.carCatalogue.brandListReview
  )
  const { loading, error, brands } = brandListReview

  return [loading, error, brands]
}
