import React, { useEffect, useState } from "react"
import "./CarDatabase.scss"
import plus from "../../images/common/plus.svg"
import MSideDrawer from "../utils/MSideDrawer"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import { CustomButton } from "../utils/Button"
import sandwich from "../../images/common/sandwich.svg"

import {
  DndContext,
  DragOverlay,
  closestCorners,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import Container from "./Container"
import { Item } from "./SortableItem"
import useYearSpecTempUpdate from "../../state/customHooks/spectemplate/useYearSpecTempUpdate"
import StatusChangePopup from "../carsmpscreens/StatusChangePopup"
import Button from "@mui/material/Button"
import Message from "../utils/Message"

export const fieldTypes = [
  { name: "Select", value: "select" },
  { name: "Number", value: "number" },
  { name: "Text", value: "text" },
  { name: "Long Text", value: "longText" },
  { name: "Multi-select", value: "multiSelect" },
  { name: "Boolean", value: "boolean" },
]

const CarDatabase = ({
  modalShow,
  setModalShow,
  activeYear,
  setActiveYear,
  setYears,
  years,
  modalShowYear,
  setModalShowYear,
  yearName,
  setYearName,
  duplicateYear,
  setDuplicateYear,
}) => {
  const [customError, setCustomError] = useState()
  const [, , successUpdate, yearUpdated] = useYearSpecTempUpdate()

  useEffect(() => {
    if (yearUpdated._id) {
      const index = years.findIndex((item) => item.id === yearUpdated._id)
      if (index !== -1) {
        const updatedItems = [...years]
        updatedItems[index] = yearUpdated
        setYears(updatedItems)
      }
    }
  }, [successUpdate])

  const [modalShowGroupDelete, setModalShowGroupDelete] = useState(false)

  const [groupName, setGroupName] = useState("")

  const [expandedGroups, setExpandedGroups] = useState([])
  const [activeGroup, setActiveGroup] = useState({})

  const [field, setField] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [index, setIndex] = useState("")
  const [activeId, setActiveId] = useState()
  const [changedId, setChangedId] = useState("")

  useEffect(() => {
    // Expand all groups on initial load
    if (activeYear.groups) {
      const allGroupNames = activeYear.groups.map((group) => group.name)
      setExpandedGroups(allGroupNames)
    }
  }, [activeYear.groups, changedId])

  const saveGroupHandler = () => {
    setActiveYear((prevYear) => ({
      ...prevYear,
      groups: prevYear.groups
        ? [
            ...prevYear.groups,
            {
              name: groupName,
              fields: [
                {
                  fieldName: "",
                  type: "text",
                  isKeyFeature: false,
                  isDependingOnTrim: false,
                },
              ],
            },
          ]
        : [
            {
              name: groupName,
              fields: [
                {
                  fieldName: "",
                  type: "text",
                  isKeyFeature: false,
                  isDependingOnTrim: false,
                },
              ],
            },
          ],
    }))
    setGroupName("")
    setModalShow(false)
  }

  const saveYearHandler = () => {
    if (duplicateYear) {
      setYears((prevYears) => {
        // Create a copy of the current years array
        const updatedYears = [...prevYears]

        // Function to find the insertion index using binary search
        const findInsertionIndex = (arr, value) => {
          let low = 0
          let high = arr.length

          while (low < high) {
            const mid = Math.floor((low + high) / 2)
            if (arr[mid].name < value) {
              low = mid + 1
            } else {
              high = mid
            }
          }
          return low
        }

        // Find the correct index to insert the new year
        const index = findInsertionIndex(updatedYears, yearName)

        // Check for duplication
        if (updatedYears[index] && updatedYears[index].name === yearName) {
          // Year already exists, return without modifying state
          setCustomError("Year Already Exist")
          return prevYears
        }

        // Insert the new year at the correct index
        const { _id, ...others } = activeYear
        updatedYears.splice(index, 0, { ...others, name: yearName })

        // Return the updated array
        return updatedYears
      })

      // Clear the yearName input
      setYearName("")
      setDuplicateYear(false)
    } else {
      setYears((prevYears) => {
        // Create a copy of the current years array
        const updatedYears = [...prevYears]

        // Function to find the insertion index using binary search
        const findInsertionIndex = (arr, value) => {
          let low = 0
          let high = arr.length

          while (low < high) {
            const mid = Math.floor((low + high) / 2)
            if (arr[mid].name < value) {
              low = mid + 1
            } else {
              high = mid
            }
          }
          return low
        }

        // Find the correct index to insert the new year
        const index = findInsertionIndex(updatedYears, yearName)

        // Check for duplication
        if (updatedYears[index] && updatedYears[index].name === yearName) {
          // Year already exists, return without modifying state
          setCustomError("Year Already Exist")
          return prevYears
        }

        // Insert the new year at the correct index
        updatedYears.splice(index, 0, { name: yearName })

        // Return the updated array
        return updatedYears
      })

      // Clear the yearName input
      setYearName("")
    }
  }

  const toggleGroup = (groupName) => {
    if (expandedGroups.includes(groupName)) {
      setExpandedGroups(expandedGroups.filter((group) => group !== groupName))
    } else {
      setExpandedGroups([...expandedGroups, groupName])
    }
  }

  const addFieldHandler = (i) => {
    setActiveYear((prevYear) => {
      const updatedGroups = [...prevYear.groups]
      const fields = updatedGroups[i].fields || []

      // Check if the last field has a name and type
      const lastField = fields[fields.length - 1]
      if (!lastField || (lastField.fieldName && lastField.type)) {
        updatedGroups[i] = {
          ...updatedGroups[i],
          fields: [
            ...fields,
            {
              fieldName: "",
              type: "",
              options: [],
              isKeyFeature: false,
              isDependingOnTrim: false,
            },
          ],
        }
      }

      return { ...prevYear, groups: updatedGroups }
    })
  }

  const updateFieldNameHandler = (i, j, updatedFieldName) => {
    setActiveYear((prevYear) => {
      const updatedGroups = [...prevYear.groups]
      updatedGroups[i] = {
        ...updatedGroups[i],
        fields: updatedGroups[i].fields.map((field, index) => {
          if (index === j) {
            return {
              ...field,
              fieldName: updatedFieldName,
            }
          }
          return field
        }),
      }
      return { ...prevYear, groups: updatedGroups }
    })
  }

  const updateTypeHandler = (i, j, updatedType) => {
    setActiveYear((prevYear) => {
      const updatedGroups = [...prevYear.groups]
      updatedGroups[i] = {
        ...updatedGroups[i],
        fields: updatedGroups[i].fields.map((field, index) => {
          if (index === j) {
            return {
              ...field,
              type: updatedType,
            }
          }
          return field
        }),
      }
      return { ...prevYear, groups: updatedGroups }
    })
  }

  const updateValueHandler = (i, j, updatedValue) => {
    setActiveYear((prevYear) => {
      const updatedGroups = [...prevYear.groups]
      updatedGroups[i] = {
        ...updatedGroups[i],
        fields: updatedGroups[i].fields.map((field, index) => {
          if (index === j) {
            return {
              ...field,
              options: updatedValue,
            }
          }
          return field
        }),
      }
      return { ...prevYear, groups: updatedGroups }
    })
  }

  const updateBolValHandler = (i, j, updatedValue) => {
    const { name, checked } = updatedValue
    setActiveYear((prevYear) => {
      const updatedGroups = [...prevYear.groups]
      updatedGroups[i] = {
        ...updatedGroups[i],
        fields: updatedGroups[i].fields.map((field, index) => {
          if (index === j) {
            return {
              ...field,
              [name]: checked,
            }
          }
          return field
        }),
      }
      return { ...prevYear, groups: updatedGroups }
    })
  }

  const updateValueHandlerArray = (i, j, field, array) => {
    updateValueHandler(i, j, [...array])
  }
  const updateFieldName = (field, i, j) => {
    updateFieldNameHandler(i, j, field)
  }

  const handleYearChange = (e) => {
    setYearName(e.target.value)
  }
  const handleGroupChange = (e) => {
    setGroupName(e.target.value)
  }

  const addYearHandler = () => {
    setModalShowYear(true)
  }

  const editGroupHandler = (i, groupName) => {
    setModalShow(true)
    setGroupName(groupName)
    setIsEdit(true)
    setIndex(i)
  }

  const editGroupName = () => {
    setActiveYear((prevYear) => ({
      ...prevYear,
      groups: prevYear.groups.map((group, i) =>
        i === index ? { ...group, name: groupName } : group
      ),
    }))
    setIsEdit(false)
  }

  const deleteGroupHandler = (i, groupName) => {
    setModalShowGroupDelete(true)
    setActiveGroup(groupName)
  }

  const removeGroupHandler = () => {
    setActiveYear((prevYear) => ({
      ...prevYear,
      groups: prevYear.groups.filter((group, i) => group.name !== activeGroup), // Filter out the group to be deleted
    }))
    setModalShowGroupDelete(false)
    setActiveGroup({})
  }

  const sensors = useSensors(useSensor(PointerSensor))

  function findContainer(id) {
    // Iterate through each group in items
    for (const group of activeYear.groups) {
      // Check if the id is found directly in the group
      for (const field of group.fields) {
        if (field.fieldName === id) {
          return group.name // Return the name of the group
        }
      }
    }
    return null // If id not found in any group, return null or any other appropriate value
  }

  function handleDragStart(event) {
    const { active } = event
    const { id } = active

    setField(
      active.data.current.sortable.items.filter(
        (obj) => obj.fieldName === id
      )[0]
    )

    setActiveId(id)
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event
    const { id } = active
    const { id: overId } = over

    // Find the containers
    const activeContainer = findContainer(id)
    const overContainer = findContainer(overId)

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return
    }

    setActiveYear((prev) => {
      const activeItems =
        prev.groups.find((group) => group.name === activeContainer)?.fields ||
        []
      const overItems =
        prev.groups.find((group) => group.name === overContainer)?.fields || []

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        (field) => field.fieldName === id
      )
      const overIndex = overItems.findIndex(
        (field) => field.fieldName === overId
      )

      let newIndex
      if (prev.groups.some((group) => group.name === overId)) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1
      } else {
        const isBelowLastItem =
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect?.offsetTop + over.rect.height

        const modifier = isBelowLastItem ? 1 : 0

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
      }

      const groups = prev.groups.map((group) => {
        if (group.name === activeContainer) {
          return {
            ...group,
            fields: group.fields.filter((field) => field.fieldName !== id),
          }
        }
        if (group.name === overContainer) {
          const newFields = [...group.fields]
          newFields.splice(newIndex, 0, activeItems[activeIndex])
          return {
            ...group,
            fields: newFields,
          }
        }
        return group
      })

      return { ...prev, groups }
    })
  }

  function handleDragEnd(event) {
    const { active, over } = event
    const { id } = active
    const { id: overId } = over

    const activeContainer = findContainer(id)
    const overContainer = findContainer(overId)

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return
    }

    const activeGroup = activeYear.groups.find(
      (group) => group.name === activeContainer
    )
    const overGroup = activeYear.groups.find(
      (group) => group.name === overContainer
    )

    const activeIndex = activeGroup.fields.findIndex(
      (field) => field.fieldName === id
    )
    const overIndex = overGroup.fields.findIndex(
      (field) => field.fieldName === overId
    )

    if (activeIndex !== overIndex) {
      const updatedItems = activeYear.groups.map((group) => {
        if (group.name === overContainer) {
          const newFields = [...group.fields]
          newFields.splice(overIndex, 0, newFields.splice(activeIndex, 1)[0])
          return { ...group, fields: newFields }
        }
        return group
      })
      setActiveYear((prev) => ({ ...prev, groups: updatedItems }))
    }

    setActiveId(null)
    setField({})
  }

  const handleIndexChange = (name, newIndex) => {
    setActiveYear((activeYearPrev) => {
      const groupIndex = activeYear.groups.findIndex(
        (group) => group.name === name
      )
      const updatedGroups = [...activeYear.groups]
      const [movedGroup] = updatedGroups.splice(groupIndex, 1)
      updatedGroups.splice(newIndex, 0, movedGroup)
      activeYearPrev.groups = updatedGroups
      return activeYearPrev
    })
    setChangedId(newIndex)
  }

  const options = activeYear?.groups?.map((_, index) => ({
    value: index + 1,
    name: index + 1,
  }))

  return (
    <div className="car-database">
      <div className="car-database-r1">
        <div className="car-database-r1-c1">
          <h2 className="text-title">Car Database</h2>
        </div>
        <div className="car-database-r1-c2">
          <div className="icon-wrapper" onClick={addYearHandler}>
            <img src={plus} alt="" className="icon" />
            <p className="link">Add Year</p>
          </div>
        </div>
      </div>
      <div className="car-database-r2">
        <div className="car-database-r2-c1">
          {years?.length
            ? years.map((year) => (
                <span
                  className={year?.name === activeYear?.name ? "link" : "dark"}
                  key={year?.name}
                  onClick={() => {
                    setActiveYear(year)
                  }}
                >
                  {year.name}
                </span>
              ))
            : null}
        </div>
      </div>
      <div className="car-database-r3">
        <div className="car-database-r3-c1">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            {activeYear?.groups?.length
              ? activeYear.groups.map((group, i) => (
                  <Container
                    id={group.name}
                    items={group.fields}
                    key={group.name}
                    group={group}
                    addFieldHandler={addFieldHandler}
                    i={i}
                    editGroupHandler={editGroupHandler}
                    deleteGroupHandler={deleteGroupHandler}
                    expandedGroups={expandedGroups}
                    toggleGroup={toggleGroup}
                    updateFieldNameHandler={updateFieldName}
                    updateTypeHandler={updateTypeHandler}
                    updateBolValHandler={updateBolValHandler}
                    updateValueHandlerArray={updateValueHandlerArray}
                    handleIndexChange={handleIndexChange}
                    options={options}
                  />
                ))
              : null}
            <DragOverlay>
              {activeId ? (
                <div className="sortable-item-overlay">
                  <img src={sandwich} alt="sandwich" className="icon" />
                  <Item id={activeId} field={field} />
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </div>

      <MSideDrawer
        show={modalShow}
        toggleDrawer={setModalShow}
        title="Add/Edit Group"
        onClose={() => {
          setGroupName("")
        }}
      >
        <div className="">
          <FormControl fullWidth>
            <label id="demo-simple-select-label" className="pb-18">
              Name
            </label>
            <TextField
              placeholder="Name"
              name="name"
              value={groupName}
              onChange={handleGroupChange}
              autoFocus={true}
            />
          </FormControl>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton
              submitHandler={isEdit ? editGroupName : saveGroupHandler}
              label={"SAVE"}
            />
          </div>
        </div>
      </MSideDrawer>
      <MSideDrawer
        show={modalShowYear}
        toggleDrawer={setModalShowYear}
        onClose={() => {
          setYearName("")
        }}
      >
        <div className="">
          <FormControl fullWidth>
            <label id="demo-simple-select-label" className="pb-18">
              Name
            </label>
            <TextField
              placeholder="Name"
              name="name"
              value={yearName}
              onChange={handleYearChange}
              autoFocus={true}
            />
          </FormControl>
          <Message
            variant="error"
            width="200px"
            error={customError}
            setError={setCustomError}
          />

          <div
            style={{
              marginTop: 40,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton submitHandler={saveYearHandler} label={"SAVE"} />
          </div>
        </div>
      </MSideDrawer>
      <StatusChangePopup trigger={modalShowGroupDelete} title="Delete Group">
        <div className="text">Are you sure you want to delete?</div>
        <div className="status-button-wrapper">
          <Button
            className="btn light"
            onClick={() => {
              setModalShowGroupDelete(false)
            }}
          >
            Cancel
          </Button>
          <Button className="btn primary" onClick={removeGroupHandler}>
            Delete
          </Button>
        </div>
      </StatusChangePopup>
    </div>
  )
}

export default CarDatabase
