import React, { useEffect, useState } from "react"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import "./LinkCreateEditScreen.scss"
import { CircularProgress } from "@mui/material"
import useLinkCreate from "../../state/customHooks/link/useLinkCreate"
import TextInput from "../../components/input/TextInput"
import ImageInput from "../../components/input/ImageInput"
import { useDispatch } from "react-redux"
import { createLink, updateLink } from "../../state/actions/linkAction"
import useLinkDetail from "../../state/customHooks/link/useLinkDetail"
import { useParams } from "react-router-dom"
import useLinkUpdate from "../../state/customHooks/link/useLinkUpdate"

const LinkCreateEditScreen = () => {
  const dispatch = useDispatch()

  const [link, setLink] = useState({})

  const { id } = useParams()

  const [response, setResponse] = useState("")

  const [loadingLinkCreate, , successCreate, linkCreate] = useLinkCreate()

  const [loadingLinkDetail, , linkDetail] = useLinkDetail(id)

  const [loadingLinkUpdate, , successUpdate, linkUpdate] = useLinkUpdate()

  const linkUpdateHandler = (e) => {
    setLink((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const updateImage = (image) => {
    setLink((prev) => {
      return {
        ...prev,
        image: image,
      }
    })
  }

  const createUpdateHandler = () => {
    if (id) {
      dispatch(updateLink(id, link))
    } else {
      dispatch(createLink(link))
    }
  }

  useEffect(() => {
    if (!loadingLinkDetail && id) {
      setLink(linkDetail)
    }
    if (successCreate) {
      setResponse(linkCreate)
    }
    if (successUpdate) {
      setResponse(linkUpdate)
      setLink(linkUpdate)
    }
  }, [successCreate, loadingLinkDetail])

  return (
    <div className="link-create-screen">
      <InnerHeader title="Link Generate" className="inner-header">
        <div className="buttons-wrapper">
          <div className="btn-save" onClick={createUpdateHandler}>
            {loadingLinkCreate || loadingLinkUpdate ? (
              <CircularProgress color="inherit" size={15} />
            ) : id ? (
              `Update`
            ) : (
              `Create`
            )}
          </div>
        </div>
      </InnerHeader>
      <InnerContainer>
        <form onChange={linkUpdateHandler}>
          <div className="section grid-4">
            <TextInput name="name" label="Link name" value={link?.name} />
          </div>
          <div className="section grid-4"></div>
          <div className="section">
            <p className="section-title">Link Detail</p>
            <div className="section grid-4">
              <TextInput name="url" label="Redirect URL" value={link?.url} />
              <TextInput name="title" label="Title" value={link?.title} />
              <TextInput
                name="description"
                label="Description"
                value={link?.description}
              />
            </div>
            <div className="section grid-4">
              <ImageInput
                image={link?.image}
                setImage={updateImage}
                label={"SEO Image"}
              />
            </div>
          </div>
        </form>

        {response && (
          <div>
            <p>Link: /redirect?to={response.slug}</p>
          </div>
        )}
      </InnerContainer>
    </div>
  )
}

export default LinkCreateEditScreen
