import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import RecentActivity from "../../components/dashboard/RecentActivity"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import { getSellerLastActivityAnalytics } from "../../state/actions/sellerAnalyticsActions"
import "./DashboardScreen.scss"
import SmsCredit from "../../components/dashboard/SmsCredit"
import UpcomingAuction from "../../components/dashboard/UpcomingAuction"
import { Link } from "react-router-dom"

const DashboardScreen = () => {
  const [activeTab, setActiveTab] = useState("dealerships")
  const [duration, setDuration] = useState(1)

  const dispatch = useDispatch()

  const sellerLastActivity = useSelector(
    (state) => state.analytics.sellerLastActivity
  )
  const { sellers } = sellerLastActivity

  useEffect(() => {
    dispatch(getSellerLastActivityAnalytics("dealerships", duration))
  }, [dispatch, duration, activeTab])

  return (
    <div className="dashboard-screen">
      <InnerHeader
        title="Dealerships / Individual Sellers"
        className="inner-header"
      >
        <div className="buttons-wrapper">
          <Button className="btn-blue">
            <Link to="/link/create" className="link-w">
              {" "}
              Generate Link
            </Link>
          </Button>
          <Button className="btn-blue">
            <a
              href="https://analytics.google.com/analytics/web/?authuser=2#/report-home/a230557000w317905363p268326041"
              target="_blank"
              rel="noreferrer"
              className="link-btn"
            >
              Google Analytics
            </a>
          </Button>
        </div>
      </InnerHeader>
      <InnerContainer>
        <div className="flex">
          <RecentActivity
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            data={sellers}
            duration={duration}
            setDuration={setDuration}
          />
          <div className="smsAuction">
            <SmsCredit />
            <div className="verticalGap">
              <UpcomingAuction />
            </div>
          </div>
        </div>
      </InnerContainer>
    </div>
  )
}

export default DashboardScreen
