import { useDispatch, useSelector } from "react-redux"
import { getLogsList, getLogsListCars } from "../../actions/logsAction"
import { useEffect, useState } from "react"

export const useLogListGroupByCars = () => {
  const dispatch = useDispatch()

  const logList = useSelector((state) => state.log.logListGroupByCars)
  const { logs, loading } = logList

  useEffect(() => {
    dispatch(getLogsListCars())
    // eslint-disable-next-line
  }, [dispatch])

  return [logs, loading]
}

export const useLogList = (queryObject) => {
  const dispatch = useDispatch()

  const [timeOutId, setTimeOutId] = useState(null)

  useEffect(() => {
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    setTimeOutId(
      setTimeout(
        () => {
          dispatch(getLogsList(queryObject))
        },
        queryObject.keyword ? 500 : 0
      )
    )

    // eslint-disable-next-line
  }, [dispatch, queryObject])

  useEffect(() => {
    // eslint-disable-next-line
  }, [dispatch, queryObject])

  const logList = useSelector((state) => state.log.logList)
  const { loading, error, page, pages, count, logs } = logList

  return [loading, error, page, pages, count, logs]
}
