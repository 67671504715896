import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getYearSpecTemplateList } from "../../actions/carDbTemplateActions"

const useYearSpecTempList = (status) => {
  const dispatch = useDispatch()

  const yearSpecTemplateList = useSelector(
    (state) => state.carDbTemplate.yearSpecTemplateList
  )
  const { loading, error, years } = yearSpecTemplateList

  useEffect(() => {
    dispatch(getYearSpecTemplateList())
    // eslint-disable-next-line
  }, [dispatch, status])

  return [loading, error, years]
}

export default useYearSpecTempList
