import React, { useEffect, useState } from "react"
import "./LoginScreen.scss"
import caramteLogo from "../../images/loginscreen/carmate-logo.svg"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { login } from "../../state/actions/userActions"
import Message from "../../components/utils/Message"

const LoginScreen = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const userLogin = useSelector((state) => state.users.userLogin)

  const { loading, error, userInfo } = userLogin

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(username, password))
  }

  useEffect(() => {
    if (!loading && userInfo) {
      navigate("/dashboard")
    }
  }, [userInfo, loading, navigate])

  return (
    <>
      <div className="login-screen">
        <div className="wrapper flex">
          <div className="c1">
            <div className="section-wrapper">
              <img src={caramteLogo} alt="Logo" className="logo" />
              <h1 className="large-text">Welcome</h1>
              <form onSubmit={submitHandler}>
                {/* Input Wrapper Username Password */}
                <div className="input-wrapper flex col">
                  <label className="label">Username</label>
                  <input
                    name="email"
                    className="input"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value)
                    }}
                  />
                </div>
                <div className="input-wrapper flex col">
                  <label className="label">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="input"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>
                {error && <Message variant="error">{error}</Message>}
                {/* Submit Button */}
                <button type="submit" className="button">
                  Login
                </button>
              </form>
            </div>
          </div>
          <div className="c2"></div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
