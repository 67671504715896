import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import logo from '../../images/header/logo.svg'

import DropDownMenu from '../selection/Dropdown'

import './Header.scss'

const Header = ({ drawerClick }) => {
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.users.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    !userInfo && navigate('/login')
  }, [userInfo, navigate])

  return (
    <>
      <header className="flex jcsb aic header">
        <div className="flex aic">
          <div className="sidedrawer-toggle-button" onClick={drawerClick} />
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="dropdown-wrapper">
          <DropDownMenu user={userInfo} />
        </div>
      </header>
    </>
  )
}

export default Header
